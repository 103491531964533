import { Text, View , StyleSheet,ScrollView,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import { Input,useToaster, Message, Button ,DatePicker ,SelectPicker,IconButton ,Modal ,Checkbox, List, Drawer, MaskedInput, Stack,Panel, Row, Grid, Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faMoneyBill, faPhone, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';

const TrafficInsurenceScreen = (props) => {
  const toaster = useToaster();
  function messageToggle(input,type){
      toaster.push(
          <Message showIcon type={type}>
            {input}
          </Message>, { placement: 'topEnd' })
  }
    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});
    const [s3KEYS , setS3Keys] = useState(getS3Keys());
    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();

    async function getComponents(){
      console.log("TRAFFIC INSURENCE GETREQUEST");
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
     }
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
            "type" : "GET_INSURANCES",
            "ID" : props.data["NO"],
            "category" : "TRAFFIC_INSURANCE"
        },{headers}).then((response)=>{
            console.log(response.data);
            if(response.data.length > 0){
              setCurrentActive(response.data);
            }
         
            setData(response.data);
        }).catch((error)=> {
            console.log(error);
        })
    }

    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          setCurrent(input[i]);
          //console.log(input[i]["S3_KEY"]);
          //fileViewRef.current.set(input[i]["S3_KEY"]);
          break;
        }
      }

        // input.forEach(element => {
        //   if(element["IS_ACTIVE"] == 1){
        //     setCurrent(element);
        //   }
        // })
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }
    const[viewData,setViewData] = useState({});
      
    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function getS3Keys(){
      // if(JSON.parse(props.data["S3 KEYS T_INSURENCES"]).data != null){
      //   return JSON.parse(props.data["S3 KEYS T_INSURENCES"]).data[0];
      // }
      return [];
    }

    const fileViewRef = useRef();

    const FileViewModal = () => {
      return(
        <View>
           <Modal size={'full'} backdrop={'static'} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                    <Modal.Header>
                    <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"TRAFİK SİGORTASI"} </Text>
                      </View>} 
                    </Modal.Title>
                    </Modal.Header>
  
                    <Modal.Body>
                      <ScrollView>
                        <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                      </ScrollView>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
        </View>
      )
    }
    const ref =useRef();

    const tableHeads= ["POLİÇE NO","BAŞLANGIÇ TARİHİ","BİTİŞ TARİHİ","TUTAR","SİGORTA ŞİRKETİ","ACENTE","ACİL YOL YARDIM NO"]
    const listHeads= ["POLİÇE NO","BİTİŞ TARİHİ","ACİL YOL YARDIM NO"]
    const labelHeads= ["POLİÇE NO","BAŞLANGIÇ TARİHİ","BİTİŞ TARİHİ","TUTAR","SİGORTA ŞİRKETİ","ACENTE","ACİL YOL YARDIM NO","NOTLAR"]

    const [headers,setHeaders] = useState([
      {"header" : "Poliçe No" , "data" : current["POLİÇE NO"] , "icon" :faSquare },
      {"header" : "Başlangıç Tarihi" , "data" : current["BAŞLANGIÇ TARİHİ"] , "icon" :faCalendar },
      {"header" : "Bitiş Tarihi" , "data" : current["BİTİŞ TARİHİ"] , "icon" :faCalendar },
      {"header" : "Tutar" , "data" : current["TUTAR"] , "icon" :faMoneyBill },
      {"header" : "Sigorta Şirketi" , "data" : current["SİGORTA ŞİRKETİ"] , "icon" :faBuilding },
      {"header" : "Acente" , "data" : current["ACENTE"] , "icon" :faBuilding },
      {"header" : "Acil Yardım Numarası" , "data" : current["ACİL YOL YARDIM NO"] , "icon" :faPhone },
      // {"header" : "Notlar" , "data" : current["NOTLAR"] , "icon" :faSquare },
    ])

    useEffect(() => {
      setHeaders([
        {"header" : "Poliçe No" , "data" : current["POLİÇE NO"] , "icon" :faSquare },
        {"header" : "Başlangıç Tarihi" , "data" : current["BAŞLANGIÇ TARİHİ"] , "icon" :faCalendar },
        {"header" : "Bitiş Tarihi" , "data" : current["BİTİŞ TARİHİ"] , "icon" :faCalendar },
        {"header" : "Tutar" , "data" : current["TUTAR"] , "icon" :faMoneyBill },
        {"header" : "Sigorta Şirketi" , "data" : current["SİGORTA ŞİRKETİ"] , "icon" :faBuilding },
        {"header" : "Acente" , "data" : current["ACENTE"] , "icon" :faBuilding },
        {"header" : "Acil Yardım Numarası" , "data" : current["ACİL YOL YARDIM NO"] , "icon" :faPhone },
        // {"header" : "Notlar" , "data" : current["NOTLAR"] , "icon" :faSquare },
      ]);
    },[current])


    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }

    return(
      <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding: 20}}>
        
            <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'}  backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                  <Drawer.Header>
                  <Drawer.Title> 
                    {<View>
                      <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                      <Text> {"TRAFİK SİGORTASI"} </Text>
                    </View>} 
                  </Drawer.Title>
                  </Drawer.Header>
                  <Drawer.Body>
                      {modalType == "New" ? (<NewTrafficInsurencePanel 
                      type={"NEW"}
                      cardata = {props.data} 
                      data = {editData}
                      reset = {() => {getComponents(); handleModalClose();}}></NewTrafficInsurencePanel>) 
                      :
                      ( <NewTrafficInsurencePanel 
                        type={"EDIT"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); handleModalClose();}} ></NewTrafficInsurencePanel> )}
                      
                  </Drawer.Body>
                  {/* <Modal.Footer>
                      <Button onClick={handleModalClose} appearance="subtle">
                          Cancel
                      </Button>
                  </Modal.Footer> */}
            </Drawer>
            {/* <ShowCurrentPanel  data = {current} headers={labelHeads} ></ShowCurrentPanel> */}
            <Grid fluid style={{width: '100%'}}>
              <Row gutter={20}>
               <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                  <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200,marginBottom: 20}} 
                header  = {
                  <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                    <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                      <Stack.Item grow={1}>
                      <IconButton 
                        style={{width: '100%', height: 35}}
                        onClick={() => {
                          setModal(true);
                          setModalType("New");}}
                          icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>}
                          >
                          Yeni Trafik Sigortası
                      </IconButton>
                      </Stack.Item>
                      <ExportData data = {data} tableHead = {tableHeads} tableName={"Trafik_Poliçeleri_" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                    </Stack> 


                </View>
                }>
                      <CustomText fontWeight= {800} fontSize={14} > {"Trafik Sigortaları" } </CustomText>
                      <ComponentListView hasDoc = {true} current={current} showDetails={showDetailsOfChoosen} chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit}  tableName = {"Trafik_Poliçeleri_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads} listHeads={listHeads}  data={data}></ComponentListView>
                  </Panel>
               </Col>
                <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                <Panel shaded style={{backgroundColor: 'white', height: 800 , minWidth: 200}} header  = {<CustomText fontWeight={800} > {"Trafik Sigorta Detayları -- " + props.data["PLAKA"] } </CustomText>}>
                <List>
                  {headers.map((item) =>
                    {
                    return(
                    <List.Item key={item} index={item}>
                      <CustomListItem item={item} ></CustomListItem>
                    </List.Item>)
                    }
                    )}
                </List>
                <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', padding: 10}}>
                  <CustomText fontWeight={800} > {"Notlar"} </CustomText>
                  <CustomText> {current["NOTLAR"]} </CustomText>
                </View>
                </Panel>
                </Col>
              </Row>
            </Grid>
            <FileViewModal></FileViewModal>

        </View>
    )
}


const NewTrafficInsurencePanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    const [activateAddButton,setActiveAddButton] = useState(true);

    // COMBO BOX ARRAYS
    const [InsurenceFirmsAll,setInsurenceFirmsAll] = useState([]);
    const [AgentFirmsAll,setAgentFirmsAll] = useState([]);

    // SENT VALUES
    const [documentNO,setdocumentNO] = useState("");
    const [agentFirm,setAgentFirm] = useState(0);
    const [insurenceFirm,setInsurenceFirm] = useState(0);
    const [emergencyHelpNo,setEmergencyHelpNo] = useState("");
    const [amount,setAmount] = useState(0);
    const [StartDate,setStartDate] = useState(new Date());
    const [EndDATE,setEndDATE] = useState(new Date());
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();

    useEffect(() => {
      if(S3Key != undefined){
        console.log(S3Key);
        sendData();
       
      }
    },[S3Key])
    useEffect(()=> {console.log(agentFirm)},[agentFirm])
    useEffect(()=> {console.log(insurenceFirm)},[insurenceFirm])
    function reset(){
        setdocumentNO("");
        setAgentFirm(0);
        setInsurenceFirm(0);
        setEmergencyHelpNo("");
        setAmount(0);
        setStartDate("");
        setEndDATE("");
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
      setdocumentNO(props.data["POLİÇE NO"]);
      setAgentFirm(props.data["AGENT_FIRM_ID"]);
      setInsurenceFirm(props.data["INSURENCE_FIRM_ID"]);
      setEmergencyHelpNo(props.data["ACİL YOL YARDIM NO"]);
      setAmount(props.data["TUTAR"]);
      setStartDate(new Date(props.data["BAŞLANGIÇ TARİHİ"]));
      setEndDATE(new Date(props.data["BİTİŞ TARİHİ"]));
      setNote(props.data["NOTLAR"]);
      setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function getDropDownValues(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }
    function sendAndLoad(){
      fileUploaderRef.current.load();
   }

   const {fetchIdToken} = useUserToken();
   async function sendData(){
    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`  
      
    }
      let unformatAmount = amount;
      if(amount.replace != undefined){
        unformatAmount = Number(amount.replace("₺ ","").replace(".","").replace(",","."));
      }

      const api = configdata.API_BASE + '/cardata';
      axios.post(api,
        {
          "ENV" :configdata.APP_ENV ,  
            "type": props.type == 'EDIT' ? "UPDATE_INSURANCE" : "NEW_INSURANCE_DOC",
            "category" : "TRAFFIC_INSURANCE",
            "DOCUMENT_NO" : documentNO,
            "START_DATE": StartDate,
            "END_DATE" : EndDATE,
            "AMOUNT" : unformatAmount,
            "INSURENCE_FIRM_ID": insurenceFirm,
            "AGENT_FIRM_ID" :agentFirm,
            "EMERGENCY_HELP_NO":emergencyHelpNo,
            "NOTES" :note,
            "CAR_ID" : props.cardata["NO"],
            "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
            "ID" : props.data["NO"] != undefined ? props.data["NO"] : 0,
        },{headers}
      ).then((response) => {
        console.log(response);
        if(response.data["errorMessage"] != null){
            messageToggle("Trafilk Poliçe Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
        }else
        {
          if(response.data.split("_")[0] == "SUCCESS"){
            messageToggle("Trafilk Poliçe Bilgileri Başarıyla Eklendi", "success")
            fileUploaderRef.current.load(response.data.split("_")[1]);
            // props.reset();
          }
          else{
            messageToggle("Trafilk Poliçe Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
          }
        }
        
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("KASKO Poliçe Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }
    useEffect(() => {
      
     if(props.type == "EDIT"){getEditData()}
     getDropDownValues("INSURENCE_FIRM",setInsurenceFirmsAll);
     getDropDownValues("AGENT_FIRM",setAgentFirmsAll);

    },[])


    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
    
    return(
          <View style= {{flexDirection: 'column',width: '100%'}}>
                <View style={styles.form}>
                  <Group header={"Poliçe No"}>
                    <Input value={documentNO} style={styles.standard} onChange={ (value) => {setdocumentNO(value);}} name= {"Poliçe No"} /> 
                  </Group>
                  
                  <Group header={"Sigorta Firması"}>
                    <SelectPicker value={insurenceFirm} style={styles.standard}  onSelect={(value) => { setInsurenceFirm(value)}} data = {InsurenceFirmsAll} ></SelectPicker>
                  </Group>

                  <Group header={"Acil Yol Yardım No"}>
                    <Input value={emergencyHelpNo} style={styles.standard}  onChange={ (value) => {setEmergencyHelpNo(value);}} name= {"acil yol yardım no"} /> 
                  </Group>
            
                  <Group header={"Başlangıç Tarihi"}>
                      <DatePicker value={StartDate} style={styles.standard} isoWeek = {true} onSelect={(value) => {setStartDate(value);}} oneTap format="dd-MM-yyyy" locale={locale} ></DatePicker>
                  </Group>

                  <Group header={"Bitiş Tarihi"}>
                    <DatePicker value={EndDATE} style={styles.standard} isoWeek = {true} onSelect={(value) =>  {setEndDATE(value);}} oneTap format="dd-MM-yyyy" locale={locale} ></DatePicker>
                  </Group>

                  <Group header={"Tutar"}>
                      <MaskedInput mask={numberMaskTL} value={amount} style={styles.standard} min={0} onChange={ (value) => {setAmount(value);}} name= {"tutar"}  />
                  </Group>

                  <Group header={"Acente"}> 
                    <SelectPicker value={agentFirm} style={styles.standard} onSelect={(value) => { setAgentFirm(value)}} data = {AgentFirmsAll} ></SelectPicker>
                  </Group>
                  {
                    props.type == "EDIT" && 
                    <Group header={"Durum"}>
                    <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                  </Group>
                  }
                  <Group header={"Notlar"}>
                    <Input value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}  style={styles.standard} /> 
                  </Group>
                  <Group header={"Döküman"}>
                    <FileLoader reset={props.reset} ref = {fileUploaderRef} fileCategory = {"TRAFFIC_INSURANCE"} carID = {props.cardata["NO"]} ></FileLoader>
                  </Group>

              </View>
              <View style={{height: 50}}></View>
              <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > <CustomText fontWeight = {800}  fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
            {/* <StandardButton color= '#F20F38' iconName = {"close-circle-outline"} buttonText = {"Cancel"}  onPress = {props.togglePanel}></StandardButton> */}
          </View>
    )
}

const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight : 5}}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 350, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});


const numberMaskTL = createNumberMask({
  mask: Number,
  prefix: '₺ ',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
})



export default TrafficInsurenceScreen;