import { Text, View, StyleSheet, TouchableOpacity,FlatList} from 'react-native'
import React, {  useState ,useEffect,useRef } from 'react'
import FileLoader from '../FileManagements/FileLoader';
import FileViewer from '../FileManagements/FileViewer';
import {useToaster, Message, Button,Modal ,Form ,DatePicker,TreePicker ,SelectPicker ,InputNumber, IconButton  } from 'rsuite';
import ShowCurrentPanel from '../CarAndFileManagement/ShowSpecsGrid';
import axios from 'axios';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
const CarCard  = (props) => {

    const[car,setCar] = useState({});
    const [refresh,setRefresh] = useState(false);

    function reset(){
      setRefresh(!refresh);
    }
    
    const {fetchIdToken} = useUserToken();
    async function getCar(carID){

        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
          "Authorization" : `Bearer ${token}`,
        }
        const header = {
          "ENV" :configdata.APP_ENV ,
            "type" : "GET_CAR_W_ID",
            "ID" : carID
        }
        axios.post(api,header,{headers}).then((response) => {
            console.log(response.data)
            setCar(response.data[0])
        }).catch((error) => {
            console.log(error)
        })

    }

    useEffect(()=>{
        getCar(props.carID);
    },[])
    
    const [generalHeaders,setGeneralHeader] = useState(["PLAKA", "ARAÇ" , "RUHSAT SAHİBİ" , "MARKA", "MODEL", "VİTES" , "RENK","MODEL YILI"])
    const CarInfoRender = () => {
      return (<GeneralCarInfo reset={reset} headers= {generalHeaders} data={car}  ></GeneralCarInfo>)
  }
    return (
      <View>
        <CarInfoRender></CarInfoRender>
      </View>
    )
  
}

const GeneralCarInfo = (props) => {

    const [modalOpen,setModalOpen] = useState(false);
    const [s3Keys,setS3Keys] = useState([]);
    const [filesRuhsat,setFilesRuhsat] = useState([]);
    const [refresh,setRefresh] = useState(false);
    function handleCloseModal(){
      setModalOpen(false);
    }
    // const GridItem = ({item}) => {
    //   return(
    
    //     <View style = {{ padding: 10,flexDirection :'column', justifyContent: 'space-evenly', backgroundColor: 'white', borderRadius: 7,height: 150}}>
    //       <View style={{ flexDirection:'row', alignItems: 'flex-start' , justifyContent: 'center', height: '50%'}}>
    //         <Text style = {{fontWeight: 'bold', textAlign: 'center',color: '#B4B4B4' }}>
    //           {item}
    //         </Text>
    //         <IconButton icon={<Icon><FontAwesomeIcon icon={solid('copy')} /></Icon>}  onClick = {copyToClipboard(item)}>
    //         </IconButton>
    //        </View>
          
    //         <View style={{alignItems: 'center' , justifyContent: 'flex-start', height: '50%'}}>
    //         <ScrollView>
    //           <Text style = {{ textAlign:'center'}} >
    //           { (item.includes("TARİH")) ? reverseDate(props.data[item]) : props.data[item] 
    //           && (item.includes("ARALIĞI") && !item.includes("BAKIM")) ? props.data[item] + " AY" : props.data[item] 
    //           && (item.includes("BAKIM ARALIĞI")) ? props.data[item] + " KM" : props.data[item] 
    //           && item.includes("TUTAR") ? fomatAmount(props.data[item]): props.data[item]}
    //           </Text>
    //         </ScrollView>
    //         </View>
          
          
           
            
    //     </View>
    //   )
  
    // }
  
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
  
    const FileViewModal = () => {
      return(
        <View>
           <Modal overflow={true} size={'full'} backdrop={true} keyboard={false} open={modalOpen} onClose={handleCloseModal}>
                    <Modal.Header>
                      <Modal.Title> {props.data["ARAÇ"]} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FileViewer inline={true} s3KEY = {s3Keys}></FileViewer>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={handleCloseModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer>
                  </Modal>
        </View>
      )
    }
    const {fetchIdToken} = useUserToken();
    async function getS3Keys(){
      const token = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${token}`,
      }
      axios.post(api,{
        "ENV" :configdata.APP_ENV ,
        "type" : "GET_S3_DATA",
        "CAR_ID" : props.data["NO"]
      },headers).then((response) => {
        // console.log(response);
        if(response != null){
          fViewRef.current.set(parseS3(response));
          setS3Keys(parseS3(response));
        }
          // return parseS3(response);
      }).catch((error) => {
        console.log(error);
      });
    }
  
    function getS3list(){
      const token = fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${token}`
      }
      
      axios.post(api,{
        "ENV" :configdata.APP_ENV ,
        "type" : "GET_S3_DATA",
        "CAR_ID" : props.data["NO"]
      },{headers}).then((response) => {
        console.log(response);
        var filesR = []
        if(response != null){
          if(response.data != null || response.data != undefined){
            //  console.log(response.data[0]["S3 KEY LICENCES"]);
            var dataS3 = JSON.parse(response.data[0]["S3 KEY LICENCES"])["data"]
            // console.log(dataS3)
            if( dataS3 != null){
               dataS3.forEach(element => {
                filesR.push(element.S3_KEY)
               })
  
               setFilesRuhsat(filesR);
            // console.log(dataS3[0].S3_KEY)
            
            }
          }
        }
          // return parseS3(response);
      }).catch((error) => {
        console.log(error);
      });
    }
  
    useEffect(()=> console.log(filesRuhsat),[filesRuhsat])
  
    function parseS3(response){
      //  console.log(response.data);
       var filesR = [];
      if(response.data != null || response.data != undefined){
        //  console.log(response.data[0]["S3 KEY LICENCES"]);
        var dataS3 = JSON.parse(response.data[0]["S3 KEY LICENCES"])["data"]
        // console.log(dataS3)
        if( dataS3 != null){
          dataS3.sort(function(a, b) {
            return parseFloat(b.ID) - parseFloat(a.ID);
        });
        // console.log(dataS3[0].S3_KEY)
          return dataS3[0].S3_KEY;
        }
        return null;
      }
      return null;
    }
  
  useEffect(()=> {
    getS3Keys();
    getS3list();
  },[])
  useEffect(()=> {
    // console.log(s3Keys);
  },[s3Keys])
  
  function modalViewOpen(){
  
    // console.log(s3Keys);
   
    if(s3Keys != null) {
      setModalOpen(true); 
      // setS3Keys(getS3Keys());
    }
    else{
      messageToggle("Ruhsat Dökümanı bulunamadı", "error")
    } 
    
  }
  
    function reset(){
        props.reset();
        setRefresh(!refresh);
        // setS3Keys(getS3Keys());
        fViewRef.current.refresh();
    }
  
    const FileListItem = ({item}) => {
       return(
        <View>
            <Text> {item} </Text>
            <IconButton icon={<Icon><FontAwesomeIcon icon={regular('file')} /></Icon>} 
                onClick={
                () => {
                  setS3Keys(item);
                }
              }
              />
        </View>
       )
    }
  
    const fViewRef = useRef();
    return(
      
        <View style= {{flex: 1, width: '100%'}}>
             <View style={{marginVertical: 10}}>
             {/* <FlatGrid    
              data={props.headers}
              renderItem = {({item}) => (<GridItem item= {item}></GridItem>)}
              keyExtractor = {data => data}
            /> */}
             <ShowCurrentPanel  data = {props.data} headers={props.headers} ></ShowCurrentPanel>
            <View>
              <View style={{flexDirection: 'row', alignItems: 'center'}}>
              <View style= {{height: 2, backgroundColor: "#B2B3B3", width: "40%"}}></View>
              <View style= {{ width: "20%"}}>
                <Text style={{ fontSize: 18, color: '#B2B3B3', textAlign: 'center'}}> DOSYALAR </Text>
              </View>
              <View style= {{height: 2, backgroundColor: "#B2B3B3", width: "40%"}}></View>
              </View>
              <View style={{flexDirection : 'row' , alignItems : 'center'}}>
                <Text style={{marginHorizontal : 10 }} > Ruhsat Yükle </Text>
                <FileLoader carID ={props.data["NO"]} fileCategory ={"CAR_LICENCE"} reset = {reset}></FileLoader>
                <IconButton icon={<Icon><FontAwesomeIcon icon={solid('maximize')} /></Icon>} onClick={modalViewOpen}/>
                {/* <FlatList
                  data ={filesRuhsat}
                  renderItem = {({item}) => (<FileListItem item={item} ></FileListItem> )}
                  keyExtractor = {data => data}
                  extraData = {filesRuhsat}
                >
                </FlatList> */}
              
                {/* <FolderManagementSystem></FolderManagementSystem> */}
                {/* <FileViewer s3KEY = {props.data["S3 KEY"]}></FileViewer> */}
              </View>
              {/* <Button style={{width : 120}}  onClick={reset}> Görüntüle </Button> */}
             <FileViewer inline={false} ref={fViewRef} s3KEY = {s3Keys}></FileViewer>
            </View>
            <FileViewModal></FileViewModal>
          </View>
        </View>
   
    )
  }

export default CarCard;