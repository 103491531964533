import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator, Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef, useCallback } from 'react'
import axios from 'axios';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { Stack,Modal, Button, Form ,Nav,TreePicker ,SelectPicker , MultiCascader,Input,Pagination,IconButton,List, Table ,CheckTreePicker,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider, Panel,Drawer, MaskedInput, InputGroup} from 'rsuite';
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import ResponsiveNav from '@rsuite/responsive-nav';
import configdata from  "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';
import { useMessage } from '../../hooks/useMessage';
import { Tabs } from 'react-bootstrap';
import SalesVisitorInfo from './SalesVisitorInfo';
import usePagination from '../../hooks/usePagination';
import PhoneInput from 'react-phone-input-2'
import useTable from '../../hooks/useTable';
import ExportData from '../../Components/TableManagement/ExportData';


const SalesCarsOffers = () => 
{

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect (() => {
        console.log("SalesVisitorCars");
        getSalesVisitorCars()
    },[]);

    const {fetchIdToken,fetchUserID} = useUserToken();
    async function getSalesVisitorCars()
    {
        setLoading(true);
        const api = configdata.API_BASE + '/sales-visitors';
        let token = await fetchIdToken();
        const headers = {
            'Authorization': `Bearer ${token}`
        }

        axios.post(api,{
            "type" : "GET_ALL_CARS_WITH_VISITOR_INFO",
        },{headers: headers}).then((response) => {
            console.log(response);
            setData(response.data);
            // setFilteredData(response.data);
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        }
        );

        setLoading(false);

    }

    useEffect(() => {
        setFilteredData(data);
    },[data]);

    // add 3 hours to the date and format it
    const FormattedDateCell = ({ rowData, dataKey, ...props }) => {
        const date = rowData[dataKey];
        const formattedDate = new Date(date);
        formattedDate.setHours(formattedDate.getHours() + 3);
        return <Table.Cell {...props}>{formattedDate.toLocaleString()}</Table.Cell>;
      }

    const [filteredData , setFilteredData] = useState(data);

    // filter by each ref and get intersection of them
    function filter(){
        console.log("filtering");
        let temps = [];
        if(aracInfoRef.current.value() != null){
            let temp = [data];
            temp = filterByColumn("CAR_NAME",aracInfoRef.current.value());
            temps.push(temp);
        }
        else {
            temps.push(data);
        }

        if (dateRef.current.value() != null){
            let temp = [data];
            temp = filterDate(dateRef.current.value());
            temps.push(temp);
        }else {
            temps.push(data);
        }

        if(stsRef.current.value() != null){
            let temp = [data];
            temp = filterByColumn("STS_DNS",stsRef.current.value());
            temps.push(temp);
        }else {
            temps.push(data);
        }


        if(temps.length > 0){
            let temp = intersection(temps);
            setFilteredData(temp);
        }
    }

    function intersection(arrays) {
        return arrays.reduce((acc, array) => acc.filter(Set.prototype.has, new Set(array)));
    }

    
    function filterByColumn (column, value) {
        console.log("filtering by column");
        console.log(column);
        console.log(value);
        let temp = data.filter((item) => {
            return item[column].toLowerCase().includes(value.toLowerCase());
        });
        return temp;
    }

    function filterDate (dates) {
        console.log("filtering by date"); 
        let firstDate = dates[0];
        let secondDate = dates[1];
        let temp = data.filter((item) => {
            let date = new Date(item.DATE);
            date.setHours(date.getHours() + 3);
            return date >= firstDate && date <= secondDate;
        });
        return temp;
    }

        

    const aracInfoRef = useRef();
    const dateRef = useRef();
    const stsRef = useRef();

    const allColumns = ["CAR_NAME","VISITOR_NAME","STS_DNS","VISITOR_PHONE","PRICE","DATE"];


    return (
        <View>
           
            <Panel header= {
                    <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15, alignItems: 'center'}}>
                    <CustomText fontSize = {18} fontWeight = {800}  style={{color: '#5A6273'}} > Ziyaretçi Araç Tablosu </CustomText>
                    <ExportData tableHead={allColumns} columns={allColumns} tableName={"Ziyaretçi Araç Tablosu"} textLabel = {" Dışarı Aktar "} data={filteredData} />
                    </View>
            } shaded style={{margin: 25, padding: 20 , backgroundColor: 'white' }}>

                <Table
                data={filteredData}
                loading={loading}
                autoHeight
                wordWrap
                headerHeight={100}
                >
                 <Table.Column width={600} align="center" >
                    <Table.HeaderCell> 
                        <View>
                            <CustomText>  Araç Bilgisi </CustomText>
                            <View style={{marginTop: 10}}></View>
                            <TextFilter
                                ref={aracInfoRef}
                                placeholder="Araç Bilgisi"
                                onChange={(value) => filter()}
                                />
                        </View>
                    </Table.HeaderCell>
                    <Table.Cell dataKey="CAR_NAME" />
                </Table.Column>
                <Table.Column width={200} align="center" >
                    <Table.HeaderCell> İsim  </Table.HeaderCell>
                    <Table.Cell dataKey="VISITOR_NAME" />
                </Table.Column>
                <Table.Column width={200} align="center" >
                    <Table.HeaderCell>
                        <View>
                            <CustomText> Danışman  </CustomText>
                            <View style={{marginTop: 10}}></View>
                            <TextFilter
                                ref={stsRef}
                                placeholder="Danışman"
                                onChange={(value) => filter()}
                                />
                        </View>
                    </Table.HeaderCell>
                    <Table.Cell dataKey="STS_DNS" />
                </Table.Column>
                <Table.Column width={200} align="center" >
                    <Table.HeaderCell> Telefon No  </Table.HeaderCell>
                    <Table.Cell dataKey="VISITOR_PHONE" />
                </Table.Column>
                <Table.Column width={200} align="center" >
                    <Table.HeaderCell> Verilen Fiyat  </Table.HeaderCell>
                    <Table.Cell dataKey="PRICE" />
                </Table.Column>
                <Table.Column width={250} align="center" >
                    <Table.HeaderCell>
                        <View>
                            <CustomText>  Tarih </CustomText>
                            <View style={{marginTop: 10}}></View>
                            <DateFilter
                                ref={dateRef}
                                placeholder="Tarih"
                                onChange={(value) => filter()}
                                />
                        </View>
                    </Table.HeaderCell>
                    <FormattedDateCell dataKey="DATE" />
                </Table.Column>
                </Table>
            </Panel>
        </View>
    )
}

const TextFilter  =  forwardRef(({placeholder,onChange},ref)  => {

    const [value,setValue] = useState("");

    useImperativeHandle(ref, () => ({
        value : () =>  {
            if (value == "" || value == undefined){
                return null;
            }
            return value;
        }
    }));

    useEffect(() => {
        onChange(value);
    },[value]);

    return (
        <InputGroup>
            <Input
            style={{width: 180}}
            placeholder={placeholder}
            value={value}
            onChange={ (value) => {
                setValue(value);
               
            }
            }
            />
        </InputGroup>

    )
}
);

const DateFilter = forwardRef(({placeholder,onChange},ref) => {

    const [value,setValue] = useState("");

    useEffect(() => {
        onChange(value);
    },[value]);

    useImperativeHandle(ref, () => ({
        value : () =>  {
            if (value == "" ||  value == undefined){
                return null;
            }
            return value;
        }
    }));

    return (
        <InputGroup>
            <DateRangePicker 
            placement='auto'
            style={{width: 180}}
            placeholder={placeholder}
            value={value}
            onChange={ (value) => {
                setValue(value);
            }
            }
            />
            
        </InputGroup>

    )
}
);


export default SalesCarsOffers