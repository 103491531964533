import { Text, View , StyleSheet,Dimensions} from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import { Input,useToaster, Message, Button,DatePicker,SelectPicker,IconButton ,Checkbox, List, Drawer,Grid,Row,Col,Stack, Panel} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faHashtag, faSquare } from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';


const HGSScreen = (props) => {

    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});
    const [s3KEYS , setS3Keys] = useState(getS3Keys());
    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
      const api = configdata.API_BASE + '/cardata';
      axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
          "type" : "GET_HGS_DATA",
          "ID" : props.data["NO"],
        },{headers}).then((response)=>{
          console.log(response.data);
          if(response.data.length > 0){
            setCurrentActive(response.data);
          }
       
          setData(response.data);
      }).catch((error)=> {
          console.log(error);
      })
  }

  function setCurrentActive(input){
    for(var i = 0; i< input.length ; i++){
      if(input[i]["IS_ACTIVE"] == 1){
        setCurrent(input[i]);
        //console.log(input[i]["S3_KEY"]);
        //fileViewRef.current.set(input[i]["S3_KEY"]);
        break;
      }
    }
  }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }

    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function getS3Keys(){
    //   if(JSON.parse(props.data["S3 KEYS T_INSURENCES"]).data != null){
    //     return JSON.parse(props.data["S3 KEYS T_INSURENCES"]).data[0];
    //   }
    //   return [];
    }

    const ref =useRef();

    const tableHeads= ["HGS ETİKET NO","BAŞLANGIÇ TARİHİ","HGS FİRMASI"]
    const listHeads = ["HGS ETİKET NO","BAŞLANGIÇ TARİHİ","HGS FİRMASI","NOTLAR"]
    const labelHeads= ["HGS ETİKET NO","BAŞLANGIÇ TARİHİ","HGS FİRMASI","NOTLAR"]
    const headers = [
      {header : "HGS ETİKET NO", data : current["HGS ETİKET NO"], icon : faHashtag},
      {header : "BAŞLANGIÇ TARİHİ", data : current["BAŞLANGIÇ TARİHİ"], icon : faCalendar},
      {header : "HGS FİRMASI", data : current["HGS FİRMASI"], icon : faBuilding},
      {header : "NOTLAR", data : current["NOTLAR"], icon : faSquare},
    ]
    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2' , padding: 20}}>
              <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'}  backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"HGS BİLGİLERİ"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        {modalType == "New" ? (<NewHGSDataPanel 
                        type={"NEW"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); handleModalClose();}}></NewHGSDataPanel>) 
                        :
                        ( <NewHGSDataPanel 
                          type={"EDIT"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {() => {getComponents(); handleModalClose();}} ></NewHGSDataPanel> )}
                       
                    </Drawer.Body>
                </Drawer>
            
          <Grid fluid style ={{width:'100%'}}>
            <Row gutter={20}>
              <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}}  header={
                    <View style={{ flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Stack wrap spacing={10} style={{ flexDirection: 'row', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Stack.Item grow={1}>
                          <IconButton icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>} 
                          style={{width: '100%'}}
                          onClick={() => {
                            setModal(true);
                            setModalType("New");}}>
                            Yeni HGS Etiket
                        </IconButton>
                        </Stack.Item>

                        <ExportData data={data} tableHead={tableHeads} tableName={"HGS_Listesi_" + props.data["PLAKA"] + "_" + new Date()}></ExportData>
                      </Stack>
                      
                    </View>
                  }>

                  <CustomText fontWeight= {800} fontSize={14} >{"Hgs Verileri"} </CustomText>
                  <ComponentListView current={current} showDetails={showDetailsOfChoosen} chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit}  tableName = {"HGS_Etiketleri" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads} listHeads={listHeads}  data={data}></ComponentListView>

                </Panel>
              </Col>
              <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}}  header={<CustomText fontWeight = {800}> {"HGS Bilgileri - " + props.data["PLAKA"] } </CustomText> }>
                        <List>
                          {headers.map((item) =>
                            {
                            return(<List.Item key={item} index={item}>
                              <CustomListItem item={item} ></CustomListItem>
                            </List.Item>)
                            }
                            )}
                        </List>

                  </Panel>
              </Col>
            </Row>
          </Grid>
        </View>
    )
}


const NewHGSDataPanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    const [activateAddButton,setActiveAddButton] = useState(true);

    // COMBO BOX ARRAYS
    const [FirmsAll,setFirmsAll] = useState([]);


    // SENT VALUES
    const [documentNO,setdocumentNO] = useState("");
    const [Firm,setFirm] = useState(0);
    const [StartDATE,setStartDATE] = useState(new Date());
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);


    function reset(){
        setdocumentNO("");
        setFirm(0);
        setStartDATE("");
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
      setdocumentNO(props.data["HGS ETİKET NO"]);
      setFirm(props.data["FIRM_ID"]);
      setStartDATE(new Date(props.data["BAŞLANGIÇ TARİHİ"]));
      setNote(props.data["NOTLAR"]);
      setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function getDropDownValues(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
          "ENV" : configdata.APP_ENV,
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }

    const {fetchIdToken} = useUserToken();
    async function sendData(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
    const api = configdata.API_BASE + '/cardata';
    axios.post(api,
      {
          "ENV" :configdata.APP_ENV ,          
          "type": props.type == 'EDIT' ? "UPDATE_HGS" : "NEW_HGS_DOC",
          "DOCUMENT_NO" : documentNO,
          "START_DATE": StartDATE,
          "FIRM_ID": Firm,
          "NOTES" :note,
          "CAR_ID" : props.cardata["NO"],
          "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
          "ID" : props.data["NO"]
      },{headers}
    ).then((response) => {
      console.log(response);
      reset();
      props.reset();
      if(response.data["errorMessage"] != null){
          messageToggle("HGS Etiket Bilgileri eklenemedi, Bağlantınızı kontrol edin", "error")
      }else{
          messageToggle("HGS Etiket Bilgileri Başarıyla Eklendi", "success")
      }
      
    })
      .catch((error) => {
        console.log(error);
        messageToggle("HGS Etiket Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

    useEffect(() => {
      
     if(props.type == "EDIT"){getEditData()}
     getDropDownValues("HGS_FIRM",setFirmsAll);

    },[])

    const item_style = {
    standard: { width : 200 },
    viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
    
    
    return(

          <View style= {{flexDirection: 'column',width: '100%'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
                 <View style={styles.form}>
                   <Group header={"Etiket No"}>
                     <Input value={documentNO} style={styles.standard} onChange={ (value) => {setdocumentNO(value);}} name= {"Poliçe No"} /> 
                   </Group>
                  
                    <Group header={"Başlangıç Tarihi"}>
                     <DatePicker value={StartDATE} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                           {setStartDATE(value);}}
                           oneTap format="dd-MM-yyyy"
                           locale={locale}
                       ></DatePicker>
                   </Group>

                   <Group header={"HGS Firması"}>
                     <SelectPicker value={Firm} style={styles.standard}  onSelect={(value) => { setFirm(value)}} data = {FirmsAll} ></SelectPicker>
                   </Group>

                  {
                    props.type == "EDIT" && 
                    <Group>
                      <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                    </Group>
                  }

                  <Group header={"Notlar"}>
                    <Input style={styles.standard} value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}  /> 
                  </Group>
                </View>
              <View style={{height: 50}}></View>
              <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > <CustomText fontWeight = {800} fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>
        </View>
    )
}

const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5}}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  form: {
    width: '100%',
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150, width: '100%'},
  input: {
    flex: 1,
  },
});


export default HGSScreen;