import React from 'react';
import Navigation from './src/Navigation'
import {SafeAreaView} from 'react-native'
import 'bootstrap/dist/css/bootstrap.min.css';
import './app.css';
import './custom-theme.less';

class App extends React.Component {
  render() {
    return (
       <SafeAreaView style = {{flex:1, flexGrow: 1,    backgroundColor: '#F2F2F2'}}>
          <Navigation/>
       </SafeAreaView>
      
    );
  }
}

export default App;