import { Text, View , Dimensions } from 'react-native'
import React, { PureComponent,useState, useEffect,useRef} from 'react'
import { Button,DatePicker,Form, Input, MaskedInput, SelectPicker ,IconButton, RadioGroup, Radio,Drawer,List,useToaster, Message, Row, Col, Grid, Panel} from 'rsuite'
import axios from 'axios';
import { useUserToken } from '../../../hooks/useUserToken';
import configdata from '../../../config/config';
import FileUploader from '../../../Components/FileManagements/FileUploader';
import FileLoaderInline from '../../../Components/FileManagements/FileLoaderInline';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import { StyleSheet } from 'react-native';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import NewCustomerScreen from '../../RentScreens/NewCustomerScreen';
import CustomListItem from './CustomListItem';
import { faCalendar, faDiamond, faMoneyBill, faPerson } from '@fortawesome/free-solid-svg-icons';
import { faModx } from '@fortawesome/free-brands-svg-icons';
import { useMessage } from '../../../hooks/useMessage';
import DatepickerComponent from '../../../Components/DatePickerComponent/DatePickerComponent';


const BuyInfoShower = (props) => {

    const [current,setCurrent] = useState({});
    const [clientData,setClientData] = useState([]);

    const {fetchIdToken} = useUserToken();
    async function getData(){
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_BUY_INFO",
            "CAR_ID" : props.carID
        }
        axios.post(api,body,{headers}).then(response => {
            console.log(response);
            let temp = {...response.data[0]};
            temp["TEDARİKÇİ"] = clientData.filter(item => item.value == temp["CLIENT_ID"])[0].label;
            setCurrent(temp);
        }).catch(error => {
            console.log(error);
        })
    }

    function getClients(){
        console.log("getClients");
        const api = configdata.API_BASE + '/customers';
          axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS_TEDARIK"
          }).then(
              (response) => {
                  console.log(response.data);
                  let temp = [];
                    response.data.forEach(element => {
                        if(element.NAME == null || element.NAME == ""){
                            temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                        }else {
                            temp.push({"label" : element.NAME , "value" : element.ID})
                        }
                    });
                    console.log(temp);
                  setClientData(temp);
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    }

    useEffect(() => {
        if(clientData.length > 0){
            console.log("getData");
            getData();
        }
    },[clientData]);

    useEffect(() => {
        getClients();
    },[]);

    function formatDateToTurkish(date){
        let temp = new Date(date);
        return temp.getFullYear() +  "-" + (temp.getMonth() + 1) + "-" + temp.getDate()
    }

    const headers =
    [ 
        {"header" : "TEDARİKÇİ" , "data" : current["TEDARİKÇİ"] , "icon" :faPerson },
        {"header" : "SATIN ALIM ŞEKLİ" , "data" : current["BUY_TYPE_NAME"] , "icon" :faModx },
        {"header" : "FATURA TARİHİ" , "data" : formatDateToTurkish(current["OFFICIAL_BUY_DATE"]) , "icon" :faCalendar },
        {"header" : "ÖTV KDV HARİÇ TUTAR" , "data" : current["TAX_EXC_PRICE"] , "icon" :faMoneyBill },
        {"header" : "ÖTV ORANI" , "data" : "% " + current["OTV"] , "icon" :faDiamond },
        {"header" : "ÖTV TUTARI" , "data" : current["OTV_TUTARI"] , "icon" :faMoneyBill },
        {"header" : "KDV ORANI" , "data" : "% " + current["KDV"] , "icon" :faDiamond },
        {"header" : "ÖTV KDV DAHİL TUTAR" , "data" : current["TAX_INC_PRICE"] , "icon" :faMoneyBill },

    ]    

    const headers2 = 
    [
        {"header" : "DİĞER KDV" , "data" : current["DIGER_KDV"] , "icon" :faDiamond },
        {"header" : "DİĞER KDV TUTARI" , "data" : current["DIGER_KDV_TUTARI"] , "icon" :faMoneyBill },
        {"header" : "DİĞER KDV'SİZ TUTAR" , "data" : current["DIGER_KDVSIZ_TUTAR"] , "icon" :faMoneyBill },
        {"header" : "DİĞER FATURASIZ TUTAR" , "data" : current["DIGER_FATURASIZ_TUTAR"] , "icon" :faMoneyBill },
        {"header" : "ALIŞ KANALI", "data" : current["BUY_DETAILED_CATEGORY"], "icon" : faDiamond},
        {"header" : "NOTLAR" , "data" : current["NOTES"] , "icon" :faDiamond}

    ]
    
    const [infoDrawer,setInfoDrawer] = useState(false);
    const [fileDrawer,setFileDrawer] = useState(false);

    const [fileCategory,setFileCategory] = useState("BUY_FILE");

    function handleOpenFileDrawer(input){
        setFileCategory(input);
        setFileDrawer(true);
    }

    function handleCloseFileDrawer(){
        setFileDrawer(false);
    }


    function handleClose(){
        setInfoDrawer(false);
    }

    function reset(){
        setInfoDrawer(false);
        getData();
    }

    return (
        <View style={{backgroundColor: '#F2F2F2', width: '100%',flex : 1, padding: 20}}>
            <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 50, minWidth: 200,marginBottom: 20 }} >
                <IconButton onClick={() => { setInfoDrawer(true)}} icon={<Icon><FontAwesomeIcon icon = {solid('plus')}></FontAwesomeIcon></Icon>}> Satın Alım Bilgisi Düzenle</IconButton>
            </Panel>
            <Grid fluid style={{width: '100%', marginBottom: 20}}>
                <Row gutter={20}>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                <List.Item key= {"Plaka"}>
                                <CustomListItem item = {{"header" : "Plaka" , "data" : props.data["PLAKA"]}}></CustomListItem>
                                </List.Item>
                                {headers.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                            </List>
                        </Panel>
                    </Col>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                {headers2.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                                <List.Item key= {"BuyFile"}>
                                    <CustomListItem type={"FILE"} item={{"header" : "ALIŞ DOSYASI", "type" : "BUY_FILE"}} openFileDrawer= {handleOpenFileDrawer} ></CustomListItem>
                                </List.Item>
                            </List>
                        </Panel>
                    </Col>
                </Row>
            </Grid>

            <Drawer overflow={true}  size={'md'}  backdrop={'static'} keyboard={false} open={infoDrawer} onClose={handleClose} >
                <Drawer.Header>
                    <Drawer.Title> {" Satın Alım Bilgisi --  " + props.data["ARAÇ EDİNİM ŞEKLİ"]}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <BuyInfoScreenNewCar reset = {reset} carID={props.carID} data ={props.data} ></BuyInfoScreenNewCar>
                </Drawer.Body>
                
            </Drawer>
            <Drawer overflow={true}  size={'full'}  backdrop={true} keyboard={false} open={fileDrawer} onClose={handleCloseFileDrawer} >
                <Drawer.Header>
                    <Drawer.Title>  Satın Alım Dosyası  </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                        <FileViewer inline={false}  height = {1000} s3KEY = {current["BUY_FILE"]}></FileViewer>

                </Drawer.Body>
            </Drawer>
        </View>
            
    )
}

    const BuyInfoScreenNewCar = (props) => {

        const {messageToggle} = useMessage();
        const [client,setClient] = useState(0);
        const [buyType,setBuyType] = useState(0);
        
        const [buyTypes,setBuyTypes] = useState([]);
        const [clientData,setClientData] = useState([]);
        const [faturaDate,setFaturaDate] = useState(new Date());
        const [otv,setOTV] = useState(0);
        const [otvValue,setOTVValue] = useState(0);
        const [kdv,setKdv] = useState(0);
        const [kdvValue,setKdvValue] = useState(0);
        const [otvKdvHaricTutar,setOtvKdvHaricTutar] = useState("0 TL");
        const [otvKdvHaricTutarValue,setOtvKdvHaricTutarValue] = useState(0);
        const [otvKdvDahilTutar,setOtvKdvDahilTutar] = useState("0 TL");
        const [kdvtutarı,setKdvTutarı] = useState("0 TL");
        const [otvtutarı,setOtvTutarı] = useState("0 TL");
        const [otvtutarıDisabled,setOtvTutarıDisabled] = useState("0 TL");
        const [otvtutarıValue,setOtvTutarıValue] = useState(0);
        const [alisKanal,setAlisKanal] = useState("");
        const [digerKDV,setDigerKDV] = useState(0);
        const [digerKDVTutarValue,setDigerKDVValue] = useState(0);
        const [digerKDVTutar,setDigerKDVTutar] = useState("0 TL");
        const [digerKDVsizTutar,setDigerKDVsizTutar] = useState(0);
        const [digerKDVsizTutarValue,setDigerKDVsizTutarValue] = useState(0);
        const [digerFaturasızTutar,setDigerFaturasızTutar] = useState("0 TL");
        const [digerFaturasızTutarValue,setDigerFaturasızTutarValue] = useState(0);
        const [notlar,setNotlar] = useState("");
        const [toplamVergisizTutar,setToplamVergisizTutar] = useState(0);
        const [toplamVergiliTutar,setToplamVergiliTutar] = useState(0);

        const [componentType,setComponentType] = useState("NEW");


        const [kdvOranları,setKdvOranları] = useState([ 
            {"label" : "%20" , "value" : 20},
            {"label" : "%18" , "value" : 18},
            {"label" : "%10" , "value" : 10},
            {"label" : "%8" , "value" : 8},
            {"label" : "%1" , "value" : 1},
            {"label" : "%0" , "value" : 0},
        ]);

        const [otvOranları,setOtvOranları] = useState([
            {"label" : "%10" , "value" : 10},
            {"label" : "%15" , "value" : 15},
            {"label" : "%40" , "value" : 40},
            {"label" : "%45" , "value" : 45},
            {"label" : "%50" , "value" : 50},
            {"label" : "%60" , "value" : 60},
            {"label" : "%70" , "value" : 70},
            {"label" : "%80" , "value" : 80},
            {"label" : "%130" , "value" : 130},
            {"label" : "%150" , "value" : 150},
            {"label" : "%220" , "value" : 220},

        ]);

        const alisKanallari = [
            {"label" : "Kurumsal" , "value" : "Kurumsal"},
            {"label" : "FBK" , "value" : "FBK"},
            {"label" : "RENT TO RENT" , "value" : "RENT TO RENT"},

        ]

        useEffect(() => {
            let temp = parseFloat(digerKDVsizTutarValue.toFixed(2))+ otvKdvHaricTutarValue + digerFaturasızTutarValue
            setToplamVergisizTutar((temp.toFixed(2)).replace(".",","));
        },[otvKdvHaricTutarValue,digerKDVsizTutarValue,digerFaturasızTutarValue,digerKDV]);

        useEffect(() => {
            setDigerFaturasızTutarValue(unformatTLvalue(digerFaturasızTutar));
        },[digerFaturasızTutar]);

        const {fetchIdToken} = useUserToken();
        function sendData(){
            buyFileRef.current.load();
        }

        async function sendToRDS(){
            const api = configdata.API_BASE + '/cardata';
            const token = await fetchIdToken();
            const headers = {
                "Authorization" : `Bearer ${token}`,
            }
            const body = {
                "ENV" : configdata.APP_ENV,
                "type" : componentType == "NEW" ? "ADD_BUY_INFO_SIFIR" : "UPDATE_BUY_INFO_SIFIR",
                "BUY_TYPE" : buyType,
                "CLIENT_ID" : client,
                "FATURA_DATE" : faturaDate,
                "OTV" : otv,
                "OTV_TUTARI" : otvtutarıValue,
                "KDV" : kdv,
                "KDV_TUTARI" : kdvValue,
                "OTV_KDV_HARIC_TUTAR" : otvKdvHaricTutarValue,
                "OTV_KDV_DAHIL_TUTAR" : otvKdvDahilTutar,
                "DIGER_KDV" : digerKDV,
                "DIGER_KDV_TUTARI" : digerKDVTutarValue,
                "DIGER_KDVSIZ_TUTAR" : digerKDVsizTutarValue,
                "DIGER_FATURASIZ_TUTAR" : digerFaturasızTutarValue,
                "NOTES" : notlar,
                "BUY_DETAILED_CATEGORY" : alisKanal,
                "CAR_ID" : props.carID
            }
            console.log(body);

            axios.post(api,body,{headers : headers}).then(response => {
                console.log(response);
                if(response.data == "SUCCESS"){
                    messageToggle("Satın Alım Bilgisi Başarıyla Eklendi","success");
                    props.reset();
                }
                else{
                    messageToggle("Satın Alım Bilgisi Eklenirken Bir Hata Oluştu","error");
                }
            }
            ).catch(error => {
                console.log(error);
            }
            )
        }

        const  getData = async () => {
            console.log("getData");
            const api = configdata.API_BASE + '/cardata';
            const token = await fetchIdToken();
            const headers = {
                'Authorization' : `Bearer ${token}`,
            }
            const body = {
                "ENV" : configdata.APP_ENV,
                "type" : "GET_BUY_INFO",
                "CAR_ID" : props.carID
            }
            axios.post(api,body,{headers}).then(response => {
                console.log(response);
                if(response.data.length == 0){
                    setComponentType("NEW");
                    return;
                }
                setComponentType("UPDATE");
                setBuyType(response.data[0].BUY_TYPE);
                setClient(response.data[0].CLIENT_ID);
                setFaturaDate(new Date(response.data[0].OFFICIAL_BUY_DATE));
                setOTV(response.data[0].OTV);
                setKdv(response.data[0].KDV);
                setOtvKdvHaricTutar(response.data[0].TAX_EXC_PRICE);
                setOtvKdvHaricTutarValue(response.data[0].TAX_EXC_PRICE);
                setOtvKdvDahilTutar(response.data[0].TAX_INC_PRICE);
                setOtvTutarı(response.data[0].OTV_TUTARI);
                setDigerKDV(response.data[0].DIGER_KDV);
                setDigerKDVTutar(response.data[0].DIGER_KDV_TUTARI);
                setDigerFaturasızTutar(response.data[0].DIGER_FATURASIZ_TUTAR);
                setAlisKanal(response.data[0].BUY_DETAILED_CATEGORY);
                setNotlar(response.data[0].NOTES);
            }
            ).catch(error => {
                console.log(error);
            }
            )
        }

        useEffect(() => {
            if(clientData.length > 0)
            { 
                getData();
            }
        },[clientData]);


        useEffect(() => {
            getDropDownValuesGlobal("BUY_TYPE",setBuyTypes);
            getClients();
        },[]);



        useEffect(() => {
            let temp =( unformatTLvalue(otvtutarı) + unformatTLvalue(otvKdvHaricTutar) )* kdv / 100;
            setKdvTutarı(temp);
            setOtvKdvDahilTutar(temp + unformatTLvalue(otvtutarı) + unformatTLvalue(otvKdvHaricTutar));
        },[kdv,otvtutarı,otv,otvKdvHaricTutar]);

        useEffect(() => {
            let temp = otvKdvDahilTutar + digerKDVTutarValue + digerFaturasızTutarValue;
            setToplamVergiliTutar(temp);
        },[otvKdvDahilTutar,digerKDVTutarValue,digerFaturasızTutarValue]);

        useEffect(() => {
            setDigerKDVValue(unformatTLvalue(digerKDVTutar));
        },[digerKDVTutar]);
        useEffect(() => {
            setOtvTutarıDisabled(numberToCurrency( otv * otvKdvHaricTutarValue / 100));
        },[otv,otvKdvHaricTutarValue,otvKdvHaricTutar]);

        useEffect(() => {
            let temp = digerKDVTutarValue / ( digerKDV / 100 + 1);
            setDigerKDVsizTutarValue(temp);
            setDigerKDVsizTutar((temp + "").replace(".",","));

        },[digerKDV,digerKDVTutarValue]);


        function reset(){
            setClient(0);
            setBuyType(0);
            setFaturaDate(new Date());
            setOTV(0);
            setOTVValue(0);
            setKdv(0);
            setKdvValue(0);
            setOtvKdvHaricTutar(0);
            setOtvKdvHaricTutarValue(0);
            setOtvKdvDahilTutar(0);
            setKdvTutarı(0);
            setOtvTutarı(0);
            setOtvTutarıDisabled("");
            setOtvTutarıValue(0);
            setDigerKDV(0);
            setDigerKDVValue(0);
            setDigerKDVTutar(0);
            setDigerKDVsizTutar(0);
            setDigerKDVsizTutarValue(0);
            setDigerFaturasızTutar(0);
            setDigerFaturasızTutarValue(0);
            setNotlar("");
        }

        function getClients(){
            console.log("getClients");
            const api = configdata.API_BASE + '/customers';
            axios.post(api,{
                "ENV" : configdata.APP_ENV,
                "type" : "GET_CLIENTS_TEDARIK"
            }).then(
                (response) => {
                    console.log(response.data);
                    let temp = [];
                        response.data.forEach(element => {
                            if(element.NAME == null || element.NAME == ""){
                                temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                            }else {
                                temp.push({"label" : element.NAME , "value" : element.ID})
                            }
                        });
                        console.log(temp);
                    setClientData(temp);
                }
            ).catch(
                (error) => {
                    console.log(error);
                }
            )
        } 

        function getDropDownValuesGlobal(input,set){

        const api = configdata.API_BASE + '/uidropdownlist';
        axios
        .post(api,{
            "ENV" :configdata.APP_ENV ,  
            "category": input,
            "type" : "GET_GLOBAL",
            
        })
        .then((response) => {
            // console.log(response);
            // console.log(response.data);
            //setUserData(JSON.parse(response.data));
            console.log(response.data);
            var result = [];
            response.data.forEach( element =>
            { 
                result.push({"label" : element.VALUE , "value" : element.ID})
            }
            );
            set(result);
            // console.log(result);
        })
        .catch((error) => {
            console.log(error);
            
        });

        }

        function handleFileUpload(file){
            console.log(file);
        }

        const [customerDrawerOpen,setCustomerDrawerOpen] = useState(false);

        function handleClose(){
            setCustomerDrawerOpen(false);
        }

        function handleOpen(){
            setCustomerDrawerOpen(true);
        }

        function resetCustomers(){
            handleClose();
            getClients();
        }

        const [BuyFile,setBuyFile] = useState(undefined);
        const buyFileRef = useRef(null);

        useEffect(() => {
            if(BuyFile != undefined){
                sendToRDS();
            }
        },[BuyFile]);

        return (
            <View style={{backgroundColor: '#F2FFFB', width: '100%',flex : 1}}>
                <Drawer  overflow={true}  size={'full'}  backdrop={true} keyboard={false} open={customerDrawerOpen} onClose={handleClose}   >
                    <Drawer.Header>
                        <Drawer.Title> Yeni Müşteri </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        <NewCustomerScreen onRow  reset={resetCustomers} ></NewCustomerScreen>
                    </Drawer.Body>
                </Drawer>


                <View style={{ padding : 24 , backgroundColor: 'white' }}>
                        <Group label={ "Satın Alım Şekli"}>
                            <SelectPicker placement='autoVerticalStart' value={buyType}  style={styles.listItem} placeholder= {""} data={buyTypes} onChange={setBuyType} />
                        </Group>
                        {
                            (
                                buyTypes.find(element => element.label.toLowerCase().includes("filo"))?.value === buyType && 
                                props.data["MARKA"] === "FIAT"
                            ) && (
                                <Group label={ "Satın Alım Kanalı"}>
                                <SelectPicker placement='autoVerticalStart' value={alisKanal} style={styles.listItem} placeholder={""} data={alisKanallari} onChange={setAlisKanal} />
                                </Group>
                            )
                        }
                        <Group label={ "Tedarikçi"}>
                            <IconButton icon={<Icon><FontAwesomeIcon icon = {solid('plus')}></FontAwesomeIcon></Icon>}
                                onClick={handleOpen}
                            ></IconButton>
                            <SelectPicker placement='autoVerticalStart' value={client} style={styles.listItem} placeholder= {""} data={clientData} onChange={setClient} />
                        </Group>
                        <Group label={ "Fatura"}>
                        <FileLoaderInline ref={buyFileRef} carID = {props.data["NO"]} fileCategory= "BUY_FILE" setS3Value={setBuyFile}></FileLoaderInline>

                        </Group>
                        <Group label={ "Fatura Tarihi"}>
                            <DatePicker placement='autoVerticalStart' value={faturaDate} onChange={setFaturaDate} style={styles.listItem} placeholder="Fatura Tarihi" format="dd-MM-yyyy"  isoWeek = {true}  oneTap/>
                           {/* <DatepickerComponent /> */}
                        </Group>

                        <Group label={ "ÖTV KDV Hariç Tutar"}>
                            <MaskedInput value={otvKdvHaricTutar} onChange={(value) => {setOtvKdvHaricTutar(value); setOtvKdvHaricTutarValue(unformatTLvalue(value))}} guide= {false} mask={numberMaskTL} style={styles.listItem} placeholder="Tutar" />
                        </Group>
                        <Group label={ "ÖTV Oranı"}>
                            <SelectPicker virtualized menuMaxHeight={250} placement='autoVerticalStart' value={otv} data={otvOranları} onChange={setOTV} style={styles.listItem} placeholder="ÖTV Oranı" />
                        </Group>
                        <Group label={ "ÖTV Tutarı"}>
                                <MaskedInput value={otvtutarı}  onChange={(value) => {setOtvTutarı(value); setOtvTutarıValue(unformatTLvalue(value))}} guide= {false} mask={numberMaskTL} style={styles.listItem} placeholder="ÖTV Tutarı" />
                        </Group>
                        <Group label={"Yaklaşık ÖTV Tutarı"}>
                                <View style={{backgroundColor: '#aac9fa', borderRadius: 7 , height: 35, width: 200, padding: 10 ,flexDirection: 'row' , alignItems: 'center'}}>
                                    <Text style={{color : 'white', textAlign  :'left', fontWeight: 'bold', fontSize : 18}}> ~ </Text>
                                    <Text style={{color : 'white', textAlign  :'left'}}> { otvtutarıDisabled} </Text>
                                </View>
                        </Group>
                        <Group label={ "KDV Oranı"}>
                            <SelectPicker value={kdv}  placement='top' style={styles.listItem} placeholder= {""} data={kdvOranları} onChange={setKdv} />
                        </Group>
                        <Group label={ "KDV Tutarı"}>
                            {/* <MaskedInput value={kdvtutarı} disabled guide= {false} mask={numberMaskTL} style={styles.listItemDisabled} placeholder="--" /> */}
                            <View style={styles.listItemDisabled}>
                                <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(kdvtutarı) } </Text>
                            </View>
                        </Group>
                        <Group label={ "Ötv Kdv Dahil Tutar"}>
                            {/* <MaskedInput value={otvKdvDahilTutar} disabled guide= {false} mask={numberMaskTL} style={styles.listItemDisabled} placeholder="--" /> */}
                            <View style={styles.listItemDisabled}>
                                <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(otvKdvDahilTutar) } </Text>
                            </View>
                        </Group>

                        <Group label={ "Diğer Faturalı KDV'li Tutar"}>
                            <MaskedInput onChange={setDigerKDVTutar} value={digerKDVTutar} guide= {false} mask={numberMaskTL} style={styles.listItem} placeholder="--" />
                        </Group>
                        <Group label={ "Diğer Faturalı KDV Oranı"}>
                            <SelectPicker placement='autoVerticalStart' value={digerKDV} style={styles.listItem} data={kdvOranları} onChange={setDigerKDV} ></SelectPicker>
                        </Group>
                        <Group label={ "Diğer Faturalı KDV'siz Tutar"}>
                            {/* <MaskedInput value={digerKDVsizTutar} disabled guide= {false} mask={numberMaskTL} style={styles.listItemDisabled} placeholder="--" /> */}
                            <View style={styles.listItemDisabled}>
                                <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(digerKDVsizTutarValue) } </Text>
                            </View>
                        </Group>
                        <Group label={ "Diğer Faturasız Tutar"}>
                            <MaskedInput onChange={setDigerFaturasızTutar} value={digerFaturasızTutar} guide= {false} mask={numberMaskTL} style={styles.listItem} placeholder="--" />
                        </Group>

                        <Group layout={'inline'} label={ "Toplam Vergisiz Tutar"}>
                            {/* <MaskedInput value={toplamVergisizTutar} disabled guide= {false} mask={numberMaskTL} style={styles.listItemDisabled} placeholder="--" />     */}
                            <View style={styles.listItemDisabled}>
                                <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(toplamVergisizTutar) } </Text>
                            </View>
                        </Group>       
                        <Group layout={'inline'} label={ "Toplam Vergili Tutar"}>
                            {/* <MaskedInput value={toplamVergiliTutar} disabled guide= {false} mask={numberMaskTL} style={styles.listItemDisabled} placeholder="--" /> */}
                            <View style={styles.listItemDisabled}>
                                <Text style={{color : 'white', textAlign  :'left'}}> { numberToCurrency(toplamVergiliTutar) } </Text>
                            </View>
                        </Group>
                        <Group layout={'inline'} label={ "Notlar"}>
                            <Input style={styles.listItem} placeholder="Notlar"  onChange={setNotlar} value={notlar} />
                        </Group>


                        { componentType == "NEW" && <IconButton onClick={reset} icon = {<Icon style={{backgroundColor: '#FF0000'}} >
                                <FontAwesomeIcon icon = {solid("trash-alt")}></FontAwesomeIcon>
                            </Icon>} style={styles.buttonRed} > Sıfırla </IconButton>}
                            <View style={{marginVertical: 10,}}></View>
                            <IconButton onClick={sendData} icon = {<Icon style={{backgroundColor: '#267aff'}} ><FontAwesomeIcon icon = {solid("save")}></FontAwesomeIcon></Icon>} style={styles.buttonBlue} > 
                            { componentType == "NEW" ? "Kaydet" : "Güncelle" }  </IconButton>

                </View>
            </View>
        )

    }

    export default BuyInfoShower;


    function Group ({ label, children, layout }) {
        return (
            <View style={{flexDirection: 'row' , marginVertical : 15, justifyContent: 'space-between'}}>
                <Text style={{ fontSize: 14, color: '#000', margin: 10}}>{label}</Text>
                <div>{children}</div>
            </View>
        );
    }


    const numberMaskTL = createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol : '.',
        suffix: ' TL' ,
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 5
    })

    function numberToCurrency(number){
        let temp = number;
        if(typeof number == "string" && !number.includes("TL")){
            temp = parseFloat(number);
        }
        return temp.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
        }
                

    const unformatTLvalue = (value) => {
        
        if(value == null || value == ""){
            return 0;
        }
        if(value.split == undefined){
            return value;
        }
        if(value == "₺0,00"){
            return 0;
        }
        let temp = value.split(" ");
        temp = temp[0].split(".");
        let result = "";
        temp.forEach(element => {
            result += element;
        });
        result = result.replace(",",".");
        return parseFloat(result);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2FFFB',
        alignItems: 'center',
        justifyContent: 'center',
    },
    group: {
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        borderRadius: 7,
        marginVertical: 10,
        padding: 10,
        height: 120,
        borderWidth: 1,
        borderColor: '#F2FFFB',
    },

    listItem: {
        backgroundColor: "#FFF",
        width: 200,
        height: 35,
    },
    listItemDisabled: {
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        backgroundColor: "#aac9fa",
        width: 200,
        height: 35,
        borderRadius: 7,
    },
    buttonRed : {
        backgroundColor: "#FF0000",
        width: 120,
        height: 35,
        color: 'white',
    },
    buttonBlue : {
        backgroundColor: "#267aff",
        width: 120,
        height: 35,
        color: 'white',

    },

});



