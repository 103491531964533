import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator, Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef, useCallback } from 'react'
import axios from 'axios';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { Stack,Modal, Button, Form ,Nav,TreePicker ,SelectPicker , MultiCascader,Input,Pagination,IconButton,List, Table ,CheckTreePicker,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider, Panel,Drawer, MaskedInput, InputGroup} from 'rsuite';
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import ResponsiveNav from '@rsuite/responsive-nav';
import configdata from  "../../config/config.json";
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';
import { useMessage } from '../../hooks/useMessage';
import { Tabs } from 'react-bootstrap';
import SalesVisitorInfo from './SalesVisitorInfo';
import usePagination from '../../hooks/usePagination';
import PhoneInput from 'react-phone-input-2'
import useTable from '../../hooks/useTable';
import ExportData from '../../Components/TableManagement/ExportData';



const Tab = createMaterialTopTabNavigator();

const TabNavigator = (props) => {

    function closeTab(id){
        console.log("close clicked");
        var TabListNew = [];
    
        props.tabList.forEach(element => {
            console.log("element.id" + element.id);
          if(element.id != id){
            console.log("element.id" + element.id);
            TabListNew.push(element);
          }
        });
        props.setArray(TabListNew);
        props.removeFromEnabled(id);
      }
  
    const CustomTabBar = ({ state, descriptors, navigation }) => {
  
      
      return (
        <View
          style={styles.bottomBar}>
          {state.routes.map((route, index) => {
            const isFocused = state.index === index;
            const { options } = descriptors[route.key];
            const borderShadowStyle = isFocused ? {
              shadowColor: '#000',
              shadowOffset: { width: 0, height: 0 }, // centered shadow
              shadowOpacity: 0.3,
              shadowRadius: 10,
              elevation: 5 ,
              borderBottomWidth: 2,
              borderColor: '#E4E3E3'
            } : {};
    
            const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;
            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
              })
              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(route.name);
              }
            }
  
  
            return (
                <View style={[{
                  backgroundColor: isFocused ? "#F8F9FA" : '#E4E3E3',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '100%',
                  flex: 1,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderBottomWidth: 1,
                  borderColor: '#ECEBEB',
                
                },borderShadowStyle]} 
                >
                  {options.id != 0 && 
                      <TouchableOpacity
                        // style={{width: 20, height: 20 , backgroundColor: '#F26938', borderRadius: 100, marginHorizontal: 5}}
                        style={{marginHorizontal: 10}}
                          key={index}
                          onPress={()=> {closeTab(options.id);}}
                          accessibilityRole="button"
                          >
                          <View >
                            <Icon><FontAwesomeIcon icon={solid('times')} size={15} color={'#5A6273'} /></Icon>
                          </View>
                      </TouchableOpacity>
                    }
  
                    <TouchableOpacity
                        key={index}
                        onPress={onPress}
                        accessibilityRole="button"
                        style = {{marginHorizontal: 10, flex: 1, justifyContent: 'center', alignItems: 'center'}}
                        >
                      <View style={{padding: 5, flexDirection: 'row'}}>
                        <View style={{width: 5}}></View>
                          <CustomText fontSize= {16} >{label}</CustomText>
                      </View>
                    </TouchableOpacity>
  
                    
                </View>
            )
          })}
        </View>
      )
    }
    return (
  
        <Tab.Navigator tabBar={(props) => <CustomTabBar {...props} />}>
              {props.tabList.map((tab) => (
                <Tab.Screen
                  key={tab.id}
                  name={tab.name}
                  component={tab.component}
                  options={{
                    id: tab.id,
                    swipeEnabled: false,
                    brand: tab.brand
                  }}
                />
            ))}
        </Tab.Navigator>
      );
  }





  const SalesVisitorsScreen = () => {

    const visitorsRef= useRef();
    const SalesVisitorsWrapper = () => {
        return <SalesVisitorsTableTab ref={visitorsRef} handleChange={handleChange} />;
    };

    const [TabList, setArray] = useState([    
        {
        id: "0",
        name: "Ziyaretçi Tablosu",
        icon: "information-outline",
        component: SalesVisitorsWrapper,
        },
    ]);


    function handleChange (input){
        setArray((TabList) => [...TabList,input])
    }

    function removeFromEnabled (input){
        visitorsRef.current.removeElement(input);
    }

    return(
        <TabNavigator removeFromEnabled={removeFromEnabled} setArray= {setArray} tabList = {TabList}></TabNavigator>
    )
}

const SalesVisitorsTableTab = forwardRef((props,ref) => {
    const [show, setShow] = useState(false);
    const [showCurrent, setShowCurrent] = useState(false);
    const [currentVisitor, setCurrentVisitor] = useState(undefined);
    const navigation = useNavigation();
    const tableRef = useRef();
    const [enabledTabs,setEnabledTabs] = useState([]);

    function handleEnabledTabs (input){
      setEnabledTabs((enabledTabs) => [...enabledTabs,input])
    }
  
    function removeElementEnabledTabs(element){
        var index = enabledTabs.indexOf(element);
        if(index >= 0){
          enabledTabs.splice(index,1);
        }
        console.log(enabledTabs);
        
    }

    function restart(){
        tableRef.current.refresh();
    }

    // const  CarInfo = () => (<CarInfoScreen reset={() => {getCars(); setRefresh(!refresh)}} getCars = {getCars} data = {choosenCar}  toggleVisible = {setPopUpVisible} isVisible = {isPopUpvisible}></CarInfoScreen>)
    const SalesVisitorInfoWrapper = (props) => (<SalesVisitorInfo data={currentVisitor} res={restart} />);

    useImperativeHandle(ref,()=>({

        removeElement: (element) => {
            removeElementEnabledTabs(element);
        }
    }))

  const AddTab = (input) => {
    if(input  != undefined ){
      if(enabledTabs.includes(input.ID)){
        console.log("avaliable tab do not create new")
        navigation.navigate(input.NAME + " " + input.SURNAME);
      }else{
        props.handleChange(
          {
            id: input.ID,
            name: input["NAME"] + " " + input["SURNAME"],
            component: SalesVisitorInfoWrapper
          })
        handleEnabledTabs(input.ID);
      }
    }
  }


    function reset(){
        setShow(false);
        tableRef.current.refresh();
    }

    function chooseCurrentVisitor(data){
        setCurrentVisitor(data);
        if(data != undefined && data.ID == currentVisitor.ID){
            navigation.navigate(data.NAME + " " + data.SURNAME);
        }
        // setShowCurrent(true);
       
    }

    useEffect(() => {
      if(currentVisitor != undefined){
        AddTab(currentVisitor);
      }
    }, [currentVisitor]);

    const [screenWidth, setScreenWidth] = useState(Dimensions.get('window').width);

    useEffect(() => {
      const handleResize = () => {
        setScreenWidth(Dimensions.get('window').width);
      };
  
      Dimensions.addEventListener('change', handleResize);
  
      return () => {
        // Cleanup event listener on component unmount
        Dimensions.removeEventListener('change', handleResize);
      };
    }, []);

    


    return (
        <View style={{padding: 50}}>
            <Drawer size={Dimensions.get('window').width < 700 ? 'full' : 'sm'} placement="right" open={show} onClose={()=> {setShow(false)}}>
                <Drawer.Header>
                    <Drawer.Title> Yeni Ziyaretçi </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <NewVisitorPanel reset={reset}/>
                </Drawer.Body>
            </Drawer>
            <Drawer size='full' placement="right" open={showCurrent} onClose={()=> {setShowCurrent(false)}}>
                <Drawer.Header>
                    <Drawer.Title> Ziyaretçi Bilgileri </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <SalesVisitorInfo data={currentVisitor} />
                </Drawer.Body>
            </Drawer>
            <Button onClick={() => {setShow(true)}} appearance="primary">
                Yeni Ziyaretçi Ekle
            </Button>
            <View style={{height: 20}}></View>
            <Panel shaded style={{backgroundColor: 'white'}}>
              {
                 screenWidth < 900 ? 
                  <VisitorsLabeled chooseCurrentVisitor={chooseCurrentVisitor} ref={tableRef} />
                  :
                  <VisitorsTable chooseCurrentVisitor={chooseCurrentVisitor} ref={tableRef} />
              }


            </Panel>
        </View>
    )
});

const VisitorsTable = forwardRef((props,ref) => {

    const [salesDNSData, setSalesDNSData] = useState([]);

    async function getSalesDNS(){
        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();
        const headers = {
          'Authorization': `Bearer ${idToken}`
        }
        let salesDNSTemp = [];
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,
            "type" : "GET_SALES_DNS",
        },{headers})
        .then((response) => {
            console.log(response);
            response.data.forEach(element => {
                console.log(element);
                salesDNSTemp.push({"label": element.NAME + " " + element.SURNAME, "value": element.ID});
            }
            );
            setSalesDNSData(salesDNSTemp);
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const {
        page, 
        limit, 
        handlePageChange, 
        handleLimitChange 
      } = usePagination();



    const {
      sortColumn, 
      sortType, 
      handleSortColumn
  } = useTable();

    const {fetchUserID,fetchIdToken} = useUserToken();
 
    useImperativeHandle(ref, () => ({
        refresh : () =>  {
            getData();
        }
    }));

    const getData = async () => {

        const UserID = await fetchUserID();
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }
        
        const api = configdata.API_BASE + '/sales-visitors';
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,  
            "type" : "GET_VISITORS",
            "USER_ID" : UserID,
        },{headers})
        .then((response) => {
            console.log(response);

                setData(response.data);
                let columns = Object.keys(response.data[0]);
                setSearchKeywords(createKeywordsArray(columns));
                setLoading(false);
         
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    useEffect(() => {
        getData();
        getSalesDNS();
    }, []);

    useEffect(() => {
        console.log("Data Changed");
    }, [data]);


    const CustomCell = ({rowData, dataKey, ...props}) => {
        return (
            <Table.Cell {...props}>
                <Button onClick={() => {
                    props.chooseCurrentVisitor(rowData);
                }}>
                    {rowData[dataKey]}
                </Button>
            </Table.Cell>
        )
    }



    const [searchKeywords, setSearchKeywords] = useState({});

    function createKeywordsArray(columnsData) {
      let keywords = {};
      columnsData.forEach((item) => {
        keywords[item] = "";
      });
      console.log(keywords);
      return keywords;
    }

          function sort(data) {
        if (sortColumn && sortType) {
          return data.sort((a, b) => {
            let x = a[sortColumn];
            let y = b[sortColumn];
            if (typeof x === 'string') {
              x = x.charCodeAt();
            }
            if (typeof y === 'string') {
              y = y.charCodeAt();
            }
            if (sortType === 'asc') {
              return x - y;
            } else {
              return y - x;
            }
          });
      }
      return data;
    }

    function filterBase(data){
      if (!Array.isArray(data)) {
        console.error('Data is not an array', data);
        return [];
      }

      let filteredData = [...data]; // Make a copy of the data array

      Object.keys(searchKeywords).forEach((column) => {
        const keyword = searchKeywords[column];
        if (keyword) {
          if (column === 'LAST_CALL' && Array.isArray(keyword)) {
            const [startDate, endDate] = keyword.map(date => new Date(date).getTime());
            filteredData = filteredData.filter((row) => {
              const rowDate = new Date(row[column]).getTime();
              return rowDate >= startDate && rowDate <= endDate;
            });
          } else if (column === 'STS_DNS' ) {
            console.log(keyword);
            // Assuming keyword is an array of selected values for tag picker
            filteredData = filteredData.filter((row) => keyword.label.includes(row[column]));
          } else if (typeof keyword === 'string') {
            filteredData = filteredData.filter((row) =>
              row[column].toString().toLowerCase().includes(keyword.toLowerCase())
            );
          }
        }
      });

      console.log(filteredData);
      return filteredData;
      
    }
   
   
  const filterData = () => {
        // Make sure data is an array before filtering
      let filteredData = filterBase(data);

      // Sorting logic remains unchanged
      filteredData = sort(filteredData);

      // Pagination logic remains unchanged
      return filteredData.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
    });
  };



    
    // Filter data based on searchKeywords
    const renderSearchInput = (column,type) => {

      const [selection,setSelection] = ""

      function clearSearch(column){
        setSearchKeywords({
          ...searchKeywords,
          [column]: ""
        });
      }


      // Check if the column is meant for date range inputs
      if (column === "LAST_CALL") {
        return (
          <DateRangePicker
            style={{ width: '80%' }}
            value={searchKeywords[column]}
            placeholder=""
            placement='auto'
            onChange={(value) => handleSearch(column, value)}
          />
        );
      }
      else if (column === "STS_DNS") {
        return (
          <SelectPicker
            style={{ width: '80%' }}
            data= {salesDNSData}
            value={selection}            
            onClean={() => clearSearch(column)}
            placeholder="Danışman Seçiniz"
            onSelect={(value, item) => {handleSearch(column, item); setSelection(item.value)}}
          />
        );
      }else{
        return Object.keys(searchKeywords).includes(column) ? (
          <Input
            placeholder="Ara"
            value={searchKeywords[column]}
            onChange={(value) => handleSearch(column, value)}
            style={{ height: 30, width: column === "ID" ? 30 : '80%' }}
          />
        ) : (
          <View style={{ height: 30 }} />
        );
      }

    };
    
    // Modified handleSearch to accommodate the date range (or add a new function specifically for dates)
    const handleSearch = (column, value,type) => {
      console.log(column, value);

      setSearchKeywords({
        ...searchKeywords,
        [column]: value,
        [type] : type
      });

    };




    const [columns,setColumns] = useState([
        {
            "DATAKEY": "NAME",
            "LABEL": "İsim",
            "TYPE": "TEXT",
            "HIDDEN": true
        },
        {
            "DATAKEY": "SURNAME",
            "LABEL": "Soyisim",
            "TYPE": "TEXT",
            "HIDDEN": true


        },
        {
            "DATAKEY": "FULL_NAME",
            "LABEL": "İsim",
            "TYPE": "TEXT",
            "HIDDEN": false
        },
        {
            "DATAKEY": "EMAIL",
            "LABEL": "Email",
            "TYPE": "TEXT",
            "HIDDEN": false

        },
        {
            "DATAKEY": "PHONE",
            "LABEL": "Telefon",
            "TYPE": "TEXT",
            "HIDDEN": false

        },

        {
            "DATAKEY": "STS_DNS",
            "LABEL": "Satış Danışmanı",
            "TYPE": "SELECT",
            "HIDDEN": false

        },
        {
          "DATAKEY": "LAST_VISIT",
          "LABEL": "Son Ziyaret",
          "TYPE": "DATE_RANGE",
          "HIDDEN": false
        },
        {
          "DATAKEY": "FIRST_VISIT",
          "LABEL": "İlk Ziyaret",
          "TYPE": "DATE_RANGE",
          "HIDDEN": false
        },
        {
            "DATAKEY": "LAST_CALL",
            "LABEL": "Son Arama",
            "TYPE": "DATE_RANGE",
            "HIDDEN": false

        },
        {
            "DATAKEY": "NOTES",
            "LABEL": "Notlar",
            "TYPE": "TEXT",
            "HIDDEN": false

        },        
        {
          "DATAKEY": "PROFESSION",
          "LABEL": "Meslek",
          "TYPE": "TEXT",
          "HIDDEN": false

      }

    ]);

    const allColumns = [
      {
      label: "İSİM",
      value: "FULL_NAME"

    },
    {
      label: "EMAIL",
      value: "EMAIL"

    },
    {
      label: "TEL",
      value: "PHONE"

    },

    {
      label: "SATIŞ DANIŞMANI",
      value: "STS_DNS"

    },
    {
      label: "SON ZİYARET",
      value: "LAST_VISIT"

    },
    {
      label: "İLK ZİYARET",
      value: "FIRST_VISIT"

    },
    {
      label: "SON ARAMA",
      value: "LAST_CALL"

    },
    {
      label: "NOT",
      value: "NOTES"

    },
    {
      label: "MESLEK",
      value: "PROFESSION"

    },

  ]
    const [choosenColumns , setChoosenColumns] = useState(["ID","FULL_NAME","EMAIL","PHONE","PROFESSION","STS_DNS","LAST_VISIT","FIRST_VISIT","LAST_CALL","NOTES"]);

    function handleColumnChange(value){
      console.log(value);
      setChoosenColumns(value);

      columns.forEach((item) => {
        if(value.includes(item.DATAKEY)){
          item.HIDDEN = false;
        }else{
          item.HIDDEN = true;
        }
      }
      )

    }


    const TextCell = ({rowData, dataKey, ...props}) => {
        return (
            <Table.Cell {...props}>
                <CustomText>{rowData[dataKey]}</CustomText>
            </Table.Cell>
        )
    }


    const [selectedFilterCars, setSelectedFilterCars] = useState([]);

    return (
        <View>
          <Panel collapsible defaultExpanded header=" Tablo Yönetimi">
            <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', marginBottom: 15, alignItems: 'center'}}>
              <CustomText style={{color: '#5A6273'}}>Sütunlar  </CustomText>
              <TagPicker data={allColumns} value={choosenColumns} onChange={handleColumnChange} style={ {flex : 1}} />
              <ExportData tableHead={choosenColumns} columns={choosenColumns} tableName={"Ziyaretçi Tablosu"} textLabel = {" Dışarı Aktar "} data={filterBase(data)} />
            </View>
          </Panel>
       
            <Table
                
                data={filterData()}
                loading={loading}
                autoHeight
                headerHeight={100}
                sortColumn={String(sortColumn)} 
                sortType={typeof(sortType) == "string" ? sortType : "desc"} 
                onSortColumn={handleSortColumn}
                wordWrap
                
            >
                <Table.Column width={50} align="center" sortable >
                    <Table.HeaderCell>Id</Table.HeaderCell>
                    <CustomCell chooseCurrentVisitor={props.chooseCurrentVisitor} dataKey="ID" />
                </Table.Column>

                {
                    columns.map((item) => {
                        return (
                          item.HIDDEN == false &&
                            <Table.Column width={250} resizable sortable >
                                <Table.HeaderCell>
                                    <View style={{alignItems: 'flex-start'}}>
                                        {renderSearchInput(item.DATAKEY,item.TYPE)}
                                        <View style={{height: 10}}></View>
                                        <CustomText style={{color: 'powderblue',  textAlign: 'center'}}> {item.LABEL} </CustomText>
                                    </View>
                                </Table.HeaderCell>
                                <TextCell dataKey={item.DATAKEY} />
                            </Table.Column>
                        )
                    })
                }

                </Table>
                <Pagination
                  prev
                  next
                  first
                  last
                  ellipsis
                  boundaryLinks
                  maxButtons={5}
                  size="xs"
                  layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                  total={data.length}
                  limitOptions={[10, 20,30,40,50,100]}
                  limit={limit}
                  activePage={page}
                  onChangePage={handlePageChange}
                  onChangeLimit={handleLimitChange}
                  locale={{
                    more: 'Daha',
                    prev: 'Önceki',
                    next: 'Sonraki',
                    first: 'İlk',
                    last: 'Son',
                    limit: '{0} / sayfa',
                    total: 'Toplam Satır: {0}',
                    skip: 'Git {0}'
                }}
                  />
        </View>
    )
});

const VisitorsLabeled = forwardRef((props,ref) => {

    const [generalSearch, setGeneralSearch] = useState("");
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const {fetchUserID,fetchIdToken} = useUserToken();
 
    useImperativeHandle(ref, () => ({
        refresh : () =>  {
            getData();
        }
    }));

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {

        const UserID = await fetchUserID();
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }
        
        const api = configdata.API_BASE + '/sales-visitors';
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,  
            "type" : "GET_VISITORS",
            "USER_ID" : UserID,
        },{headers})
        .then((response) => {
            console.log(response);

                setData(response.data);
                // let columns = Object.keys(response.data[0]);
                // setSearchKeywords(createKeywordsArray(columns));
                // setLoading(false);
         
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }

    function search(){

        //filter for all columns but handle nullable column values
        let temp = data.filter((item) => {
            let flag = false;
            Object.keys(item).forEach((key) => {
                if(item[key] != null){
                    if(item[key].toString().toLowerCase().includes(generalSearch.toLowerCase())){
                        flag = true;
                    }
                }
            }
            );
            return flag;
        });
        setData(temp);
    }

    return(
      <View>
        <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-between', marginBottom: 15}}>
          <Group header="Ara">
            <InputGroup>
            <Input style={styles.standard} value={generalSearch} onChange={setGeneralSearch} />
            <InputGroup.Button onClick={search}>
              <Icon><FontAwesomeIcon icon={solid('search')} size={15} color={'#5A6273'} /></Icon>
            </InputGroup.Button>
            </InputGroup>
          </Group>
        </View>
        <List>
          {data.map((item) => {
            return(
            <List.Item>
              <VisitorLabel data={item} addTab = {props.chooseCurrentVisitor} />
            </List.Item>
            )
          })}
        </List>
      </View>
    )
});

const VisitorLabel = (props) => {
    return (
      <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
        <View>
            <CustomText fontSize={16} fontWeight={800} style={{color: '#5A6273'}}>{props.data.NAME + " " + props.data.SURNAME}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.EMAIL}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.PHONE}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.PROFESSION}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.STS_DNS}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.LAST_CALL}</CustomText>
            <CustomText fontSize={14} fontWeight={400} style={{color: '#5A6273'}}>{props.data.NOTES}</CustomText>
        </View>
        <View>
            <Button onClick={() => {props.addTab(props.data)}}>Görüntüle</Button>
        </View>
      </View>

    )
}


const NewVisitorPanel = (props) => {
    const {messageToggle} = useMessage()
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [notes, setNotes] = useState("");
    const [loading, setLoading] = useState(false);

    const [salesDNS, setSalesDNS] = useState("");
    const [salesDNSData, setSalesDNSData] = useState([]);



    useEffect(() => {

        getSalesDNS();
    }, []);

    const {fetchUserID,fetchIdToken} = useUserToken();

    const postData = async () => {
        const UserID = await fetchUserID();
        const idToken = await fetchIdToken();
        const api = configdata.API_BASE + '/sales-visitors';

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }



        axios.post(api,{
            "ENV" :configdata.APP_ENV ,
            "type" : "NEW_VISITOR",
            "STS_DNS" : salesDNS.value,
            "VISITOR_NAME" : name,
            "VISITOR_SURNAME" : surname,
            "VISITOR_EMAIL" : email,
            "VISITOR_PHONE" : phone,
            "VISITOR_NOTES" : notes
        },{headers})
        .then((response) => {
            console.log(response);
            if(response.data == "SUCCESS"){
            props.reset();
            setLoading(false);
            messageToggle("Ziyaretçi Eklendi","success");
            }
        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }




    const getSalesDNS = async () => {
        const api = configdata.API_BASE + '/sales-visitors';
        const idToken = await fetchIdToken();

        const headers = {
            'Authorization': `Bearer ${idToken}`
        }

        let salesDNSTemp = [];
        axios.post(api,{
            "ENV" :configdata.APP_ENV ,
            "type" : "GET_SALES_DNS",
        },{headers})
        .then((response) => {
            console.log(response);
            response.data.forEach(element => {
                console.log(element);
                salesDNSTemp.push({"label": element.NAME, "value": element.ID});
            }
            );
            setSalesDNSData(salesDNSTemp);

        })
        .catch((error) => {
            console.log("POST ERROR" + error);
        });
    }








    return (
        <View>
            <View style={{flex: 1, flexDirection: 'column', justifyContent: 'space-between', width: '100%'}}>
                <Group header="Satış Danışmanı">
                    <SelectPicker style={styles.standard} data={salesDNSData} value={salesDNS.value} onSelect={(value,item) => {setSalesDNS(item); console.log(item)}} />
                </Group>
                <View style={{height: 20}}></View>
                <Group header="İsim">
                    <Input style={styles.standard}  value={name} onChange={setName} />
                </Group>
                <Group header="Soyisim">
                    <Input style={styles.standard} value={surname} onChange={setSurname} />
                </Group>
                <Group header="Email">
                    <Input style={styles.standard} value={email} onChange={setEmail} />
                </Group> 
                <Group header="Telefon">
                {/* <PhoneInput
                          inputStyle={styles.standard}
                          country={'tr'}
                          value={phone}
                          onChange={phone => setPhone(phone)}
                      /> */}
                  <MaskedInput 
                      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                      value={phone} 
                      onChange={ (value) => setPhone(value)}
                      style={styles.standard} 
                    />
                </Group>
                <Group header="Notlar">
                    <Input as="textarea"  style={styles.standard} value={notes} onChange={setNotes} rows={10} />

                </Group>


                <View style={{height: 20}}></View>
            
                <Button appearance="primary" onClick={postData} loading={loading}> <CustomText fontWeight={800} style={{color: 'white'}}>Kaydet</CustomText> </Button>
            </View>
        </View>
    )
}



const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5 }}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);




const styles = StyleSheet.create({
    group: {
      width: '100%',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginVertical: 3,
    },
    buttonStyle: {
      width: 120,
      alignSelf: 'flex-end',
      backgroundColor: '#5E6EED',
      color: 'white',
      borderRadius: 7,
      marginVertical: 5,
    },
    standard: { flex: 1 , maxWidth: 350, minWidth: 150, width: '100%'},
    Panel: {
        backgroundColor: 'white',
      marginTop: 20,
    },

    bottomBar: {
        height: 40,
        backgroundColor: '#fff',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent:'flex-start',
      },
});


export default SalesVisitorsScreen;
