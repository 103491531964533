import React, { useState ,useEffect, useRef} from 'react';
import { Button , Divider, Drawer, Input, List, Stack ,IconButton } from 'rsuite';
import { PDFDownloadLink , View, Text,Image , pdf, PDFViewer} from '@react-pdf/renderer';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { Font  } from '@react-pdf/renderer';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import CustomText from '../../../Components/CustomText';
import axios from 'axios';
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
Font.register({
    family: 'Artifakt', // give a name for your font
    src: require('../../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT REGULAR.ttf'), // replace with your font file path
});

Font.register({
    family: 'Artifakt-Bold', // give a name for your font
    src: require('../../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT BOLD.ttf'), // replace with your font file path
});

Font.register({
    family: 'Artifakt-Black', // give a name for your font
    src: require('../../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT BLACK.ttf'), // replace with your font file path
});



const CarSummaryCardPdf = (props) => {
    const {fetchIdToken} = useUserToken();

    const DownloadPdf = () => {

        const [loading, setLoading] = useState(false);
        


        const getAllDataAndDownload = async () => {
            let buyinfo = await getBuyInfo();
            let sellinfo = getSellInfo();
            // await download(buyinfo,sellinfo);
            await download();
        }

        const download = async () => {
            setLoading(true);
            const doc = <CarCard data={props.data} buyInfo = {buyInfo} sellinfo = {sellinfo} />;
            const asPDF = pdf([]); // pass an empty array
            asPDF.updateContainer(doc);
            const blob = await asPDF.toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${props.data["PLAKA"]} Araç Özet.pdf`;
            link.click();
            URL.revokeObjectURL(url);
            setLoading(false);
        };

        return(
            <Button onClick={getAllDataAndDownload}>
            {loading ? 'Döküman Oluşturuluyor...' : 'Dökümanı İndir'}
            </Button>
        )
    }

    async function getSellInfo(){
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_SELL_INFO",
            "CAR_ID" : props.data["ID"]
        }
        axios.post(api,body,{headers}).then(response => {
            console.log(response);
            let temp = {...response.data[0]};
            // temp["TEDARİKÇİ"] = clientData.filter(item => item.value == temp["CLIENT_ID"])[0].label;
            // setCurrent(temp);
        }).catch(error => {
            console.log(error);
        })
    }


    async function getBuyInfo(){
        const api = configdata.API_BASE + '/cardata';
        try {
            const token = await fetchIdToken();
            const headers = {
                'Authorization' : `Bearer ${token}`,
            }
            const body = {
                "ENV" : configdata.APP_ENV,
                "type" : "GET_BUY_INFO",
                "CAR_ID" : props.data["ID"]
            }
            const response = await axios.post(api, body, { headers });
            console.log(response);
            let temp = {...response.data[0]};
            // Assuming you might want to uncomment the next line in the future.
            // temp["TEDARİKÇİ"] = clientData.filter(item => item.value == temp["CLIENT_ID"])[0].label;
            console.log(temp);
            return temp;
        } catch (error) {
            console.error(error);
            throw error; // Rethrow the error so it can be caught by the calling function
        }
    }

    async function getDamageHistory(){
        
    }

    const CarCardContainer = (props) => {
        const [buyInfo, setBuyInfo] = useState(undefined);
    
        useEffect(() => {
            async function fetchBuyInfo() {
                try {
                    const data = await getBuyInfo();
                    setBuyInfo(data);
                } catch (error) {
                    console.error('Failed to fetch buy info', error);
                }
            }
    
            fetchBuyInfo();
        }, []); // The empty array ensures this effect runs only once after the initial render
    
        // Render CarCard only after buyInfo is fetched
        return buyInfo != undefined ? (
            <CarCard data={props.data} buyInfo={buyInfo} />
        ) : (
            <CarCard data = {props.data} />

        );
    };

    const [showPdf, setShowPdf] = useState(false);

    const handleClosePdf = () => {
        setShowPdf(false);
    }

    return(
        <View style={{width: '60%'}}>
            <Drawer size="md" placement="left" open={showPdf} onClose={handleClosePdf}>
                <Drawer.Header>
                    <Drawer.Title>PDF Dökümanı</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        {/* <CarCard data={props.data} buyInfo={getBuyInfo()} /> */}
                        <CarCardContainer data={props.data} />
                    </PDFViewer>
                </Drawer.Body>
            </Drawer>
            <IconButton style={{width: '100%'}} icon={<Icon><FontAwesomeIcon icon= {solid('file')} ></FontAwesomeIcon></Icon>} onClick={() => setShowPdf(true)}><CustomText>Özet</CustomText> </IconButton>
        </View>
    )
}




const CarCard = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.headerSection}>
                <Image source={require('../../../../assets/OfferWizard/logoBlueColored.png')} style={{width: 140}} resizeMode='contain' />
                <Text style={styles.title}>Araç Bilgi Formu </Text>
                <Text style={[styles.title,{fontSize : 16}]}> {new Date().toLocaleDateString()}</Text>
            </View>
            <View style={{marginTop: 30, width: '92%' , flexDirection: 'row', justifyContent :'space-between', alignItems: 'center'}}>
                <Image source={require('../../../../assets/BrandLogos/LOGO_' + props.data["MARKA"] + '.png')} style={{height: 80}} resizeMode='contain' />
                <View style={{alignItems: 'flex-start'}}>
                    <Text style={styles.textStyle} > {props.data["MARKA"] + ' ' + props.data["MODEL YILI"]}</Text>
                    <Text style={styles.textStyle} > {props.data["ARAÇ"]} </Text>
                    <Text style={styles.textStyle} > {props.data["VİTES"] + ' , ' + props.data["YAKIT TİPİ"]}</Text>
                </View>
            </View>
            <View style={{marginTop: 30, width: '92%' , flexDirection: 'row', justifyContent :'space-between', alignItems: 'center'}}>
                <View style={{alignItems: 'flex-start'}}>
                    <LabelWithHeader header = "Şasi No : " value = {props.data["ŞASİ"]}/>
                    <LabelWithHeader header = "Motor No : " value = {props.data["MOTORNO"]}/>
                </View>
            </View>
            <View style={{marginTop: 30, width: '92%' , flexDirection: 'row', justifyContent :'space-between', alignItems: 'center'}}>
                {props.buyInfo != undefined && 
                <View style={{alignItems: 'flex-start'}}>
                    <LabelWithHeader header = "Araç Edinim Şekli : " value = {props.data["ARAÇ EDİNİM ŞEKLİ"]}/>
                    <LabelWithHeader header = "KDV Dahil Alış Fiyatı : " value = {formatToCurrency(props.buyInfo["TAX_INC_PRICE"])}/>
                    <LabelWithHeader header = "KDV Hariç Alış Fiyatı : " value = {formatToCurrency(props.buyInfo["TAX_EXC_PRICE"])}/>
                </View>
                }

            </View>
        </Page>
    </Document>
);

function formatToCurrency(input) {
  return input.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
}

const LabelWithHeader = (props) => {
    return( 
    <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', margin: 5}}>
        <Text style={styles.textStyleBold}>{props.header}</Text>
        <Text style={styles.textStyle}>{props.value}</Text>
    </View>
    )
}


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Artifakt',
        fontSize: 11,
        padding: 30,
        alignItems: 'center',
    },
    headerSection: {
        alignItems: 'center',
        width: '95%',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    title: {
        fontSize: 18,
        textAlign: 'center',
        fontFamily: 'Artifakt-Bold',
    },
    textStyle: { textAlign:'left', fontSize: 12, fontFamily: 'Artifakt'},
    textStyleBold : { textAlign:'left', fontSize: 12, fontFamily: 'Artifakt-Bold'},
});
    


export default CarSummaryCardPdf;
