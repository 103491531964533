import React, { useEffect,useState,useCallback, forwardRef, useImperativeHandle } from 'react';
import { useToaster,Schema  ,Message, Button ,List,IconButton ,SelectPicker ,InputGroup, MaskedInput, Input, Drawer, RadioGroup, Radio, Stack, Divider ,Panel } from 'rsuite';
import { Image,View, Text, StyleSheet,TouchableOpacity, Dimensions} from 'react-native';
import { useDropdownData } from '../../hooks/useDropdownData';
import { useUserToken } from '../../hooks/useUserToken';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { createNumberMask } from 'text-mask-addons';
import axios from 'axios';
import configdata from '../../config/config.json';
import CustomText from '../../Components/CustomText';
import { useMessage } from '../../hooks/useMessage';

const RentContractView = forwardRef((props,ref) => {
    
    const {messageToggle} = useMessage();

    const [cars,setCars] = useState([]);


    const [vitesYakıt,setVitesYakıt] = useState("");
    const [bodyStyle,setBodyStyle] = useState({value: '' , label : ''});
    const [bodyStyleData,setBodyStyleData] = useState([]);
    const [clientName,setClientName] = useState('');
    const [price,setPrice] = useState(" TRY");
    const [KMAlt,setKMAlt] = useState(0);
    const [KMUst,setKMUst] = useState(0);
    const [limitKM,setLimitKM] = useState(0);
    const [kmPrice,setKmPrice] = useState(0);
    const [BMP,setBMP] = useState(["","",""])
    const [BMP_ID,setBMP_ID] = useState([0,0,0])
    const [modelYear,setModelYear] = useState("");
    const [month,setMonth] = useState(0);
    const [monthData,setMonthData] = useState([]);
    const [city,setCity] = useState('');
    const [company,setCompany] = useState(undefined);
    const [teklifNo,setteklifNo] = useState("TK000000");

    const initialPrices = {
        MUAF: 0,
        TAM: 0,
        MINI: 0,
        IMM: 0,
        TIRE: 0,
        DIRVER: 0
    };
    
    const [classAPrices, setClassAPrices] = useState( initialPrices);
    const [classBPrices, setClassBPrices] = useState(initialPrices);
    const [classCPrices, setClassCPrices] = useState( initialPrices);
    const [classDPrices, setClassDPrices] = useState(  initialPrices);
    const [classEPrices, setClassEPrices] = useState( initialPrices);
    const [classFPrices, setClassFPrices] = useState(initialPrices);

    function getWithClass(input){
        if(input == 'A'){
            return classAPrices;
        }
        if(input == 'B'){
            return classBPrices;
        }
        if(input == 'C'){
            return classCPrices;
        }
        if(input == 'D'){
            return classDPrices;
        }
        if(input == 'E'){
            return classEPrices;
        }
        if(input == 'F'){
            return classFPrices;
        }
    }

    const modelYearData= [
        {"label" : "2024" , "value" : "2024"},
        {"label" : "2023" , "value" : "2023"},
        {"label" : "2022" , "value" : "2022"},
        {"label" : "2021" , "value" : "2021"},
        {"label" : "2020" , "value" : "2020"},
        {"label" : "2019" , "value" : "2019"},
    ]

    const [templateCars,setTemplateCars] = useState([])

    const vitesYakıtData = [
        {label : 'Benzin Manuel' , value : 'Benzin Manuel'},
        {label : 'Benzin Otomatik' , value : 'Benzin Otomatik'},
        {label : 'Dizel Manuel' , value : 'Dizel Manuel'},
        {label : 'Dizel Otomatik' , value : 'Dizel Otomatik'},
        {label : 'Elektrik Otomatik' , value : 'Elektrik Otomatik'},
        {label : 'Hibrit Otomatik' , value : 'Hibrit Otomatik'},
    ]

    const [carClasses , setCarClasses] = useState([
        {label: 'A' , value: 'A'},
        {label: 'B' , value: 'B'},
        {label: 'C' , value: 'C'},
        {label: 'D' , value: 'D'},
        {label: 'E' , value: 'E'},
        {label: 'F' , value: 'F'},
    ]);

    const [carClass,setCarClass] = useState("");
    const [choosenCarClasses,setChoosenCarClasses] = useState([]);
    const [safetyCatalog,setSafetyCatalog] = useState({});

    const [signatures,setSignatures] = useState([
        {label: 'Oğuzhan Çokgünlü', value:'Oğuzhan Çokgünlü' , object: {name: 'Oğuzhan Çokgünlü', title: 'Filo Yöneticisi', mail: 'oguzhan.cokgunlu@bilen.pro' , phone: '0 535 889 06 06'}},
        {label: 'Ziya Çokgünlü', value:'Ziya Çokgünlü' , object:  {name: 'Ziya Çokgünlü', title: 'Genel Müdür', mail: 'ziya@bilen.pro' , phone: '0532 710 11 10'}},
        {label: 'Hasan Baykal', value: 'Hasan Baykal' , object: {name: 'Hasan Baykal', title: 'Araç Kiralama Yetkilisi', mail: 'hasan.baykal@bilen.pro' , phone: '0 533 397 93 78'}},
        {label: 'Hasan Çankaya', value: 'Hasan Çankaya' , object: {name: 'Hasan Çankaya', title: 'Araç Kiralama Danışmanı', mail: 'hasan.cankaya@bilen.pro' , phone: '0 538 333 17 67'}},
        {label: 'Cemal Turunç', value: 'Cemal Turunç' , object: {name: 'Cemal Turunç', title: 'Araç Kiralama Danışmanı', mail: 'cemal.turunc@bilenoto.com.tr' , phone: '0 532 221 15 55'}},
        ]);
    const [choosenSignatures,setChoosenSignatures] = useState([]);
    const [signature,setSignature] = useState({});

    const [choosenImage,setChoosenImage] = useState('');
    const [offerWaitTime,setOfferWaitTime] = useState(undefined);
    const offerWaitTimes = populateOfferWaitTimes();
    const [openImageDrawer,setOpenImageDrawer] = useState(false);

    const [winterTireAvaliable,setWinterTireAvaliable] = useState(false);
    const [cities,setCities] = useState([
        {label: 'Adana', value: 'Adana'},
        {label: 'Mersin', value: 'Mersin'},
        {label: 'Ankara', value: 'Ankara'},
        {label: 'İstanbul', value: 'İstanbul'},
        {label: 'İzmir', value: 'İzmir'},
    ]);

    const {fetchIdToken} = useUserToken();

    function unformatTLvalue(value){
        return Number(value.replace(' TL','').replace('.','').replace(',','.'));
    }

    async function sendData(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}` 
        }

        let carsTemp = [];
        for(let i = 0; i < cars.length; i++){
            carsTemp.push({
                "BRAND" : cars[i].BMP[0],
                "MODEL" : cars[i].BMP[1],
                "PACKAGE" : cars[i].BMP[2],
                "VITES_YAKIT" : cars[i].VITES_YAKIT,
                "RENT_PRICE" : unformatTLvalue(cars[i].FIYAT),
                "CAR_BODY_STYLE" : cars[i].CAR_BODY_STYLE,
                "KM" : cars[i].KM,
                "KM_LIMIT" : cars[i].KM_LIMIT,
                "KM_LIMIT_PRICE" : unformatTLvalue(cars[i].KM_FIYAT),
                "MODEL_YEAR" : cars[i].MODEL_YILI,
                "RENT_TIME" : cars[i].RENT_TIME,
                "IMAGE" : cars[i].image,
                "CAR_CLASS" : cars[i].class,
            })
        }

        let sfpacks = [];
        for(let i = 0; i < choosenCarClasses.length; i++){
            sfpacks.push({
                "CLASS" : choosenCarClasses[i],
                "PRICE_MUAF" : getWithClass(choosenCarClasses[i])["MUAF"],
                "PRICE_TAM" : getWithClass(choosenCarClasses[i])["TAM"],
                "PRICE_IMM" : getWithClass(choosenCarClasses[i])["IMM"],
                "PRICE_MINI" : getWithClass(choosenCarClasses[i])["MINI"],
                "PRICE_TIRE" : getWithClass(choosenCarClasses[i])["TIRE"],
                "PRICE_DRIVER" : getWithClass(choosenCarClasses[i])["DRIVER"],
                
            })
        }
            
        const body = {
            "type" : "ADD_RENT_INFO",
            "ENV" : configdata.APP_ENV,
            "COMPANY" : company,
            "CLIENT" : clientName,
            "CITY" : city,
            "OFFER_TIME_LIMIT": offerWaitTime,
            "OFFER_DATE" : new Date(),
            "CARS" : carsTemp,
            "SAFETY_PACKS" : sfpacks,
            "SIGNED_EMAIL": choosenSignatures.map((item) => item.mail),
        }

        console.log(body);
        try {
            const response = await axios.post(api, body, { headers: header });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            return error;
        }

    }

    useImperativeHandle (ref, () => ({

        sendData : () => {
            console.log("SEND DATA");
            return sendData();
        },

        getCars : () => {
            console.log("REF CARS ");
            return cars;
        }
        ,
        getCustomer : () => {
            return {"Müşteri" : clientName, "Şirket" : company};
        }
        ,
        getOffer : () => {
            return {"time": offerWaitTime};
        },
        getSignatures : () => {
            return choosenSignatures;
        },
        getSafetyPacks : () => {

            let temp = []
            choosenCarClasses.forEach((carClass) => {
                temp.push( { "class" : carClass , "data" :  getWithClass(carClass)});
            })
            console.log(temp);
            return temp;
            
        }
    }));

    async function getSafetyPackCatalog(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();

        const header = {
            'Authorization' : `Bearer ${token}`
        }
        
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_SAFETY_PACK_CATALOG",
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setSafetyCatalog(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    useEffect(() => {
        if(safetyCatalog.length > 0 && props.safetyPacks == undefined)
        {
            console.log("SAFETY CATALOG");
            safetyCatalog.forEach((item) => {
                    carClasses.forEach((carClass) => {
                        // console.log(item["PRICE_"+carClass]);
                        handleInputChange(item["PRICE_"+carClass.value],item["NAME"],carClass.value);
                })
            })
        }
    },[safetyCatalog])

        

    function handleClose () {
        setOpenImageDrawer(false);
    }


    function populateOfferWaitTimes(){
        const waitTimes = [];
        for(let i = 1; i <= 7; i++){
            waitTimes.push({label: i + ' Gün', value: i});
        }
        return waitTimes;
    }

    const {getBrands, getDropdownValuesWithObject,getDropDownValues} = useDropdownData();

    useEffect(() => {
        // getBrands(setOptionsBMP);
        populateMonthData();
        getSafetyPackCatalog();
        getTemplateCars();
        getDropDownValues("CAR_BODY_STYLES",setBodyStyleData)
    },[]);

    useEffect(() => {
        handleClose();
    },[choosenImage]);


    function addCar(){
        if (isEditing){
            updateCar();
            return;
        }

        const car = {
            'ID' : cars.length,
            'OZET': BMP[0] + ' ' + BMP[1] + ' ' + BMP[2],
            'KASA' : bodyStyle.label,
            'VITES_YAKIT' : vitesYakıt,
            'FIYAT' : price,
            'RENT_TIME': month,
            'KM' : KMAlt + ' - ' + KMUst,
            'CAR_BODY_STYLE' : bodyStyle.label,
            'CAR_BODY_STYLE_ID' : bodyStyle.value,
            'KM_LIMIT' : limitKM,
            'KM_FIYAT' : kmPrice,
            'MODEL_YILI' : modelYear,
            'name' :( BMP[0] + '_' + BMP[1] + '_' + bodyStyle.label  + "_" + BMP[2] ).toUpperCase(),
            'image' : choosenImage,
            'BMP' : BMP,
            'BMP_ID' : BMP_ID,
            'class' : carClass,
        }
        console.log(car);
        if(car != undefined)
        {        
            props.setCars([...cars,car]);
            setCars([...cars,car]);
            console.log([...cars,car]);
        }

        if(!choosenCarClasses.includes(carClass)){
            setChoosenCarClasses([...choosenCarClasses,carClass]);
        }

    }

    function removeCar(car){
        const newCars = cars.filter((item) => item.ID != car.ID);
        setCars(newCars);
        props.setCars(newCars);
        if(choosenCarClasses.includes(car.class) && newCars.filter((item) => item.class == car.class).length == 0){
            const newChoosenCarClasses = choosenCarClasses.filter((item) => item != car.class);
            setChoosenCarClasses(newChoosenCarClasses);
        }

    }

    function addSignature(){
        if(signature != undefined)
        {
            if(choosenSignatures.includes(signature.object)){
                return;
            }
            setChoosenSignatures([...choosenSignatures,signature.object]);
            console.log([...choosenSignatures,signature.object]);
            props.setSignatures([...choosenSignatures,signature.object]);
        }
    }


    function removeSignature(signature){
        const newSignatures = choosenSignatures.filter((item) => item.name != signature.name);
        setChoosenSignatures(newSignatures);
        props.setSignatures(newSignatures);
    }

    // function that replace two items in an array for cars array
    const handleSortEnd = ({ oldIndex, newIndex }) =>
    {
        const newCars = arrayMove(cars, oldIndex, newIndex);
        console.log(newCars);
        setCars(newCars);
        props.setCars(newCars);
    
    }
    
    const arrayMove = (array, oldIndex, newIndex) => {
        const newArray = array.slice(); // Copy the original array to avoid mutations
        if (oldIndex < 0 || oldIndex >= newArray.length || newIndex < 0 || newIndex >= newArray.length) {
            console.error("Invalid index provided.");
            return newArray;
        }
        const temp = newArray[oldIndex];
        newArray[oldIndex] = newArray[newIndex];
        newArray[newIndex] = temp;
        return newArray;
    };
      

    function populateMonthData(){
        const monthData = [];
        for(let i = 1; i <= 36; i++){
            monthData.push({label: i + ' AY', value: i});
        }
        setMonthData(monthData);
    }

    function getTargetImagesByChoosenCar(){
        const targetImages = []
        let nameData = Object.keys(images);
        if(BMP[0] != undefined && BMP[1] != undefined && BMP[2] != undefined){
        nameData.forEach((name) => {
            if(name.includes(BMP[0].toUpperCase()) && name.includes(BMP[1].toUpperCase())){
                targetImages.push(name);
            }
        });
     }
        return targetImages;
    }


    function handleInputChange(value,name, classType) {

        // console.log(name + "  " + classType + typeof(value))
        let valueFormatted = typeof(value) != "number" ? Number(value.replace('TL','').replace('.','').replace(',','.')) : value;

        if(classType == 'A'){
            setClassAPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }
        if(classType == 'B'){
            setClassBPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }
        if(classType == 'C'){
            setClassCPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }
        if(classType == 'D'){
            setClassDPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }
        if(classType == 'E'){
            setClassEPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }
        if(classType == 'F'){
            setClassFPrices(prevPrices => ({
                ...prevPrices,
                [name]: valueFormatted
            }));
        }

    }
    
    function getPriceValue(name, classType){

        if(classType == 'A'){
            return classAPrices[name];
        }
        if(classType == 'B'){
            return classBPrices[name];
        }
        if(classType == 'C'){
            console.log(classCPrices);
            return classCPrices[name];
        }
        if(classType == 'D'){
            return classDPrices[name];
        }
        if(classType == 'E'){
            return classEPrices[name];
        }
        if(classType == 'F'){
            return classFPrices[name];
        }
    }

    useEffect(() => {
        if(props.cars != undefined){
            console.log(props.cars);
            setCars(props.cars);
            // setCustomer(props.Customer);
            // setOffer(props.offer);
        }
    },[props.cars]);


    async function saveCarAsTemplate(){

        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type" : "ADD_RENT_OFFER_CAR_TEMPLATE",
            "BRAND" : BMP_ID[0],
            "MODEL" : BMP_ID[1],
            "PACKAGE" : BMP_ID[2],
            "FUEL_TRANSMISSION" : vitesYakıt,
            "CAR_BODY_STYLE" : bodyStyle.value,
            "MODEL_YEAR" : modelYear,
            "CAR_CLASS" : carClass,
            "IMAGE" : choosenImage,
        }

        console.log(body);

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            if(response.data == "SUCCESS"){
                messageToggle("Şablon başarıyla kaydedildi","success")
            }
            else{
                messageToggle("Şablon kaydedilirken bir hata oluştu.","error");
            }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    async function getTemplateCars(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_RENT_OFFER_CAR_TEMPLATE",
        }


        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            if(response.data != undefined){
                let temp = [];
                console.log(response.data);
                response.data.forEach((item) => {
                temp.push({data : {
                    "BRAND" : item["BRAND"],
                    "BRAND_NAME" : item["BRAND_NAME"],
                    "MODEL" : item["MODEL"],
                    "MODEL_NAME" : item["MODEL_NAME"],
                    "PACKAGE" : item["PACKAGE"],
                    "PACKAGE_NAME" : item["PACKAGE_NAME"],
                    "VITES_YAKIT" : item["FUEL_TRANSMISSION"],
                    "MODEL_YEAR" : item["MODEL_YEAR"].toString(),
                    "CAR_BODY_STYLE" : item["CAR_BODY_STYLE"],
                    "CAR_BODY_STYLE_NAME" : item["CAR_BODY_STYLE_NAME"],
                    "IMAGE" : item["IMAGE"],
                    "CAR_CLASS" : item["CAR_CLASS"],
                    "name" : item["BRAND_NAME"] + " " + item["MODEL_NAME"] + " " + item["PACKAGE_NAME"] + " " + item["CAR_BODY_STYLE_NAME"] + " " + item["MODEL_YEAR"] + " " + item["FUEL_TRANSMISSION"]
                
                }, value : item["BRAND_NAME"] + " " + item["MODEL_NAME"] + " " + item["PACKAGE_NAME"] + " " + item["CAR_BODY_STYLE_NAME"] + " " + item["MODEL_YEAR"] + " " + item["FUEL_TRANSMISSION"]
                , label : item["BRAND_NAME"] + " " + item["MODEL_NAME"] + " " + item["PACKAGE_NAME"] + " " + item["CAR_BODY_STYLE_NAME"] + " " + item["MODEL_YEAR"] + " " + item["FUEL_TRANSMISSION"]
            })
            }
            )
            setTemplateCars(temp);
            
        }
        })

        .catch(function (error) {
            console.log(error);
        });
    }


    useEffect(() => {
        console.log(props.Customer);
        if(props.Customer != undefined){
            console.log(props.Customer);
            setClientName(props.Customer["CLIENT"]);
            setCity(props.Customer["CITY"]);
            setCompany(props.Customer["COMPANY"]);
            setteklifNo(props.Customer["RENT_NO"]);
            setOfferWaitTime(props.Customer["OFFER_TIME_LIMIT"]);
            setWinterTireAvaliable(props.Customer["WINTER_TIRE_COND"]);

        }

        if (props.signedMembers != undefined){
            signatures.forEach((signature) => {
                if(signature.object.mail == props.signedMembers){
                    console.log(signature);
                    setChoosenSignatures([...choosenSignatures,signature.object]);
                }
                }
            )
            
        }
    },[props.Customer]);

    function setClassPrices(classType,prices){
        if(classType == 'A'){
            setClassAPrices(prices);
        }
        if(classType == 'B'){
            setClassBPrices(prices);
        }
        if(classType == 'C'){
            setClassCPrices(prices);
        }
        if(classType == 'D'){
            setClassDPrices(prices);
        }
        if(classType == 'E'){
            setClassEPrices(prices);
        }
        if(classType == 'F'){
            setClassFPrices(prices);
        }
    }

    useEffect(() => {
        if(props.safetyPacks != undefined){

                let choosenCarClasses = [];
                props.safetyPacks.forEach((item) => {
                    console.log(item);
                    choosenCarClasses.push(item.CLASS);
                    setClassPrices(item.CLASS,item.data);
                }
            )
            setChoosenCarClasses(choosenCarClasses);

        }
    },[props.safetyPacks]);


    function assignTemplateValues(value){
        console.log(value);
        setBMP_ID([value.BRAND,value.MODEL,value.PACKAGE]);
        setSelectedBrandID(value.BRAND)
        setSelectedModelID(value.MODEL)
        setBMP([value.BRAND_NAME,value.MODEL_NAME,value.PACKAGE_NAME]);
        setVitesYakıt(value.VITES_YAKIT);
        setBodyStyle({label: value.CAR_BODY_STYLE_NAME , value : value.CAR_BODY_STYLE})
        setModelYear(value.MODEL_YEAR);
        setChoosenImage(value.IMAGE);
        setCarClass(value.CAR_CLASS);

    }

    const [isEditing , setIsEditing] = useState(false);
    const [editCarIndex,setEditCarIndex] = useState(0);

    function editCar(car){
        console.log(car);
        setBMP_ID([car.BMP_ID[0],car.BMP_ID[1],car.BMP_ID[2]]);
        setSelectedBrandID(car.BMP_ID[0]);
        setSelectedModelID(car.BMP_ID[1]);
        setBMP([car.BMP[0],car.BMP[1],car.BMP[2]]);
        setVitesYakıt(car.VITES_YAKIT);
        setBodyStyle({label: car.CAR_BODY_STYLE , value : car.CAR_BODY_STYLE_ID})
        setModelYear(car.MODEL_YILI.toString());
        setChoosenImage(car.image);
        setCarClass(car.class);
        setKMAlt(car.KM.split('-')[0]);
        setKMUst(car.KM.split('-')[1]);
        setLimitKM(car.KM_LIMIT);
        setKmPrice(car.KM_FIYAT.toString().replace('.',',') + ' TL');
        setPrice(car.FIYAT.toString().replace('.',',') + ' TL');
        setMonth(car.RENT_TIME);
        setIsEditing(true);
        setEditCarIndex(car.ID);
    }

    function updateCar(){
        const car = {
            'ID' : editCarIndex,
            'OZET': BMP[0] + ' ' + BMP[1] + ' ' + BMP[2],
            'KASA' : bodyStyle.label,
            'VITES_YAKIT' : vitesYakıt,
            'FIYAT' : price,
            'RENT_TIME': month,
            'KM' : KMAlt + ' - ' + KMUst,
            'CAR_BODY_STYLE' : bodyStyle.label,
            'CAR_BODY_STYLE_ID' : bodyStyle.value,
            'KM_LIMIT' : limitKM,
            'KM_FIYAT' : kmPrice,
            'MODEL_YILI' : modelYear,
            'name' :( BMP[0] + '_' + BMP[1] + '_' + bodyStyle.label  + "_" + BMP[2] ).toUpperCase(),
            'image' : choosenImage,
            'BMP' : BMP,
            'BMP_ID' : BMP_ID,
            'class' : carClass,

        }
        console.log(car);
        if(car != undefined)
        {
            const newCars = cars.map((item) => {
                if(item.ID == editCarIndex){
                    return car;
                }
                return item;
            });
            setCars(newCars);
            props.setCars(newCars);
            console.log(newCars);
        }

        if(!choosenCarClasses.includes(carClass)){
            setChoosenCarClasses([...choosenCarClasses,carClass]);
        }
        setIsEditing(false);
    }




    const {BrandTypes,ModelTypes,PaketTypes,setSelectedBrandID,setSelectedModelID} = useBrandsDropDown();



    return(
        <View style={{flex: 1, height: '100%', padding: 2}}>
            <Drawer size='md' placement={'right'} onClose={handleClose} open={openImageDrawer} >
                <Drawer.Header>
                    <Drawer.Title> Araç Resimleri </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <ChooseImageComponent targetImages={getTargetImagesByChoosenCar()} setImage={setChoosenImage} />
                </Drawer.Body>
            </Drawer>
            <View>
                <Panel shaded style={{padding: 20,marginBottom: 20, backgroundColor:'white'}}>
                    <View style = {{alignItems : 'center', flex: 1, backgroundColor:'#F2F2F2', padding: 10}}>
                        <CustomText fontWeight={800} fontSize={16} style={{color: '#5A6273'}} > Teklif Bilgileri </CustomText>
                    </View>
                    <Stack wrap spacing={6}>
                        <Group label = {"Şirket"} layout={'column'} >
                            <InputGroup  style={{width: 265}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'building'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <Input value={company} onChange={(value) => {setCompany(value); }}/>
                            </InputGroup>
                        </Group>
                        <Group label = {"İlgili Kişi"} layout={'column'} >
                            <InputGroup  style={{width: 265}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'user'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <Input value={clientName} onChange={(value) => {setClientName(value) }} />
                            </InputGroup>
                        </Group>
                        <Group label = {"Şehir"} layout={'column'} >
                            <InputGroup  style={{width: 265}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'arrow-right'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker style={{width: 225}} placement='autoVerticalStart' data={cities} value={city} onChange={(value) => {setCity(value)}} />
                            </InputGroup>
                        </Group>
                        <Group label = {"Teklif No"} layout={'column'} >
                            <InputGroup  style={{width: 265}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'hashtag'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <Input disabled value={teklifNo} onChange={(value) => {setteklifNo(value)}} />
                            </InputGroup>
                        </Group>
                        <Group label = {"Teklif Geçerlilik süresi"} layout={'column'} >
                            <InputGroup  style={{width: 265}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'clock'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={offerWaitTime} placement='autoVerticalStart' style={{width: 225}} data={offerWaitTimes}  onSelect={(item,value) => {setOfferWaitTime(value.value)}} />
                            </InputGroup>
                        </Group>
                        <Group   label = 'Teklif Verecek Kişi' layout={'column'}>
                            <InputGroup  style={{width: 250}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={solid('file-signature')} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker placement='autoVerticalStart' style={{width: 210}} data ={signatures} onSelect={(item,value) => {setSignature(value)}} ></SelectPicker>
                                <IconButton icon={<Icon><FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Icon>} onClick={addSignature} />
                            </InputGroup>
                        </Group>

                    </Stack>
                    <View>
                        <List style={{flexDirection: 'row', padding: 20}}>
                            {choosenSignatures.map((signature) => {
                                return(
                                    <List.Item>
                                        <SignatureLabel remove={removeSignature} signature = {signature} />
                                    </List.Item>
                                )
                            })}
                        </List>
                    </View>
                </Panel>
                <Panel shaded style={{padding: 20,marginBottom: 20, backgroundColor:'white'}}>
                    <View style = {{alignItems : 'center', flex: 1, backgroundColor:'#F2F2F2', padding: 10}}>
                        <CustomText fontWeight={800} fontSize={16} style={{color: '#5A6273'}} > Araç Girişi </CustomText>
                    </View>
                    <Group label={'Şablon Seç'} layout='column' >
                            <InputGroup  style={{width: 650}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'car'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker placement='autoVerticalStart' style={{width: 600}} data ={templateCars} onSelect={(item,value) => {assignTemplateValues(value.data)}}  ></SelectPicker>
                            </InputGroup>
                    </Group>
                    <Stack wrap spacing={5} >

                        <Group label={'Marka'} layout='column' >
                            <BrandTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></BrandTypes>
                        </Group>
                        <Group label={'Model'} layout='column' >
                            <ModelTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></ModelTypes>
                        </Group>
                        <Group label={'Paket'} layout='column' >
                            <PaketTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} BMP = {BMP}  BMP_ID = {BMP_ID} ></PaketTypes>
                        </Group>
                        <Group label = 'Kasa Tipi' layout={'column'}>
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'car'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={bodyStyle.value} placement='autoVerticalStart' style={{width: 160}} data ={bodyStyleData} onSelect={(item,value) => {setBodyStyle(value)}} ></SelectPicker>
                            </InputGroup>
                        </Group>
                        <Group label = 'Araç Resmi' layout={'column'}>
                                <Button style={{backgroundColor: 'white'}} onClick={() => {setOpenImageDrawer(true)}}>
                                {
                                    <Image source={images[choosenImage]} style={{width: 300, height: 200 }} resizeMode='contain' /> 
                                } 
                                </Button>
                        </Group>
                        <View style={{width: 3}}></View>
                        <Group label = 'Model Yılı' layout={'column'}>
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'calendar'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={modelYear} placement='autoVerticalStart' style={{width: 160}} data ={modelYearData} onSelect={(item,value) => {setModelYear(value.value)}} ></SelectPicker>
                            </InputGroup>
                        </Group>
                        <Group label = 'Vites - Yakıt Tipi' layout={'column'}>
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'car'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={vitesYakıt} placement='autoVerticalStart' style={{width: 160}} data ={vitesYakıtData} onSelect={(item,value) => {setVitesYakıt(item)}} ></SelectPicker>
                            </InputGroup>
                        </Group>
                        <Group label = 'Araç Sınıfı' layout={'column'}> 
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'car'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={carClass} placement='autoVerticalStart' style={{width: 200}} data ={carClasses} onSelect={(item,value) => {setCarClass(value.value)}} ></SelectPicker>
                            </InputGroup>
                        </Group>
                        <Group label={'Km Aralığı'} layout='column' >
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'tachometer-alt'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <MaskedInput value={KMAlt} mask={kmMask} onChange={(value) => {setKMAlt(value)}} />
                                <InputGroup.Addon> - </InputGroup.Addon>
                                <MaskedInput value={KMUst} mask={kmMask} onChange={(value) => {setKMUst(value)}} />
                            </InputGroup>
                        </Group>
                        <Group label = 'Toplam Km Limiti' layout={'column'}>
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'tachometer-alt'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <MaskedInput value={limitKM} mask={kmMask} onChange={(value) => {setLimitKM(value)}} />
                            </InputGroup>
                        </Group>
                        <Group label = 'Km Aşım Fiyatı' layout={'column'}>
                            <InputGroup  style={{width: 200}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <MaskedInput value={kmPrice} mask={tlMask} style={{width: 100}} onChange={(value) => {setKmPrice(value)}} ></MaskedInput>
                            </InputGroup>
                        </Group>
                        <Group label = 'Aylık Kiralama Tutarı ( KDV Hariç )' layout={'column'}>
                            <InputGroup  style={{width: 250}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <MaskedInput value={price} mask={tlMask} style={{width: 200}} onChange={(value) => {setPrice(value)}} ></MaskedInput>
                            </InputGroup>
                        </Group>
                        <Group label = 'Kiralama Süresi (Aylık) ' layout={'column'}>
                            <InputGroup  style={{width: 240}}>
                                <InputGroup.Addon>
                                    <Icon><FontAwesomeIcon icon={'calendar'} ></FontAwesomeIcon></Icon>
                                </InputGroup.Addon>
                                <SelectPicker value={month}   placement='autoVerticalStart' style={{width: 200}} data ={monthData} onSelect={(item,value) => {setMonth(value.value)}} ></SelectPicker>
                            </InputGroup>
                        </Group>
                    </Stack>
                    <View style={{marginVertical: 15 , padding: 20}} >
                        <IconButton icon={<Icon><FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Icon>} onClick={addCar} appearance="primary" color="green" >{ isEditing ? "Güncelle" :  "Araç Ekle"} </IconButton>
                        <View style={{height: 10}} ></View>
                        <IconButton icon={<Icon><FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Icon>} onClick={saveCarAsTemplate} appearance="primary" color="blue" > Şablon Olarak Kaydet </IconButton>
                    </View>
                    <View style = {{alignItems : 'center', flex: 1, backgroundColor:'#F2F2F2', padding: 10}}>
                        <CustomText fontWeight={800} fontSize={16} style={{color: '#5A6273'}} > Araçlar </CustomText>
                    </View>
                    <List sortable onSort={handleSortEnd}>
                        {cars.map((car, index) => { // Add index parameter here
                            console.log(car);
                            return (
                                <List.Item key={car["ID"]} index={index}> {/* Use index here */}
                                    <CarLabel removeCar={removeCar} car={car} editCar={editCar}></CarLabel>
                                </List.Item>
                            );
                        })}
                    </List>
                </Panel>
            </View>
            <View style={{height: 30}}> </View>
        
                <Panel shaded style={{padding: 20,marginBottom: 20, backgroundColor:'white'}}>
                        <View style = {{alignItems : 'center', flex: 1, backgroundColor:'#F2F2F2', padding: 10}}>
                            <CustomText fontWeight={800} fontSize={16} style={{color: '#5A6273'}} >  Güvenlik Paketleri Fiyatlaması </CustomText>
                        </View>
                    {choosenCarClasses.map((className) => {
                            return(
                            <View>
                            <CustomText style={styles.labelBold}> {className +  " Sınıfı"}  </CustomText>
                            <Stack wrap spacing={5} >
                                <Group label={'Tam Kapsamlı Fiyatı'} layout='column' >
                                    <InputGroup  style={{width: 150}}>
                                        <InputGroup.Addon>
                                            <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                        </InputGroup.Addon>
                                        <MaskedInput value={getPriceValue("TAM" , className)} mask={tlMask} onChange={(value) => {handleInputChange(value,"TAM",className)}} name="TAM" />
                                    </InputGroup>
                                </Group>
                                <Group label={'Mini Onarım Fiyatı'} layout='column' >
                                    <InputGroup  style={{width: 150}}>
                                        <InputGroup.Addon>
                                            <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                        </InputGroup.Addon>
                                        <MaskedInput value={getPriceValue("MINI" , className)} mask={tlMask} onChange={(value) => {handleInputChange(value,"MINI",className)}} name="MINI" />
                                    </InputGroup>
                                </Group>
                                <Group label={'İMM Paketi Fiyatı'} layout='column' >
                                    <InputGroup  style={{width: 150}}>
                                        <InputGroup.Addon>
                                            <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                        </InputGroup.Addon>
                                        <MaskedInput value={getPriceValue("IMM" , className)} mask={tlMask} onChange={(value) => {handleInputChange(value,"IMM",className)}} name="IMM" />
                                    </InputGroup>
                                </Group>
                                <Group label={'Kış Lastiği fiyatı'} layout='column' >
                                    <InputGroup  style={{width: 150}}>
                                        <InputGroup.Addon>
                                            <Icon><FontAwesomeIcon icon={'lira-sign'} ></FontAwesomeIcon></Icon>
                                        </InputGroup.Addon>
                                        <MaskedInput value={getPriceValue("TIRE" , className)} mask={tlMask} onChange={(value) => {handleInputChange(value,"TIRE",className)}} name="TIRE" />
                                    </InputGroup>
                                </Group>
                            </Stack>
                        </View>
                            )
                        })
                    }
                </Panel>

            <View style={{height: 30}}> </View>

            {/* <Button onClick={sendData}> Teklifi Kaydet </Button> */}
        </View>)
});



export default RentContractView;




const SignatureLabel = (props) => {
    return (
        <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around'}} >  
            <Button style={{backgroundColor:'#FF4747'}} onClick={() => {props.remove(props.signature)}} > <CustomText style={{color: '#fff'}}> Sil </CustomText> </Button>
            <CustomText fontWeight={800}> {props.signature.name} </CustomText>
            <CustomText> {props.signature.title} </CustomText>
            <CustomText> {props.signature.mail} </CustomText>
            <CustomText> {props.signature.phone} </CustomText>
        </View>
    )
}

const ChooseImageComponent = (props) => {

    return (
      <View>
        <List>
            {props.targetImages.map((image) => {
                return (
                    <List.Item>
                        <View style={{alignItems: 'center', width: 420}}>
                         
                            <Button style={{backgroundColor: 'white'}} onClick={() => {props.setImage(image)}} >    <Image source={images[image]} style={{width: 400, height: 400 / 3 }} resizeMode='contain' /></Button>
                        </View>
                    </List.Item>
                );
            })}
        </List>
      </View>
    );
}

const CarLabel = (props) => {

    return (
        <View style={{flexDirection: 'row', height: 120, alignItems: 'center', padding: 3 , flex: 1}}>
            <IconButton icon={<Icon><FontAwesomeIcon icon={solid("edit")}></FontAwesomeIcon></Icon>} onClick={() => {props.editCar(props.car)}} />
            <Image {...props.car} style={{height: 72, width: 180}} resizeMode='contain' source={images[props.car.image]} />
            <View style ={{flex: 1 }}>
                <CustomText  style={styles.label} > {props.car.OZET.toUpperCase()} </CustomText>
                <CustomText  style={styles.label} > {props.car.VITES_YAKIT} </CustomText>
                <CustomText  style={styles.label} > {props.car.MODEL_YILI} </CustomText>
                <CustomText  style={styles.label} > {props.car.KM} </CustomText>
            </View>
            <View style ={{flex: 1 }}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <CustomText style={styles.label} > Kiralama Süresi : </CustomText>
                    <CustomText  style={styles.label} >  {props.car.RENT_TIME + " AY"} </CustomText>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <CustomText style={styles.label} > Km Limiti : </CustomText>
                    <CustomText style={styles.label} >  {props.car.KM_LIMIT} </CustomText>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <CustomText style={styles.label} > Km Aşım Fiyatı : </CustomText>
                    <CustomText style={styles.label} >  {props.car.KM_FIYAT} </CustomText>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <CustomText style={styles.label} > Aylık Kira Bedeli : </CustomText>
                    <CustomText style={styles.label} >  {props.car.FIYAT} </CustomText>
                </View>
            </View>
            <Button style={{width: 8, height: '100%'}} onClick={() => {props.removeCar(props.car)}} > x </Button>

        </View>
    );
}


const kmMask =  createNumberMask({
    prefix: '',
    suffix: ' KM',
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 0,
    integerLimit: 7,
});


const tlMask =  createNumberMask({
    prefix: '',
    suffix: ' TL',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2,
    integerLimit: 7,
});

// Create styles
const styles = StyleSheet.create({
    label: {
        textAlign: 'left',
        padding: 5,
    },
    labelBold: {
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    labelHeader:{
        textAlign: 'right',
        padding: 5,
    },
    labelWhite: {
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    standard: {
        width: 160,
    },
    dropDownGroupBack : {
        borderRadius: 12,flexDirection: 'row',height: 40, paddingHorizontal : 0,justifyContent: 'center', alignItems: 'center',backgroundColor:'#F2FFFB'}

});

function Group ({ label, children, layout }) {
    return (
        <View style={{flexDirection: layout == "inline" ? 'row' : 'column', flex : 1, width: '100%',alignItems: 'flex-start',margin: 10, maxWidth: 300 , justifyContent: 'center'}}>
            <CustomText style={{ fontSize: 14, color: '#000', margin: 10, textAlign: 'right'}}>{label}</CustomText>
            <div>{children}</div>
        </View>
    );
}


export const useBrandsDropDown = () => {
    const [BMPOptions, setBMPOptions] = useState([]);
    const [choosableModels, setChoosableModels] = useState([]);
    const [choosablePacks, setChoosablePacks] = useState([]);
    const [selectedBrandID, setSelectedBrandID] = useState(0);
    const [selectedModelID, setSelectedModelID] = useState(0);
    const { getBrands } = useDropdownData();
  
    useEffect(() => {
      getBrands(setBMPOptions);
    }, []);

    
  
    useEffect(() => {
      console.log("Selected Brand ID:", selectedBrandID);
      const filteredItems = BMPOptions.filter((item) => item.id === selectedBrandID);
      if (filteredItems.length > 0 && filteredItems[0].children) {
        setChoosableModels(filteredItems[0].children);
      }
    }, [BMPOptions, selectedBrandID]);

    useEffect(() => {
      const filteredItems = choosableModels.filter((item) => item.id === selectedModelID);
      console.log("Filtered Items:", filteredItems);
    
      if (filteredItems.length > 0 && filteredItems[0].children) {
        console.log("Setting choosablePacks:", filteredItems[0].children);
        setChoosablePacks(filteredItems[0].children);
      } else {
        console.log("Resetting choosablePacks to []");
        setChoosablePacks([]); // <-- Consider resetting if conditions aren't met
      }
    }, [choosableModels, selectedModelID]);
    
    function setSelectedBrand(value){
      setSelectedBrandID(value);
    }
    function setSelectedModel(value){
      setSelectedModelID(value);
    }

  

    const BrandTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard} placeholder={"MARKA"}  value={props.BMP[0]} onClean={() => {props.setBMP_ID([0,0,0]); props.setBMP(["","",""])}}  data ={BMPOptions} 
              onSelect={(item,value) => { props.setBMP([value.label,"",""]); props.setBMP_ID([value.id,0,0]); setSelectedBrandID(value.id)}} 
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const ModelTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard}  placeholder={"MODEL"} value={props.BMP[1]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],0,0]); props.setBMP([props.BMP[0],"",""])}}
              data ={choosableModels} onSelect={(item,value) => { props.setBMP([props.BMP[0],value.label,""]); props.setBMP_ID([props.BMP_ID[0],value.id,0]); 
            setSelectedModelID(value.id)
            }}
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const PaketTypes = useCallback (
      (props) => {
      return (
        <View style={styles.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={styles.standard}  placeholder={"PAKET"} value={props.BMP[2]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],0]); props.setBMP([props.BMP[0],props.BMP[1],""])}}
              data ={choosablePacks} onSelect={(item,value) => { props.setBMP([props.BMP[0],props.BMP[1],value.label]); props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],value.id]); }}
          ></SelectPicker>
        </View>
      )
    },[choosablePacks,choosableModels,BMPOptions])


    return {
      BrandTypes,
      ModelTypes,
      PaketTypes,
      setSelectedBrandID,
      setSelectedModelID,
    }
}



const images = {
    'FIAT_EGEA_SEDAN_EASY': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_EASY.PNG'),
    'FIAT_EGEA_SEDAN_URBAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_URBAN.PNG'),
    'FIAT_EGEA_SEDAN_LOUNGE': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_LOUNGE.PNG'),
    'ALFA ROMEO_TONALE': require('../../../assets/CarStockImages/ALFA ROMEO_TONALE.PNG'),
    'AUDI_A3': require('../../../assets/CarStockImages/AUDI_A3.PNG'),
    'AUDI_A4': require('../../../assets/CarStockImages/AUDI_A4.PNG'),
    'AUDI_A6': require('../../../assets/CarStockImages/AUDI_A6.PNG'),
    'CITROEN_C3_AIRCROSS': require('../../../assets/CarStockImages/CITROEN_C3_AIRCROSS.PNG'),
    'CITROEN_C3': require('../../../assets/CarStockImages/CITROEN_C3.PNG'),
    'DACIA_DUSTER': require('../../../assets/CarStockImages/DACIA_DUSTER.PNG'),
    'FIAT_DOBLO': require('../../../assets/CarStockImages/FIAT_DOBLO.PNG'),
    'FIAT_EGEA_CROSS_WAGON': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS_WAGON.PNG'),
    'FIAT_EGEA_CROSS': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS.PNG'),
    'FIAT_EGEA_SEDAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN.PNG'),
    'FIAT_EGEA_HATCHBACK': require('../../../assets/CarStockImages/FIAT_EGEA_HATCHBACK.PNG'),
    'FIAT_FIORINO': require('../../../assets/CarStockImages/FIAT_FIORINO.PNG'),
    'FORD_COURIER': require('../../../assets/CarStockImages/FORD_COURIER.PNG'),
    'FORD_RANGER': require('../../../assets/CarStockImages/FORD_RANGER.PNG'),
    'HYUNDAI_BAYON': require('../../../assets/CarStockImages/HYUNDAI_BAYON.PNG'),
    'HYUNDAI_i20': require('../../../assets/CarStockImages/HYUNDAI_i 20.PNG'),
    'JEEP_COMPASS': require('../../../assets/CarStockImages/JEEP_COMPASS.PNG'),
    'JEEP_RENEGADE': require('../../../assets/CarStockImages/JEEP_RENEGADE.PNG'),
    'JEEP_AVENGER': require('../../../assets/CarStockImages/JEEP_AVENGER.PNG'),
    'MERCEDES_VITO': require('../../../assets/CarStockImages/MERCEDES_VITO.PNG'),
    'MITSIBUSHI_L200': require('../../../assets/CarStockImages/MITSIBUSHI_L200.PNG'),
    'PEUGEOT_2008': require('../../../assets/CarStockImages/PEUGEOT_2008.PNG'),
    'PEUGEOT_208': require('../../../assets/CarStockImages/PEUGEOT_208.PNG'),
    'PEUGEOT_408': require('../../../assets/CarStockImages/PEUGEOT_408.PNG'),
    'PEUGEOT_3008': require('../../../assets/CarStockImages/PEUGEOT_3008.PNG'),
    'PEUGEOT_TRAVALLER': require('../../../assets/CarStockImages/PEUGEOT_TRAVALLER.PNG'),
    'RENAULT_CLIO': require('../../../assets/CarStockImages/RENAULT_CLIO.PNG'),
    'RENAULT_CAPTUR': require('../../../assets/CarStockImages/RENAULT_CAPTUR.PNG'),
    'RENAULT_TALIANT': require('../../../assets/CarStockImages/RENAULT_TALIANT.PNG'),
    'RENAULT_MEGANE': require('../../../assets/CarStockImages/RENAULT_MEGANE.PNG'),
    'RENAULT_ZOE': require('../../../assets/CarStockImages/RENAULT_ZOE.PNG'),
    'TOYOTA_COROLLA': require('../../../assets/CarStockImages/TOYOTA_COROLLA.PNG'),
    'TOYOTA_HILUX': require('../../../assets/CarStockImages/TOYOTA_HILUX.PNG'),
    'SKODA_OCTAVIA': require('../../../assets/CarStockImages/SKODA_OCTAVIA.PNG'),
    'SKODA_SUPERB': require('../../../assets/CarStockImages/SKODA_SUPERB.PNG'),
    'VOLKSWAGEN_POLO': require('../../../assets/CarStockImages/VOLKSWAGEN_POLO.PNG'),
    'VOLKSWAGEN_PASSAT': require('../../../assets/CarStockImages/VOLKSWAGEN_PASSAT.PNG'),
    'VOLKSWAGEN_CARAVELLE': require('../../../assets/CarStockImages/VOLKSWAGEN_CARAVELLE.PNG'),
    'OPEL_CORSA': require('../../../assets/CarStockImages/OPEL_CORSA.PNG'),
    '' : require('../../../assets/CarStockImages/placeholderCar.png'),
};
        