import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { Message , useToaster,Modal, Button, Form ,Nav,TreePicker ,SelectPicker , MultiCascader,Input,Pagination,IconButton, Table ,CheckTreePicker,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider} from 'rsuite';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'

import ExportData from '../../Components/TableManagement/ExportData';

import UpdateCustomerScreen from './UpdateCustomerScreen';
import FreeTextFilter from '../../Components/TableFilters/FreeTextFilter';
import TagPickerFilter from '../../Components/TableFilters/TagPickerFilter';

import configdata from '../../config/config.json';
import NewCustomerScreen from './NewCustomerScreen';

  const RentCustomerManagementScreen = forwardRef((props,ref) =>  {

    const tableRef = useRef()
    const filterRef = useRef()
    const [data,setData] = useState([]);   
    const [activate,closeIndictor] = useState(true);  
    const [refresh,setRefresh] = useState(false);
    
    function getCustomers () {
          const api = configdata.API_BASE + '/customers';
          axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS_TABLE"
          }).then(
              (response) => {
                  console.log(response.data);
                  setData(response.data);
                  closeIndictor(false);
                  // findOwnerFirms(response.data);
                  // getCarBodyTypes(response.data);
                  // getServiceKMTypes(response.data);
                  // getMinimumMaximumYear(response.data);
                  
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    } 

    function reset(){
      console.log("reset");
      getCustomers();
    }

    useEffect(()=> {
      if(data.length > 0){
        console.log("dataChanged");
        tableRef.current.refresh();
      }
    },[data])

    // TABLE MANAGEMENT
    // all table headers ?? TODO -> can be taken from sql database 
    const [tableHead,setTableHead] = useState([ 
        "NO",
        "MÜŞTERİ ADI",
        "MÜŞTERİ TİPİ",
        "TCKN",
        "ADRES",
        "MAIL ADRESİ",
        "KEP ADRESİ",  
        "VKN",
        "VERGİ DAİRESİ",
        "İLETİŞİM NUMARASI",
        "TEDARİKÇİ",

]); 

    useImperativeHandle(ref,()=>({

        removeElement: (element) => {
            removeElementEnabledTabs(element);
        }
    }))

    
    const [choosenAgreement, setChoosenAgr] = useState(undefined)
    const [CarChoosenRefresh,setChooseRefresh] = useState(false);
    const [isPopUpvisible,setPopUpVisible] = useState(false) // car popup for expandable car window option TO BE DEVELOPED -- ADD SCROLL for pdf viewer


    function chooseRow(input){
      setChooseRefresh(!CarChoosenRefresh);
      setChoosenAgr(input);
    }



    //  const  AgreementInfo = () => (<AgreementInfoScreen data= {choosenAgreement} ></AgreementInfoScreen>)

    // table Button controllers

    useEffect(()=>{if(choosenAgreement != undefined ||choosenAgreement != null) {
        
        // AddTab(choosenAgreement); console.log("car choose")
    } 
    } ,[CarChoosenRefresh])
    
    useEffect(()=>{getCustomers(); },[])

    
    // FILTER Operations 
    const [filteredData,setFiltered] = useState([]);
    const [isFiltered, setApplied] = useState(false);

    function resetTableFilter(){
      setApplied(false);
      setFiltered(data);
      tableRef.current.refresh();
    }

    function filterApplied(input){
      setApplied(input)
      tableRef.current.setPage1();
    }

    return(
      <View style = {{ flex: 1 ,height: '100%'}}>
          {data.length == 0 ? (
            <ActivityIndicator animating = {data.length == 0} color = '#BABABA' size = 'large' style={{marginTop : 40}}/>) : (
                <View style = {{flex:4}}>
                    <View style={{height :'100%',padding: 20,flex: 1}}>
                            <TableFilter  ref ={filterRef}  reset = {resetTableFilter} initialData= {data}  data =  {data} isFilterApplied={isFiltered} setFiltered = {setFiltered} setApplied={filterApplied}> </TableFilter>
                            <TableRSuite reset={() => {reset();}} refresh={refresh} setCarData={chooseRow} ref ={tableRef} tableHead = {tableHead} data = { isFiltered ? filteredData :data}></TableRSuite>
                    </View>
                </View>
          )}
      </View>
    );
  }
  );
  
  const TableFilter = forwardRef( (props,ref) =>{


    const [refresh , setRefresh] = useState(false);
    const [filterChange , setFilterChange] = useState(0);
    const toggle_refresh = () => {
        setRefresh(!refresh)
    }

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      refresh: () => {
        toggle_refresh()
      },
      filter: () => {
        toggle_refresh();
      }
    }))

    const customerNameRef = useRef();
    const customerTypeRef = useRef();
    const customerVKNRef = useRef();
    const customerTCKNRef = useRef();

    function resetAndClear(){
      props.reset();
      customerNameRef.current.clear();
      customerTypeRef.current.clear();
      customerVKNRef.current.clear();
      customerTCKNRef.current.clear();
      props.setApplied(false)
    }

    // function reset(){
    //   props.reset();
    //   setFilterChange(filterChange + 1);
    // }

    // useEffect(()=> {
    //   if(filterChange > 0){
    //     filter();
       
    //   }
    // },[filterChange])

    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }
    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }
    function multipleFilter(input){
      let first =  input.shift();
      let final = intersect(first,input);
      props.setFiltered(final)
      props.setApplied(true)
    }

    function filter(){
    
      var arr1 = customerNameRef.current.filter();
      var arr2 = customerTypeRef.current.filter();
      var arr3 = customerVKNRef.current.filter();
      var arr4 = customerTCKNRef.current.filter();
      multipleFilter([arr1,arr2,arr3,arr4])
    }

    const item_style = {
      narrow: {width: 150},
      standard: { width : 500, marginVertical : 10 },
      viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    return(
         <View style = {{ marginBottom: 10, backgroundColor :'#fff' ,padding : 5, width : '100%' , borderRadius: 7}}>
            <Text style={{marginVertical : 5, fontSize : 16, fontWeight : 'bold', color: '#B4B4B4'}}> FİLTRELE </Text>
        
         <ScrollView contentContainerStyle= {{flexGrow: 1}} horizontal= {true}>
         <View style = {{ alignSelf : 'center',marginBottom: 10 ,padding : 5, width : '100%' , alignItems : 'flex-start' , borderRadius: 7}}>
            <View style = {{flexDirection :'row',justifyContent: 'space-between'}}>
                    {/* <View style={{marginHorizontal : 10}}>
                      <FreeTextFilter filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={plakaRef} data = {props.data} tableHeadName = {"PLAKA"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></FreeTextFilter>
                      <BMPFilter filter={filter}  item_style={item_style} refresh={toggle_refresh} ref ={bmpref} data = {props.data} tableHeadName = {"MARKA"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></BMPFilter>
                      <TagPickerFilter filter={filter}  item_style={item_style} refresh={toggle_refresh} ref ={ruhsatRef} data = {props.data} tableHeadName = {"RUHSAT SAHİBİ"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></TagPickerFilter>
                    
                    </View> */}
                    <View style={{marginHorizontal : 10}}>
                      <FreeTextFilter  filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={customerNameRef} data = {props.data} tableHeadName = {"MÜŞTERİ ADI"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></FreeTextFilter>
                      <TagPickerFilter filter={filter}  item_style={item_style} refresh={toggle_refresh} ref ={customerTypeRef} data = {props.data} tableHeadName = {"MÜŞTERİ TİPİ"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></TagPickerFilter>
                    </View>
                    <View style={{marginHorizontal : 10}}>
                      <FreeTextFilter isVKN filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={customerVKNRef} data = {props.data} tableHeadName = {"VKN"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied} ></FreeTextFilter>
                      <FreeTextFilter isTCKN filter={filter} item_style={item_style} refresh={toggle_refresh} ref ={customerTCKNRef} data = {props.data} tableHeadName = {"TCKN"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied} ></FreeTextFilter>
                    </View>
              </View>
            </View>    
          </ScrollView>

            <View style={{ marginVertical : 10,  flexDirection : 'row', justifyContent : 'flex-end'}}>

              <View style = {{marginHorizontal : 10}}>
                    <Button style={item_style.narrow} onClick = {() => {
                    // props.reset();
                    resetAndClear();
                    }}> Temizle </Button>
                </View>
                
                <View style = {{marginHorizontal : 10}}>
                  <Button style={item_style.narrow} onClick = {() => {
                   // props.reset();
                    filter();}}> Filtrele </Button>
                </View>
            </View>

         
        </View>
      
       
    )
  })





  const EditCustomerModal = forwardRef((props,ref) => {

    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData,setChoosenData] = useState(undefined);
    function handleModalClose(){
      setPopupActive(false);
   }



    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      open: (rowData) => {
        console.log(rowData);
          setChoosenData(rowData);
          setPopupActive(true);
      },
      close: () => {
        handleModalClose();
      }
      
    }))


  
    return(
      choosenData != undefined  ?
        (<Modal overflow={true} size={"full"} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
          <Modal.Header>
            <Modal.Title> {choosenData["MÜŞTERİ ADI"] } </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            
            <NewCustomerScreen update reset={props.reset} type = {choosenData["MÜŞTERİ TİPİ"]}  data = {choosenData}></NewCustomerScreen>
          
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleModalClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
      </Modal>):
      (<View>

      </View>)
    )
  });


  // filterItems = {filterItems} setFilterItems = {setFilterItems}
  const TableRSuite = forwardRef( (props,ref) => {
    
    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [PopupActive, setPopupActive] = useState(false);
    const [refresh , setRefresh] = useState(false);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);
    };
  
    const data = props.data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
  
    const getData = () => {
      if (sortColumn && sortType) {
        return data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }
      return data;
    };
  
    const handleSortColumn = (sortColumn, sortType) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
      }, 500);
    };

    const toggle_refresh = () => {
        console.log(refresh);
        setRefresh(!refresh);
    }
    function handleModalClose(){
      setPopupActive(false);
  }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        refresh: () => {
          toggle_refresh()
        },
        setPage1:() => {
          setPage(1);
        },
        filter: () => {
          toggle_refresh();
        }
      }))
  

    // const data = 
    const columns = props.tableHead;
    

    const updateCompRef = useRef();
    function editChoosen(rowData){
      updateCompRef.current.open(rowData);
    }

    function   resetEditCustomer(){
      props.reset();
      updateCompRef.current.close();
    }



    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    function formatAmount(input){
      return formatter.format(input).replace("₺","₺ ")
    }

    function editRowData(input,dataKey){
      if(dataKey.includes("TARİH") && input[dataKey] != null && input[dataKey] != undefined){
       return reverseDate(input[dataKey]);
      }
      else if(dataKey.includes("TUTAR") && input[dataKey] != null && input[dataKey] != undefined){
        return formatAmount(input[dataKey]);
       }
       else if(dataKey == "TEDARİKÇİ" && input[dataKey] != null && input[dataKey] != undefined){
        return input["TEDARİKÇİ"] == 1 ? "EVET" : "HAYIR";
        }
      else{
        return input[dataKey];
      }
    }
  
    function reverseDate(input){
      var result = "";  
  
      var comps = input.split("-");
  
      for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
          result += comps[c][i];
      }
      c != 0 ? result += "-": result += "";
      }
     
      return result;
  }
  const ActionCellEdit = ({ rowData, dataKey, ...props }) => (
    <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
      <View style={{ height: '80%'  }}>
       <IconButton icon={<Icon><FontAwesomeIcon icon={solid('pen')} /></Icon>} 
          onClick={
          () => {
            editChoosen(rowData);
          }
        }></IconButton>
        
      </View>
    </Table.Cell>
);

    const CustomCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
         <View style={{  justifyContent : 'center'  }}>
            <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
        </View>
      </Table.Cell>
  );

   
      function getWidth(input){
        var max = input.length * 10;
        // console.log(props.data);
        props.data.forEach(element => {
          var str = editRowData(element,input) + "";
          if(str.length * 10 > max){
            max = str.length * 10;
          }
        })

        if(max < 50)
         { max = 50}
          return max;
      }

      // list Tags with different clor of rounded rectangles not editable , if there is no tag return empty view
      const TagsCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        <View style={{ height: '100%', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'center' }}>
          {rowData[dataKey] != null && rowData[dataKey] != undefined && rowData[dataKey] != "" ? (
            rowData[dataKey].split(",").map((tag, index) => {
              return (
                <View key={index} style={{ backgroundColor: '#f0f0f0', borderRadius: 5, padding: 5, margin: 2, flexDirection: 'row', alignItems: 'center' }}>
                  <Text style={{ fontSize: 12, color: '#000' }}>{tag}</Text>
                </View>
              )
            })  ) : (<View></View>)
          }
        </View>
      </Table.Cell>
      );

    return ( 

    <View style = {{height: '80%'}}>
            <EditCustomerModal reset={resetEditCustomer} ref = {updateCompRef} ></EditCustomerModal>
            <View style = {{flexDirection : 'row', marginVertical: 10}}>
              <ExportData data = {props.data} tableHead = {props.tableHead} tableName={"ARAÇLAR"}></ExportData>
            </View>
            <Container>
                <Table
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={getData()}
                        bordered={bordered}
                        cellBordered={bordered}
                        autoHeight
                    >
                    <Table.Column  sortable resizable>
                      <Table.HeaderCell> Düzenle </Table.HeaderCell>
                      <ActionCellEdit dataKey= 'EDIT'/>
                    </Table.Column>
                    <Table.Column   resizable>
                      <Table.HeaderCell> Etiketler </Table.HeaderCell>
                      <TagsCell dataKey= 'TAGS'/>
                    </Table.Column>
                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column  sortable resizable width={getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}
                    </Table>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[10, 20]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                          more: 'Daha',
                          prev: 'Önceki',
                          next: 'Sonraki',
                          first: 'İlk',
                          last: 'Son',
                          limit: '{0} / sayfa',
                          total: 'Toplam Satır: {0}',
                          skip: 'Git {0}'
                      }}
                        />
            </Container>
        </View>
    
  
    )
  
  }) 
  
  const styles = StyleSheet.create({
    bottomBar: {
      height: 60,
      backgroundColor: 'white',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent:'flex-start',
      borderBottomColor :'#00589B',
      borderBottomWidth: 1
    },

        HeadStyle: { 
          height: 50,
          alignContent: "center",
          backgroundColor: '#f7f7f5',
          // borderWidth: "1px",
          //  borderColor: "black"
        },
        RowStyle: { 
          height: 40,
          alignContent: "center",
          backgroundColor: '#fff'
        },
        TableText: { 
          textAlign: 'center',
          margin: 10
        },
        row: { flexDirection: 'row', backgroundColor: '#fff', height: 40,
      // borderColor: "black", borderWidth : "1px" 
        },
        headerRow: { flexDirection: 'row', backgroundColor: '#f7f7f5', height: 40,
        // borderColor: "black", borderWidth : "1px" 
        },
        btn: { width: "100%", },
        btnText: { textAlign: 'center', color: 'black' },
        modalView: {
          width: "95%", 
          height:"95%",
          margin: 5,
          backgroundColor: "white",
          borderRadius: 7,
          padding: 5,
          alignItems: "center",
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          elevation: 5
        },
        modalText: {
          marginBottom: 15,
          textAlign: "center"
        }
  })



export default RentCustomerManagementScreen;

