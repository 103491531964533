import React, { useState ,useEffect, useRef} from 'react';
import { Button , Divider, Drawer, Input, InputGroup, List, Panel, SelectPicker, Stack  } from 'rsuite';
import { PDFDownloadLink , View, Text,Image , pdf, PDFViewer} from '@react-pdf/renderer';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import { Font  } from '@react-pdf/renderer';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import axios from 'axios';
import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';
import CarDeliveryInfoTab from './CarDeliveryInfoTab';

const CarDeliveryDocument = () => {

    const inputRef = useRef()
    const [showPdf, setShowPDF] = useState(false)


    function getInfo(){
        
        if(inputRef.current === undefined || inputRef.current === null){
            return null
        }
        return inputRef.current.getInfo()

    }


    function handleClosePdf(){
        setShowPDF(!showPdf)
    }



    return (
      <View>
        <CarDeliveryInfoTab ref={inputRef}/>


        <Drawer size="md" placement="left" open={showPdf} onClose={handleClosePdf}>
            <Drawer.Header>
                <Drawer.Title>PDF Dökümanı</Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <MyDocument data={getInfo()}/>
                </PDFViewer>
            </Drawer.Body>
        </Drawer>
        <Panel header="Döküman Oluştur" style={{margin: 20, backgroundColor: '#fff'}}>
            <CustomText text="Döküman Oluştur" />
            <Button onClick={() => handleClosePdf(true)}>PDF Oluştur</Button>
        </Panel>
      </View>
    )
  
}




const MyDocument = (props) => (
    
    <Document>
        <Page size="A4" style={styles.page}>
        <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'space-between', padding: 20, alignItems: 'center' }}>
            <View style={{ height: 40, flex: 1 , flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingHorizontal: 5}}>
                <Text  style={styles.header}>Araç Teslim Tutanağı</Text>
            </View>
            <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'flex-start', padding: 20, alignItems: 'center',}}>
               <Image   src={require('../../../assets/BrandLogos/LOGO_FIAT.png')}  style={{ height: 30}}/>
               <View style={{ width: 1, height : 40 , backgroundColor: '#3e3e3e', marginHorizontal: 20}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 14}}> Bilen Otomotiv </Text>
            </View>

        </View> 
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Teslimatı yapılan araca ait bilgiler </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}><Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}>Model : </Text>  {props.data.carModel}</Text>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}><Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}>Motor : </Text> {props.data.motor}</Text>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}><Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}>Şasi No : </Text> {props.data.chassisNo}</Text>
        </View>

        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
         <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Araç Bakım Periyodu ve Garanti Bilgileri </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            {
                ModelMaintenanceDetails.map((item, index) => {
                    if(props.data.carModel.includes(item.model)){
                        return item.maintenance.map((item, index) => {
                            return (
                                <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}>{item}</Text>
                            )
                        })
                    }
                }
                )
            }

        </View>

        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Araçta bulunan ekipmanlar </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <CarTools type={props.data.stepne}/>        

        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10, padding: 20}}> Yukarıda bilgileri bulunan aracı belirtilen donanımlar ve evrakları ile birlikte Bilen Otomotiv Tic. Ltd. Şti.'den hasarsız, temiz ve eksiksiz olarak teslim aldım.</Text>

        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
      
            
        </View>
        <View style={{  flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 10}}>
            <View style={{ height: 100 ,width: 300 ,  flexDirection: 'column', justifyContent: 'center', padding: 10, alignItems: 'flex-start', borderWidth: 1, borderColor: '#3e3e3e'}}>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 11}}> Teslim Alan Kişi Bilgileri </Text>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}> Ad Soyad : {props.data.customerName}</Text>

                <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}> İmza : </Text>
            </View>
            <View style = {{ width: 20 }}> </View>
            <View style={{ height: 100 ,width: 300 , flexDirection: 'column', justifyContent: 'center', padding: 10, alignItems: 'flex-start', borderWidth: 1, borderColor: '#3e3e3e'}}>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 11}}> Teslim Eden Kişi Bilgileri </Text>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}> Ad Soyad : </Text>

                <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}> İmza : </Text>
            </View>
        </View>
        <View style={{flex: 1}}></View>
        <Footer PN = {1} ></Footer>
        </Page>
        <Page size="A4" style={styles.page}>
        <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'space-between', padding: 20, alignItems: 'center' }}>
            <View style={{ height: 40, flex: 1 , flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingHorizontal: 5}}>
                <Text  style={styles.header}>Ekstra Tekliflerimiz</Text>
            </View>
            <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'flex-start', padding: 20, alignItems: 'center',}}>
               <Image   src={require('../../../assets/BrandLogos/LOGO_FIAT.png')}  style={{ height: 30}}/>
               <View style={{ width: 1, height : 40 , backgroundColor: '#3e3e3e', marginHorizontal: 20}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 14}}> Bilen Otomotiv </Text>
            </View>
        </View> 
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Uzatılmış Garanti </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20}}>
            <Paragraph text = {    "Uzatılmış garanti aracınızın garantisinin devamı niteliğinde olup mekanik ve elektrik aksamını garanti altına almaktadır. Bu sayede ilerleyen dönemlerde aracınızda ürün veya üretim kaynaklı herhangi bir olumsuzluk yaşamanız durumunda kapsam dahilinde FIAT garantisi ile ücretsiz olarak giderilir."    }/>
            <View style={{flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <Card header= "Yıl Uzatma Seçenekleri"  text = { "+2 yıl uzatma seçeneği ile aracınızın garantisini 4.yıl ve 5. Yıl 150.000 km’ye kadar uzatabilirsiniz. (Doblo, Yeni Doblo Combi-Cargo, Fiorino araçlar için geçerlidir.)"} ></Card>
                <Card header = "Kilometre Uzatma Seçenekleri" text = { "+50.000 km garanti uzatma seçeneğimiz ile; \n" +
                                "Egea modeli için; aracınızın garantisini 5. Yıl 150.000 km’ye veya 6. Yıl 100.000 km’ye kadar uzatabilirsiniz. \n" +
                                "Diğer modellerimiz için; aracınızın garantisini 3. Yıl 200.000 km’ye kadar uzatabilirsiniz. (Diğer modeller kapsamında, FIAT 500 ailesi ve Panda araçlar yer almaktadır.)  \n"  }></Card>
                </View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 10}}> Tüm modellerimizde bakım gecikmesi yaşanılması durumunda, yağlama kaynaklı sorunlar (turbo vs.) garanti dışı olacaktır.</Text>
        </View>
        <View style={{height: 20}}></View>
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Kontrollü Uzatılmış Garanti </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20}}>
            <Paragraph text = {"Kontrollü Uzatılmış Garanti hizmeti ile garantisi sona eren aracınızı 1 yıl/30.000 km daha FIAT güvencesiyle kullanmaya devam edebilirsiniz. Ayrıca bu süre zarfında acil yol yardım hizmetlerini ücretsiz olarak sunuyoruz."    }/>
            <Paragraph text = {"Kontrollü uzatılmış garanti aracınızın motor, vites kutusu, direksiyon sistemi ve üniteleri (Body Computer, ECU Motor Kontrol Ünitesi, AirBag Ünitesi, ABS Ünitesi, Elektrikli Direksiyon Ünitesi, Klima Ünitesi, Gösterge Ünitesi, MTA Vites Kutusu Ünitesi ve araçta bulunan benzeri elektronik kontrol üniteleri) 1yıl/30.000 km boyunca garanti altına almaktadır. Bu sayede ilerleyen dönemlerde aracınızda ilgili parçalarda ürün veya üretim kaynaklı bir olumsuzluk yaşamanız durumunda FIAT garantisi ile ücretsiz olarak giderilir. "} />
             <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: 20, padding: 20}}>
                <Text style={styles.italicLightText}>Kontrollü uzatılmış garanti hizmetini satın almak için; servisimiz ile irtibata geçebilirsiniz.</Text>
                <Text style={styles.italicLightText}>Kontrollü uzatılmış garanti 1 yıl ya da 30.000 kilometre hangisi önce dolarsa sona erer. </Text>
                <Text style={styles.italicLightText}>Kontrollü uzatılmış  garanti satışı öncesi aracın kontrol formuna göre kontrolleri yapılır. Bir arıza tespit edilmesi halinde arızaların onarımı müşteri ödemeli olarak gerçekleştirilir.  </Text>
                <Text style={styles.italicLightText}>Ducato, Scudo, Freemont, Fullback modelleri haricinde, garanti süresi dolmuş araçlara satılabilmektedir.  </Text>
                <Text style={styles.italicLightText}>Garantinin geçerliliği için FIAT tarafından belirlenen periyodlarda aracınızı FIAT Yetkili Servislerinde bakım yapılmalıdır. </Text>
            </View>
        </View>
        <View style={{flex: 1}}></View>
        <Footer PN = {2} ></Footer>
        </Page>

        <Page size="A4" style={styles.page}>
        <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'space-between', padding: 20, alignItems: 'center' }}>
            <View style={{ height: 40, flex: 1 , flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingHorizontal: 5}}>
                <Text  style={styles.header}>Ekstra Tekliflerimiz</Text>
            </View>
            <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'flex-start', padding: 20, alignItems: 'center',}}>
               <Image   src={require('../../../assets/BrandLogos/LOGO_FIAT.png')}  style={{ height: 30}}/>
               <View style={{ width: 1, height : 40 , backgroundColor: '#3e3e3e', marginHorizontal: 20}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 14}}> Bilen Otomotiv </Text>
            </View>
        </View> 
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> FIAT Yol Arkadaşım Connect  </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingHorizontal: 20}}>
            <Paragraph text = {"Uzaktan kontrol özelliği sayesinde otomobilinizi uzaktan kilitleyip kilitlerini açabilir,Aracınızla ilgili anlık arıza bildirimlerini alabilir,Konum takibi sayesinde aracınızın nereye çekildiğini görebilir,Sürüş performans takibi ve yakıt verimliliğinizi artırabilirsiniz."}/>

            <View style={{width: 500, padding: 10, margin: 10, borderWidth: 1, borderColor: '#3e3e3e', borderRadius: 7, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
            <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 8}}>FYA Fonksiyonları</Text>
            {
                FYA_termsList.map((item, index) => {
                    return (
                        <Text style={{ fontFamily: 'Artifakt-Italic', fontSize: 8}}>{item}</Text>
                    )
                }
                )

            }
            </View>
            <View style = {{ height :20}} ></View>
            <Paragraph text = {"Bayimizden Connect hizmetini satın alın. FIAT Yol Arkadaşım web sitesi veya akıllı telefonunuza indireceğiniz FIAT Yol Arkadaşım mobil uygulamadan Connect özelliğini aktive edin.Kontrol artık sizde!"}/>

        </View>
        <View style={{height: 20}}></View>
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Acil Yol Yardım Hizmeti </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            <Paragraph text = {"Nerede olursanız olun, 365 gün 24 saat yanınızdayız! Aracınızda herhangi bir sorun ile karşılaştığınızda tek yapmanız gereken FIAT Müşteri İlgi Merkezi 444 22 55’i arayarak operatöre bulunduğunuz yeri bildirmek."}/>
            <Paragraph text = {"Acil yol yardım hizmeti kapsamında yol kenarında yardım, aracın en yakın Fiat yetkili servisine çekilmesi, aracın kullanılamayışı nedeniyle konaklama, daimi ikametgaha seyahat ve yolculuğa devam seyahati hizmetleri verilir. Garantisi bitmiş olan araçların yolda kalması durumlarında hizmetlerimiz ücretlidir; ( Ücretsiz faydalanmak isteyen araçlar için 1 yıllık Acil Yol Yardım Ekstra paketini satın alınabilir.) Diğer durumlarda tüm acil yol yardım hizmetleri müşterilerimize ücretsiz olarak sağlanır."} />
             <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', marginTop: 20, padding: 20}}>
                <Text style={{fontFamily: 'Artifakt-Black', fontSize: 8}}>Acil yol yardım hizmeti kapsamında</Text>
                <Text style={styles.italicLightText}>•	Aracın kaza yapması.</Text>
                <Text style={styles.italicLightText}>•	Yakıt Bitmesi.</Text>
                <Text style={styles.italicLightText}>•	Lastik Değişimi</Text>
                <Text style={styles.italicLightText}>•	Anahtarın araç içerisinde unutulması</Text>
                <Text style={styles.italicLightText}>•	Anahtarın kaybedilmesi</Text>
                <Text style = {{fontFamily: 'Artifakt-Black', fontSize: 8}}> durumlarında acil yol yardım desteği ücretsiz olarak verilir. </Text>
            </View>
            <View style={{height: 20}}></View>
            <Paragraph text = {"*Anahtar kaybı ve lastik değişimi işlemlerinde; işçilik bedeli ve yedek parça değişimi ücretlidir. + \n" + " *Acil Yol Yardım Ekstra kapsamına sürücü kursu ve kiralama araçları ile Ducato ve Scudo modeli dahil değildir."}/>
        </View>
        <View style={{flex: 1}}></View>
        <Footer PN = {3} ></Footer>
        </Page>
        <Page size="A4" style={styles.page}>
        <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'space-between', padding: 20, alignItems: 'center' }}>
            <View style={{ height: 40, flex: 1 , flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', paddingHorizontal: 5}}>
                <Text  style={styles.header}>Ekstra Tekliflerimiz</Text>
            </View>
            <View style={{ height: 80,   flexDirection: 'row', justifyContent: 'flex-start', padding: 20, alignItems: 'center',}}>
               <Image   src={require('../../../assets/BrandLogos/LOGO_FIAT.png')}  style={{ height: 30}}/>
               <View style={{ width: 1, height : 40 , backgroundColor: '#3e3e3e', marginHorizontal: 20}}></View>
                <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 14}}> Bilen Otomotiv </Text>
            </View>
        </View> 
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Hasar Koruma Paketi  </Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            <Paragraph text = {"Kaskonuz olsun veya olmasın artık aracınız koruma altında! \n " + "Hasar Koruma Paketi kontrat süresi boyunca;"}/>

            <View style={{width: 500, padding: 10, margin: 10, borderWidth: 1, borderColor: '#3e3e3e', borderRadius: 7, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
            {
                hasarKorumaPaketi.map((item, index) => {
                    return (
                        <Text style={{ fontFamily: 'Artifakt-Italic', fontSize: 8}}>{item.label}</Text>
                    )
                }
                )

            }
            </View>
            <View style = {{ height :20}} ></View>
            <Text style={styles.italicLightText}>*Sadece perakende ve şahıs müşterilerimize sunulabilecektir.</Text>
            <Text style={styles.italicLightText}>*Faydalar kontrat satışından itibaren 1 yıl boyunca geçerli olacaktır.</Text>
            <Text style={styles.italicLightText}>*Hasar Koruma Paketinin faydalarından yararlanabilmek için hasar tarihinin kontrat başlangıç tarihinden sonra gerçekleşmiş olması gerekmektedir.</Text>

        </View>
        <View style={{height: 20}}></View>
        <View style={{ height: 40,   flexDirection: 'row', justifyContent: 'space-between', padding: 10, alignItems: 'center' }}>
        <Text style={{ fontFamily: 'Artifakt-Black', fontSize: 12}}> Yakıt Güvence Sistemi</Text>
            <View style={{ flex: 1 , borderBottomColor: '#3e3e3e', borderBottomWidth: 1, alignItems: 'center'}}></View>
        </View>
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            <Paragraph text = {"Akaryakıtınızı sürekli olarak OPET istasyonlarından alın, akaryakıttan kaynaklanabilecek hasarlara karşı onarım maliyetleri size yansımasın. Aracınızın mekanik garanti ve uzatılmış garanti süresi boyunca yakıt güvence sistemi geçerlidir."}/>
    
        </View>
        <View style={{flex: 1}}></View>
        <Footer PN = {4} ></Footer>
        </Page>
    </Document>
);

    const FYA_termsList = [
    "Araç Lokasyonu",
    "Lastik Durumu",
    "Uzaktan Kapı Açma Kapama- Bagaj açma",
    "Kaza Anında Ambulans ve Yol Yardım Hizmeti", 
    "Çekilme Uyarısı", 
    "Kaza Kara Nokta Bildirimi", 
    "Hız ve Mesafe Limiti", 
    "Araç Arıza Araması,Yakıt ve Akü Durum Bilgisi"
    ]

    
    const hasarKorumaPaketi = [
        {label: 'Sınırsız asistans ve çekici hizmeti', value: 'Sınırsız asistans ve çekici hizmeti'},
        {label: 'Toplamda maksimum 5 günlük ikame araç hizmeti', value: 'Toplamda maksimum 5 günlük ikame araç hizmeti'},
        {label: 'Her hasar onarımlarında %30 işçilik ve yedek parçada indirim imkanı', value: 'Her hasar onarımlarında %30 işçilik ve yedek parçada indirim imkanı'},
        {label: 'Her mekanik işlemlerinde %10 işçilik ve yedek parçada indirim imkanı', value: 'Her mekanik işlemlerinde %10 işçilik ve yedek parçada indirim imkanı'},
    ]




const Card = (props) => {
    return (
        <View style={{width: 500, padding: 10, margin: 10, borderWidth: 1, borderColor: '#3e3e3e', borderRadius: 7, flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start'}}>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}>{props.header}</Text>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 8}}>{props.text}</Text>
        </View>
    )
}

const Paragraph = (props) => {
    return (
        <View style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            <Text style={{ fontFamily: 'Artifakt', fontSize: 10}}>{props.text}</Text>
        </View>
    )
}

const Footer = (props) => {
    return (
        <View style={{ borderTopWidth : 3 , borderTopColor : '#575757'}}>
            <View style={{width: 595, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 25, backgroundColor: 'white'}}>
                <View style={{height: 80, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 18}}> Bilen Otomotiv </Text>
                    {/* <View style={{height: 5}}></View> */}
                    {/* <View style={{height: 1, backgroundColor: '#575757', width: 100}}></View> */}
                    <View style={{height: 7}}></View>
                    <View style={{flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                        <Image source={require('../../../assets/BrandLogos/LOGO_FIAT.png')} style={{width: 15, height: 15}} resizeMode='contain' />
                    </View>
                </View>
                <View style={{height: 80, flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    {/* <View style={{width: 20 , height: 80, justifyContent: 'center', alignItems: 'center'}}>
                        <View style={{height: 5}}></View>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/location_logo.png')} style={{width: 12, height: 12}} resizeMode='contain' />
                        <View style={{height: 10}}></View>
                        <Image source={require('../../../assets/ARJ_SALES_ASSETS/phone_logo.png')} style={{width: 12, height: 12}} resizeMode='contain' />
                    </View>
                    <View style={{width: 8}}></View> */}
                    <View style={{justifyContent: 'center', alignItems: 'flex-start'}}>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"H.Okan Merzeci Bulvarı"}</Text>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"No 449 Toroslar/Mersin"}</Text>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"(0324) 226 16 16"}</Text>
                        <Text style={{color: '#575757', fontFamily: 'Artifakt-Bold', fontSize: 8, textAlign: 'left'}}>{"www.bilenotomotiv.com.tr"}</Text>

                    </View>
                    <View style={{width : 12 }}></View>
                    <View style={{height: 80, justifyContent: 'center', alignItems: 'flex-end'}}>
                        <Image source={require('../../../assets/Icons/bilenoto_qr.png')} style={{width: 30, height: 30}} resizeMode='contain' />
                    </View>
                </View>
            </View>
            {/* Page Number*/}
            <Text style={{position: 'absolute', bottom: 10, right: 10, width: 15 ,textAlign: 'center', borderLeftWidth: 1 , paddingLeft: 3 ,color: '#003D84', fontFamily: 'Artifakt-Bold', fontSize: 10,}}>{props.PN}</Text>
        </View>
    )
}

const CarTools = (props) => {
    const ekipmanlarWStepne = [
        {label: 'Araç Anahtarı', value: 'Araç Anahtarı'},
        {label: 'Araç Yedek Anahtarı', value: 'Araç Yedek Anahtarı'},
        {label: 'Anahtar Kod Kartı', value: 'Anahtar Kod Kartı'},
        {label: 'Stepne', value: 'Stepne'},
        {label: 'Kriko', value: 'Kriko'},
        {label: 'Avadanlık', value: 'Avadanlık'},
        {label: 'Garanti Belgesi', value: 'Garanti Belgesi'},
        {label: 'Araç Faturası', value: 'Araç Faturası'},
    ]
    const ekipmanlarWTamir= [
        {label: 'Araç Anahtarı', value: 'Araç Anahtarı'},
        {label: 'Araç Yedek Anahtarı', value: 'Araç Yedek Anahtarı'},
        {label: 'Anahtar Kod Kartı', value: 'Anahtar Kod Kartı'},
        {label: 'Tamir Kiti', value: 'Tamir Kiti'},
        {label: 'Garanti Belgesi', value: 'Garanti Belgesi'},
        {label: 'Araç Faturası', value: 'Araç Faturası'},
    ]

    // with grid style and checkbox for each item on the list
    return (
        <View style={{  flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: 20}}>
            {props.type === 'Stepne' ? 
            ekipmanlarWStepne.map((item, index) => {
                return (
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: 5}}>
                        <Image src={require('../../../assets/Icons/check.png')} style={{ height: 10, width: 10}}/>
                        <Text style={{ fontFamily: 'Artifakt',fontSize: 10,marginLeft: 5}}>{item.label}</Text>
                    </View>
                )
            })
            : 
            ekipmanlarWTamir.map((item, index) => {
                return (
                    <View style={{ flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', padding: 5}}>
                        <Image src={require('../../../assets/Icons/check.png')} style={{ height: 10, width: 10}}/>
                        <Text style={{ fontFamily: 'Artifakt', fontSize: 10, marginLeft: 5}}>{item.label}</Text>
                    </View>
                )
            })
            }
        </View>
    )
}

const ModelMaintenanceDetails = [
    {
        model: 'EGEA',
        maintenance: ["1.3 - 1.4 - 1.5 Motorlu araçlar için 10.000 KM ya da yılda bir bakım yapılmalıdır.",
        "1.6 - 1.0 Motorlu araçlar için 20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Egea modellerimizde yasal garantimiz 3 Yıl veya 60.000 km'dir (hangisi önce dolarsa). Bakımların eksiksiz ve zamanında yapılması durumunda 5 yıl veya 100.000 km (hangisi önce dolarsa) onarım güvencesi kapsamına alınmkatadır.",
        "Yıl uzatma seçenekerimizde 6 Yıl 100.000 km'ye kadar uzatma seçeneği sunulmaktadır."
    ]
    },
    {
        model: 'DOBLO',
        maintenance: ["1.3  Motorlu araçlar için 10.000 KM ya da yılda bir bakım yapılmalıdır.",
        "1.5  Motorlu araçlar için 15.000 KM ya da yılda bir bakım yapılmalıdır.",
        "1.6  Motorlu araçlar için 20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Doblo modellerimizde yasal garantimiz 3 Yıl veya 150.000 km'dir (hangisi önce dolarsa)."]
    },
    {
        model: 'FIORINO',
        maintenance: ["1.3  Motorlu araçlar için 10 bin KM ya da yılda bir bakım yapılmalıdır.",
        "1.4  Motorlu araçlar için 20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Fiorino modellerimizde yasal garantimiz 3 Yıl veya 150.000 km'dir (hangisi önce dolarsa)."]
    },
    {
        model : 'DUCATO',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Ducato modellerimizde yasal garantimiz kilometre sınırsız 2 Yıl'dır."]
    },
    {
        model : 'SCUDO',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Scudo mmodellerimizde yasal garantimiz kilometre sınırsız 2 Yıl'dır."]
    },
    {
        model : 'PANDA',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "Panda modellerimizde yasal garantimiz 3 Yıl veya 150.000 km'dir (hangisi önce dolarsa)."]
    },
    {
        model: '500x',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır.",
        "500x modellerimizde yasal garantimiz 3 Yıl veya 150.000 km'dir (hangisi önce dolarsa)."]
    },
    {
        model: '500e',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır."]
    },
    {
        model: 'TOPOLINO',
        maintenance: ["20.000 KM ya da yılda bir bakım yapılmalıdır."]
    }

] 

const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff'
    },
    italicLightText: {
        fontFamily: 'Artifakt-Italic',
        fontSize: 8,
        color: '#3e3e3e',
        textAlign: 'left'
    },
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        backgroundColor: '#fff'

    },
    header: {
        fontFamily: 'Artifakt-Black',
        fontSize: 14,
        // borderBottomColor: '#3e3e3e',
        // borderBottomWidth: 2,
        // color: '#3e3e3e',
        padding: 5,
    }
});


export default CarDeliveryDocument;