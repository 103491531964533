import { Text, View } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react';
import {  Modal, Button} from 'rsuite';

import FileLoader from '../../../Components/FileManagements/FileLoader';



const CarLicenceAddModal = forwardRef((props,ref) => {

    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData,setChoosenData] = useState(undefined);
    function handleModalClose(){
      setPopupActive(false);
   }



    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      open: (rowData) => {
        console.log(rowData);
          setChoosenData(rowData);
          setPopupActive(true);
      },
      
    }))


  
    return(
      choosenData != undefined  ?
        (<Modal overflow={true} size={"full"}  backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
          <Modal.Header>
            <Modal.Title> {choosenData["ARAÇ"]} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            
          <View style={{flexDirection : 'row' , alignItems : 'center'}}>
            <Text style={{marginHorizontal : 10 }} > Ruhsat Yükle </Text>
            <FileLoader reset={props.reset} carID ={choosenData["ID"]} fileCategory = {"CAR_LICENSE"} ></FileLoader>
            {/* <Button style={{width : 120}}  onClick={() => {if(getS3Keys().length > 0) setModalOpen(true); else messageToggle("Ruhsat Dökümanı bulunamadı", "error")}}> Görüntüle </Button> */}
           
          </View>
          
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleModalClose} appearance="subtle">
              Cancel
            </Button>
          </Modal.Footer>
      </Modal>):
      (<View>

      </View>)
    )
  });

  export default CarLicenceAddModal;