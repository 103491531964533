import { Text, View ,ScrollView,StyleSheet,Image, Dimensions} from 'react-native'
import React, { useState ,useEffect, useRef} from 'react';
import { useToaster,Message,Button,Table,Column,Input, Container,IconButton, Modal, SelectPicker, Drawer, Stack, Panel, List, Row, Col, Grid } from 'rsuite';
import * as XLSX from 'xlsx';
import axios from 'axios';
import configdata from '../config/config.json'
import 'typeface-roboto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { useUserToken } from '../hooks/useUserToken';
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis , PieChart, Pie, Sector, LabelList,Layer} from 'recharts';
import { Card } from 'react-bootstrap';
import CustomText from '../Components/CustomText';
import '../../custom-theme.less';
import CarLicenceAddModal from './CarScreens/Modals/CarLicenceAddModal';
import FileViewer from '../Components/FileManagements/FileViewer';
import CarSummaryCardPdf from './CarScreens/CarTabScreens/CarSummaryCardPdf';
const DashboardScreen = () => {

    const {fetchIdToken} = useUserToken();

    // async function getData(){
    //     // const currentSession = await Auth.currentSession();
    //     // const idToken = currentSession.getIdToken().getJwtToken();
    //     const api = configdata.API_BASE + '/analytics';
    //     const token = await fetchIdToken();
    //     // console.log(idToken);
    //     const header = {
    //         "ENV" : configdata.APP_ENV,
    //         "type": "GET_ALL_CAR_BRANDS"
    //     }
    //     const headers = {
    //         "Authorization" : `Bearer ${token}`,
    //     }

    //     console.log(headers);
    //     axios.post(api,header,{"headers":headers}).then((response)=>{
    //         console.log(response);
    //         setData(response.data);
    //     }).catch((error)=>{
    //         console.log(error);
    //     })
        
    // }

    const [brandData,setBrandData] = useState([]); 
    const [dataCLIENT,setDataCLIENT] = useState([]); 
    const [kaskoPrice,setKaskoPrice] = useState(0);
    const [muayeneCars,setMuayeneCars] = useState([]);
    const [trafikCars,setTrafikCars] = useState([]);
    const [kaskoCars,setKaskoCars] = useState([]);
    const [buyPrice,setBuyPrice] = useState(0);
    const [araçDurums,setAraçDurums] = useState([])
    const [modelYears,setModelYears] = useState([]);
    const [fuelTypes,setFuelTypes] = useState([]);
    const [transmissionTypes,setTransmissionTypes] = useState([]);
    const [carOwners,setCarOwners] = useState([]);
    const [kamuCars,setKamuCars] = useState([]);
    
    const [cars,setCars] = useState([]);
    // async function getDataCLIENT(){
    //     const token = await fetchIdToken();
    //     const api = configdata.API_BASE + '/analytics';
    //     const body = {
    //         "ENV" : configdata.APP_ENV,
    //         "type": "GET_CLIENT_CONTRACT_ANALYSIS"
    //     }

    //     const headers = {
    //         "Authorization" : `Bearer ${token}`,

    //     }

    //     axios.post(api,body,{headers}).then((response)=>{
    //         console.log(response);
    //         setDataCLIENT(response.data);
    //     }).catch((error)=>{
    //         console.log(error);
    //     })
        
    // }

    async function getData(input,setData){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/analytics';
        const body = {
            "ENV" : configdata.APP_ENV,
            "type": input
        }
        const headers = {
            "Authorization" : `Bearer ${token}`,
        }
        axios.post(api,body,{headers}).then((response)=>{
            // console.log(input);
            // console.log(response.data);
            console.log(response.data);
            setData(response.data);
            
        }).catch((error)=>{
            console.log(error);
        })

    }

    async function getDataCars(){
        const token = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const body = {
            "ENV" : configdata.APP_ENV,
            "type": "GET_CARS",
        }
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        axios.post(api,body,{headers}).then((response)=>{
            console.log(response.data);
            setCars(response.data);
        }).catch((error)=>{
            console.log(error);
        })
    }

    useEffect(()=> {
        getDataCars();
        getData("GET_ALL_CAR_BRANDS",setBrandData);
        // getData("GET_CLIENT_CONTRACT_ANALYSIS",setDataCLIENT);
        getData("GET_CAR_KAMU",setKamuCars);
        getData("GET_KASKO_PRICE_ALL",setKaskoPrice);
        getData("MUAYENE_INFO",setMuayeneCars);
        getData("TRAFFIC_INFO",setTrafikCars);
        getData("KASKO_INFO",setKaskoCars);
        getData("GET_BUY_PRICE_ALL",setBuyPrice);
        getData("GET_ARAÇ_DURUMU",setAraçDurums);
        getData("GET_MODEL_YEAR",setModelYears);
        getData("GET_FUEL_TYPE",setFuelTypes);
        getData("GET_TRANSMISSION_TYPE",setTransmissionTypes);
        getData("GET_CAR_OWNERS",setCarOwners);
    },[]);


    function filterData(Column,Value){
        const filtered = cars.filter((element)=>{
            return element[Column] == Value;
        })
        console.log(filtered);
        setCars(filtered);
    }

    let usedColorForBrandPie = [];

    function componentToHex(c) {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }
    
    function rgbToHex(r, g, b) {
      return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }
    
    function getRandomBrightColorHex() {
        const min = 128; 
        const max = 255; 
        const r = Math.floor(Math.random() * (max - min) + min);
        const g = Math.floor(Math.random() * (max - min) + min);
        const b = Math.floor(Math.random() * (max - min) + min);
        
        return rgbToHex(r, g, b);
    }
      

    function getSumBrandPie(){
        var sum = 0;
        if(brandData.length > 0){  
        brandData.forEach(element => {
            sum += element.ADET;
        });
        }
        return sum;
    }

    const [araçDurumDrawer,setAraçDurumDrawer] = useState(false)
    function enableAraçDurumDrawer(header,input){
        console.log(header);
        console.log(input);
        filterData(header,input);
        setAraçDurumDrawer(true);
    }

    function handleCloseAraçDurumDrawer(){
        setAraçDurumDrawer(false);
    }

    function aggregateSmallDataPoints(data) {
        // Calculate the total value for the dataset
        const total = data.reduce((acc, entry) => acc + entry.ADET, 0);
      
        // Separate the data into two arrays: one for items above 10%, and one for below
        let dataAboveThreshold =  data.filter(entry => (entry.ADET / total * 100) >= 10);
        const dataBelowThreshold = data.filter(entry => (entry.ADET / total * 100) < 10);
      
        // Sum the ADET values of entries below the threshold to create the "Others" entry
        const othersTotal = dataBelowThreshold.reduce((acc, entry) => acc + entry.ADET, 0);
        console.log(dataBelowThreshold);
        if(dataBelowThreshold.length == 1 ){
            dataAboveThreshold.push(dataBelowThreshold[0]);
        }else{
            if (othersTotal > 0) {
                dataAboveThreshold.push({ name: 'Diğerleri', ADET: othersTotal });
            }
        }
        return dataAboveThreshold;
      }
    
    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, startAngle , endAngle, fill}) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';
      
        return (
          <g>
            {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
              {payload.name}
            </text> */}
            <Sector
              cx={cx}
              cy={cy}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={startAngle}
              endAngle={endAngle}
              fill={fill}
            />
            {/* <Sector
              cx={cx}
              cy={cy}
              startAngle={startAngle}
              endAngle={endAngle}
              innerRadius={outerRadius + 6}
              outerRadius={outerRadius + 10}
              fill={fill}
            /> */}
            <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333" fontSize={Dimensions.get('window').width < 700 ? 12 : 16} >{`${payload.name}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} color='#FF4747' textAnchor={textAnchor} fill="#FF4747" fontSize={Dimensions.get('window').width < 700 ? 12 : 16}>
              {`${(percent * 100).toFixed(0)}%`}
            </text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={34} textAnchor={textAnchor} fill="#999" fontSize={Dimensions.get('window').width < 700 ? 10 : 14}>
                {`${payload.ADET} Adet ` }
            </text>
          </g>
        );
      };

      const renderActiveShape = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload, startAngle , endAngle, fill}) => {
        return(
             <g>
                <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
                {payload.name}
                </text> 
             </g> 
        )
      }
      
      
    //     return (
    //         <text x={labelX} y={labelY}  textAnchor={textAnchor} fontSize={16} dominantBaseline="central" fontFamily='BarlowCN' >
    //          <tspan fontSize={14} fontFamily='Barlow' fill='#FF4747' >{`(${payload.ADET})`} </tspan>  {`${payload.name} (${(percent * 100).toFixed(0)}%)  `} 
    //         </text>
    //     );
    //   };



      
      

    const PieChartComponent = (props) => {
        const [activeIndex,setActiveIndex] = useState(0);
        const onPieEnter = (data, index) => {
            console.log(index);
            setActiveIndex(index);
        };
    
        return(
            <View>

            <Panel shaded bordered bodyFill  style={styles.panelStyle} >
             
            <Button style={styles.panelHeader} onClick={() => { chooseData(props.data,props.nameKey)}}>
                <View style={{ position: 'absolute', top: 12, right: 10 }}>
                    <Icon><FontAwesomeIcon icon = {solid('up-right-and-down-left-from-center')}></FontAwesomeIcon></Icon>
                </View>
               
                <CustomText style={styles.panelText}> {props.header} </CustomText>
            </Button>
          
            <ResponsiveContainer height={400} width="100%" margin={{ top: 50, right: 30, left: 30, bottom: 50 }}>
                <PieChart>
                    <Pie
                    data={aggregateSmallDataPoints(props.data)}
                    cx="50%"
                    cy="50%"
                    labelLine={true}
                    activeIndex={activeIndex}
                    activeShape={renderActiveShape}
                    label={renderCustomizedLabel}
                    outerRadius={'40%'}
                    innerRadius={'25%'}
                    fill="#8884d8"
                    dataKey="ADET"
                    paddingAngle={5}
                    onMouseEnter={onPieEnter}
                    speed={10}
                    >
                    {
                        aggregateSmallDataPoints(props.data).map((entry, index) => (
                        <Cell  key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))
                    }
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </Panel>
            </View>

        )
    
    }

    const BarChartComponent = (props) => {

        return(
            <Panel shaded bordered bodyFill  style={styles.panelStyle} >
                <View style={styles.panelHeader}>
                    <CustomText style={styles.panelText}> {props.header} </CustomText>
                </View>
                <View style={{height: 50}}></View>

                <ResponsiveContainer height={350}>
                    <BarChart   data={props.data} height={350}>
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Bar
                            fill="#8884d8"
                            dataKey="ADET"
                            nameKey="MODEL_YILI"
                            textRendering={'auto'}
                        >
                        {
                            props.data.map((entry, index) => (
                             <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))
                        }
                         <LabelList dataKey="ADET" position="top" /> 
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
        </Panel>
        )
    
    }

    
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #999', margin: 0, padding: 10 }}>
                    <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
                </div>
            );
        }
    
        return null;
    };
    
    function shortenName(name) {
        return name.length > 10 ? name.substring(0, 10) + "..." : name;
    }
    
    const HorizontalBarChartComponent = (props) => {
        // Map the data to include shortened names
        const shortenedData = props.data.map(entry => ({
            ...entry,
            shortName: shortenName(entry.name)
        }));
    
        return (
            <Panel shaded bordered bodyFill style={styles.panelStyle}>
                <View style={styles.panelHeader}>
                    <CustomText style={styles.panelText}> {props.header} </CustomText>
                </View>
                <View style={{height: 50}}></View>
                <ResponsiveContainer height={350}>
                    <BarChart
                        layout="vertical"
                        width={500}
                        height={350}
                        data={shortenedData}
                        margin={{
                            top: 5, right: 25, left: 0, bottom: 5,
                        }}
                    >
                        <XAxis type="number" />
                        <YAxis dataKey="shortName" type="category" width={120} fontSize={8}  />
                        <Tooltip content={<CustomTooltip />} />
                        <Bar dataKey="ADET" fill="#8884d8">
                            {shortenedData.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                            ))}
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Panel>
        );
    };



    const [choosenData,setChoosenData] = useState([]);
    const [choosenNameKey,setChoosenNameKey] = useState("");
    function chooseData(input,nameKey){
        console.log(input);
        console.log(nameKey);
        setChoosenData(input);
        setChoosenNameKey(nameKey);
        setIsPopupActive(true);
    }

    const [isPopupActive,setIsPopupActive] = useState(false);

    const handleClose = () => {
        setIsPopupActive(false);
    }

    function getRatio(adet){
        var sum = getSumBrandPie();
        var ratio = (adet / sum) * 100;
        return ratio.toFixed(0) + "%";
    }


    return(
        <View style={{flex: 1 , width : '100%', backgroundColor:'#f2f2f2'}}>
            <Modal open={isPopupActive} onClose={handleClose} size="full" >
                <Modal.Header>
                    <Modal.Title>  </Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <List>
                    {choosenData.map((element)=>{
                        return(
                            <List.Item>
                                <View style ={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                                    <IconButton icon={<Icon><FontAwesomeIcon icon={solid("arrow-right")}></FontAwesomeIcon></Icon>} onClick={() => {enableAraçDurumDrawer(choosenNameKey,element.name)}}>
                                    </IconButton>
                                    <CustomText fontSize = {16} fontWeight = {800} style={{marginLeft : 10 , color :'#FF4747'}}> {getRatio(element.ADET)} </CustomText> 
                                    <View style ={{flex: 1 , flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                                        <CustomText> {"   " + element.name } </CustomText>
                                        <CustomText fontSize = {16} fontWeight = {800} style={{color: '#FF4747'}}> {element.ADET + " Adet"} </CustomText>
                                    </View>
                                </View>
                            </List.Item>
                        )
                    })}
                </List>
                </Modal.Body>
            </Modal>
            <Drawer open={araçDurumDrawer} onClose={handleCloseAraçDurumDrawer} size='full'>
                <Drawer.Header>
                    <Drawer.Title> Araçlar </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <ListData data={cars}></ListData>
                </Drawer.Body>
            </Drawer>
            {/* <Button appearance='default'>This should be a red button</Button> */}
            <View style={{width: '100%' ,marginVertical: 20,justifyContent: 'center',paddingHorizontal: 20, alignItems: 'center'}}>   
                <Grid fluid  style={{width:'100%'}}>
                    <Row gutter={20}>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <IncreasingTextComponent input={getSumBrandPie()} format= {"STANDARD"} counter={20} header={"Toplam Araç Sayısı"}> </IncreasingTextComponent>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <IncreasingTextComponent input={buyPrice} format= {"PRICE"} counter={1000000} header={"Toplam Alış Fiyatı"}> </IncreasingTextComponent>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <IncreasingTextComponent input={kaskoPrice} format={"PRICE"} counter={500000} header={"Toplam Kasko Değer Fiyatı"}/>
                        </Col>
                    </Row>
                </Grid>
            </View>
            <View style={{width: '100%' ,marginVertical: 20,justifyContent: 'center',paddingHorizontal: 20, alignItems: 'center'}}>   
                <Grid fluid  style={{width:'100%'}}>
                    <Row gutter={20}>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <PieChartComponent nameKey={"MARKA"} header={"Marka Dağılımı"} data={brandData}></PieChartComponent>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <HorizontalBarChartComponent nameKey={"MÜŞTERİ"} header={"Kamu Dağılımı"} data={kamuCars}></HorizontalBarChartComponent>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <PieChartComponent nameKey={"ARAÇ DURUM"} header={"Araç Durum Dağılımı"} data={araçDurums}></PieChartComponent>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <BarChartComponent nameKey ={"MODEL YILI"} header={"Model Yılı Dağılımı"} data={modelYears}></BarChartComponent>
                        </Col> 
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <PieChartComponent nameKey={"YAKIT TİPİ"} header={"Yakıt Tipi Dağılımı"} data={fuelTypes}></PieChartComponent>
                        </Col>        
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <PieChartComponent nameKey={"VİTES"} header={"Vites Tipi Dağılımı"} data={transmissionTypes}></PieChartComponent>
                        </Col>          
                        {/* <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <BarChartComponent nameKey={"RUHSAT SAHİBİ"} header={"Araç Sahibi Dağılımı"} data={carOwners}></BarChartComponent>
                        </Col>      */}
                    </Row>
                </Grid>
            </View>
            <View style={{width: '100%' ,marginVertical: 20,justifyContent: 'center',paddingHorizontal: 20, alignItems: 'center'}}>   
                <Grid fluid  style={{width:'100%'}}>
                    <Row gutter={20}>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <InfoTable header={"Muayene Tarihi Yaklaşan Araçlar"} headers={["PLAKA" , "ARAÇ","KALAN GÜN"]} data ={muayeneCars}></InfoTable>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <InfoTable  header= {"Kasko Bitişi Yaklaşan Araçlar"} headers={["PLAKA" , "ARAÇ","KALAN GÜN"]} data ={kaskoCars} ></InfoTable>
                        </Col>
                        <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={12} xl ={8}>
                            <InfoTable header= {"Trafik Sigortası Bitişi Yaklaşan Araçlar"} headers={["PLAKA" , "ARAÇ","KALAN GÜN"]} data ={trafikCars}  ></InfoTable>
                        </Col>
                    </Row>
                </Grid>
            </View>
            <View style={{height: 200}}></View>
        </View>


    )


}




export default DashboardScreen;


const ListData = (props) => {
    const [data,setData] = useState([]);
    const [refresh,setRefresh] = useState(false);
    const [activate,closeIndictor] = useState(true);
    const [isPopUpvisible,setPopUpVisible] = useState(false)
    const [choosenCar, setChoosenCar] = useState(undefined)
    const [CarChoosenRefresh,setCarChooseRefresh] = useState(false);
    const [isFiltered, setApplied] = useState(false);
    const [filteredData,setFiltered] = useState([]);
    const[choosen_s3KEY,setChoosenS3KEY] = useState("");
    const [PopupActive, setPopupActive] = useState(false);
  
    function handleModalClose(){
      setPopupActive(false);
    }
  
    function chooseCar(input){
      console.log(input);
    //   props.setCarData(input);
    }
  
  
    function view_car_licence(input){
      var dataS3 = JSON.parse(input["S3 KEYS LICENCES"]).data
      console.log(dataS3);
      if( dataS3 != null){
        dataS3.sort(function(a, b) {
          return parseFloat(b.ID) - parseFloat(a.ID);
      });}
      console.log(dataS3);
      setChoosenS3KEY(dataS3[0].S3_KEY);
      setPopupActive(true);
      // console.log(dataS3[0].s3_KEY);
    }
  
    const carlicenceModalRef = useRef();
    function viewCarLicenceEditPopup(rowData){
      carlicenceModalRef.current.open(rowData);
    }
  
  
      
  
    const ExpandableListItem = ({ item }) => {
      
      return(
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', padding: 10}}>
          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
              <CustomText style={{fontSize: 16, color: '#000', fontWeight :'700'}}>{item.PLAKA}</CustomText>
              <CustomText style={{fontSize: 16, color: '#E4002B'}}>{item["MODEL YILI"]}</CustomText>
              <CustomText style={{fontSize: 14, color: '#000'}}>{item["RENK"]}</CustomText>
              <CustomText style={{fontSize: 14, color: '#000'}}>{item["ARAÇ"]}</CustomText>
              <CustomText style={{fontSize: 14, color: '#000'}}>{item["ARAÇ DURUM"]}</CustomText>
              <CustomText style={{fontSize: 12, color: '#000'}}>{item["VİTES"] + " , " + item["YAKIT TİPİ"]}</CustomText>
              <CustomText style={{fontSize: 12, color: '#000'}}>{}</CustomText>
              <CustomText style={{fontSize: 12, color: '#000'}}>{item["KM"]}</CustomText>
            </View>
            </View>
            <View style={{flex: 1, flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>
              <IconButton style={{width: '60%'}} icon = {<Icon><FontAwesomeIcon icon = {solid('car')}></FontAwesomeIcon></Icon>} onClick={() => {chooseCar(item);}}>
                <CustomText style={{fontSize: 14  }}>Araç Bilgileri</CustomText>
              </IconButton>
              <View style = {{height: 10}}></View>
                {
                (JSON.parse(item["S3 KEYS LICENCES"]).data != null ) ?  
                <IconButton  style={{width: '60%'}} icon={<Icon><FontAwesomeIcon icon={solid('file-circle-check')} /></Icon>}  onClick={() => {view_car_licence(item)}}> <CustomText> Ruhsat </CustomText>  </IconButton>
                : 
                <IconButton style={{width: '60%'}}  icon={<Icon><FontAwesomeIcon icon={regular('file')} /></Icon>}  onClick={() => {  viewCarLicenceEditPopup(item); }} >   <CustomText> Ruhsat </CustomText></IconButton>
                }
              
              <View style = {{height: 10}}></View>
              <CarSummaryCardPdf data={item}></CarSummaryCardPdf>
              </View>
        </View>
      )
      }
  
  
  
    return(
      <View style={{ flex: 1, height: '100%' }}>
      <CarLicenceAddModal reset={props.reset} ref = {carlicenceModalRef} ></CarLicenceAddModal>
      <Drawer style={{width: '95%'}} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
                <Drawer.Header>
                    {props.data["ARAÇ"]} 
                </Drawer.Header>
                <Drawer.Body >
                    <FileViewer inline={true} s3KEY = {choosen_s3KEY}></FileViewer>
                </Drawer.Body >
        </Drawer>
        <List>
          { props.data.map((item, index) => {
          return ( 
            <List.Item>
              <ExpandableListItem item={item} />
            </List.Item>
          )
        })}
        </List>
      </View>
    )
  }

const IncreasingTextComponent = ({input,format,counter,header}) => {
    const [value,setValue] = useState(0);
    // const [isIncreasing,setIsIncreasing] = useState(false);
    
    useEffect(()=>{    
    if(input != null) 
       { if(input > value){
           
            setValue(value + counter);
        }else{
       
            setValue(input);
        }}
    },[value,input])

    function formatToCurrency(amount) {
        return amount.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
    }


    return(
        <Panel shaded bordered bodyFill style={{backgroundColor: 'white', justifyContent:'center', alignItems:'center', height: 140, padding: 10}} >
                <View style={{flexDirection: 'row' , flex : 1 , paddingHorizontal: 15, justifyContent :'space-between', backgroundColor: '#F2F2F2', height: 40, alignItems :'center'}}>
                    {/* <Icon style={{width: 30 , height: 30, color :'black'}}><FontAwesomeIcon icon={solid("car")}></FontAwesomeIcon></Icon> */}
                    <CustomText style={{fontSize: 18, textAlign :'center',flex: 1 ,color: 'black'}}> {header} </CustomText>
                </View>
                <View style={{flex: 1 , height: 90, display: 'flex', justifyContent : 'center', alignItems :'center'}}>
                    <CustomText fontWeight = {800}  style={{textAlign: 'center', fontSize : 30  ,color:'#5A6273'}}>
                        {
                            format == "STANDARD" ? value : formatToCurrency(value)
                        }
                    </CustomText>
                </View>
        </Panel>
    )
}




const InfoTable = (props) => {
    
    const CustomCell = ({ rowData, dataKey, ...props }) => {
        return (
          <Table.Cell {...props} 
            style={{
                backgroundColor: rowData["KALAN GÜN"].toString()[0] === '-' ?  '#f29494' : '',
                flex: 1,
                padding: 4,
                justifyContent: 'center',
                alignItems: 'center',
            }}
          >
            <View>
              <CustomText 
                style={{
                  textAlign: 'left',
                  fontWeight: dataKey === "KALAN GÜN" ? 'bold' : '400', 
                  fontSize: 12, 
                  color: rowData[dataKey].toString()[0] === '-' ? '#F2F2F2' : 'black'
                }}
              > 
                {rowData[dataKey]} 
              </CustomText>
            </View>
          </Table.Cell>
        );
      };

      const ListItem = (props) => {
        return(
            <Stack wrap spacing={10}>
                <CustomText fontWeight = '800' style={{color:  props.data["KALAN GÜN"] < 0 ? '#E4002B' : '#001489', fontSize : 14}} > {props.data["KALAN GÜN"] +  " Gün"} </CustomText>
                <CustomText fontWeight = '800' style={{fontSize : 14}} > {props.data["PLAKA"]} </CustomText>
                <CustomText style={{fontSize : 14}} > {props.data["ARAÇ"]} </CustomText>
            </Stack>
        )
    }
    
    return(
        <Panel shaded bordered bodyFill style={styles.panelStyle} >
                <View style={{flexDirection: 'row' , flex : 1 , paddingHorizontal: 15, justifyContent :'space-between', backgroundColor: '#F2F2F2', height: 40, alignItems :'center'}}>
                    {/* <Icon style={{width: 30 , height: 30, color :'black'}}><FontAwesomeIcon icon={solid("car")}></FontAwesomeIcon></Icon> */}
                    <CustomText style={{fontSize: 18, textAlign :'center',flex: 1 ,color: 'black'}}> {props.header} </CustomText>
                </View>
                <View style={{flex: 1 , height: 450, display: 'flex', justifyContent : 'flex-start'}}>
                    <List>
                        {props.data.map((element) => {
                            return(
                                <List.Item>
                                    <ListItem data = {element}></ListItem>
                                </List.Item>
                            )
                        })
                    }
                    </List>
                </View>
        </Panel>
    )
}

// Link to create to forward websites Shortcut Cards 



const styles = StyleSheet.create({
    cardView: {
        minWidth: 410,
        height: 400,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: 5,
        shadowColor: "#BDC9FF",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.25,
        shadowRadius: 12,
        elevation: 5,
    },
    panelHeader: {flexDirection: 'row' , flex : 1 , width: '100%', paddingHorizontal: 15, justifyContent :'space-between', backgroundColor: '#F2F2F2', height: 40, alignItems :'center'},
    panelText: {fontSize: 18, textAlign :'center',flex: 1 ,color: 'black'},
    panelStyle : {backgroundColor: 'white', justifyContent:'center', alignItems:'center', padding: 10},
});

// <Modal
// animationType= 'slide'
// visible={PDFPopupActive}
// transparent={true}
// >
//  <View style={{width: '90%' ,alignItems:'center', justifyContent:'center',padding: 10,backgroundColor:'white',borderRadius: 7}}>
//     {/* <PDF url = {"https://bilenmobiframework64ae2979a8b64ea1a1abec97e4917140645-dev.s3.eu-central-1.amazonaws.com/PDFTEST.pdf"} togglePDFPopup = {togglePDFPopup}/> */}
//     <PDF url = {url} togglePDFPopup = {togglePDFPopup}/>
//     {/* <Image
        
//         source={{
//         uri: {url},
//         }}
//     /> */}
// </View>

// </Modal> 

                {/* <View style={styles.cardView}>
                    <Text style={{fontWeight: 'light', fontSize: 18, marginVertical: 5}}> Marka Bazında Araç Sayıları </Text>
                    <ResponsiveContainer width="90%" height="90%">
                    <PieChart width={400} height={400}>
                        <Tooltip />
                        <Pie dataKey="ADET" nameKey={"MARKA"} data={brandData} cx="50%" cy="50%" outerRadius={80}>
                            {
                                brandData.map((entry, index) => (
                                    <Cell key={index} fill={getRandomColor()} />
                                ))
                            }
                        </Pie>
                    </PieChart>
                    </ResponsiveContainer>

                </View> */}