import { Text, View , StyleSheet,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef} from 'react'
import axios from 'axios';
import Amplify, { Auth } from 'aws-amplify';
import { Input,useToaster, Message, Button ,DatePicker ,SelectPicker,IconButton  ,Modal ,Checkbox, List, Drawer, MaskedInput, Grid, Row, Col, Panel, Stack} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar, faHashtag, faMoneyBill, faGauge} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';


const MaintenanceScreen = (props) => {

    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [defaultData,setDefaultData] = useState({"BAKIM ARALIĞI" : props.data["BAKIM KM PERİYODU"],"SONRAKİ BAKIM KM" : (props.data["ARAÇ KM"] + props.data["BAKIM KM PERİYODU"] ) });
    const [editData,setEditData] = useState({});

    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
      const api = configdata.API_BASE + '/cardata';
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
          "type" : "GET_MAINTENANCE_DATA",
          "ID" : props.data["NO"],
        },{headers}).then((response)=>{

            if(response.data.length > 0){
              setCurrentActive(response.data);
              // fileViewRef.current.set(parseS3(response.data["S3_KEY"]));
            }
           
            setData(response.data);
        }).catch((error)=> {
            console.log(error);
        })
    }

    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          input[i]["SONRAKİ BAKIM KM"] = input[i]["BAKIM KM"] + input[i]["BAKIM ARALIĞI"];
          setCurrent(input[i]);
          // console.log(input[i]["S3_KEY"]);
          // fileViewRef.current.set(parseS3(input[i]["S3_KEY"]));
          break;
        }
      }
        // input.forEach(element => {
        //   if(element["IS_ACTIVE"] == 1){
        //     // console.log(element["BAKIM KM"]);
        //     // console.log(element["BAKIM ARALIĞI"]);
        //     element["SONRAKİ BAKIM KM"] = element["BAKIM KM"] + element["BAKIM ARALIĞI"];
        //     setCurrent(element);
        //   }
        // })
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }
    const[viewData,setViewData] = useState({});
      
    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }


    function getS3Keys(){
      // if(JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data != null){
      //   return JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data[0];
      // }
      return [];
    }
    const fileViewRef = useRef();
    const FileViewModal = () => {
      return(
        <View>
           <Modal size='full' backdrop={'static'} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                    <Modal.Header>
                    <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"BAKIM BİLGİLERİ"} </Text>
                      </View>} 
                    </Modal.Title>
                    </Modal.Header>
  
                    <Modal.Body>
                    <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
        </View>
      )
    }
    const ref =useRef();

    const tableHeads= ["BAKIM KM","BAKIM TARİHİ","BAKIM TİPİ","BAKIM TUTARI","SERVİS","BAKIM ARALIĞI","SONRAKİ BAKIM KM"];
    const listHeaders = ["BAKIM KM","BAKIM TARİHİ","BAKIM TİPİ","BAKIM TUTARI","SERVİS","BAKIM ARALIĞI","SONRAKİ BAKIM KM","NOTLAR"];
    const labelHeads= ["BAKIM KM","BAKIM TARİHİ","BAKIM TİPİ","BAKIM TUTARI","SERVİS","BAKIM ARALIĞI","SONRAKİ BAKIM KM","NOTLAR"];

    const headers = [
      {"header" : "BAKIM KM" , "data" : current["BAKIM KM"] , "icon" :faGauge },
      {"header" : "BAKIM TARİHİ" , "data" : current["BAKIM TARİHİ"] , "icon" : faCalendar},
      {"header" : "BAKIM TİPİ" , "data" : current["BAKIM TİPİ"] , "icon" : faHashtag},
      {"header" : "BAKIM TUTARI" , "data" : current["BAKIM TUTARI"] , "icon" : faMoneyBill},
      {"header" : "SERVİS" , "data" : current["SERVİS"] , "icon" : faBuilding},
      {"header" : "BAKIM ARALIĞI" , "data" : current["BAKIM ARALIĞI"] , "icon" : faGauge},
      {"header" : "SONRAKİ BAKIM KM" , "data" : current["SONRAKİ BAKIM KM"] , "icon" : faGauge},
    ]


    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding: 20}}>
              <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'} backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"BAKIM BİLGİLERİ"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                        {modalType == "New" ? (<NewMaintenancePanel 
                        type={"NEW"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); handleModalClose();}}></NewMaintenancePanel>) 
                        :
                        ( <NewMaintenancePanel 
                          type={"EDIT"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {() => {getComponents(); handleModalClose();}} ></NewMaintenancePanel> )}
                       
                    </Drawer.Body>
                </Drawer>
                <Grid fluid style={{width: '100%'}}>
                  <Row gutter={20}>
                  <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>

                      <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}} 
                          header  = {
                            <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                              <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack.Item grow={1}>
                                <IconButton 
                                  style={{width: '100%', height: 35}}
                                  onClick={() => {
                                    setModal(true);
                                    setModalType("New");}}
                                    icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>}
                                    >
                                    Yeni Bakım Bilgisi
                                </IconButton>
                                </Stack.Item>
                                <ExportData data = {data} tableHead = {tableHeads} tableName={"Bakım_Bilgileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                              </Stack> 
                              
                        </View>
                        } >
                          
                      <CustomText fontWeight= {800} fontSize={14} > {"Bakım Bilgileri" } </CustomText>

                        <ComponentListView hasDoc = {true} current={current} showDetails={showDetailsOfChoosen} listHeads={listHeaders} chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit}  tableName = {"Bakım_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads}  data={data}></ComponentListView>

     
                    </Panel>
                    </Col>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>

                      <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}}  header  = { <CustomText fontWeight={800}> {"Bakım Detayları -- " + props.data["PLAKA"]} </CustomText>} >
                        <List>
                          {headers.map((item) =>
                            {
                            return(
                            <List.Item key={item} index={item}>
                              <CustomListItem item={item} ></CustomListItem>
                            </List.Item>)
                            }
                            )}
                        </List>
                      </Panel>
                    </Col>
                  </Row>
                </Grid>
                <FileViewModal></FileViewModal>
        </View>
    )
}


const NewMaintenancePanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }


    const [activateAddButton,setActiveAddButton] = useState(true);


    // Conbo Box Values
    
    const [maintenanceTypes ,setMaintenanceTypes] = useState([]);
    // SENT VALUES
    const [Km,setKM] = useState(0);
    const [maintenanceType,setMaintenanceType] = useState("");
    const [maintenanceDate,setMaintenanceDate] = useState(new Date());
    const [service,setService] = useState("");
    const [amount,setAmount] = useState(0);
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();

    useEffect(() => {
      if(S3Key != undefined){
        console.log(S3Key);
        sendData();
       
      }
    },[S3Key])
    function reset(){
        setKM("");
        setMaintenanceType(0);
        setMaintenanceDate(new Date());
        setAmount(0);
        setService("");
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
        setKM(props.data["BAKIM KM"]);
        setMaintenanceType(props.data["BAKIM TİPİ ID"]);
        setMaintenanceDate(new Date(props.data["BAKIM TARİHİ"]));
        setService(props.data["SERVİS"]);
        setAmount(props.data["BAKIM TUTARI"] == null ? 0 : props.data["BAKIM TUTARI"]);
        setNote(props.data["NOTLAR"]);
        setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function getDropDownValues(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }
    function sendAndLoad(){
      fileUploaderRef.current.load();
   }

   const {fetchIdToken} = useUserToken();

   async function sendData(){
    const idToken = await fetchIdToken();
    const headers = {
      "Authorization" : `Bearer ${idToken}`  
      
    }
    let amountValue = amount;
    if(amountValue.replace != undefined){
      amountValue = Number(amountValue.replace("₺ ","").replace(".","").replace(",","."));
    }

    let newKM = Km.replace != undefined ?  Km.replace(" KM","").replace(".","").replace(",","."): Km;

    const body =  {
      "ENV" :configdata.APP_ENV ,  
        "type": props.type == 'EDIT' ? "UPDATE_MAINTENANCE" : "NEW_MAINTENANCE_DOC",
        "MAINTENANCE_KM" : newKM,
        "MAINTENANCE_DATE": maintenanceDate,
        "MAINTENANCE_TYPE" : maintenanceType,
        "AMOUNT" : amountValue,
        "SERVICE" : service,
        "NOTES" :note,
        "CAR_ID" : props.cardata["NO"],
        "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
        "ID" : props.data["NO"],
    }

    console.log(body);

    const api = configdata.API_BASE + '/cardata';
    axios.post(api,
     body,{headers}
    ).then((response) => {
      console.log(response);
      reset();
      props.reset();
      if(response.data["errorMessage"] != null){
          messageToggle("Bakım Bilgileri eklenemedi , Bağlantınızı kontrol edin", "error")
      }else{
          if(response.data.split("_")[0] == "SUCCESS"){
            messageToggle("Bakım Bilgileri başarıyla eklendi", "success")
            fileUploaderRef.current.load(response.data.split("_")[1]);
          }
          else{
            messageToggle("Bakım Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
          }
      }
      
    })
    .catch((error) => {
      console.log(error);
      messageToggle("Bakım Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
    });
    }

    useEffect(() => {
      
     if(props.type == "EDIT"){getEditData()}
     getDropDownValues("MAINTENANCE_TYPE",setMaintenanceTypes);
    

    },[])

    const item_style = {
    standard: { width : 200 },
    viewStyle: {marginHorizontal : 10 ,flex:1,flexGrow:1,  alignItems :'center', padding : 10}
    }

    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);
    
   
    
    return(
          <View style= {{flexDirection: 'column',width: '100%'}}>
            <View style={styles.form}>

                <Group header="Kilometre">
                  <MaskedInput mask={kmMask} value={Km} style={styles.standard} onChange={ (value) => {setKM(value);}} name= {"KM"} /> 
                </Group>
                
                <Group header="Bakım Tipi">
                  <SelectPicker value={maintenanceType} style={styles.standard}  onSelect={(value) => { setMaintenanceType(value)}} data = {maintenanceTypes} ></SelectPicker>
                </Group>
                <Group header="Servis">
                  <Input value={service} style={styles.standard}  onChange={ (value) => {setService(value);}} name= {"service"} /> 
                </Group>
                <Group header="Bakım Tarihi">
                  <DatePicker  value={maintenanceDate} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                        {setMaintenanceDate(value);}}
                        oneTap format="dd-MM-yyyy"
                        locale={locale}
                    ></DatePicker>
                </Group>
                <Group header="Bakım Tutarı">
                  <MaskedInput value={amount} style={styles.standard} mask={numberMaskTl} onChange={ (value) => {setAmount(value);}} name= {"tutar"} />
                </Group>
                {
                  props.type == "EDIT" &&
                  <Group header="Durum">
                    <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                  </Group>
                }
                <Group header="Notlar">
                  <Input value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}  style={styles.standard} /> 
                </Group>
                <Group header="Dosya Yükle">
                  <FileLoader reset= {props.reset} ref = {fileUploaderRef} fileCategory = {"MAINTENANCE"} carID = {props.cardata["NO"]} ></FileLoader>
                </Group>

              </View>

              <View style={{height: 50}}></View>
              <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > <CustomText fontWeight = {800} fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>

          </View>

    )
}


const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{ marginRight: 5 }}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});

const numberMaskTl = createNumberMask({
  prefix: '₺ ',
  suffix: '',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: false,
});

const kmMask = createNumberMask(
  {
    prefix: '',
    suffix: ' KM',
    thousandsSeparatorSymbol :'.',
    allowDecimal : false,
    allowLeadingZeroes: false,
    integerLimit: 7,
    allowNegative: false
  }
)


export default MaintenanceScreen;