import { Text, View , StyleSheet } from 'react-native'
import React, { useState, useEffect ,useRef } from 'react'
import axios from 'axios';
import { useToaster, Message, Button, Form ,SelectPicker,IconButton,  List, Drawer, MaskedInput,Loader,Row,Stack,Panel, Grid, Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css"; 
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import {  faCalendar, faHashtag, faMoneyBill,faCar} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import ExportData from '../../../Components/TableManagement/ExportData';
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import CustomText from '../../../Components/CustomText';


const KaskoValueScreen = (props) => {
  


    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [editData,setEditData] = useState({});
    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();


    async function getComponents(){
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${idToken}`,
      }
      const body = {
        "ENV" :configdata.APP_ENV , 
        "type" : "GET_KASKO_VALUE_DATA",
        "ID" : props.data["NO"],
    }
      axios.post(api,body,{headers}).then((response)=>{
            if(response.data.length > 0){
              setCurrent(response.data[0])
              // setCurrent(response.data[0]);
            }
           
            setData(response.data);
            // if(response.data.length = 0){
            //   setDefaultData([{"GÜNCEL KM" : props.data["ARAÇ KM"],"EDİNİMDEKİ KM" :props.data["ARAÇ KM"]  ,"OLUŞTURULMA TARİHİ" : props.data["UPDATE_DATE"]}])
            // }
        }).catch((error)=> {
            console.log(error);
        })
    }

    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          setCurrent(input[i]);
          break;
        }
      }
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }

   
    const listref =useRef();
    function toggleTableLoading(){
        listref.current.loadingToggle();
    }



    const tableHeads= ["MARKA KODU","TİP KODU", "MARKA ADI" , "TİP ADI","MODEL YILI", "DEĞER" , "DEĞER TARİHİ"];
    const labelHeads= ["MARKA KODU","TİP KODU", "MARKA ADI" , "TİP ADI", "DEĞER" , "DEĞER YILI" , "DEĞER AYI"];
    const listHeads = [ "MARKA ADI" , "TİP ADI","MODEL YILI", "DEĞER", "DEĞER AYI" ];

    const headers = [
      {header : "MARKA KODU", data : current["MARKA KODU"], icon : faHashtag},
      {header : "TİP KODU", data : current["TİP KODU"], icon : faHashtag},
      {header : "MARKA ADI", data : current["MARKA ADI"], icon : faCar},
      {header : "TİP ADI", data : current["TİP ADI"], icon : faCar},
      {header : "DEĞER", data : current["DEĞER"], icon : faMoneyBill},
      {header : "DEĞER YILI", data : current["DEĞER YILI"], icon : faCalendar},
      {header : "DEĞER AYI", data : current["DEĞER AYI"], icon : faCalendar},


    ]

    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2' , padding: 20}} >

<Drawer overflow={true}  size={'md'}  backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"BAKIM BİLGİLERİ"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                         <SetEditKaskoValue toggleTableLoading={toggleTableLoading} reset={() => {props.reset(); getComponents()}} carData={props.data}></SetEditKaskoValue>
                    </Drawer.Body>
                </Drawer>
            {/* <ShowCurrentPanel data = {current} headers={labelHeads} ></ShowCurrentPanel> */}
            <Grid fluid style={{width: '100%'}}>
              <Row gutter = {20}>
              <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                      <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} 
                          header  = {
                            <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                              <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack.Item grow={1}>
                                <IconButton 
                                  style={{width: '100%', height: 35}}
                                  onClick={() => {
                                    setModal(true);
                                    setModalType("New");}}
                                    icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>}
                                    >
                                    Kasko Değer Kodu Düzenle ve Hesapla
                                </IconButton>
                                </Stack.Item>
                                <ExportData data = {data} tableHead = {tableHeads} tableName={"Kasko Değer Bilgileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                              </Stack> 
                              <CustomText style={{fontWeight: 'bold'}}> {"Kasko Değer Bilgileri" } </CustomText>
                        </View>
                        } >
                          <ComponentListView listHeaders ref={listref} setchoosen_data={chooseANDEdit}  tableName = {"Kasko Değer_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads} listHeads={listHeads}  data={data}></ComponentListView>

                    </Panel>
              </Col>
              <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} header={<CustomText fontWeight= {800} > {"Değer Bilgileri" } </CustomText>}>
                  <List>
                        {headers.map((item) =>
                          {
                          return(<List.Item key={item} index={item}>
                            <CustomListItem item={item} ></CustomListItem>
                          </List.Item>)
                          }
                          )}
                      </List>
                </Panel>
              </Col>

              </Row>
            </Grid>
              {/* <SetEditKaskoValue toggleTableLoading={toggleTableLoading} reset={() => {props.reset(); getComponents()}} carData={props.data}></SetEditKaskoValue> */}
        </View>
    )
}

const SetEditKaskoValue = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    const[brandValue,setBrandValue] = useState(props.carData["KASKO MARKA ID"] != null ?props.carData["KASKO MARKA ID"] : 0 );
    const[typeValue,setTypeValue] =  useState(props.carData["KASKO TİP ID"] != null ?props.carData["KASKO TİP ID"] : 0 );
    const[brands,setBrands] = useState([])
    const[types,setTypes] = useState([])
    const[brandType,setBrandType] = useState([])

    const [degerAy,setDegerAy] = useState(0);
    const [degerYıl,setDegerYıl] = useState(0);

    const [errorAy,setErrorAy] = useState(false);
    const [errorYil,setErrorYil] = useState(false);

    useEffect(()=> {
        getDropDownValues();
    },[])


    useEffect(() => { 
       console.log(degerAy);
          setErrorAy( (degerAy.toString().length != 2 ||  degerAy > 12) ? true : false )
      }
    ,[degerAy])
    useEffect(() => { 
      console.log(degerYıl);
      setErrorYil( (degerYıl.toString().length != 4 ||  degerYıl > new Date().getFullYear() || degerYıl < 2000) ? true : false )
    }
  ,[degerYıl])


    const { fetchIdToken} = useUserToken();

    async function getDropDownValues(){
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${idToken}`,
      }

      const body = {
        "ENV" :configdata.APP_ENV ,
          "type" : "GET_KASKO_DROPDOWN_DATA",
          "BRAND": "%"+ props.carData["MARKA"]+"%",
          "MODEL_YEAR" : "%" + props.carData["MODEL YILI"] + "%",
          "MODEL" :  "%" + props.carData["MODEL"].toUpperCase() + "%",
      }

      axios.post(api,body,{headers}).then((response)=>{
            parseDropDownData(response.data);
            // if(response.data.length = 0){
            //   setDefaultData([{"GÜNCEL KM" : props.data["ARAÇ KM"],"EDİNİMDEKİ KM" :props.data["ARAÇ KM"]  ,"OLUŞTURULMA TARİHİ" : props.data["UPDATE_DATE"]}])
            // }
        }).catch((error)=> {
            console.log(error);
        })
    }

    function parseDropDownData(input){
        var Result = [];
        var brand_type = [];
        var typesArr=[];
        var addedBrands = [];
        input.forEach(element => {
            if(!addedBrands.includes(element["BRAND_NAME"])){
                addedBrands.push(element["BRAND_NAME"]);
                Result.push({"label" : element["BRAND_CODE"] + " - " +  element["BRAND_NAME"] , "value" : element["BRAND_CODE"]})
                brand_type.push({"brand" : element["BRAND_CODE"], "children" : []})
            }
            typesArr.push({"label" : element["TYPE_CODE"] + " - " +  element["TYPE_NAME"] , "value" : element["TYPE_CODE"]})
        })
        input.forEach(element => {
           brand_type.find(btype => element["BRAND_CODE"] == btype["brand"]).children.push({"label" : element["TYPE_CODE"] + " - " +  element["TYPE_NAME"] , "value" : element["TYPE_CODE"]})
        })
        setBrandType(brand_type);
        setBrands(Result);
        setTypes(typesArr);
    }


    async function sendData(){
      const idToken = await fetchIdToken();
        const api = configdata.API_BASE + '/cardata';
        const headers = {
          "Authorization" : `Bearer ${idToken}`,
        }

        const body =  {
            "ENV" :configdata.APP_ENV ,
            "type" : "UPDATE_TSB_IDS_CAR",
            "MARKA_ID": brandValue,
            "TİP_ID" : typeValue,
            "MARKA_TİP_ID" : parseInt(brandValue+""+typeValue), 
            "ID" : props.carData["NO"]
        }
        
        axios.post(api,body,{headers}).then((response)=>{
            console.log(response.data);
            props.reset();
            if(response.data["errorMessage"] != null){
                messageToggle("KASKO Marka ve Tip Kodları eklenemedi, Bağlantınızı kontrol edin", "error")
            }else{
                messageToggle("KASKO Marka ve Tip Kodları  Başarıyla Eklendi", "success")
                props.toggleTableLoading();
                fetchData();
            }
        }).catch((error)=> {
            console.log(error);
            messageToggle("KASKO Marka ve Tip Kodları eklenemedi, Girilen bilgileri kontrol edin", "error")
        })
    }

    async function fetchData(){
      setButtonPressed(true);
      setIsLoaded(false);
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        "Authorization" : `Bearer ${idToken}`,
      }
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,
            "type" : "FETCH_CAR_KASKO_VALUE",
            "MARKA_ID": brandValue,
            "TİP_ID" : typeValue,
            "MODEL_YILI" : props.carData["MODEL YILI"],
            "MONTH": degerAy,
            "YEAR": degerYıl,
            "ID" : props.carData["NO"]
        },{headers}).then((response)=>{
            console.log(response.data);
           setIsLoaded(true);
            if(response.data["errorMessage"] != null){
                messageToggle("KASKO Marka ve Tip Kodları alınırken bir sıkıntıyla karşılaşıldı, Bağlantınızı kontrol edin", "error")
                props.toggleTableLoading();
            }else{
                props.reset();
                props.toggleTableLoading();
            }
        }).catch((error)=> {
            console.log(error);
            messageToggle("KASKO Marka ve Tip Kodları alınırken bir sıkıntıyla karşılaşıldı", "error")
            props.toggleTableLoading();
            setIsLoaded(true);
        })
    }

    const [isLoaded,setIsLoaded] = useState(false);
    const [ButtonPressed,setButtonPressed] = useState(false);
    useEffect(() => {  if(isLoaded) {  setButtonPressed(false);}},[isLoaded])


    const item_style = {
        standard: { width : 200 },
        viewStyle: {flex:1,flexGrow:1,  alignItems :'center', padding : 10}
        }

    const Months = [
      {"label" : "OCAK" , "value" : "01" },
      {"label" : "ŞUBAT" , "value" : "02" },
      {"label" : "MART" , "value" : "03" },
      {"label" : "NİSAN" , "value" : "04" },
      {"label" : "MAYIS" , "value" : "05" },
      {"label" : "HAZİRAN" , "value" : "06" },
      {"label" : "TEMMUZ" , "value" : "07" },
      {"label" : "AĞUSTOS" , "value" : "08" },
      {"label" : "EYLÜL" , "value" : "09" },
      {"label" : "EKİM" , "value" : "10" },
      {"label" : "KASIM" , "value" : "11" },
      {"label" : "ARALIK" , "value" : "12" },
    ]

    // year data 2000 to current year
    const Years = Array.from(new Array(new Date().getFullYear() - 1999), (val, index) => index + 2000).map((item) => {
      return {"label" : item.toString() , "value" : item.toString()}
    })

    return(
      <View style={{width: '100%'}}>
        <View style={{backgroundColor: 'white', marginBottom: 20}}>
              <Form layout='horizontal'>
                <Form.Group>
                    <Form.ControlLabel> TSB Kasko Marka Kodu </Form.ControlLabel>
                    <MaskedInput  mask={mask} value = {brandValue}  onChange={setBrandValue} ></MaskedInput>
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel> TSB Kasko Tip Kodu  </Form.ControlLabel>
                    <MaskedInput mask={mask} value = {typeValue} onChange = {setTypeValue} ></MaskedInput>
                </Form.Group>
            </Form>
            <View style={{height: 12}}></View>
            <Button
                   onClick={sendData}
              > Kaydet</Button>
        </View>
        <View style={{backgroundColor: 'white', flex: 1 , width: '100%', alignItems: 'flex-start'}}>
             <View style={{marginVertical: 10}}>
                <Text> Kasko Değer Hesaplama </Text>
             </View>
              <Form layout='horizontal' style={{width: '100%'}}>
                <Form.Group>
                    <Form.ControlLabel> Değer Ayı </Form.ControlLabel>
                    <SelectPicker placement= 'autoVerticalStart' style={item_style.standard} value = {degerAy}  onChange={setDegerAy} data={Months} ></SelectPicker>
                
                </Form.Group>
                <Form.Group>
                    <Form.ControlLabel> Değer Yılı </Form.ControlLabel>
                    <SelectPicker placement= 'autoVerticalStart' style={item_style.standard} value = {degerYıl} onChange = {setDegerYıl} data={Years} ></SelectPicker> 
                </Form.Group>
              </Form>
              <Button onClick={fetchData} style={{width: 200}}>
                    Hesapla
              </Button>
            {
            (!isLoaded && ButtonPressed )&&
            <View style={{flexDirection: 'row', backgroundColor: 'powderblue', borderRadius: 12, padding: 3, marginVertical: 5}}>
              
              <Text style={{marginHorizontal: 10, color: 'white' , fontWeight: 'bold' }}>Kasko Değeri Hesaplanıyor ... </Text>
              <Loader></Loader>

            </View>
          }
        </View>
      </View>

    )


}

const mask = createNumberMask({
  allowDecimal : false,
  integerLimit: 7,
  allowNegative: false,
  allowLeadingZeroes: true,
  thousandsSeparatorSymbol: '',
  prefix: '',
  suffix: ''
})

// const ComponentListView = forwardRef((props,ref) => {

//         const [loading, setLoading] = useState(false);
//         const [bordered, setBordered] = useState(true);
//         const [showHeader, setShowHeader] = useState(true);
//         const [sortColumn, setSortColumn] = useState();
//         const [sortType, setSortType] = useState();
//         const [limit, setLimit] = React.useState(10);
//         const [page, setPage] = React.useState(1);
//         const [refresh , setRefresh] = useState(false);
    
//         const toggle_refresh = () => {
//             setRefresh(!refresh)
    
//         }

//         useImperativeHandle(ref,() => ({
//             loadingToggle: () => {
//                 setLoading(!loading)
//             }
//         }))
     
//         useEffect(()=> {getData()},[props.data]);
      
//         const handleChangeLimit = dataKey => {
//           setPage(1);
//           setLimit(dataKey);
    
//         };
      
//         const data = props.data.filter((v, i) => {
//           const start = limit * (page - 1);
//           const end = start + limit;
//           return i >= start && i < end;
//         });
//         const columns = props.tableHead;
        
    
        
    
//       var currency_symbol = "₺"
//         var formatter = new Intl.NumberFormat('tr-TR', {
//           style: 'currency',
//           currency: 'TRY',
        
//           // These options are needed to round to whole numbers if that's what you want.
//           //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
//           //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
//         });
    
//         function formatAmount(input){
//           return formatter.format(input).replace("₺","₺ ")
//         }
    
//         const Months = ["OCAK","ŞUBAT", "MART" , "NİSAN" , "MAYIS", "HAZİRAN", "TEMMUZ" , "AĞUSTOS", "EYLÜL" , "EKİM" ,"KASIM" ,"ARALIK"]
    
//       function editRowData(input,dataKey){
//         if(input[dataKey] == null || input[dataKey] == undefined ) {
//           return input[dataKey];
//         }
//         else if(dataKey.includes("AY")){
//           return Months[input[dataKey] - 1];
//         }
        
//         else if(dataKey.includes("DEĞER") && dataKey.includes("TARİH")){
//             var strArr = input[dataKey].split("-");
//             var result = strArr[0] + " - " + Months[parseInt(strArr[1])-1]
//             return result
//         }
//         else{
//             if(dataKey == "DEĞER"){
//                 return formatAmount(input[dataKey])
//             }
//           return input[dataKey];
//         }
       
//       }
    
//       function reverseDate(input){
//         var result = "";  
        
//         var comps = input.split("-");
    
//         for(var c = 2 ; c >= 0 ; c--){
//           for(var i = 0 ; i < comps[c].length  ; i++){
//             result += comps[c][i];
//         }
//         c != 0 ? result += "-": result += "";
//         }
       
//         return result;
//     }
//       const CustomCellView = ({ rowData, dataKey, ...props }) => (
//         <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
//           <View style={{ height: '80%' , alignItems : 'center'  }}>
//               <Text>{editRowData(rowData,dataKey)}</Text>
//           </View>
//         </Table.Cell>
//     );
    
//           const [checkedKeys, setCheckedKeys] = React.useState([]);
//           let checked = false;
//           let indeterminate = false;
        
//           if (checkedKeys.length === data.length) {
//             checked = true;
//           } else if (checkedKeys.length === 0) {
//             checked = false;
//           } else if (checkedKeys.length > 0 && checkedKeys.length < data.length) {
//             indeterminate = true;
//           }
        
//           const handleCheckAll = (value, checked) => {
//             const keys = checked ? data.map(item => item.id) : [];
//             setCheckedKeys(keys);
//           };
//           const handleCheck = (value, checked) => {
//             const keys = checked ? [...checkedKeys, value] : checkedKeys.filter(item => item !== value);
//             setCheckedKeys(keys);
//           };
        
//           const getData = () => {
//             if (sortColumn && sortType) {
//              return data.sort((a, b) => {
//                 let x = a[sortColumn];
//                 let y = b[sortColumn];
//                 if (typeof x === 'string') {
//                   x = x.charCodeAt();
//                 }
//                 if (typeof y === 'string') {
//                   y = y.charCodeAt();
//                 }
//                 if (sortType === 'asc') {
//                   return x - y;
//                 } else {
//                   return y - x;
//                 }
//               });
//             }
    
//               return data;
           
//           };
    
//           const handleSortColumn = (sortColumn, sortType) => {
//             setLoading(true);
//             setTimeout(() => {
//               setLoading(false);
//               setSortColumn(sortColumn);
//               setSortType(sortType);
//             }, 500);
//           };
    
//           function getWidth(input){
    
//             var max = input.length * 20;
//             data.forEach(element => {
//               var str = element[input] + "";
//               if(str.length * 10 > max){
//                 max = str.length * 10;
//               }
             
//             })
//               return max;
//           }
        
//         return ( 
    
//         <View style = {{marginVertical: 3}}>
//                 <View style = {{flexDirection : 'row', marginVertical: 3}}>
//                   <ExportData data = {data} tableHead = {props.tableHead} tableName={props.tableName}></ExportData>
//                 </View>
//                 <View>
//                     <Table
//                             wordWrap="break-word"
//                             showHeader={showHeader}
//                             sortColumn={sortColumn}
//                             sortType={sortType}
//                             onSortColumn={handleSortColumn}
//                             data={getData()}
//                             bordered={bordered}
//                             cellBordered={bordered}
//                             affixHeader
//                             affixHorizontalScrollbar
//                         >
//                         {columns.map(column => {
//                             const { key, label, ...rest } = column;
//                             return (
//                                 <Table.Column sortable resizable width = {getWidth(column)}>
//                                     <Table.HeaderCell>{column}</Table.HeaderCell>
//                                     <CustomCellView dataKey={column} />
//                                 </Table.Column>
//                             );
//                         })}
//                         </Table>
//                         <Pagination
//                             prev
//                             next
//                             first
//                             last
//                             ellipsis
//                             boundaryLinks
//                             maxButtons={5}
//                             size="xs"
//                             layout={['total', '-', 'limit', '|', 'pager', 'skip']}
//                             total={props.data.length}
//                             limitOptions={[10, 20]}
//                             limit={limit}
//                             activePage={page}
//                             onChangePage={setPage}
//                             onChangeLimit={handleChangeLimit}
//                             locale={{
//                                 more: 'Daha',
//                                 prev: 'Önceki',
//                                 next: 'Sonraki',
//                                 first: 'İlk',
//                                 last: 'Son',
//                                 limit: '{0} / sayfa',
//                                 total: 'Toplam Satır: {0}',
//                                 skip: 'Git {0}'
//                             }}
//                             />
//                 </View>
//             </View>
        
      
//         )
//     }
// ) 


const styles = StyleSheet.create(
  {
    cardView:{
      width: '100%',
      height: 90,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: 12,
      padding: 5,
      shadowColor: "#BDC9FF",
      shadowOffset: {
          width: 0,
          height: 1,
      },
      shadowOpacity: 0.25,
      shadowRadius: 12,
      elevation: 5,
      margin: 10
    }
  }
)

export default KaskoValueScreen;