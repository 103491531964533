import { Text, View , Dimensions } from 'react-native'
import React, { PureComponent,useState, useEffect,useRef} from 'react'
import { Button,DatePicker,Form, Input, MaskedInput, SelectPicker ,IconButton, RadioGroup, Radio,Drawer,List,useToaster, Message,Row, Col, Grid, Panel} from 'rsuite'
import axios from 'axios';
import { useUserToken } from '../../../hooks/useUserToken';
import configdata from '../../../config/config';
import FileUploader from '../../../Components/FileManagements/FileUploader';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import { StyleSheet } from 'react-native';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import CustomListItem from './CustomListItem';
import { faCalendar, faDiamond, faGauge, faMoneyBill, faPerson } from '@fortawesome/free-solid-svg-icons';
import { faModx } from '@fortawesome/free-brands-svg-icons';
import { useMessage } from '../../../hooks/useMessage';
import { useDropdownData } from '../../../hooks/useDropdownData';
import { useValidationRules } from '../../../hooks/useUtils';


const SellInfoScreen = (props) => {


    const [current,setCurrent] = useState({});
    const [clientData,setClientData] = useState([]);


    const {fetchIdToken} = useUserToken();
    async function getData(){
        const api = configdata.API_BASE + '/cardata';
        const token = await fetchIdToken();
        const headers = {
            'Authorization' : `Bearer ${token}`,
        }
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_SELL_INFO",
            "CAR_ID" : props.data["ID"]
        }
        axios.post(api,body,{headers}).then(response => {
            console.log(response.data);
            if(response.data.length == 0){
                setCurrent({});
            }else{
                let temp = {...response.data[0]};
                // temp["TEDARİKÇİ"] = clientData.filter(item => item.value == temp["SELL_CLIENT"])[0].label;
                setCurrent(temp);

            }
           
        }).catch(error => {
            console.log(error);
        })
    }

    function getClients(){
        console.log("getClients");
        const api = configdata.API_BASE + '/customers';
          axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS_TEDARIK"
          }).then(
              (response) => {
                  console.log(response.data);
                  let temp = [];
                    response.data.forEach(element => {
                        if(element.NAME == null || element.NAME == ""){
                            temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                        }else {
                            temp.push({"label" : element.NAME , "value" : element.ID})
                        }
                    });
                    console.log(temp);
                  setClientData(temp);
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    }

    // useEffect(() => {
    //     if(clientData.length > 0){
    //         console.log("getData");
    //         getData();
    //     }
    // },[clientData]);

    useEffect(() => {
        getClients();
        getData();
    },[]);



    const headers =
    [ 
        {"header" : "MÜŞTERİ" , "data" : current["CLIENT_INFO_NAME"] , "icon" :faPerson },
        {"header" : "SATIŞ KM" , "data" : current["KM"] , "icon" :faGauge },
        {"header" : "SATIŞ TARİHİ" , "data" : current["SELL_DATE"] , "icon" :faCalendar },
        {"header" : "SATIŞ TUTARI" , "data" : current["TAX_INC_PRICE"] , "icon" :faMoneyBill },
        {"header" : "SATIŞ KDV ORANI" , "data" : current["KDV"] , "icon" :faModx },
        {"header" : "SATIŞ KDV'SİZ TUTAR" , "data" : current["TAX_EXC_PRICE"] , "icon" :faMoneyBill },
    ]    

    const headers2 =
    [ 
        {"header" : "DİĞER FATURALI TUTAR" , "data" : current["DIGER_KDV_TUTARI"] , "icon" :faMoneyBill },
        {"header" : "DİĞER FATURASIZ TUTAR" , "data" : current["DIGER_FATURASIZ_TUTAR"] , "icon" :faPerson },
        {"header" : "DİĞER FATURALI KDV'SİZ TUTAR" , "data" : current["DIGER_KDVSIZ_TUTAR"] , "icon" :faMoneyBill },
        {"header" : "DİĞER FATURALI KDV ORANI" , "data" : current["DIGER_KDV"] , "icon" :faModx },
        {"header" : "SATIŞ DANIŞMANI", "data" : current["STAFF"], "icon" : faPerson}
    ]    
    
    const [infoDrawer,setInfoDrawer] = useState(false);
    const [fileDrawer,setFileDrawer] = useState(false);
    const [fileCategory,setFileCategory] = useState("BUY_FILE");

    function handleClose(){
        setInfoDrawer(false);
    }

    function handleOpen(){
        setInfoDrawer(true);
    }

    function handleOpenFileDrawer(category){
        setFileCategory(category);
        setFileDrawer(true);
    }

    function handleCloseFileDrawer(){
        setFileDrawer(false);
    }

    function reset(){
        setInfoDrawer(false);
        getData();
    }
    
    

    return (
        <View style={{backgroundColor: '#F2F2F2', width: '100%',flex : 1, padding: 20}}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 50, minWidth: 200,marginBottom: 20 }} >
                <IconButton onClick={() => { setInfoDrawer(true)}} icon={<Icon><FontAwesomeIcon icon = {solid('plus')}></FontAwesomeIcon></Icon>}> Satış Bilgisi Düzenle</IconButton>
            </Panel>
            <Grid fluid style={{width: '100%', marginBottom: 20}}>
                <Row gutter={20}>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                {headers.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                            </List>
                        </Panel>
                    </Col>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 500, minWidth: 200,marginBottom: 20}} >
                            <List>
                                {headers2.map((item) =>
                                {
                                return(<List.Item key={item} index={item}>
                                    <CustomListItem item={item} ></CustomListItem>
                                </List.Item>)
                                }
                                )}
                                <List.Item key= {"NoterFile"}>
                                    <CustomListItem type={"FILE"} item={{"header" : "NOTER DOSYASI","type" : "NOTER_FILE"}} openFileDrawer= {handleOpenFileDrawer} ></CustomListItem>
                                </List.Item>

                            </List>
                        </Panel>
                    </Col>
                </Row>
            </Grid>

            <Drawer overflow={true}  size={'md'}  backdrop={'static'} keyboard={false} open={infoDrawer} onClose={handleClose} >
                <Drawer.Header>
                    <Drawer.Title> Satış Bilgisi </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <SellInfo data={props.data} type="" reset = {reset} carID={props.data["ID"]}></SellInfo>
                </Drawer.Body>
                
            </Drawer>

            <Drawer backdrop= {'static'} size={'full'}  open={fileDrawer} onClose={handleCloseFileDrawer} >
                <Drawer.Header>
                    <Drawer.Title> Satış Dosyası </Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    {
                        <FileViewer inline = {false} height = {1000} s3KEY = {current["NOTER_FILE"]}></FileViewer>
                    }
                </Drawer.Body>
            </Drawer>
        </View>
            
    )

}

const SellInfo = (props) => {
    const [clientData,setClientData] = useState([]);
    const [staffData,setStaffData] = useState([
    {
        "label" : "Halil Çokgünlü",
        "value" : 1
    },
    {
        "label" : "Yemliha Kocamaz",
        "value" : 2
    },
]);

    const [current,setCurrent] = useState({});
    const [noterFile,setNoterFile] = useState(null);
    const [ekspertizFile,setEkspertizFile] = useState(null);

    const [toplamTutar,setToplamTutar] = useState(0);
    const [vergisizTutar,setVergisizTutar] = useState(0);
    const [kdv,setKdv] = useState(0);
    const [clientID,setClientID] = useState(0);
    const [staffID,setStaffID] = useState(0);
    const [officialSellDate,setOfficialSellDate] = useState(new Date());
    const [ekspertizDurumu,setEkspertizDurumu] = useState("YOK");
    const [digerFaturalıTutar,setDigerFaturalıTutar] = useState(0);
    const [digerFaturasızTutar,setDigerFaturasızTutar] = useState(0);
    const [digerFaturalıKDVsizTutar,setDigerFaturalıKDVsizTutar] = useState(0);
    const [digerKdv,setDigerKdv] = useState(0); 
    const [km,setKm] = useState(0);
    const [newPlateNumber,setNewPlateNumber] = useState(0);

    const {fetchIdToken} = useUserToken();
    const {messageToggle} = useMessage();
    const {fixPlaka} = useValidationRules();

    const {kdvOranları} = useDropdownData();


    useEffect(() => {
        getClients();
        getStaffData();
    },[]);


    function getClients(){
        const api = configdata.API_BASE + '/customers';
        const idToken = fetchIdToken();

        const header = {
            'Authorization' : `Bearer ${idToken}`,
        }

        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS"
        }

        axios.post(api,body,{headers : header}).then((response) => {
            console.log(response.data);
            let temp = [];
            response.data.forEach(element => {
                if(element.NAME == null || element.NAME == ""){
                    temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                }else {
                    temp.push({"label" : element.NAME , "value" : element.ID})
                }
            });
            console.log(temp);
            setClientData(temp);
        }).catch((error) => {
            console.log(error);
        })

    }

    function getStaffData(){
        setStaffData([
            {
                "label" : "Halil Çokgünlü",
                "value" : 1
            },
            {
                "label" : "Yemliha Kocamaz",
                "value" : 2
            },
        ]);

    }

    async function submit(){
        const api = configdata.API_BASE + '/cardata';
        const idToken = await fetchIdToken();
        const headers = {
            "Authorization" : `Bearer ${idToken}`,
        }
        
        const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "ADD_SELL_INFO",
            "CAR_ID" : props.carID,
            "CLIENT_ID" : clientID,
            "STAFF_ID" : staffID,
            "OFFICIAL_SELL_DATE" : officialSellDate,
            "KM" : km,
            "EXPERTIZ" : ekspertizDurumu,
            "EXPERTIZ_FILE" : ekspertizFile,
            "KDV" : kdv,
            "TAX_EXC_PRICE" : toplamTutar / ((kdv / 100) + 1),
            "TAX_INC_PRICE" : toplamTutar,
            "DIGER_KDV" : digerKdv,
            "DIGER_TUTAR" : digerFaturalıTutar,
            "DIGER_FATURASIZ_TUTAR" : digerFaturasızTutar,
            "NOTER_FILE" : noterFile,
            "NEW_PLATE_NUMBER" : newPlateNumber
        }

        console.log(body);
        axios.post(api,body,{headers}).then((response) => {
            console.log(response);
            if(response.data == "SUCCESS"){
                messageToggle("Satış Bilgisi Başarıyla Eklendi","success");
                props.reset();
            }
            else{
                messageToggle("Satış Bilgisi Eklenirken Bir Hata Oluştu","error");
            }
        }
        ).catch((error) => {
            console.log(error);
            messageToggle({type : "error" , message : "Satış Bilgisi Kaydedilemedi."});
        })


    }


    return ( 
        <View style={{flex: 1, alignItems: 'center', padding: 10}}>
            <View style={styles.row}>
                {/* Satış Tarihi */}
                <Group label={'Satış Tarihi'} layout={'inline'} >
                    <DatePicker shouldDisableDate={ (date) => { return date > new Date() }} value={officialSellDate}
                    placement='autoVerticalStart' format="dd-MM-yyyy"  isoWeek = {true}  oneTap style={styles.formComp} onChange={(value) => {setOfficialSellDate(value)}} />
                </Group>
                {/* Müşteri */}
                <Group label={'Müşteri'} layout={'inline'} >
                    <SelectPicker data={clientData} style={styles.formComp} onChange={(value) => {setClientID(value)}} value={clientID} />
                </Group>
                {/* Danışman */}
                <Group label={'Danışman'} layout={'inline'} >
                    <SelectPicker data={staffData} style={styles.formComp} onChange={(value) => {setStaffID(value)}} value={staffID} />
                </Group>
                {/* Satış Tutarı */}
                <Group label={'Satış Tutarı'} layout={'inline'} >
                    <MaskedInput mask={numberMaskTL} style={styles.formComp} onChange={(value) => {setToplamTutar(unformatTLvalue(value))}} />
                </Group>
                {/* Satış KDV */}
                <Group label={'Satış KDV'} layout={'inline'} >
                    <SelectPicker data={kdvOranları} style={styles.formComp} onChange={(value) => {setKdv(value)}} value={kdv} />
                </Group>
                {/* Satış KDV'siz Tutar */}
                <Group label={'Satış KDV\'siz Tutar'}  layout={'inline'} >
                    <DisabledLabel label={numberToCurrency(toplamTutar / ((kdv / 100) + 1) )}  />
                </Group>

                <Group label={'Noter Dosyası'} layout={'inline'} >
                        <FileUploader type={"NOTER_FILE"} carID={props.carID} setFile={setNoterFile} />
                </Group>
                <Group label={'Yeni Plaka'} layout={'inline'} >
                    <Input style={styles.formComp} value={newPlateNumber} onChange={(value) => { setNewPlateNumber(fixPlaka(value))}} />
                </Group>

                {/* Diğer Faturalı Tutar */}
                <Group label={'Diğer Faturalı Tutar'} layout={'inline'} >
                    <MaskedInput mask={numberMaskTL} style={styles.formComp} onChange={(value) => {setDigerFaturalıTutar(unformatTLvalue(value))}} value={digerFaturalıTutar} />
                </Group>
                {/* Diğer Faturasız Tutar */}
                <Group label={'Diğer Faturasız Tutar'} layout={'inline'} >
                    <MaskedInput mask={numberMaskTL} style={styles.formComp} onChange={(value) => {setDigerFaturasızTutar(unformatTLvalue(value))}} value={digerFaturasızTutar} />
                </Group>
                {/* Diğer Faturalı KDVsiz Tutar */}
                <Group label={'Diğer KDVsiz Tutar'} layout={'inline'} value={digerFaturalıKDVsizTutar}>
                    <DisabledLabel label={numberToCurrency(digerFaturalıTutar / ((digerKdv / 100) + 1) )} />
                </Group>
                {/* Diğer Faturalı KDV */}
                <Group label={'Diğer KDV'} layout={'inline'} >
                    <SelectPicker data={kdvOranları} style={{ width: 250 }} onChange={(value) => {setDigerKdv(value)}} value={digerKdv} />
                </Group>

                {/* Toplam Tutar */}
                <Group label={'Toplam Tutar'} layout={'inline'} >
                    <DisabledLabel label={numberToCurrency(toplamTutar + digerFaturalıTutar + digerFaturasızTutar)} />
                </Group>
                {/* Vergisiz Tutar */}
                <Group label={'Vergisiz Tutar'} layout={'inline'} >
                    <DisabledLabel label={numberToCurrency((toplamTutar + digerFaturalıTutar + digerFaturasızTutar) / ((kdv / 100) + 1) )} />
                </Group>
                {/* KDV */}
                <Group label={'KDV'} layout={'inline'} >
                    <DisabledLabel label={numberToCurrency((toplamTutar + digerFaturalıTutar + digerFaturasızTutar) - ((toplamTutar + digerFaturalıTutar + digerFaturasızTutar) / ((kdv / 100) + 1)))} />
                </Group>
                {/* KM */}
                <Group label={'Satış KM'} layout={'inline'} >
                    <MaskedInput mask={kmMask} style={styles.formComp} onChange={(value) => {setKm(value)}} />
                </Group>
                <Group label={'Ekspertiz Durumu'} layout={'inline'} >
                    <RadioGroup name="radioList" inline appearance="picker" defaultValue="YOK" onChange={(value) => {setEkspertizDurumu(value)}}>
                        <Radio value="VAR">VAR </Radio>
                        <Radio value="YOK">YOK </Radio>
                    </RadioGroup>
                </Group>
            </View>
            { ekspertizDurumu == "VAR" &&
                <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                    <Text style={{marginHorizontal: 15}} > Ekspertiz Dosyası </Text>
                    <FileUploader type={"EKSPERTIZ_FILE"} carID={props.carID} setFile={setEkspertizFile} />
                </View>

            }


            <View style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                <Button style={styles.buttonRed} onClick={() => {props.reset()}} >İptal</Button>
                <View style={{width: 25}} ></View>
                <Button style={styles.buttonBlue} onClick={() => {submit()}} >Kaydet</Button>
            </View>
        </View>
    )
}

export default SellInfoScreen



    

const kmMask = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol : '.',
    suffix: ' KM' ,
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 5
})


const DisabledLabel = (props) => {
    return (
        <View style={styles.disabledLabel}>
            <Text style = {{color: 'white'}} >{props.label}</Text>
        </View>
    )
}

function Group ({ label, children, layout }) {
    return (
        <View style={{flexDirection: layout == "inline" ? 'row' : 'column', flex : 1, width: '100%',marginVertical: 10 , justifyContent: 'space-between'}}>
            <Text style={{ fontSize: 14, color: '#000', margin: 10, textAlign: 'right'}}>{label}</Text>
            <div>{children}</div>

        </View>
    );
}


const numberMaskTL = createNumberMask({
    prefix: '',
    thousandsSeparatorSymbol : '.',
    suffix: ' TL' ,
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 5
  })

  function numberToCurrency(number){
    let temp = number;
    if(typeof number == "string" && !number.includes("TL")){
        temp = parseFloat(number);
    }
    return temp.toLocaleString('tr-TR', { style: 'currency', currency: 'TRY' });
    }
            

  const unformatTLvalue = (value) => {
    
    if(value == null || value == ""){
        return 0;
    }
    if(value.split == undefined){
        return value;
    }
    if(value == "₺0,00"){
        return 0;
    }
    let temp = value.split(" ");
    temp = temp[0].split(".");
    let result = "";
    temp.forEach(element => {
        result += element;
    });
    result = result.replace(",",".");
    return parseFloat(result);
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F2FFFB',
        alignItems: 'center',
        justifyContent: 'center',
    },
    group: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        backgroundColor: 'white',
        borderRadius: 7,
        marginVertical: 10,
        padding: 10,
        height: 120,
        borderWidth: 1,
        borderColor: '#F2FFFB',
    },

    listItem: {
        backgroundColor: "#FFF",
        width: 200,
        height: 35,
    },
    listItemDisabled: {
        backgroundColor: "#aac9fa",
        width: 200,
        height: 35,
        borderRadius: 7,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',

    },
    formComp:{
        width: 250,
        height: 35,
    },
    row: {
        flex: 1,
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
        marginVertical: 20,
    },
    disabledLabel: {
        backgroundColor: "#aac9fa",
        width: 250,
        height: 35,
        borderRadius: 7,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },

    buttonRed : {
        backgroundColor: "#FF0000",
        width: 120,
        height: 35,
        color: 'white',

    },
    buttonBlue : {
        backgroundColor: "#267aff",
        width: 120,
        height: 35,
        color: 'white',

    },

});

