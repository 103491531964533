import { Text, View , StyleSheet,Dimensions } from 'react-native'
import React, { useState, useEffect ,useRef} from 'react'
import axios from 'axios';
import { Input,useToaster, Message, Button ,DatePicker,IconButton , Modal ,Checkbox, List, Drawer, MaskedInput, Stack, Panel, Grid, Row ,Col} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import locale from '../../../tr'
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faCalendar,faMoneyBill, faSquare , faArrowsLeftRight} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CustomText from '../../../Components/CustomText';
import ExportData from '../../../Components/TableManagement/ExportData';

const ExhaustScreen = (props) => {

    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState({});
    const [defaultData,setDefaultData] = useState({"EGZOZ EMİSYON ARALIĞI" : props.data["EGZOZ EMİSYON ARALIĞI"],"SONRAKİ EGZOZ TARİHİ" :getAddedDate(props.data["MUAYENE TARİHİ"], props.data["EGZOZ EMİSYON ARALIĞI"] ) });
    const [editData,setEditData] = useState({});
    const [s3KEYS , setS3Keys] = useState(getS3Keys());
    function handleModalClose(){
        setModal(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getComponents();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();
    async function getComponents(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
        const api = configdata.API_BASE + '/cardata';
        axios.post(api,{
          "ENV" :configdata.APP_ENV ,  
            "type" : "GET_EXHAUST_DATA",
            "ID" : props.data["NO"],
        },{headers}).then((response)=>{
            console.log(response.data);
            if(response.data.length > 0){
              setCurrentActive(response.data);
          
            }
           
            setData(response.data);
        }).catch((error)=> {
            // console.log(error);
        })
    }
    function addMonths(date, months) {
      var d = date.getDate();
      
      date.setMonth(date.getMonth() + months);
      if (date.getDate() != d) {
        date.setDate(0);
      }
      return date;
  }

  function getAddedDate(date,months){
    var interval = months + 1;
   
    var newDate = addMonths(new Date(date),interval);
    var month_str = newDate.getMonth() < 10 ? "0" + newDate.getMonth():newDate.getMonth() ;
    var day_str = newDate.getDate() < 10 ? "0" + newDate.getDate(): newDate.getDate() ;
     return newDate.getFullYear() + "-" + month_str + "-" + day_str;

  }

    function setCurrentActive(input){

      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          var date = new Date(input[i]["EGZOZ EMİSYON TARİHİ"]);
          // date.setMonth(date.getMonth() + element["EGZOZ EMİSYON ARALIĞI"] );
          var interval = parseInt(input[i]["EGZOZ EMİSYON ARALIĞI"]) + 1;
          // console.log(element["EGZOZ EMİSYON ARALIĞI"]);
          // console.log(interval);
          var newDate = addMonths(date,interval);
          var month_str = newDate.getMonth() < 10 ? "0" + newDate.getMonth():newDate.getMonth() ;
          var day_str = newDate.getDate() < 10 ? "0" + newDate.getDate(): newDate.getDate() ;
          input[i]["SONRAKİ EGZOZ TARİHİ"] = newDate.getFullYear() + "-" + month_str + "-" + day_str;
          setCurrent(input[i]);
          // console.log(input[i]["S3_KEY"]);
          // fileViewRef.current.set(parseS3(input[i]["S3_KEY"]));
          break;
        }
      }
        // input.forEach((element) => {
        //   if(element["IS_ACTIVE"] == 1){
            
        //     var date = new Date(element["EGZOZ EMİSYON TARİHİ"]);
        //     // date.setMonth(date.getMonth() + element["EGZOZ EMİSYON ARALIĞI"] );
        //     var interval = parseInt(element["EGZOZ EMİSYON ARALIĞI"]) + 1;
        //     // console.log(element["EGZOZ EMİSYON ARALIĞI"]);
        //     // console.log(interval);
        //     var newDate = addMonths(date,interval);
        //     var month_str = newDate.getMonth() < 10 ? "0" + newDate.getMonth():newDate.getMonth() ;
        //     var day_str = newDate.getDate() < 10 ? "0" + newDate.getDate(): newDate.getDate() ;
        //      element["SONRAKİ EGZOZ TARİHİ"] = newDate.getFullYear() + "-" + month_str + "-" + day_str;
        //     setCurrent(element);
            
        //   }
        // })
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModal(true);
    }

    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }

    const[viewData,setViewData] = useState({});
    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function getS3Keys(){
      // if(JSON.parse(props.data["S3 KEYS EXHAUST"]).data != null){
      //   return JSON.parse(props.data["S3 KEYS EXHAUST"]).data[0];
      // }
      return [];
    }
    const fileViewRef = useRef();
    const FileViewModal = () => {
      return(
        <View>
           <Modal  size='full' backdrop={true} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                    <Modal.Header>
                    <Modal.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"EGZOZ EMİSYON BİLGİLERİ"} </Text>
                      </View>} 
                    </Modal.Title>
                    </Modal.Header>
  
                    <Modal.Body>

                    <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                    </Modal.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Modal>
        </View>
      )
    }
    const ref =useRef();

    const tableHeads= ["EGZOZ EMİSYON TARİHİ","TUTAR","İSTASYON","EGZOZ EMİSYON ARALIĞI","SONRAKİ EGZOZ EMİSYON TARİHİ"];
    const labelHeads= ["EGZOZ EMİSYON TARİHİ","TUTAR","İSTASYON","EGZOZ EMİSYON ARALIĞI","SONRAKİ EGZOZ EMİSYON TARİHİ","NOTLAR"];
    const listHeads = ["EGZOZ EMİSYON TARİHİ","SONRAKİ EGZOZ EMİSYON TARİHİ","NOTLAR"];

    const headers = [
      {"header" : "EGZOZ EMİSYON TARİHİ" , "data" : current["EGZOZ EMİSYON TARİHİ"] , "icon" : faCalendar},
      {"header" : "TUTAR" , "data" : current["TUTAR"] , "icon" : faMoneyBill},
      {"header" : "İSTASYON" , "data" : current["İSTASYON"] , "icon" : faBuilding},
      {"header" : "EGZOZ EMİSYON ARALIĞI" , "data" : current["EGZOZ EMİSYON ARALIĞI"] , "icon" : faArrowsLeftRight},
      {"header" : "SONRAKİ EGZOZ EMİSYON TARİHİ" , "data" : current["SONRAKİ EGZOZ EMİSYON TARİHİ"] , "icon" : faCalendar},
      {"header" : "NOTLAR" , "data" : current["NOTLAR"] , "icon" : faSquare},
    ]


    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding: 20}}>
              <Drawer size= {Dimensions.get('window').width < 700 ? 'full' : 'sm'}  backdrop={'static'} keyboard={false} open={modal} onClose={handleModalClose}>
                    <Drawer.Header>
                        <Drawer.Title>{<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"EGZOZ EMİSYON BİLGİLERİ"} </Text>
                      </View>}  
                      </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      <View>
                      {modalType == "New" ? (<NewMaintenancePanel 
                        type={"NEW"}
                        cardata = {props.data} 
                        data = {editData}
                        reset = {() => {getComponents(); handleModalClose();}}></NewMaintenancePanel>) 
                        :
                        ( <NewMaintenancePanel 
                          type={"EDIT"}
                          cardata = {props.data} 
                          data = {editData}
                          reset = {() => {getComponents(); handleModalClose();}} ></NewMaintenancePanel> )}
                      </View>
                    </Drawer.Body>
              </Drawer>


              <Grid fluid style={{width: '100%'}}>
                <Row gutter={20}>
                  <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                  <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200, marginBottom: 20}} 
                          header  = {
                            <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                              <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack.Item grow={1}>
                                <IconButton 
                                  style={{width: '100%', height: 35}}
                                  onClick={() => {
                                    setModal(true);
                                    setModalType("New");}}
                                    icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>}
                                    >
                                    Yeni Muayene Bilgisi
                                </IconButton>
                                </Stack.Item>
                                <ExportData data = {data} tableHead = {tableHeads} tableName={"Muayene_Bilgileri" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                              </Stack> 
                             
                        </View>
                        } > 
                            <CustomText fontWeight= {800} fontSize={14} > {"Egzoz Muayene Bilgileri" } </CustomText>
                            <ComponentListView hasDoc={true}  current={current} showDetails={showDetailsOfChoosen}   chooseANDShow={chooseANDShow} setchoosen_data={chooseANDEdit} listHeads={listHeads}  tableName = {"Egzoz Muayene Bilgileri" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads}  data={data}></ComponentListView>

                        </Panel>
                  </Col>
                  <Col xs = {24} sm = {24} md={24} lg={16} xl ={12}>
                    <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} header = {<CustomText fontWeight={800} > {"Egzoz Muayene Detayları -- " + props.data["PLAKA"] } </CustomText> } >
                        <List>
                        {headers.map((item) =>
                          {
                          return(<List.Item key={item} index={item}>
                            <CustomListItem item={item} ></CustomListItem>
                          </List.Item>)
                          }
                          )}
                      </List>
                    </Panel>
                  </Col>
                </Row>
              </Grid>
          
                <FileViewModal></FileViewModal> 
 
        </View>
    )
}


const NewMaintenancePanel = (props) => {
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }



    const [activateAddButton,setActiveAddButton] = useState(true);


    // Conbo Box Values
    

    // SENT VALUES
    const [exhasutDate,setExhaustDate] = useState(new Date());
    const [exhasutDate2,setExhaustDate2] = useState(new Date());
    const [service,setService] = useState("");
    const [amount,setAmount] = useState(0);
    const [note,setNote] = useState("");
    const [IS_ACTIVE,setIS_ACTIVE] = useState(true);
    const [S3Key,setS3Key] = useState(undefined);
    const fileUploaderRef= useRef();
    function reset(){
        setExhaustDate(new Date());
        setExhaustDate2(new Date());
        setService("");
        setAmount(0);
        setNote("");
        setIS_ACTIVE(true);
    }

    function getEditData(){
        setExhaustDate(new Date(props.data["EGZOZ EMİSYON TARİHİ"]));
        setExhaustDate2(new Date(props.data["SONRAKİ EGZOZ EMİSYON TARİHİ"]));
        setService(props.data["İSTASYON"]);
        setAmount(props.data["TUTAR"]);
        setNote(props.data["NOTLAR"]);
        setIS_ACTIVE(props.data["IS_ACTIVE"] == 1);
  }

    function check(){
          let result = true;
          setActiveAddButton(result);
    }

    function getDropDownValues(input,set){
   
      const api = configdata.API_BASE + '/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": input,
          "type" : "GET_GLOBAL",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
           result.push({"label" : element.VALUE , "value" : element.ID})
          }
         );
        set(result);
        console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });

    }

    function sendAndLoad(){
       fileUploaderRef.current.load();
    }

    useEffect(() => {
      if(S3Key != undefined){
        console.log(S3Key);
        sendData();
       
      }
    },[S3Key])

    const {fetchIdToken} = useUserToken();
    async function sendData(){
     const idToken = await fetchIdToken();
     const headers = {
       "Authorization" : `Bearer ${idToken}`  
       
     }
      const api = configdata.API_BASE + '/cardata';
      let newAmount = convertBackToMoney(amount);
      axios.post(api,
        {
          "ENV" :configdata.APP_ENV ,  
            "type": props.type == 'EDIT' ? "UPDATE_EXHAUST" : "NEW_EXHAUST_DOC",
            "EXHAUST_DATE": exhasutDate,
            "NEXT_EXHAUST_DATE" : exhasutDate2,
            "AMOUNT" : newAmount,
            "STATION" : service,
            "NOTES" :note,
            "CAR_ID" : props.cardata["NO"],
            "IS_ACTIVE" : IS_ACTIVE ? "1" : "0",
            "ID" : props.data["NO"],
        },{headers}
      ).then((response) => {
        console.log(response.data);
        if(response.data["errorMessage"] != null){
            messageToggle("Egzoz Emisyon Bilgileri eklenemedi , Bağlantınızı kontrol edin", "error")
        }else{
          if(response.data.split("_")[0] == "SUCCESS"){
            fileUploaderRef.current.load(response.data.split("_")[1]);
            messageToggle("Egzoz Emisyon Bilgileri başarıyla eklendi", "success")
        }
        else{
          messageToggle("Egzoz Emisyon Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
        }
      }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Egzoz Emisyon Bilgileri eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }

    useEffect(() => {
      
     if(props.type == "EDIT"){getEditData()}
    //  getDropDownValues("MAINTENANCE_TYPE",setMaintenanceTypes);
    

    },[])

    function handleS3(input){
        setS3Key(input);
    }


    const Textarea = React.forwardRef((props, ref) => <Input {...props} as="textarea" ref={ref} />);

    
    return(
          <View style= {{flexDirection: 'column',width: '100%'}}>
              {/* <DropDownComponent setValue ={setValue}></DropDownComponent>  */}
                <View style={styles.form} >
                  <Group header="Egz. Emisyon Tarihi">

                      <DatePicker value={exhasutDate} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                            {setExhaustDate(value);}}
                            oneTap format="dd-MM-yyyy"
                            locale={locale}
                        ></DatePicker>
                    </Group>
                    <Group header="Sonraki Egz. Emisyon Tarihi">

                      <DatePicker value={exhasutDate2} style={styles.standard} isoWeek = {true} onSelect={(value) =>
                            {setExhaustDate2(value);}}
                            oneTap format="dd-MM-yyyy"
                            locale={locale}
                        ></DatePicker>
                    </Group>
                      {/* Tutar */}
                    <Group  header="Tutar">
                      <MaskedInput mask={tlMask} value={amount} style={styles.standard} min={0} onChange={ (value) => {setAmount(value);}} name= {"tutar"} /> 
                    </Group>
                
                    <Group header="İstasyon">
                      <Input value={service} style={styles.standard}  onChange={ (value) => {setService(value);}} name= {"service"} /> 
                    </Group>
                    <Group header="Notlar">
                      <Input style={styles.standard} value={note} as={"textarea"} onChange={ (value) => {setNote(value);}} name= {"notes"}   /> 
                    </Group>
                    <Group header="Dosya Yükle">
                      <FileLoader reset={props.reset} ref = {fileUploaderRef} fileCategory = {"EXHAUST"} carID = {props.cardata["NO"]} ></FileLoader>
                    </Group>
                    {
                    props.type == "EDIT" &&
                    <Group header="Durum">
                      <Checkbox checked={IS_ACTIVE} onChange={(value,checked) => { setIS_ACTIVE(checked)}} ></Checkbox>
                    </Group>
                    }
                  </View>
                  <View style={{height: 50}}></View>
              <Button
                  disabled = {!activateAddButton}
                  style={styles.buttonStyle}
                  onClick={() => { sendData();}}
                > <CustomText fontWeight = {800} fontSize = {14} style={{color : '#fff'}}> Kaydet </CustomText> </Button>

          </View>

    )
}


const Group = ({ children, ...props }) => (
  <View {...props} style={styles.group}>
    <View style = {{marginRight: 5 }}>
      <CustomText fontSize = {14} fontWeight={800} style={{color : '#5A6273'}} >{props.header}</CustomText>
    </View>
    <View style={{flex: 1, alignItems: 'flex-end'}}>
      {children}
    </View>
  </View>
);

const styles = StyleSheet.create({
  group: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 3,
  },
  form: {
    width: '100%',
  },
  buttonStyle: {
    width: 120,
    alignSelf: 'flex-end',
    backgroundColor: '#5E6EED',
    color: 'white',
    borderRadius: 7,
    marginVertical: 5,
  },
  standard: { flex: 1 , maxWidth: 300, minWidth: 150,width: '100%'},
  input: {
    flex: 1,
  },
});


const tlMask = createNumberMask({
  prefix: '₺ ',
  suffix: '',
  allowDecimal: true,
  decimalLimit: 5,
  integerLimit: 7,

});

const convertBackToMoney = (input) => {
  if(input == "" || input == null || input == undefined){
    return 0;
  }
  if(typeof input == "number"){
    return input;
  }

  return parseFloat(input.replace("₺ ", "").replace(",", "."));
}






export default ExhaustScreen;