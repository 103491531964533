import { Text, View , StyleSheet,ScrollView } from 'react-native'
import React, { useState, useEffect ,useRef, useCallback } from 'react'
import axios from 'axios';
import { useToaster,Schema  ,Message, Button, Form ,DatePicker,TreePicker,IconButton ,SelectPicker ,InputGroup, MaskedInput,Popover, CheckPicker, Radio, RadioGroup, ButtonGroup  } from 'rsuite';
import { NumericFormat } from 'react-number-format'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import locale from '../../tr';
import "rsuite/dist/rsuite.min.css";
import configdata from '../../config/config.json';
import { set } from 'react-hook-form';
import { useUserToken } from '../../hooks/useUserToken';
import { CheckBox } from 'react-native-web';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import {useDropdownData} from '../../hooks/useDropdownData';

const EditCarScreen = (props) => {
    const { StringType, NumberType,ArrayType ,ObjectType} = Schema.Types;
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }

    //#region Number Mask
    var formatterKm = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    });

    var formatterStandard = new Intl.NumberFormat('tr-TR', {
      style: 'unit',
      unit: 'kilometer',
    });
    //#endregion

    //#region Styles
    const styles = {
      buttonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 120
      },
      wideButtonStyle:{
        textAlign: 'center', backgroundColor : '#00589B',color: 'white', borderRadius: 7,width: 200
      }
    }

    const item_style = {
      header: {fontWeight: 'bold'},
      standard: { width : 200 },
      short: { width : 100 },
      standardText : {fontWeight: '400'},
      viewStyle: { backgroundColor: 'white', height : 200 ,borderRadius: 5, width:'90%',justifyContent: 'center' , alignItems :'space-between', padding : 25, marginVertical : 12},
      viewStyleSingleLine: { backgroundColor: 'white', height : 135 ,borderRadius: 12, width:'90%',justifyContent: 'center' , alignItems :'space-between', padding : 25, marginVertical : 12
    ,shadowColor: "#BDC9FF", shadowOffset: {width: 2,height: 5,},shadowOpacity: 0.25,shadowRadius: 12,elevation: 5},
      numeric :{width : 200 ,borderStyle: 'solid', borderWidth: '1px',borderColor :'#e5e5ea', borderRadius: 5, height: 35, outlineColor: '#4d98f7', outlineWidth: '1px' , padding : 5},
      dropDownGroupBack : {borderRadius: 12,flexDirection: 'row',height: 40, paddingHorizontal : 0, justifyContent: 'center', alignItems: 'center',backgroundColor:'#F2FFFB'}
    }
    //#endregion

    //#region consts UseState
    const [activateAddButton,setActiveAddButton] = useState(true);


  // COMBO BOX ARRAYS
  const [optionsBMP,setOptionsBMP] = useState([]);
  const [optionsCarTags,setOptionsCarTags] = useState([]);
  const [carBodyStyles,setCarBodyStyles] = useState([]);
  const [factory_years,setfactory_years] = useState([]);
  const [fuel_types,setYakıtTipis] = useState([]);
  const [cc_types,setcc_types] = useState([]);
  const [carOwners,setCarOwners] = useState([]);
  const [colors,setColors] = useState([]);
  const [transmissions,settransmissions] = useState([]);
  const [km_periods,setKm_periods] = useState([]);
  const [araçDurumlar,setAraçDurumlar] = useState([]);
  const [buydeps,setBuydeps] = useState([]);
  const [carBuyTypes,setCarBuyTypes] = useState([]);


  // SENT VALUES
  const [carBuyType,setCarBuyType] = useState(0);
  const [buyDep,setBuyDep] = useState(0);
  const [araçDurum, setAraçDurum] = useState(1);
  const [BMP,setBMP] = useState(["","",""])
  const [BMP_ID,setBMP_ID] = useState([0,0,0])
  const [cartag,setCarTag] = useState(["","",""])
  const [cartag_id,setCartag_id] = useState("")
  const [tescilTarihi,setTescilTarihi] = useState(null);
  const [ilkTescilTarihi,setIlkTescilTarihi] = useState(null);
  const [plaka,setPlaka] = useState("");
  const [KM,setKM] = useState("0");
  const [kasa,setKasa] = useState(-1);
  const [modelYılı,setModelYılı] = useState(-1);
  const [yakıtTipi,setYakıtTipi] = useState(-1);
  const [CC,setCC] = useState(-1);
  const [vites,setVites] = useState(-1);
  const [km_period,setKm_period] = useState(-1);
  const [CC_SECTION,setCC_SECTION] = useState("");
  const [CC_SECTION_value,setCC_SECTION_value] = useState("");
  const [HP_value,setHP_value] = useState("");
  const [KW_value,setKW_value] = useState("");
  const [KM_valueStr,setKM_valueStr] = useState("0");
  const [sasi,setSasi] = useState("");
  const [motorno,setMotorno] = useState("");
  const [aracMarkaRenk,setAracMarkaRenk] = useState("");
  const [HGSNO,setHGSNO] = useState("");
  const [TrafficInsurenceNO,setTrafficInsurenceNO] = useState("");
  const [TrafficInsurenceDate,setTrafficInsurenceDate] = useState("");
  const [KaskoNO,setKaskoNO] = useState("");
  const [radioCode,setRadioCode] = useState("");
  const [KaskoDATE,setKaskoDATE] = useState("");
  const [MuayeneDATE,setMuayeneDATE] = useState(new Date());
  const [RUHSAT_NO,setRUHSAT_NO] = useState("");
  const [carOwner,setCarOwner] = useState(-1);
  const [color,setColor] = useState(-1);
  const [aracMarkaKodu,setAracMarkaKodu] = useState(0);
  const [aracTipKodu,setAracTipKodu] = useState(0);
  const [isMounted,setIsMounted] = useState(false);
  const [hpDisabled,setHpDisabled] = useState(0);
  const [kwDisabled,setKwDisabled] = useState(0);
  const [taahhutVar,setTaahhutVar] = useState(0);
  const [taahhütMonth,setTaahhütMonth] = useState(0);
  const [taahhütKm,setTaahhütKm] = useState(0);
  const [taahhütLogic,setTaahhütLogic] = useState(0);
  const [taahhütNotes,setTaahhütNotes] = useState("");

  const [rowID,setRowID] = useState(0);
  const taahhütMonthsData = [
    {label : " AY TAAHHÜTÜ YOK " , value : 0},
    {label : "4 Ay" , value : 4},
    {label : "6 Ay" , value : 6},
    {label : "9 Ay" , value : 9},
    {label : "10 Ay" , value : 10},
    {label : "12 Ay" , value : 12},
  ]

  const taahhütKmData = [
    {label : " KM TAAHHÜTÜ YOK " , value : 0},
    {label : "1.000 KM" , value : 1000},
    {label : "5.000 KM" , value : 5000},
    {label : "6.000 KM" , value : 6000},
    {label : "10.000 KM" , value : 10000},
    {label : "15.000 KM" , value : 15000},
    {label : "20.000 KM" , value : 20000}
  ]

  const taahhütLogicData = [
    {label :"VE" , value : "VE"},
    {label :"VEYA" , value : "VEYA"}
  ]

    const [selectedBrandModels,setSelectedBrandModels] = useState([]);
    const [selectedModelPacks,setSelectedModelPacks] = useState([]);

    const [errorLocation,setErrorLocation] = useState("bottomStart");
    const [showErrors,setShowErrors] = useState({
        "MARKA" : false,
        "MODEL" : false,
        "PAKET" : false,
        "PLAKA" : false,
        "KASA" : false,
        "YAKIT" : false,
        "VİTES" : false,
        "CC" : false,
        "MODEL_YILI" : false,
        "KM" : false,
        "HP" : false,
        "KW" : false,
        "ŞASİ" : false,
        "MOTOR" : false,
        "RENK" : false,
    })

    const [markaError,setMarkaError] = useState(false);
    const [modelError,setModelError] = useState(false);
    const [paketError,setPaketError] = useState(false);
    const [plakaError,setPlakaError] = useState(false);
    const [kasaError,setKasaError] = useState(false);
    const [yakıtError,setYakıtError] = useState(false);
    const [vitesError,setVitesError] = useState(false);
    const [ccError,setCCError] = useState(false);
    const [modelYılıError,setModelYılıError] = useState(false);
    const [hpError,setHpError] = useState(false);
    const [kwError,setKwError] = useState(false);
    const [sasiError,setSasiError] = useState(false);
    const [motorError,setMotorError] = useState(false);
    const [renkError,setRenkError] = useState(false);
    const [ruhsatError,setRuhsatError] = useState(false);
    const [ruhsatErrorText,setRuhsatErrorText] = useState("");
    const [plakaErrorText,setPlakaErrorText] = useState("");
    const [kmError,setKmError] = useState(false);
    const [kmErrorText,setKmErrorText] = useState("");
    const [ruhsatSahibiError,setRuhsatSahibiError] = useState(false);


    const [errors,SetErrors] = useState({
        "MARKA" : "Marka alanı boş bırakılamaz",
        "MODEL" : "Model alanı boş bırakılamaz",
        "PAKET" : "Paket alanı boş bırakılamaz",
        "MODEL_YILI" : "Model yılı alanı boş bırakılamaz",
        "VİTES": "Vites alanı boş bırakılamaz",
        "CC" : "CC alanı boş bırakılamaz",
        "KASA" : "Kasa alanı boş bırakılamaz",
        "YAKIT" : "Yakıt alanı boş bırakılamaz",
        "HP" : "HP alanı boş bırakılamaz",
        "KW" : "KW alanı boş bırakılamaz",
        "RENK" : "Renk alanı boş bırakılamaz",
        "ŞASİ" : "Şasi alanı boş bırakılamaz",
        "MOTORNO" : "Motor no alanı boş bırakılamaz",
        "RUHSAT SAHİBİ" : "Ruhsat sahibi alanı boş bırakılamaz",
    })

    const checkBMP = () => {
        if(BMP[0] == "" || BMP[0] == undefined || BMP[0] == null || BMP_ID[0] == 0 || BMP_ID[0] == undefined || BMP_ID[0] == null){
          setMarkaError(true);
        }else{
          setMarkaError(false);
        }
        if(BMP[1] == "" || BMP[1] == undefined || BMP[1] == null || BMP_ID[1] == 0 || BMP_ID[1] == undefined || BMP_ID[1] == null){
          setModelError(true);
        }else{
          setModelError(false);
        }
        if(BMP[2] == "" || BMP[2] == undefined || BMP[2] == null || BMP_ID[2] == 0 || BMP_ID[2] == undefined || BMP_ID[2] == null) {
          setPaketError(true);
        } else{
          setPaketError(false);
        }
    }

    //#endregion

    //#region Functions
    function reset(){
        setBMP(["","",""])
        setBMP_ID([0,0,0])
        setCarTag(["","",""])
        setCartag_id("")
        setTescilTarihi(null);
        setIlkTescilTarihi(null);
        setPlaka("");
        setKM(0);
        setKM_valueStr("");
        setKasa(-1);
        setModelYılı (-1);
        setYakıtTipi(-1);
        setCC(-1);
        setVites (-1);
        setKm_period (-1);
        setCC_SECTION ("");
        setHP_value("");
        setKW_value("");
        setSasi ("");
        setMotorno ("");
        setAracMarkaRenk("");
        setHGSNO("");
        setTrafficInsurenceNO("");
        setTrafficInsurenceDate("");
        setKaskoNO ("");
        setKaskoDATE ("");
        setMuayeneDATE("");
        setRUHSAT_NO("");
        setCarOwner(0);
        setColor(0);
        setRadioCode("");
    }

    function editCarExcelImport(){
        setBMP([props.data["MARKA_NAME"],props.data["MODEL_NAME"],props.data["PAKET_NAME"]])
        setBMP_ID([props.data["MARKA"],props.data["MODEL"],props.data["PAKET"]])
        setSelectedBrandID(props.data["MARKA"]);
        setSelectedModelID(props.data["MODEL"]);
        setCarTag(["",""])
        setCartag_id([0,0])
        setTescilTarihi((props.data["TESCİL TARİHİ"] == null || props.data["TESCİL TARİHİ"] == "0000-00-00" ) ? null : new Date(props.data["TESCİL TARİHİ"]));
        setIlkTescilTarihi( (props.data["İLK TESCİL TARİHİ"] == null || props.data["TESCİL TARİHİ"] == "0000-00-00") ? null : new Date(props.data["İLK TESCİL TARİHİ"]));
        setPlaka(props.data["PLAKA"]);
        setKasa(props.data["KASA"]);
        setModelYılı (props.data["MODEL YILI"]);
        setYakıtTipi(props.data["YAKIT TİPİ"]);
        setCC(props.data["CC"]);
        setVites (props.data["VİTES"]);
        setCC_SECTION (props.data["CC SINIFI"]);
        setHP_value(props.data["HP"]);
        setKW_value(props.data["KW"]);
        setSasi (props.data["ŞASİ"]);
        setMotorno (props.data["MOTORNO"]);
        setAracMarkaRenk(props.data["MARKA RENK"]);
        setColor(props.data["RENK_ID"]);
        setRUHSAT_NO(props.data["TESCİL BELGE NO"]);
        setCarOwner(props.data["ARAÇ_SAHİBİ"]);
        setRadioCode(props.data["RADYO KODU"])
        setAraçDurum(props.data["ARAÇ_DURUM_ID"]);
        if(props.data["ID"] != null && props.data["ID"] != undefined && props.data["ID"] != "" && props.type == "UPDATE_ON_ROW"){
          console.log("ROW ID SETTED");
          console.log(props.data["ID"]);
          setRowID(props.data["ID"]);
        }

    }

    function editCarDatabaseTable()
    {
      console.log(props.data);
      setBMP([props.data["MARKA"],props.data["MODEL"],props.data["PAKET"]])
      setBMP_ID([props.data["MARKA_NO"],props.data["MODEL_NO"],props.data["PAKET_NO"]])
      setSelectedBrandID(props.data["MARKA_NO"]);
      setSelectedModelID(props.data["MODEL_NO"]);
      
      // // setCarTag(["",""])
      // // setCartag_id([0,0])
      setTescilTarihi((props.data["TESCİL TARİHİ"] == null || props.data["TESCİL TARİHİ"] == "0000-00-00" ) ? null : new Date(props.data["TESCİL TARİHİ"]));
      setIlkTescilTarihi( (props.data["İLK TESCİL TARİHİ"] == null || props.data["TESCİL TARİHİ"] == "0000-00-00") ? null : new Date(props.data["İLK TESCİL TARİHİ"]));
      setPlaka(props.data["PLAKA"] == null ? "" : props.data["PLAKA"]);
      setKasa(props.data["KASA_ID"] == null ? "" : props.data["KASA_ID"]);
      setModelYılı (props.data["MODEL YILI"] == null ? "" : props.data["MODEL YILI"]);
      setYakıtTipi(props.data["YAKIT_ID"] == null ? "" : props.data["YAKIT_ID"]);
      setCC(props.data["CC_ID"] == null ? "" : props.data["CC_ID"]);
      setVites (props.data["VİTES_ID"] == null ? "" : props.data["VİTES_ID"]);
      setCC_SECTION (props.data["CC SINIFI"] == null ? "" : props.data["CC SINIFI"]);
      setHP_value(props.data["HP"] == null ? "" : props.data["HP"]);
      setKW_value(props.data["KW"] == null ? "" : props.data["KW"]);
      setSasi (props.data["ŞASİ"] != null ? props.data["ŞASİ"] : "");
      setMotorno (props.data["MOTORNO"] != null ? props.data["MOTORNO"] : "");
      setAracMarkaRenk(props.data["MARKA RENK"] != null ? props.data["MARKA RENK"] : "");
      setColor(props.data["RENK_ID"] != null ? props.data["RENK_ID"] : 0);
      setRUHSAT_NO(props.data["TESCİL BELGE NO"] != null ? props.data["TESCİL BELGE NO"] : "");
      setCarOwner(props.data["ARAÇ_SAHİBİ_ID"] != null ? props.data["ARAÇ_SAHİBİ_ID"] : 0);
      setRadioCode(props.data["RADYO KODU"] != null ? props.data["RADYO KODU"] : "");
      console.log(props.data["ID"]);
      setAraçDurum(props.data["ARAÇ_DURUM_ID"] != null ? props.data["ARAÇ_DURUM_ID"] : 0);
      if(props.data["ID"] != null && props.data["ID"] != undefined && props.data["ID"] != "" && props.type == "UPDATE_ON_ROW"){
        console.log("ROW ID SETTED");
        console.log(props.data["ID"]);
        setRowID(props.data["ID"]);
      }
      // if(carTagTemp != null && carTagTemp != undefined && carTagTemp != "" && carTagTempID != null && carTagTempID != undefined && carTagTempID != ""){
      //   var splitArr = carTagTemp.split("-");
      //   var splitArrID = carTagTempID.split("-");
      //   if(splitArr.length == 1){
      //     setCarTag([splitArr[0]])
      //     setCartag_id("" + [parseInt(splitArrID[0])])
      //   }
      //   if(splitArr.length == 2){
      //     setCarTag([splitArr[0],splitArr[1]])
      //     setCartag_id(parseInt(splitArrID[0]) + "-" +parseInt(splitArrID[1]))
      //   }
       
      // }
    }

    const {fetchIdToken} = useUserToken();

    async function sendData(){

      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const body = {
        "ENV" :configdata.APP_ENV ,  
        "type": "UPDATE_CAR",
        "CAR_ID" : props.data["NO"],
        "data" :{ 
          "TESCİL_TARİHİ": tescilTarihi,
          "İLK_TESCİL_TARİHİ": ilkTescilTarihi,
          "MARKA": BMP_ID[0],
          "MODEL": BMP_ID[1],
          "PAKET": BMP_ID[2],
          "PLAKA": plaka,
          "KASA_TİPİ" : kasa,
          "BAKIM_KM_PERİYODU" : km_period,
          "VİTES_TİPİ" : vites,
          "YAKIT_TİPİ" : yakıtTipi,
          "CC" : CC,
          "MODEL_YILI" : modelYılı,
          "ARAC_KM" : KM,
          "CC_SINIF" : CC_SECTION,
          "HP" : HP_value,
          "KW" : KW_value,
          "ŞASİ_NO": sasi,
          "MOTOR_NO": motorno,
          "RENK": aracMarkaRenk,
          "RENK_ID": color,
          "HGS_ETİKET_NO": HGSNO,
          "TRAFIK_POLİÇE_NO": TrafficInsurenceNO,
          "TRAFIK_POLICE_BİTİŞ_TARİHİ": TrafficInsurenceDate,
          "KASKO_NO": KaskoNO,
          "KASKO_BİTİŞ_TARİHİ": KaskoDATE,
          "MUAYENE_BİTİŞ_TARİHİ": MuayeneDATE,
          "RUHSAT_SERİ_NO": RUHSAT_NO,
          "ARAÇ_SAHİBİ" : carOwner,
          "KULLANICI" : 0,
          "RADIO_CODE" : radioCode,
          "ARAÇ_DURUM" : araçDurum
        }
      }

        console.log(body);

        const headers = {
          'Authorization': `Bearer ${idToken}`,
        }

      axios.post(api,body,{headers}
      ).then((response) => {
        console.log(response);
        if(response.data != "SUCCESS"){
          messageToggle("Araç eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      }else{
        messageToggle("Araç Başarıyla Güncellendi", "success");
        if(props.type == "NEW"){
          reset();
        }  
        else {
          props.reset();
        }

      }
       
      })
      .catch((error) => {
        console.log(error);
        messageToggle("Araç eklenemedi , lütfen girilen bilgileri kontrol edin", "error")
      });
    }
    function rowUpdate(){
        const body = {
          "TESCİL TARİHİ": tescilTarihi,
          "İLK TESCİL TARİHİ": ilkTescilTarihi,
          "MARKA": BMP_ID[0],
          "MODEL": BMP_ID[1],
          "PAKET": BMP_ID[2],
          "PLAKA": plaka,
          "KASA" : kasa,
          "BAKIM KM PERİYODU" : km_period,
          "VİTES" : vites,
          "YAKIT TİPİ" : yakıtTipi,
          "CC" : CC,
          "MODEL YILI" : modelYılı,
          "ARAC_KM" : KM,
          "CC_SINIF" : CC_SECTION,
          "HP" : HP_value,
          "KW" : KW_value,
          "ŞASİ": sasi,
          "MOTORNO": motorno,
          "RENK": aracMarkaRenk,
          "RENK_ID": color,
          "HGS_ETİKET_NO": HGSNO,
          "TRAFIK_POLİÇE_NO": TrafficInsurenceNO,
          "TRAFIK_POLICE_BİTİŞ_TARİHİ": TrafficInsurenceDate,
          "KASKO_NO": KaskoNO,
          "KASKO_BİTİŞ_TARİHİ": KaskoDATE,
          "MUAYENE_BİTİŞ_TARİHİ": MuayeneDATE,
          "RUHSAT_SERİ_NO": RUHSAT_NO,
          "ARAÇ_SAHİBİ" : carOwner,
          // "KULLANICI" : cartag_id,
          "RADIO_CODE" : radioCode,
          "ARAÇ_DURUM" : araçDurum,
          "ID": rowID
        }

        // console.log(body);
        props.setRow(body);
    }

    function checkForms(){
      let res = true;
      if(markaError || modelError || paketError || modelYılıError || kasaError || yakıtError || 
        vitesError || ccError || hpError || kwError || renkError || sasiError || motorError || 
        ruhsatSahibiError || ruhsatError || kmError || plakaError){
        res = false;
      }
      if (res) {
        if(props.type == "UPDATE_ON_ROW"){
          rowUpdate();
        }else{
          sendData();
        }
      }else{
        console.error('Form Error');
        return;
      }
      
    }

    //#endregion

    //#region Use Effect Section
    const {getDropDownValuesService,getDropDownValues,getBrands,getDropDownValuesGlobal} = useDropdownData();
    useEffect(() => {
      getBrands();
      getDropDownValues("SERVICE_KM_PERIOD",setKm_periods);
      getDropDownValuesGlobal("CAR_OWNER",setCarOwners);
      getDropDownValuesGlobal("BUY_DEPARTMENT",setBuydeps);
      getDropDownValuesGlobal("CAR_BUY_TYPE",setCarBuyTypes);
      getDropDownValuesService("GET_LAST_50_YEARS",setfactory_years);
      getDropDownValues("CC_TYPES",setcc_types);
      getDropDownValues("FUEL_TYPES",setYakıtTipis);
      getDropDownValues("TRANSMISSION_TYPES",settransmissions);
      getDropDownValues("COLORS",setColors);
      getDropDownValues("CAR_BODY_STYLES",setCarBodyStyles);
      
      getParseAraçDurum();
      setIsMounted(true);
    },[])


    //#region Use Effect parsers

    useEffect(() => { checkBMP();
    } ,[BMP]);
    useEffect(() => { 
      console.log("BMP_ID CHANGED");
      let brand = optionsBMP.find((element) => element.id == BMP_ID[0]);
      if(brand != undefined && BMP_ID[0] != undefined && BMP_ID[1] != undefined && BMP_ID[2]){
        let model = brand.children.find((element) => element.id == BMP_ID[1]);
        let pack = model.children.find((element) => element.id == BMP_ID[2]);
        setBMP([brand.label,model.label,pack.label]);
      }
    } ,[BMP_ID]);

    useEffect(() => { setModelYılıError((modelYılı == -1 || modelYılı == undefined )? true : false); } ,[modelYılı]);
    useEffect(() => { setKasaError((kasa == -1 || kasa == undefined )? true : false); } ,[kasa]);
    useEffect(() => { setYakıtError((yakıtTipi == -1 || yakıtTipi == undefined)? true : false); } ,[yakıtTipi]);
    useEffect(() => { setVitesError((vites == -1 || vites == undefined )? true : false); } ,[vites]);
    useEffect(() => { setCCError((CC == -1 || CC == undefined )? true : false); } ,[CC]);
    useEffect(() => setHpError((HP_value == "" || HP_value == undefined )? true : false) ,[HP_value]);
    useEffect(() => setKwError((KW_value == "" || KW_value == undefined )? true : false) ,[KW_value]);
    useEffect(() => { setRenkError((color == 0 || color == undefined )? true : false); } ,[color]);
    useEffect(() => { setSasiError((sasi == "" || sasi == undefined )? true : false); } ,[sasi]);
    useEffect(() => { setMotorError((motorno == "" || motorno == undefined )? true : false); } ,[motorno]);
    useEffect(() => {
      console.log("CAR OWNER CHANGED");
      setRuhsatSahibiError((carOwner == 0 || carOwner == undefined)? true : false);
    },[carOwner])
  
    useEffect(() => { 
      fixPlaka(); 
    },[plaka])
    useEffect(() => {
      if(RUHSAT_NO != null || RUHSAT_NO != undefined) 
      {
        setRUHSAT_NO(RUHSAT_NO.toUpperCase()) 
      }

      let ruhstatValid  = isValidRuhsat(RUHSAT_NO);
      if(ruhstatValid.valid){
        setRuhsatError(false);
      }else{
        setRuhsatError(true);
        setRuhsatErrorText(ruhstatValid.error);
      }
    },[RUHSAT_NO])

    useEffect(() => {
      let kmValid = isValidKM(KM);
      if(kmValid.valid){
        setKmError(false);
      }else{
        setKmError(true);
        setKmErrorText(kmValid.error);
      }
    },[KM])

    useEffect(() => {if(HP_value != null || HP_value != undefined)setKwDisabled(String(HP_value / 1.3596216173).substring(0,5))},[HP_value])
    useEffect(() => {if(KW_value != null || KW_value != undefined)setHpDisabled(String(KW_value * 1.3596216173).substring(0,5))},[KW_value])
    useEffect(() => {if(sasi != null || sasi != undefined)setSasi(sasi.toUpperCase())},[sasi])
    useEffect(() => {if(radioCode != null || radioCode != undefined) setRadioCode(radioCode.toUpperCase())},[radioCode])
    useEffect(() => {if(motorno != null || motorno != undefined) setMotorno(motorno.toUpperCase())},[motorno])
    useEffect(() => {
      if(props.type == "UPDATE" && isMounted ) {
        editCarDatabaseTable();
      }
      if(props.type == "UPDATE_ON_ROW" && isMounted) {
        editCarExcelImport();

      }
    },[isMounted])

    //#endregion
    
    //#region Parse Functions

    function getParseAraçDurum(){
      const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
      axios
      .post(api,{
        "ENV" :configdata.APP_ENV ,  
          "category": "ARAÇ_DURUM",
          "hasParent": "NO",
          "type" : "GET",
          
      })
      .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        response.data.forEach( element =>
          { 
            if(element.PARENT == -1){
              result.push({"label" : element.İSİM , "value" : element.ID, children : []})
            }
          }
         );

         response.data.forEach( element =>
          {
            if(element.PARENT != -1){
              result.find( parent => parent.value == element.PARENT).children.push({"label" : element.İSİM , "value" : element.ID})
            }
          });
        setAraçDurumlar(result);
        // console.log(result);
      })
      .catch((error) => {
        console.log(error);
        
      });
    }

    //#endregion
    //#endregion

      
    //#endregion
 
    //#region RULES
      function isValidRuhsat(ruhsat) {
        const isNumeric = (char) => !isNaN(parseInt(char));
        const letterCount = Array.from(ruhsat).filter(char => !isNumeric(char) && char !== " ").length;
        const spaceCount = Array.from(ruhsat).filter(char => char === " ").length;
      
        if ((isNumeric(ruhsat.charAt(0)) || isNumeric(ruhsat.charAt(1)))) {
          return { valid: false, error: "ilk 2 hane harf olmalıdır" };
        }
        if (letterCount > 2) {
          return { valid: false, error: "Harf Sayısı 2 olmalıdır" };
        }
        if (ruhsat.length !== 8) {
          return { valid: false, error: "girilen seri no uzunluğu 8 olmalıdır" };
        }
        if (spaceCount > 0) {
          return { valid: false, error: "Boşluk Bırakılamaz" };
        }
      
        return { valid: true, error: null };
      }

      function isValidPlaka(plaka) {
        const isNumeric = (char) => !isNaN(parseInt(char));
        const letterCount = Array.from(plaka).filter(char => !isNumeric(char) && char !== " ").length;
        const arr = plaka.split(" ");
      
        if (!plaka) {
          return { valid: false, error: "plaka değeri boş olamaz" };
        }
        if (!isNumeric(plaka.charAt(0)) || !isNumeric(plaka.charAt(1))) {
          return { valid: false, error: "ilk 2 hane sayı olmalıdır" };
        }
        if (isNumeric(plaka.charAt(2))) {
          return { valid: false, error: "üçüncü hane sayı olamaz" };
        }
        if (letterCount > 3) {
          return { valid: false, error: "Harf Sayısı 3 ü geçemez" };
        }
        if (plaka.length > 2 && isNumeric(plaka.charAt(3))) {
          return { valid: false, error: "İl kodundan sonra harf gelmelidir" };
        }
        if (letterCount === 1) {
          if (arr.length === 3 && arr[2].length !== 4) {
            return { valid: false, error: "Tek harfli plakalarda plaka kodu 4 hane olmalıdır" };
          }
        }
        if (letterCount === 2) {
          if (arr.length === 3 && (arr[2].length < 3 || arr[2].length > 4)) {
            return { valid: false, error: "İki harfli plakalarda plaka kodu 3 veya 4 hane olmalıdır" };
          }
        }
        if (letterCount === 3) {
          if (arr.length === 3 && (arr[2].length < 2 || arr[2].length > 3)) {
            return { valid: false, error: "Üç harfli plakalarda plaka kodu 2 veya 3 hane olmalıdır" };
          }
        }
        if (plaka.length < 7) {
          return { valid: false, error: "girilen plaka uzunluğu en az 7 olmalıdır" };
        }
      
        return { valid: true, error: null };
      }
      function fixPlaka(){
        var plakatemp = plaka;
        var letter_count = 0;
        for(var i = 0 ; i < plakatemp.length ; i++){
            if(!is_numeric(plakatemp.charAt(i)) && plakatemp.charAt(i) != " "){
              letter_count ++
            }
        }
        plakatemp = plakatemp.toUpperCase();
        
        if(plakatemp.length > 2 && plakatemp.charAt(2) != " "){
          plakatemp = setCharAt(plakatemp,1,plakatemp.charAt(1) + " ")
        }
        if(letter_count == 1){
          if(plakatemp.length > 4 && plakatemp.charAt(4) != " " && !is_numeric(plakatemp.charAt(3)) ){
         
            plakatemp = setCharAt(plakatemp,3,plakatemp.charAt(3) + " ")
          }
        }
        else if(letter_count == 2){
          if(plakatemp.length > 5 && plakatemp.charAt(5) != " " && !is_numeric(plakatemp.charAt(4)) ){
         
            plakatemp = setCharAt(plakatemp,4,plakatemp.charAt(4) + " ")
          }
        }
        else{
          if(plakatemp.length > 6 && plakatemp.charAt(6) != " " ){
              plakatemp = setCharAt(plakatemp,5,plakatemp.charAt(5) + " ")
          }
        }
        let result = isValidPlaka(plakatemp);
        if(!result.valid){
          setPlakaError(true);
          setPlakaErrorText(result.error);
        }else{
          setPlakaError(false);
        }
        setPlaka(plakatemp);
      }


      function isValidKM(inputKM) {
        const kmParsed = inputKM.replace(".", "").replace(" KM", "");
      
        if (inputKM === "") {
          return { valid: false, error: "KM Alanı Boş bırakılamaz" };
        } else {
          if (kmParsed.length >= 6) {
            console.log("KM " + kmParsed.length + " hane girdi");
            if (kmParsed.length > 6) {
              return { valid: false, error: "Km 999.999 KM den fazla olamaz" };
            }
          }
        }
      
        return { valid: true, error: null };
      }
        
      

      function setCharAt(str,index,chr) {
        if(index > str.length-1) return str;
        return str.substring(0,index) + chr + str.substring(index+1);
      }
      function is_numeric(str){
        return /^\d+$/.test(str);
      }
      const numberMaskKM = createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol : '.',
        length: 8,
        suffix: ' KM'// This will put the dollar sign at the end, with a space.
      })
      const numberMaskCC = createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol : '.',
        suffix: ' CC' // This will put the dollar sign at the end, with a space.
      })
      
    
    //#endregion

    const {BrandTypes,ModelTypes,PaketTypes, setSelectedBrandID,setSelectedModelID} = useBrandsDropDown();
    

    return(
      <View style= {{ backgroundColor:'#F2FFFB', paddingTop: 50,justifyContent: 'flex-start', alignItems: 'center', marginBottom: 50}}>
            <View style= {{flexDirection: 'column',width: '100%',justifyContent: 'flex-start',alignItems :'center'}}>
              <View style={item_style.viewStyleSingleLine}>{/* Araç Durumu , Araç Sahibi , Satın Alma Tarihi */}
              <Form layout='inline'>
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel> Araç Durumu </Form.ControlLabel>
                    <TreePicker placement='autoVerticalStart' value={araçDurum} style={item_style.standard} onSelect={(value) => {
                      console.log(value);
                        setAraçDurum(value.value)}} data = {araçDurumlar} ></TreePicker>
                  </Form.Group>
                  {/* <Form.Group style={{width: 200}}>
                    <Form.ControlLabel> Alış Departmanı </Form.ControlLabel>
                    <SelectPicker placement='autoVerticalStart' value={buyDep} style={item_style.standard} onChange={setBuyDep} data = {buydeps} ></SelectPicker>
                  </Form.Group>
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel> Alış Tipi </Form.ControlLabel>
                    <SelectPicker placement='autoVerticalStart' value={carBuyType} style={item_style.standard} onChange={setCarBuyType} data = {carBuyTypes} ></SelectPicker>
                  </Form.Group> */}
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* Plaka , İlk Tesicl , Tescil */}
                <Form layout='inline'>
                <Form.Group style={{width: 200}}>
                  <Form.ControlLabel style={araçDurum == 1 ? item_style.standardText : item_style.header}> Plaka NO </Form.ControlLabel>
                  <Form.Control value={plaka} style={item_style.standard} onChange={ (value) => {setPlaka(value);}} name= {"PLAKA"} />
                  <Form.ErrorMessage show = {plakaError}>
                        {plakaErrorText}
                  </Form.ErrorMessage>
                  {/* <Form.Control value={plaka} style={item_style.standard} onChange={ (value) => {setPlaka(value);}} name= {"PLAKA"} rule={plakaRule}/>  */}
                </Form.Group>
                <Form.Group style={{width: 200}}>
                  <Form.ControlLabel>TESCİL TARİHİ </Form.ControlLabel>
                  <DatePicker
                  placement='autoVerticalStart'
                  style={item_style.standard} isoWeek = {true} onSelect={(value) =>
                        {setTescilTarihi(value);}} format="dd-MM-yyyy"
                        locale={locale} oneTap
                        value={tescilTarihi}
                        shouldDisableDate = {(date) => { return date >= new Date()}}
                    ></DatePicker>
                </Form.Group>
                <Form.Group style={{width: 200}}>
                  <Form.ControlLabel>İLK TESCİL TARİHİ </Form.ControlLabel>
                  <DatePicker
                  placement='autoVerticalStart'
                  style={item_style.standard} onSelect={(value) =>
                        {setIlkTescilTarihi(value);}} 
                        locale={locale} oneTap
                        value={ilkTescilTarihi}
                        format="dd-MM-yyyy"
                        isoWeek = {true}
                        shouldDisableDate = {(date) => { return date >= new Date()}}
                    ></DatePicker>
                </Form.Group>
                </Form>
              </View> 
              <View style={item_style.viewStyleSingleLine}>{/* Marka , Model , Paket */}
                <Form layout='inline'>
                    {/* MARKA */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Marka *</Form.ControlLabel>
                      <BrandTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} item_style = {item_style} BMP = {BMP}  BMP_ID = {BMP_ID} ></BrandTypes>
                      <Form.ErrorMessage show = {markaError}> {errors["MARKA"]} </Form.ErrorMessage>
                    </Form.Group >
                    {/* MODEL */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}>  Model *</Form.ControlLabel>
                      <ModelTypes  setBMP = {setBMP} setBMP_ID = {setBMP_ID} item_style = {item_style} BMP = {BMP}  BMP_ID = {BMP_ID} ></ModelTypes>
                      <Form.ErrorMessage show = {modelError}>   {errors["MODEL"]}   </Form.ErrorMessage>
                    {/* PAKET */}
                    </Form.Group>
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Paket *</Form.ControlLabel>
                      <PaketTypes setBMP = {setBMP} setBMP_ID = {setBMP_ID} item_style = {item_style} BMP = {BMP}  BMP_ID = {BMP_ID} ></PaketTypes>
                      <Form.ErrorMessage show = {paketError}> {errors["PAKET"]}</Form.ErrorMessage>
                    </Form.Group>
                    {/* Marko Kodu Kasko */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Marka Kodu </Form.ControlLabel>
                      <Form.Control value={aracMarkaKodu} style={item_style.standard} onChange={ (value) => {setAracMarkaKodu(value);}} name= {"marka_kodu"} />
                    </Form.Group>
                    {/* Tip Kodu Kasko */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Tip Kodu </Form.ControlLabel>
                      <Form.Control value={aracTipKodu} style={item_style.standard} onChange={ (value) => {setAracTipKodu(value);}} name= {"tip_kodu"} />
                    </Form.Group>

                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* Kasa Renk Model Yılı*/}
                <Form layout='inline'>
                  {/* KASA*/}
                  <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Kasa Tipi * </Form.ControlLabel>
                      <KasaDropDown value= {kasa} item_style= {item_style} set = {setKasa}  ></KasaDropDown>
                      <Form.ErrorMessage show = {kasaError}> {errors["KASA"]}  </Form.ErrorMessage>
                  </Form.Group>
                  {/* RENK */}
                  <Form.Group style={{width: 200}}>
                      <Form.ControlLabel> Renk </Form.ControlLabel>
                      <RenkDropDown item_style= {item_style} setColor = {setColor} value = {color}></RenkDropDown>
                      <Form.ErrorMessage show = {renkError}>   {errors["RENK"]}  </Form.ErrorMessage>
                  </Form.Group>

                  {/* MARKA RENK */}
                  <Form.Group style={{width: 200}}>
                      <Form.ControlLabel> Marka Renk </Form.ControlLabel>
                      <Form.Control value={aracMarkaRenk} style={item_style.standard} onChange={ (value) => {setAracMarkaRenk(value);}} name= {"renk"} /> 
                  </Form.Group>
                  {/* Model Tarihi */}
                  <Form.Group style={{width: 200}} >
                    <Form.ControlLabel style={item_style.header}> Model Yılı *</Form.ControlLabel>
                    <Form.Control placement='autoVerticalStart' accepter={SelectPicker} name={"modelYear"} value={modelYılı} style={item_style.standard} menuMaxHeight={400} onSelect={(value) => { setModelYılı(value)}} data = {factory_years} ></Form.Control>
                    <Form.ErrorMessage show = {modelYılıError}> {errors["MODEL_YILI"]}  </Form.ErrorMessage>
                  </Form.Group>
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* Motorno Şase  */}
                <Form layout='inline'>
                    {/* ŞASİ */}
                    <Form.Group style={{width: 200}}>
                        <Form.ControlLabel style={araçDurum == 1 ? item_style.standardText : item_style.header}> Şasi NO  </Form.ControlLabel>
                        <Form.Control value={sasi} style={item_style.standard} onChange={ (value) => {setSasi(value);}} name= {"SASİ"}/> 
                        <Form.ErrorMessage show = {sasiError}>
                                {errors["ŞASİ"]}
                        </Form.ErrorMessage>
                    </Form.Group>
                      {/* Motor NO*/}
                  <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={araçDurum == 1 ? item_style.standardText : item_style.header}> Motor NO </Form.ControlLabel>
                      <Form.Control value = {motorno} style={item_style.standard} onChange={ (value) => {setMotorno(value);}} name= {"motor"}  /> 
                      <Form.ErrorMessage show = {motorError}>
                            {errors["MOTORNO"]}
                      </Form.ErrorMessage>
                    </Form.Group>
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* CC_Sınıf , CC , HP , KW  */}
                <Form layout='inline'>
                     {/* CC*/}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel style={item_style.header}> Motor(lt)  </Form.ControlLabel>
                      <Form.Control placement='autoVerticalStart' accepter={SelectPicker}  name={"cc"} value={CC} style={item_style.standard} onSelect={(value) => { setCC(value)}} data = {cc_types} ></Form.Control>
                        <Form.ErrorMessage show = {ccError}>
                            {errors["CC"]}
                        </Form.ErrorMessage>
                    </Form.Group>

                  {/* CC sınıf */}
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel style={item_style.header}> Motor Hacmi(cc) </Form.ControlLabel>
                    {/* <InputNumber value={CC_SECTION} style={item_style.standard} onChange={ (value) => {setCC_SECTION(value)}} name= {"cc_section"} required={true}/>  */}
                    <Form.Control
                    accepter= {MaskedInput}
                    name= "cc_sınıf"
                    guide= {false}
                    mask={numberMaskCC}
                    style={item_style.standard} 
                    value = {CC_SECTION}   onChange={(value) => {
                      setCC_SECTION(value.replace(" CC","").replace(".",""))
                    }} />
                  </Form.Group>


                  {/* HP  */}
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel style={item_style.header}> HP  </Form.ControlLabel>
                    <Form.Control style={item_style.standard} accepter={InputGroup} name={"Hp"}  > 
                      <MaskedInput guide= {false} mask={[/\d/,/\d/,/\d/]} value={HP_value}  onChange={ (value) => {setHP_value(value)}} name= {"hp_section"} />
                      <InputGroup.Addon> {"~ KW " + kwDisabled}</InputGroup.Addon>
                    </Form.Control>
                    <Form.ErrorMessage show = {hpError}>
                          {errors["HP"]}
                      </Form.ErrorMessage>
                  </Form.Group>


                  {/* KW  */}
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel style={item_style.header}> KW  </Form.ControlLabel>
                    <Form.Control style={item_style.standard} accepter={InputGroup} name={"KW"} > 
                      <MaskedInput guide= {false}  mask={[/\d/,/\d/,/\d/]} value={KW_value}  onChange={ (value) => {setKW_value(value)}} name= {"kw_section"} required={true}/> 
                      <InputGroup.Addon> {"~ HP " + hpDisabled}</InputGroup.Addon>
                    </Form.Control>
                    <Form.ErrorMessage show = {kwError}>
                          {errors["KW"]}
                    </Form.ErrorMessage>
                  </Form.Group>
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* KM , Yakıt , Vites */}
                <Form layout='inline'>
                    {/* KM */}
                  <Form.Group style={{width: 200}} >
                    <Form.ControlLabel style={item_style.header}> Araç KM</Form.ControlLabel>
                    <Form.Control style={item_style.standard} name = "KM"  accepter={MaskedInput} guide= {false} mask={numberMaskKM} value = {KM}
                        onChange={(value) => {  setKM(value.replace(" KM","").replace(".","")); }} ></Form.Control>
                    <Form.ErrorMessage show = {kmError}>  {kmErrorText} </Form.ErrorMessage>
                  </Form.Group>
                  {/* YAKIT*/} 
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel style={item_style.header}> Yakıt Tipi  </Form.ControlLabel>
                    <YakıtDropDown item_style={item_style} value= {yakıtTipi} set = {setYakıtTipi} ></YakıtDropDown>
                    <Form.ErrorMessage show = {yakıtError}> {errors["YAKIT"]} </Form.ErrorMessage>
                  </Form.Group>

                  {/* vites*/}
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel style={item_style.header}> Vites Tipi </Form.ControlLabel>
                    <VitesDropDown item_style={item_style} value = {vites} set = {setVites} ></VitesDropDown>
                    <Form.ErrorMessage show = {vitesError}>  {errors["VİTES"]}   </Form.ErrorMessage>
                  </Form.Group>
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* SeriNo / Ruhsat Sahibi */}
                <Form layout='inline'>
                          {/* ruhsat*/} 
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel> <Text style={araçDurum == 1 ? item_style.standardText : item_style.header}> Ruhsat Seri No </Text> </Form.ControlLabel>
                    <Form.Control value ={RUHSAT_NO} style={item_style.standard} onChange={ (value) => {setRUHSAT_NO(value);}} name= {"ruhsat"} /> 
                    <Form.ErrorMessage show = {ruhsatError}>
                        {ruhsatErrorText}
                    </Form.ErrorMessage>
                  </Form.Group> 
                  {/* owner*/}
                  <Form.Group style={{width: 200}}>
                    <Form.ControlLabel> <Text style= {araçDurum == 1 ? item_style.standardText : item_style.header}>  Ruhsat Sahibi </Text> </Form.ControlLabel>
                    <SelectPicker placement='autoVerticalStart' value={carOwner} style={item_style.standard} onSelect={(value) => { console.log(value); setCarOwner(value)}} data = {carOwners} ></SelectPicker>
                    <Form.ErrorMessage show = {ruhsatSahibiError}>
                          {errors["RUHSAT SAHİBİ"]}
                    </Form.ErrorMessage>
                  </Form.Group>
                </Form>
              </View>
              <View style={item_style.viewStyleSingleLine}>{/* KM Period / Radio Code / Muayene Tarihi */}
                <Form layout='inline'>
                    {/* KM Period*/}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel> Servis KM Periyodu </Form.ControlLabel>
                      <SelectPicker placement='autoVerticalStart' value={km_period} style={item_style.standard} onSelect={(value) => { setKm_period(value)}} data = {km_periods} ></SelectPicker>
                    </Form.Group>

                    {/* Radio Code */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel> Radyo Kodu </Form.ControlLabel>
                      <Form.Control  value={radioCode} style={item_style.standard} onChange={ (value) => {setRadioCode(value);}} name= {"radioCode"} /> 
                    </Form.Group>

                    {/* Muayene Tarihi */}
                    <Form.Group style={{width: 200}}>
                      <Form.ControlLabel> Muayene Tarihi </Form.ControlLabel>
                      <DatePicker
                      style={item_style.standard} placement='autoVerticalStart' onSelect={(value) =>
                            {setMuayeneDATE(value);}}
                            locale={locale} oneTap
                            value={MuayeneDATE}
                            format="dd-MM-yyyy"
                            isoWeek = {true}
                            shouldDisableDate = {(date) => { return date <= new Date()}}
                        ></DatePicker>
                    </Form.Group>
                </Form>
              </View>
              <View style={{flexDirection: 'row' , alignItems: 'center', justifyContent:'center'}}>
                <View style={{height: 50}}></View>
                <Button
                    disabled = {!activateAddButton}
                    style={styles.buttonStyle}
                    onClick={() => { checkForms();}}
                  > Güncelle </Button>
              </View>
             
            </View>          
      </View>
  )
  }


  export default EditCarScreen;
  

  const RenkDropDown = (props) => {

    const [colors, setColors] = useState([]);
    const {getDropDownValuesGlobal} = useDropdownData();

    useEffect(() => {
      getDropDownValuesGlobal("COLOR",setColors);
    },[])

    return (
      <View style={props.item_style.dropDownGroupBack} >
        <IconButton onClick={() => { getDropDownValuesGlobal("COLOR",setColors);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
        <View style= {{width: 2}}></View>
        <SelectPicker placement='autoVerticalStart' value={props.color} style={props.item_style.standard} onSelect={(value) => { props.setColor(value)}} data = {colors} ></SelectPicker> 
      </View>
    )
  } 

  const KasaDropDown = (props) => {
    const [carBodyStyles, setCarBodyStyles] = useState([]);
    const {getDropDownValues} = useDropdownData();

    useEffect(() => {
      getDropDownValues("CAR_BODY_STYLES",setCarBodyStyles);

    },[])
    return (
      <View style={props.item_style.dropDownGroupBack} >
        <IconButton onClick={() => { getDropDownValuesGlobal("CAR_BODY_STYLE",setCarBodyStyles);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
        <View style= {{width: 2}}></View>
        <SelectPicker placement='autoVerticalStart' value={props.value} style={props.item_style.standard} onSelect={(value) => { props.set(value)}} data = {carBodyStyles} ></SelectPicker>
      </View>
    )
  }

  const VitesDropDown = (props) => {
    const [transmissions, setTransmissions] = useState([]);
    const {getDropDownValues} = useDropdownData();

    useEffect(() => {
      getDropDownValues("TRANSMISSIONS",setTransmissions);
    },[])
    return (
      <View style={props.item_style.dropDownGroupBack} >
        <IconButton onClick={() => { getDropDownValues("TRANSMISSIONS",setTransmissions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
        <View style= {{width: 2}}></View>
        <SelectPicker placement='autoVerticalStart' value={props.value} style={props.item_style.standard} onSelect={(value) => { props.set(value)}} data = {transmissions} ></SelectPicker>
      </View>
    )
  }
  
  const YakıtDropDown = (props) => {
    const [fuel_types, setYakıtTipis] = useState([]);
    const {getDropDownValues} = useDropdownData();

    useEffect(() => {
      getDropDownValues("FUEL_TYPES",setYakıtTipis);

    },[])
    return (
      <View style={props.item_style.dropDownGroupBack} >
        <IconButton onClick={() => { getDropDownValues("FUEL_TYPES",setYakıtTipis);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
        <View style= {{width: 2}}></View>
        <SelectPicker placement='autoVerticalStart' value={props.value} style={props.item_style.standard} onSelect={(value) => { props.set(value)}} data = {fuel_types} ></SelectPicker>
      </View>
    )
  }

  export const useBrandsDropDown = () => {
    const [BMPOptions, setBMPOptions] = useState([]);
    const [choosableModels, setChoosableModels] = useState([]);
    const [choosablePacks, setChoosablePacks] = useState([]);
    const [selectedBrandID, setSelectedBrandID] = useState(0);
    const [selectedModelID, setSelectedModelID] = useState(0);
    const { getBrands } = useDropdownData();
  
    useEffect(() => {
      getBrands(setBMPOptions);
    }, []);

    
  
    useEffect(() => {
      console.log("Selected Brand ID:", selectedBrandID);
      const filteredItems = BMPOptions.filter((item) => item.id === selectedBrandID);
      if (filteredItems.length > 0 && filteredItems[0].children) {
        setChoosableModels(filteredItems[0].children);
      }
    }, [BMPOptions, selectedBrandID]);

    useEffect(() => {
      const filteredItems = choosableModels.filter((item) => item.id === selectedModelID);
      console.log("Filtered Items:", filteredItems);
    
      if (filteredItems.length > 0 && filteredItems[0].children) {
        console.log("Setting choosablePacks:", filteredItems[0].children);
        setChoosablePacks(filteredItems[0].children);
      } else {
        console.log("Resetting choosablePacks to []");
        setChoosablePacks([]); // <-- Consider resetting if conditions aren't met
      }
    }, [choosableModels, selectedModelID]);
    
  

    const BrandTypes = useCallback (
      (props) => {
      return (
        <View style={props.item_style.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={props.item_style.standard} placeholder={"MARKA"}  value={props.BMP[0]} onClean={() => {props.setBMP_ID([0,0,0]); props.setBMP(["","",""])}}  data ={BMPOptions} 
              onSelect={(item,value) => { props.setBMP([value.label,"",""]); props.setBMP_ID([value.id,0,0]); setSelectedBrandID(value.id)}} 
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const ModelTypes = useCallback (
      (props) => {
      return (
        <View style={props.item_style.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={props.item_style.standard} placeholder={"MODEL"} value={props.BMP[1]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],0,0]); props.setBMP([props.BMP[0],"",""])}}
              data ={choosableModels} onSelect={(item,value) => { props.setBMP([props.BMP[0],value.label,""]); props.setBMP_ID([props.BMP_ID[0],value.id,0]); 
            setSelectedModelID(value.id)
            }}
          ></SelectPicker>
        </View>
      )
    },[choosableModels,BMPOptions])

    const PaketTypes = useCallback (
      (props) => {
      return (
        <View style={props.item_style.dropDownGroupBack} >
          <IconButton onClick={() => { getBrands(setBMPOptions);}} style={{height: 35,backgroundColor:'#F2FFFB'}} icon = {<Icon><FontAwesomeIcon icon = {solid("rotate-right")}></FontAwesomeIcon></Icon> }></IconButton>
          <View style= {{width: 2}}></View>
          <SelectPicker placement='autoVerticalStart' style={props.item_style.standard} placeholder={"PAKET"} value={props.BMP[2]} onClean={() => {props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],0]); props.setBMP([props.BMP[0],props.BMP[1],""])}}
              data ={choosablePacks} onSelect={(item,value) => { props.setBMP([props.BMP[0],props.BMP[1],value.label]); props.setBMP_ID([props.BMP_ID[0],props.BMP_ID[1],value.id]); }}
          ></SelectPicker>
        </View>
      )
    },[choosablePacks,choosableModels,BMPOptions])


    return {
      BrandTypes,
      ModelTypes,
      PaketTypes,
      setSelectedBrandID,
      setSelectedModelID,
    }
  }