import React, { useState ,useEffect, useRef} from 'react';
import { Button , Divider, Drawer, Input, InputGroup, List, Panel, SelectPicker, Stack  } from 'rsuite';
import { PDFDownloadLink , View, Text,Image , pdf, PDFViewer} from '@react-pdf/renderer';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import RentContractView from './RentContractView';
import { Font  } from '@react-pdf/renderer';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import axios from 'axios';
import configdata from '../../config/config.json';
import RentOfferText from './RentOfferText.json';
import { useUserToken } from '../../hooks/useUserToken';
import CustomText from '../../Components/CustomText';

// Register the custom font
Font.register({
    family: 'Artifakt', // give a name for your font
    src: require('../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT REGULAR.ttf'), // replace with your font file path
});

Font.register({
    family: 'Artifakt-Italic', // give a name for your font
    src: require('../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT MEDIUM ITALIC.ttf'), // replace with your font file path
});

Font.register({
    family: 'Artifakt-Bold', // give a name for your font
    src: require('../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT BOLD.ttf'), // replace with your font file path
});

Font.register({
    family: 'Artifakt-Black', // give a name for your font
    src: require('../../../assets/fonts/Artifakt/ARTIFAKT ELEMENT BLACK.ttf'), // replace with your font file path
});

Font.register({
    family: "NotoSans",
    src: require('../../../assets/fonts/NotoSansSymbols-VariableFont_wght.ttf'),
});



const RentContractTemplate = (props) => {
    const [cars,setCars] = useState([]);
    const [Customer,setCustomer] = useState({"Müşteri": ""});
    const [offer,setOffer] = useState({"time": "", "teklifNo": "" , "winterTileAvaliable": false});
    const [signatures,setSignatures] = useState([{"name": "", "surname": ""}]);

    useEffect(() => {
        if(props.cars != undefined && props.cars != null && props.cars != []){
            setCars(props.cars);
        } 
        if(props.Customer != undefined && props.Customer != null && props.Customer != {}){
            setCustomer(props.Customer);
        }
    },[]);

    const refView = useRef();

    const getProps = () => {
        refView.current.getProps();
    }

    const getCars = () => {
        if(refView.current == undefined)
            return [];
        return refView.current.getCars();
    }

    const getCustomer = () => {
        if(refView.current == undefined)
            return [];
        return refView.current.getCustomer();
    }

    const getOffer = () => {
        if(refView.current == undefined)
            return [];
        return refView.current.getOffer();
    }

    const getSignatures = () => {
        if(refView.current == undefined)
           return [];
        return refView.current.getSignatures();
    }

    const getSafetyPacks = () => {
        if(refView.current == undefined)
              return [];
        return refView.current.getSafetyPacks();
    }

    function DownloadButton() {
        const [loading, setLoading] = React.useState(false);
      
        const downloadPDF = async () => {
            const rentNo = await refView.current.sendData();  
            if (rentNo.status == "error" || rentNo.status == "warning") {
                alert(rentNo.message);
                return;
            }

            await generateDocumentWithRentNo(rentNo);
        };
        
        const generateDocumentWithRentNo = async (rentNo) => {
            setLoading(true);
            console.log("rentNo : " + rentNo);
            const doc =   <MyDocument currency= {currency} LANG={language} rentNo={rentNo} cars={getCars()} Customer={getCustomer()} offer={getOffer()} signatures={getSignatures()}  safetyPacks = {getSafetyPacks()} />;
            const asPDF = pdf([]); // pass an empty array
            asPDF.updateContainer(doc);
            const blob = await asPDF.toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${getCustomer().Müşteri} ${getDateFormatted()} Araç Kiralama Teklifi.pdf`;
            link.click();
            URL.revokeObjectURL(url);
            setLoading(false);
        };
        
      
        return (
          <Button onClick={downloadPDF}>
            {loading ? 'Teklif Dökümanı Oluşturuluyor...' : 'Teklif Dökümanını İndir'}
          </Button>
        );
      }

    const {fetchIdToken} = useUserToken()
      
    function MailPDFButton() {
        const [pdfBlob, setPdfBlob] = useState(null);
    
        const generatePDFBlob = async () => {
            const doc = (
                <MyDocument 
                    rentNo={"000000"} 
                    cars={getCars()} 
                    Customer={getCustomer()} 
                    offer={getOffer()} 
                    signatures={getSignatures()} 
                    safetyPacks={getSafetyPacks()} 
                />
            );
            const asPDF = pdf([]); // pass an empty array
            asPDF.updateContainer(doc);
            const blob = await asPDF.toBlob();
            setPdfBlob(blob);
        };

        function blobToBase64(blob) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = function() {
                    resolve(reader.result.split(',')[1]);
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        }
        const [mail, setMail] = useState('');
    
        const sendPDF = async () => {

       
            await generatePDFBlob();
            
            if (!pdfBlob) {
                alert('Please generate the PDF first.');
                return;
            }
    

            
            const base64Pdf = await blobToBase64(pdfBlob);
            
            const body = {
                "type": 'SEND_EMAIL',
                "data": base64Pdf,
                "mailto" : mail,
            };
            

            const api = configdata.API_BASE + '/cardata'
            const token = await fetchIdToken();
    
            const header = {
                'Authorization' : `Bearer ${token}`,
            }


            try {
                const response = await axios.post(api, body, {headers: header});
                console.log(response.data);
            } catch (error) {
                console.error("Error uploading the file.", error);
            }
        };
    
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                <Input placeholder="Mail Adresi" style={{width: 200}} onChange={setMail} />
                <div style={{width: 10}}></div>
                <Button onClick={sendPDF}>
                    Mail Gönder
                </Button>
            </div>
        );
    }
      
      const [showPdf, setShowPdf] = React.useState(false);

      function handleClosePdf() {
        setShowPdf(false);
      }

      const languages = [
        {
          "label": "Türkçe",
          "value": "tr",
        },
        {
          "label": "English",
          "value": "en",
        }
      ]

      const currencyList = [
        {
            "label": "TL",
            "value": "TL",
        },
        {
            "label": "USD",
            "value": "USD",
        },
        {
            "label": "EUR",
            "value": "EUR",

        }]

      const [language, setLanguage] = useState(languages[0].value);
      const [currency, setCurrency] = useState("TL");

      




    return (
        <View style={{flex: 1, backgroundColor: '#F2F2F2'}}>
            <Drawer size="md" placement="left" open={showPdf} onClose={handleClosePdf}>
                <Drawer.Header>
                    <Drawer.Title>PDF Dökümanı</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <PDFViewer style={{width: '100%', height: '100%'}}>
                        <MyDocument currency= {currency} LANG={language} rentNo={"000000"} cars={getCars()} Customer={getCustomer()} offer={getOffer()} signatures={getSignatures()}  safetyPacks = {getSafetyPacks()} />
                    </PDFViewer>
                </Drawer.Body>
            </Drawer>


            <Stack direction='column'>

                <View style={{height: 20}}></View>
                <RentContractView 
                    ref = {refView}
                    cars={props.cars}
                    Customer={props.customer}
                    offer={offer}
                    signedMembers={props.offerInfo.SIGNED_EMAIL}
                    safetyPacks={props.safetyPacks}
                    signatures={signatures}
                    setCars={setCars} 
                    setCustomer={setCustomer} 
                    setOffer={setOffer} 
                    setSignatures={setSignatures}  
                />
                    <div style={{  
                        padding: '20px 20px 20px 20px' ,
                        backgroundColor: 'white',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4,
                        width: 500
                    }}>
                        <CustomText > Dil ve Para Birimi </CustomText>
                        <div style={{width: 10}}></div>
                        <InputGroup style={{padding: 2 , width: 130}}>
                            <InputGroup.Addon>
                                <Icon><FontAwesomeIcon icon={solid("language")}></FontAwesomeIcon></Icon>
                            </InputGroup.Addon>
                            <SelectPicker placement='top' style={{width : 120}} data = {languages} onChange={setLanguage} > Dil Seçimi </SelectPicker>
                        </InputGroup>

                        <InputGroup style={{padding: 2, width: 130}}>
                            <InputGroup.Addon>
                                <Icon><FontAwesomeIcon icon={solid("money-bill")}></FontAwesomeIcon></Icon>
                            </InputGroup.Addon>
                            <SelectPicker placement='top' style={{width : 120}} data = {currencyList} onChange={setCurrency} > Para Birimi </SelectPicker>
                        </InputGroup>
                    </div>
                    <div style={{height: 20}}></div>
                    <div style={{  
                        padding: '20px 20px 20px 20px' ,
                        backgroundColor: 'white',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 4,
                    }}>
                        <Button onClick={() => setShowPdf(true)} appearance="primary" style={{marginRight: 10}}>Önizleme</Button>
                        {refView.current != undefined &&
                        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                            <DownloadButton />
                            <div style={{width: 10}}></div>
                            <MailPDFButton></MailPDFButton>
                        </div>

                        }
                    </div>

             
            </Stack>

        </View>
    );
}

export default RentContractTemplate;

// Create Document Component
const MyDocument = (props) => (
    <Document>
    <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: '#003D84', alignItems:'center', position: 'relative'}}>

        <Image 
            src={require('../../../assets/OfferWizard/firstPageBack.png')} 
            style={{position: 'absolute', top: 90, left: 0, width: 595, height: 648, zIndex: -1}} 
        />
        <View style={{width: 500,height: 90 ,flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', paddingHorizontal: 20, paddingTop: 10}}>
            <Text style={styles.labelWhiteAlignedRight}>{"NO: " + props.rentNo}</Text>
            <View style={{height: 3, backgroundColor: 'white', width: 500}}></View>
        </View>
        <View style={{flex: 1, padding : 2}}>
            <View style={{width: 500, alignItems: 'flex-start', paddingTop: 20}}>
                <Text style={{fontFamily: 'Artifakt-Black', fontSize: 42 , color: 'white' ,textAlign: 'left'}}>
                        {
                            props.LANG == "tr" ?  "UZUN DÖNEM \nKİRALAMA \nTEKLİF DOSYASI"
                            : "LONG TERM \nRENTAL OFFER \n FILE"
                        }
                </Text>
            </View>
        </View>
        <View style={{backgroundColor : 'white', height : 3 , marginBottom: 15, width : 500}}></View>
        <Footer></Footer>
    </Page>
    <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: '#ECECEC', alignItems:'center', position: 'relative'}}>
        <Image 
            src={require('../../../assets/OfferWizard/PageBackGray.png')} 
            style={{position: 'absolute', top: 60, left: 0, width: 595, height: 648, zIndex: -1}} 
        />
        <HeaderSection rentNo={props.rentNo} offer={props.offer}></HeaderSection>

        <View style={{flex: 1, padding: 50 , paddingBottom: 20}}>
            <Text style={{fontFamily: 'Artifakt', fontSize: 14 , color: '#003D84', textIndent: 15 ,textAlign: 'left'}}>
                {RentOfferText[props.LANG].COVER_HEADER}
            </Text>
            <Text style={{fontFamily: 'Artifakt-Bold', fontSize: 14 , color: '#003D84' ,textAlign: 'left', marginTop: 15}}>
                { props.Customer.Müşteri  + "  -  " + props.Customer.Şirket}
            </Text>
            <Text style={{fontFamily: 'Artifakt', fontSize: 12 ,letterSpacing: 0.2, textIndent: 15 , lineHeight: '250%', color: '#003D84' ,textAlign: 'justify',marginTop: 15}}>
            {
                RentOfferText[props.LANG].COVER
            }
            </Text>
            <View style={{flex: 1 , justifyContent: 'flex-end', alignItems: 'flex-end', paddingVertical: 40}}>
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                    {props.signatures.map((signature) => {
                        return (
                            <View style={{flexDirection: 'row', alignItems: 'center', padding: 2, height: 100}}>
                                <Image style={{height: 50}} source={icons["person"]} />
                                <View style={{width: 5}}></View>
                                <View style={{height: 70 , width: 0.5 , backgroundColor: '#003D84'}}></View>
                                <View style={{width: 5}}></View>
                                <SignatureLabel lang={props.LANG} signature={signature}/>
                                <View style={{width: 5}}></View>
                                <View style={{height: 100 , width: 7 , backgroundColor: '#003D84'}}></View>
                            </View>

                        );
                    })}
                </View>
            </View>
        </View>
        <View style={{backgroundColor : 'white', height : 3 , marginBottom: 15, width : 500}}></View>
        <Footer pageNumber={1}></Footer>
    </Page>
    <Page size="A4" style={{flexDirection: 'column', flex :1, padding: 2}} >
        <HeaderSection rentNo={props.rentNo} offer={props.offer} header={ RentOfferText[props.LANG].HEADER}></HeaderSection>
        <View style={{flex: 1, paddingHorizontal : 40 , paddingVertical: 5 , justifyContent :'flex-start'}}>
            <CarList lang={props.LANG} currency= {props.currency} cars={props.cars} Customer={props.Customer} />
        </View>
        <View style={{backgroundColor : '#F2F2F2', height : 3 , marginBottom: 15, width : 500}}></View>
        <Footer pageNumber={2}></Footer>
    </Page>
    <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: 'white', alignItems:'center', position: 'relative'}} >
        <HeaderSection rentNo={props.rentNo} header={props.LANG == "tr" ? "KİRALAMA ŞARTLARI" : "RENT DETAILS"}  offer={props.offer}></HeaderSection>
        <View style={{flex: 1, paddingHorizontal : 40 , paddingVertical: 5 , justifyContent :'flex-start'}}>
                <Text style={styles.blueBoldHeader}>
                    { props.LANG == "tr" ? "» Kiralama kapsamında sunduğumuz hizmetler : " : "» Services included in the rental :"} 
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "» Araçların tüm periyodik bakımlarının yetkili servislerde yaptırılması." : "» All periodic maintenance of the vehicles is carried out at authorized services."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "» İhtiyari mali mesuliyet sigortası, zorunlu trafik sigortası, hasar güvencesi (Kasko), MTV." : "» Optional financial liability insurance, compulsory traffic insurance, damage guarantee (Kasko), MTV."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "» Aracın 72 saatten fazla hareketsiz kalması durumunda ücretsiz ikame araç" : "» Free replacement vehicle in case the vehicle is stationary for more than 72 hours"}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "» Her 50.000 km’de lastik değişimi. " : "» Tire change every 50,000 km."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "» Yol yardım (kaza anında ücretsiz çekici) hizmeti." : "» Road assistance (free towing in case of accident) service."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ?  "» Araç Kasko Değerinin % 6'sının altında kalan hasarlar kasko kapsamına girmemektedir. Muafiyetsiz kasko paketi alınarak bu hasarlar da güvence altına alınabilir. Paket detayları, güvence paketleri bölümünde araç sınıfı bazında belirtilmiştir." : "» Damages below 6% of the vehicle's Kasko Value are not covered by Kasko. These damages can be covered by purchasing the Kasko package without exemption. Package details are specified by vehicle class in the safety packages section."}
                </Text>
             

                <View style={{height: 15}}></View>

                <Text style={styles.blueBoldHeader}>
                    {props.LANG == "tr" ?  "» Müşteri Yükümlülükleri: " : "» Customer Obligations:"}
                </Text>
                
                {props.LANG == "tr" ? 
                    (
                        <Text style={styles.blueStr10}>
                        » Yukarıdaki tabloda verilen fiyatlar <Text style={styles.inlineBold}> KDV hariçtir. </Text> Kira bedeli, kira süresince sabittir.
                        </Text>
                    ):
                    (
                        <Text style={styles.blueStr10}>
                        » The prices given in the table above are <Text style={styles.inlineBold}> excluding VAT. </Text> The rental fee is fixed during the rental period.
                        </Text>
                    )
                }
               
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "»  Kira faturaları aylık tanzim edilip, 5 gün içinde tahsil edilecektir." : "» Rental invoices will be issued monthly and collected within 5 days."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "»  Belirtilen süreden önce teslim edilmesi durumunda cayma bedeli uygulanacaktır." : "» In case of delivery before the specified period, a cancellation fee will be applied."}
                </Text>

                <View style={{height: 15}}></View>

                {/* <Text style={styles.blueBoldHeader}>
                    {props.LANG == "tr" ?  "» Araç Özellikleri :  " : "» Vehicle Features :"}
                </Text>
                {
                    props.LANG == "tr" ? (
                        <Text style={styles.blueStr10}>
                            {"»  Teklifimize " } <Text style={styles.inlineBold}>{props.offer.winterTileAvaliable ? "kış lastiği dahildir":"kış lastiği dahil değildir"}</Text>  
                        </Text>
                    ):
                    (
                        <Text style={styles.blueStr10}>
                            {"»  Winter tires are " } <Text style={styles.inlineBold}>{props.offer.winterTileAvaliable ? "included":"not included"}</Text>  
                        </Text>
                    )
                }                */}
                 {/* <View style={{height: 15}}></View> */}

                <Text style={styles.blueBoldHeader}>
                    {props.LANG == "tr" ? "» Teklif Geçerliliği :" : "» Offer Validity :"}
                </Text>
                {
                    props.LANG == "tr" ? ( <Text style={styles.blueStr10}>
                        {"»  Bu teklifin geçerlilik süresi " } <Text style={styles.inlineBold}>{props.offer.time}</Text>  {" gündür ve "} <Text style={styles.inlineBold}>{(parseInt(getDateFormatted( props.offer.time).split(".")[0]))+ "." + getDateFormatted(props.offer.time).split(".")[1] + "." + getDateFormatted(props.offer.time).split(".")[2]}</Text>   {" tarihinde sona erecektir."}
                    </Text>)
                    :
                    ( <Text style={styles.blueStr10}>
                        {"»  This offer is valid for " } <Text style={styles.inlineBold}>{props.offer.time}</Text>  {" days and will expire on "} <Text style={styles.inlineBold}>{(parseInt(getDateFormatted( props.offer.time).split(".")[0]))+ "." + getDateFormatted(props.offer.time).split(".")[1] + "." + getDateFormatted(props.offer.time).split(".")[2]}</Text>
                    </Text>)
                    
                }
               
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "»  Teslim süresine ve teslim yerine karşılıklı mutabakatla karar verilir. " : "»  Delivery time and delivery place are decided by mutual agreement."}
                </Text>
                <Text style={styles.blueStr10}>
                    {props.LANG == "tr" ? "»  Araçların teslim tarihine kadar ÖTV, genel ekonomik koşullar, mevzuat değişikliği vb. \n maliyeti etkileyen unsurlarda değişiklik olması durumunda kiralama fiyatı revize edilir." : "»  In case of change in the factors affecting the cost such as SCT, general economic conditions, legislation change, etc. until the delivery date of the vehicles, the rental price is revised."}
                </Text>

        </View>
        <View style={{backgroundColor : '#F2F2F2', height : 3 , marginBottom: 15, width : 595}}></View>
        <Footer pageNumber={3}></Footer>
    </Page>
    <Page size="A4" style={{flexDirection: 'column', flex :1, padding: 2}} >
        <HeaderSection rentNo={props.rentNo} header={props.LANG == "tr" ? "Güvence Paketleri Fiyat Listesi" : "Safety Packages Price List"}  offer={props.offer}></HeaderSection>
        <View style={{flex: 1, paddingHorizontal : 40 , paddingVertical: 5 , justifyContent :'flex-start'}}>
            <EkstraPackages LANG={props.LANG} currency= {props.currency} safetyPacks={props.safetyPacks} ></EkstraPackages>
        </View>
        <Footer pageNumber={4}></Footer>
    </Page>
    <Page size="A4" style={{flexDirection: 'column', flex: 1, backgroundColor: '#003D84', alignItems:'center', position: 'relative'}}>

        <Image 
            src={require('../../../assets/OfferWizard/firstPageBack.png')} 
            style={{position: 'absolute', top: 90, left: 0, width: 595, height: 648, zIndex: -1}} 
        />
        <View style={{flex: 1, padding : 2, justifyContent :'center', alignItems: 'center'}}>
                <Image source={require('../../../assets/CarStockImages/bcrLogoText.png')} style={{width: 300}}  />  
        </View>
        <View style={{backgroundColor : 'white', height : 3 , marginBottom: 15, width : 500}}></View>
        <Footer ></Footer>
    </Page>
    </Document>
);

function getDateFormatted(daysToAdd) {
    let date = new Date();
    if(daysToAdd != undefined)
        date = addDays(date, daysToAdd);
        
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if(day < 10)
        day = "0" + day;
    if(month < 10)
        month = "0" + month;


    return day + "." + month + "." + year;
}

function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}


const Footer = (props) => {
    return (
        <View>
            <View style={{width: 595, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', paddingHorizontal: 50, backgroundColor: 'white'}}>
                <View style={{height: 80, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={styles.labelWithBackground}>bilen.pro</Text>
                    <Text style={{color: '#003D84', fontFamily: 'Artifakt-Bold', fontSize: 10}}>444 80 33</Text>
                </View>
                <Image source={require('../../../assets/OfferWizard/logoBlueColored.png')} style={{width: 140}} resizeMode='contain' />
            </View>
            {/* Page Number*/}
            <Text style={{position: 'absolute', bottom: 10, right: 10, width: 15 ,textAlign: 'center', borderLeftWidth: 1 , paddingLeft: 3 ,color: '#003D84', fontFamily: 'Artifakt-Bold', fontSize: 10,}}>{(props.pageNumber)}</Text>
        </View>

    );
}

function checkIfAll1 (array) {
    for (let i = 0; i < array.length; i++) {
        if (array[i] != 1) {
            return false;
        }
    }
    return true;
}

const EkstraPackages = (props) => {

    const stylesExtraPackages ={ 
        headerStyle : {fontSize: 10,fontFamily: 'Artifakt-Bold', backgroundColor: '#003D84', color: 'white', padding: 4, textAlign: 'center', marginBottom: 5, justifyContent: 'center'},
        containerStyle : {borderWidth : 1 , height: 150 ,width: 540, borderStyle: 'dashed' , justifyContent : 'flex-start', alignItems: 'flex-start', padding: 4}
    }
    return (
        <View style={{alignItems: 'center'}}>
            <View style={{height: 650, justifyContent: 'flex-start', alignItems: 'center'}}>
                <Text style={styles.blueBoldHeader}> {props.LANG == "tr" ? "Güvence Paketleri" : "Safety Packages"} </Text>

                {
                    !checkIfAll1(props.safetyPacks.map((pack)=> pack.data["TAM"])) &&
                    <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                        <Text style={stylesExtraPackages.headerStyle}> {props.LANG == "tr" ? "Muafiyetsiz Kasko Paketi" : "Kasko Package Without Exemption"} </Text>
                        <View style={{ height: 35 , width: 500}}>
                            <Text style={[styles.blueStr,{textAlign: 'justify', lineHeight: '200%'}]}>
                            {props.LANG == "tr" ? " Bu paketin alınması halinde aracın herhangi bir kazası anında (sigorta şirketinin isteyeceği kaza evraklarının tam sunulması koşuluyla) %6 oranındaki kasko muafiyeti uygulanmayacaktır. " : " In case of purchasing this package, the Kasko exemption rate of 6% will not be applied in case of any accident of the vehicle (provided that the accident documents requested by the insurance company are fully submitted)."}

                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                        {props.safetyPacks.map((pack)=> {
                                    return (
                                        <PriceTagWithClass lang={props.LANG}  currency= {props.currency} class={pack.class} price={pack.data["TAM"]} monthly={true} />
                                    );
                                })}
                        </View>
                    </View>
                }
                <View style ={{height: 10}}></View>

                {
                    !checkIfAll1(props.safetyPacks.map((pack)=> pack.data["MINI"])) &&
                    <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                        <Text style={stylesExtraPackages.headerStyle}> {props.LANG == "tr" ? "Mini Onarım Paketi" : "Mini Repair Package"} </Text>
                        {/* <View style ={{height: 12}}></View> */}
                        <View style={{ height: 30 , width: 500}}>
                            <Text style={[styles.blueStr,{textAlign: 'justify', lineHeight: '200%'}]}>
                            {props.LANG == "tr" ? " Rapor düzenlenmesi veya tutanak tutulması mümkün olmayan tek taraﬂı hasar durumları için müşteriye 10.000 TL’ye kadar beyanla onarım hakkı sunan hasar onarım paketidir." : "It is a damage repair package that provides the customer with the right to repair up to 10,000 TL for unilateral damage situations where it is not possible to prepare a report or to keep a record."}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                                {props.safetyPacks.map((pack)=> {
                                    return (
                                        <PriceTagWithClass lang={props.LANG} currency= {props.currency} class={pack.class} price={pack.data["MINI"]} monthly={true} />
                                    );
                                })}
                        </View>
                    </View>
                }
                <View style ={{height: 10}}></View>
                {                
                    !checkIfAll1(props.safetyPacks.map((pack)=> pack.data["IMM"])) &&
                    <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                        <Text style={stylesExtraPackages.headerStyle}>{props.LANG == "tr" ? "İhtiyari Mali Mesuliyet Paketi ": "Optional Financial Liability Package"}</Text>
                        <View style={{ height: 30 , width: 500}}>
                            <Text style={[styles.blueStr,{textAlign: 'justify', lineHeight: '200%'}]}>
                            {props.LANG == "tr" ? "Traﬁk Sigortası kapsamında 200.000 Tl’ye kadar karşılanan 3. şahıslara ilişkin hasarların ekstra İMM paketiyle 1.000.000 TL’ye kadar çıkartılmasıdır." : "It is to increase the damages related to third parties covered up to 200,000 TL within the scope of Traffic Insurance to 1,000,000 TL with the extra IMI package."}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                                {props.safetyPacks.map((pack)=> {
                                    return (
                                        <PriceTagWithClass lang={props.LANG} currency= {props.currency} class={pack.class} price={pack.data["IMM"]} />
                                    );
                                })}
                        </View>

                    </View> 
                    }
                <View style ={{height: 10}}></View>

                {
                    !checkIfAll1(props.safetyPacks.map((pack)=> pack.data["TIRE"])) &&
               
                    <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                        <Text style={stylesExtraPackages.headerStyle}> {props.LANG == "tr" ? "Kış Lastiği" : "Winter Tire"} </Text>
                        <View style={{ height: 30 , width: 500}}>
                            <Text style={[styles.blueStr,{textAlign: 'justify', lineHeight: '200%'}]}>
                            {props.LANG == "tr" ? "Kiralama süresince kış lastiği temini için sunulan pakettir." : "It is the package offered for the provision of winter tires during the rental period."}
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                                {props.safetyPacks.map((pack)=> {
                                    return (
                                        <PriceTagWithClass lang={props.LANG} currency= {props.currency} class={pack.class} price={pack.data["TIRE"]} />
                                    );
                                })}
                        </View>
                    </View>
                 }
                {/* <View style ={{height: 10}}></View>
                <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                    <Text style={stylesExtraPackages.headerStyle}> Ek Sürücü </Text>
                    <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                            {props.safetyPacks.map((pack)=> {
                                return (
                                    <PriceTagWithClass class={pack.class} price={pack.data["DRIVER"]} />
                                );
                            })}
                    </View>
                </View>
                <View style ={{height: 10}}></View>
                <View style={[stylesExtraPackages.containerStyle,{height: 120}]}>
                    <Text style={stylesExtraPackages.headerStyle}> Kış Lastiği </Text>
                    <View style={{ flexDirection: 'row', marginVertical : 2 , height: 50, width: 500, justifyContent : 'space-around', alignItems: 'center'}}>
                            {props.safetyPacks.map((pack)=> {
                                return (
                                    <PriceTagWithClass class={pack.class} price={pack.data["TIRE"]} />
                                );
                            })}
                    </View>
                </View> */}
         </View>
        </View>


    );
}


function formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}


const PriceTagWithClass = (props) => {
    return (
        <View style={{justifyContent: 'center', alignItems: 'center'}}>
            {
                props.price != 1 &&
                    <View style={{justifyContent: 'center', alignItems: 'center',marginHorizontal: 10}}>
                        
                        <View style={{height: 20, width: 100, backgroundColor: '#F2F2F2', justifyContent: 'center', alignItems: 'center', padding: 2, paddingTop: 4}}>
                            <Text style={[styles.blueBold,{textAlign: 'center'}]} >{props.lang == "tr" ? props.class + " Sınıfı" : "Class " + props.class} </Text>
                        </View>
                        <View style={{height: 4}}></View>
                        <View style={{height: 20, width: 100, backgroundColor: '#F2F2F2', justifyContent: 'center', alignItems: 'center', padding: 2, paddingTop: 4}}>
                            {
                                props.price == 0 ?

                                    <Text style={[styles.blueBold,{textAlign: 'center'}]} > {"DAHİLDİR"} </Text>

                                    :
                                    props.price == 1 ?

                                    <Text style={[styles.blueBold,{textAlign: 'center'}]} > {"-"} </Text>
                                        :
                                    <Text style={[styles.blueBold,{textAlign: 'center'}]} > {formatNumber(props.price) + " " + props.currency + " +  KDV"} </Text>

                            }
                        </View>
                        {
                            props.monthly != undefined &&
                            <Text style={{fontFamily: 'Artifakt', fontSize: 8, textAlign: 'center', color: '#003D84'}}>{props.lang == "tr" ? "(Aylık)" : "(Monthly)"}</Text>
                        }
                    </View>
               }
        </View>
    );
}





const HeaderSection = (props) => {
    return (
        <View style={{alignItems: 'center', width: 595}}>
            <View style={{width: 500,height: 60 ,flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end', paddingHorizontal: 20, paddingTop: 10}}>
                <Text style={[styles.labelWhiteAlignedRight,{color: '#003D84'}]}>{"NO : " + props.rentNo}</Text>
            </View>
            <View style={{height: 4, borderWidth: 1, borderColor:'#003D84', width: 500}}></View>
            <View style={{justifyContent: 'space-between',flexDirection: 'row' , alignItems:'flex-end', paddingHorizontal: 5, width: 500}}>
                <Text style={[styles.blueBold,{fontSize: 20}]} > {props.header} </Text>
                <Text style={styles.date} > {getDateFormatted()} </Text>
            </View>
        </View>
    );
}

const CarList = (props) => {
    return(
        <View style={{flex: 1, paddingHorizontal: 5}}>
            <View style={{marginBottom: 5,paddingHorizontal: 20}}>
                <Text style = {styles.blueBoldHeader} >
                    { props.Customer.Müşteri  + "  -  " + props.Customer.Şirket}
                </Text>
                <Text style = {styles.blueBold} >
                    {props.lang == "tr" ? "Firmamızdan talep ettiğiniz araç kiralama konusundaki teklifimiz aşağıdaki değerlendirmenize sunulmuştur" : "Our offer regarding the vehicle rental you requested from our company is presented to your evaluation below."}
                </Text>
            </View>
            <View style={{height: 24}}></View>
            {
                props.cars.map((car) => {
                    return (
                        <View style={{borderTopWidth: 1, borderTopColor: '#003D84'}}>
                            <View style={{height: 1}}></View>
                            <CarLabel lang={props.lang} currency= {props.currency} car={car} />
                            <View style={{height: 1}}></View>
                        </View>
                    );
                })
            }
        </View>
        )
}

const SignatureLabel = (props) => {

    const signatureStyle = {
        name: {
            fontFamily: 'Artifakt-Bold',
            fontSize: 12,
            textAlign: 'left',
            color: '#003D84',
        },
        title: {
            fontFamily: 'Artifakt-Bold',
            fontSize: 10,
            textAlign: 'left',
            color: '#003D84',
        },
        standard: { 
            fontFamily: 'Artifakt',
            fontSize: 8,
            textAlign: 'left',
            color: '#003D84',
        },
        
    }

    function translateTitle(title) {
        if(props.lang == "en"){
            if(title == "Araç Kiralama Müdürü"){
                return "Car Rental Manager";
            }
            else if(title == "Araç Kiralama Yetkilisi"){
                return "Car Rental Specialist";
            }
            else if(title == "Genel Müdür"){
                return "General Manager";
            }
            else if(title == "Araç Kiralama Danışmanı"){
                return "Car Rental Consultant";
            }
            else{
                return title;
            }
        }
        else{
            return title;
        }
    }


    
    return (
        <View style = {{justifyContent: 'space-between', alignItems: 'flex-start'}}>  
            <Text style={signatureStyle.name}> {props.signature.name} </Text>

            <Text style={signatureStyle.title}> {translateTitle(props.signature.title)} </Text>

            <Text style={signatureStyle.standard}> {props.signature.mail} </Text>

            <Text style={signatureStyle.standard}> {props.signature.phone} </Text>

            <Text style={signatureStyle.standard}>{" H.Okan Merzeci Bulvarı No:449"} </Text>

            <Text style={signatureStyle.standard}>{" FIAT Plaza 33090 Toroslar / MERSİN"} </Text>

        </View>
    )
}

const CarLabel = (props) => {


    function getVitesYakıtTranslation(vitesYakıt) {
        console.log(props.lang);
        console.log(vitesYakıt);
        if(props.lang == "en"){
            if(vitesYakıt == "Benzin Otomatik"){
                return "PETROL AUTOMATIC";
            }
            else if(vitesYakıt == "Dizel Otomatik"){
                return "DIESEL AUTOMATIC";
            }
            else if(vitesYakıt == "Dizel Manuel"){
                return "DIESEL MANUAL";
            }
            else if(vitesYakıt == "Benzin Manuel"){
                return "PETROL MANUAL";
            }
            else if(vitesYakıt == "Elektrik Otomatik"){
                return "ELECTRIC AUTOMATIC";
            }
            else if(vitesYakıt == "Hibrit Otomatik"){
                return "HYBRID AUTOMATIC";
            }
            else{
                return vitesYakıt;
            }
        }
        else{
            return vitesYakıt;
        }
    }

    const carImages = {
        'FIAT_EGEA_SEDAN_EASY': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_EASY.PNG'),
        'FIAT_EGEA_SEDAN_URBAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_URBAN.PNG'),
        'FIAT_EGEA_SEDAN_LOUNGE': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_LOUNGE.PNG'),
        'ALFA ROMEO_TONALE': require('../../../assets/CarStockImages/ALFA ROMEO_TONALE.PNG'),
        'AUDI_A3': require('../../../assets/CarStockImages/AUDI_A3.PNG'),
        'AUDI_A4': require('../../../assets/CarStockImages/AUDI_A4.PNG'),
        'AUDI_A6': require('../../../assets/CarStockImages/AUDI_A6.PNG'),
        'CITROEN_C3_AIRCROSS': require('../../../assets/CarStockImages/CITROEN_C3_AIRCROSS.PNG'),
        'CITROEN_C3': require('../../../assets/CarStockImages/CITROEN_C3.PNG'),
        'DACIA_DUSTER': require('../../../assets/CarStockImages/DACIA_DUSTER.PNG'),
        'FIAT_DOBLO': require('../../../assets/CarStockImages/FIAT_DOBLO.PNG'),
        'FIAT_EGEA_CROSS_WAGON': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS_WAGON.PNG'),
        'FIAT_EGEA_CROSS': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS.PNG'),
        'FIAT_EGEA_SEDAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN.PNG'),
        'FIAT_EGEA_HATCHBACK': require('../../../assets/CarStockImages/FIAT_EGEA_HATCHBACK.PNG'),
        'FIAT_FIORINO': require('../../../assets/CarStockImages/FIAT_FIORINO.PNG'),
        'FORD_COURIER': require('../../../assets/CarStockImages/FORD_COURIER.PNG'),
        'FORD_RANGER': require('../../../assets/CarStockImages/FORD_RANGER.PNG'),
        'HYUNDAI_BAYON': require('../../../assets/CarStockImages/HYUNDAI_BAYON.PNG'),
        'HYUNDAI_i20': require('../../../assets/CarStockImages/HYUNDAI_i 20.PNG'),
        'JEEP_COMPASS': require('../../../assets/CarStockImages/JEEP_COMPASS.PNG'),
        'JEEP_RENEGADE': require('../../../assets/CarStockImages/JEEP_RENEGADE.PNG'),
        'JEEP_AVENGER': require('../../../assets/CarStockImages/JEEP_AVENGER.PNG'),
        'MERCEDES_VITO': require('../../../assets/CarStockImages/MERCEDES_VITO.PNG'),
        'MITSIBUSHI_L200': require('../../../assets/CarStockImages/MITSIBUSHI_L200.PNG'),
        'PEUGEOT_2008': require('../../../assets/CarStockImages/PEUGEOT_2008.PNG'),
        'PEUGEOT_208': require('../../../assets/CarStockImages/PEUGEOT_208.PNG'),
        'PEUGEOT_408': require('../../../assets/CarStockImages/PEUGEOT_408.PNG'),
        'PEUGEOT_3008': require('../../../assets/CarStockImages/PEUGEOT_3008.PNG'),
        'PEUGEOT_TRAVALLER': require('../../../assets/CarStockImages/PEUGEOT_TRAVALLER.PNG'),
        'RENAULT_CLIO': require('../../../assets/CarStockImages/RENAULT_CLIO.PNG'),
        'RENAULT_CAPTUR': require('../../../assets/CarStockImages/RENAULT_CAPTUR.PNG'),
        'RENAULT_TALIANT': require('../../../assets/CarStockImages/RENAULT_TALIANT.PNG'),
        'RENAULT_MEGANE': require('../../../assets/CarStockImages/RENAULT_MEGANE.PNG'),
        'RENAULT_ZOE': require('../../../assets/CarStockImages/RENAULT_ZOE.PNG'),
        'TOYOTA_COROLLA': require('../../../assets/CarStockImages/TOYOTA_COROLLA.PNG'),
        'TOYOTA_HILUX': require('../../../assets/CarStockImages/TOYOTA_HILUX.PNG'),
        'SKODA_OCTAVIA': require('../../../assets/CarStockImages/SKODA_OCTAVIA.PNG'),
        'SKODA_SUPERB': require('../../../assets/CarStockImages/SKODA_SUPERB.PNG'),
        'VOLKSWAGEN_POLO': require('../../../assets/CarStockImages/VOLKSWAGEN_POLO.PNG'),
        'VOLKSWAGEN_PASSAT': require('../../../assets/CarStockImages/VOLKSWAGEN_PASSAT.PNG'),
        'VOLKSWAGEN_CARAVELLE': require('../../../assets/CarStockImages/VOLKSWAGEN_CARAVELLE.PNG'),
        'OPEL_CORSA': require('../../../assets/CarStockImages/OPEL_CORSA.PNG'),

    };
    // style={{padding: 3}}
    return (

        <View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center',marginTop: 1}}>
                <View >
                    <Image style={{width: 12}} source={icons["car"]} />
                </View>
                <Text  style={styles.blueBold} > {props.car.OZET} </Text>
            </View>
        <View style={{flexDirection: 'row', height: 90, alignItems: 'center',padding: 2 }}>
        <Text style={{textOrientation: "upright" , fontFamily : 'Artifakt-Bold' , fontSize : 12 , borderWidth: 1, borderColor: '#003D84' , color: '#003D84'}} > {props.lang == "tr" ? props.car.class + " SINIFI" : "CLASS " + props.car.class} </Text> 
        <View style ={{width: 3 }}></View>
        <Image {...props.car} style={{width: 90, height: 36}} source={carImages[props.car.image]} />
        <View style ={{width: 3 }}></View>
        <View style ={{flex: 1 }}>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical : 2}}>
                <View>
                    <Image style={{height: 12}} source={icons["car"]} />
                </View>
                <Text style={styles.blueStr} > {props.car.KASA} </Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginVertical : 2}}>
                <View >
                    <Image style={{height: 12}} source={icons["fuel_trm"]} />
                </View>
                <Text  style={styles.blueStr} > {getVitesYakıtTranslation(props.car.VITES_YAKIT)} </Text>

            </View>
       
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' , marginVertical : 2}}>
                <View>
                    <Image style={{height: 12}} source={icons["calendar"]} />
                </View>
                <Text  style={styles.blueStr} > {props.car.MODEL_YILI} </Text>

            </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' , marginVertical : 2}}>
                <View>
                    <Image style={{height: 12}} source={icons["km"]} />
                </View>
                <Text  style={styles.blueStr} > {props.car.KM} </Text>
            </View>
        </View>
        <View style ={{flex: 1 }}>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue', marginVertical : 2}}>
                <Text style={styles.blueStr} >{props.lang == "tr" ? "Kiralama Süresi" : "Rental Time"} : </Text>
                <Text  style={styles.blueBold} >  {props.lang == "tr" ? props.car.RENT_TIME +  " AY" : props.car.RENT_TIME + " MONTHS"} </Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue', marginVertical : 2}}>
                <Text style={styles.blueStr} >{props.lang == "tr" ? "Toplam KM Limiti" : "Rental KM Limit"} : </Text>
                <Text style={styles.blueBold} >  {props.car.KM_LIMIT} </Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start', borderBottomWidth: 0.5 , borderBottomColor: 'powderblue', marginVertical : 2}}>
                <Text style={styles.blueStr} >{props.lang == "tr" ? "KM Aşım Bedeli" : "KM Excess Price"} : </Text>
                <Text style={styles.blueBold} >  {props.car.KM_FIYAT.toString().replace(" TL"," " + props.currency) + "/KM + KDV"} </Text>
            </View>
            <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue', marginVertical : 2}}>
                <Text style={styles.blueStr} >{props.lang == "tr" ? "Aylık Kira Bedeli" : "Monthly Rent Price"} : </Text>
                <Text style={styles.PRICE} >  {props.car.FIYAT.toString().replace(" TL"," " + props.currency) + " + KDV"} </Text>
            </View>
            {/* <View>
                <Text style={[styles.blueStr,{fontSize: 7}]} > {props.lang == "tr" ? "(Toplam KM Aşım fiyatı aşılan toplam KM ile çarpılarak hesaplanacaktır)" : "(The total KM excess price will be calculated by multiplying the total exceeded KM)"} </Text>
            </View> */}
        </View>
        <Button style={{width: 8, height: '100%'}} onClick={() => {props.removeCar(props.car)}} > x </Button>
        </View>
        </View>

    );
}


const icons = {
    "car" : require('../../../assets/OfferWizard/Icons/car.png'),
    "calendar" : require('../../../assets/OfferWizard/Icons/calendar.png'),
    "fuel_trm" : require('../../../assets/OfferWizard/Icons/fuel_trm.png'),
    "km" : require('../../../assets/OfferWizard/Icons/km.png'),
    "person" : require('../../../assets/OfferWizard/Icons/person.png'),
}


// Create styles
const styles = StyleSheet.create({
    label: {
        fontFamily: 'Artifakt',
        fontSize: 8,
        flex: 1,
        textAlign: 'center',
        padding: 5,
    },
    PRICE: {
        fontFamily: 'Artifakt-Bold',
        fontSize: 9,
        flex: 1,
        textAlign: 'left',
        color: 'red'
    },
    blueStr : {
        color: '#003D84',
        fontFamily: 'Artifakt',
        fontSize: 8,
        textAlign: 'left',
    },
    blueStr10 : {
        color: '#003D84',
        fontFamily: 'Artifakt',
        fontSize: 10,
        textAlign: 'left',
        marginVertical: 4 ,
        marginLeft: 15,
        lineHeight: 1.5
    },
    blueBold10 : {
        color: '#003D84',
        fontFamily: 'Artifakt-Bold',
        fontSize: 10,
        textAlign: 'center',
        marginVertical: 4 ,
    },
    blueBoldHeader : {
        color: '#003D84',
        fontFamily: 'Artifakt-Bold',
        fontSize: 12,
        textAlign: 'left',
    },
    blueBold: {
        fontFamily: 'Artifakt-Black',
        fontSize: 8,
        textAlign: 'left',
        color: '#003D84',

    },
    date:{
        fontFamily: 'Artifakt',
        fontSize: 12,
        textAlign: 'center',
        color: '#003D84',
        padding: 5,
    },
    labelBold: {
        fontFamily: 'Artifakt-Bold',
        fontSize: 8,
        flex: 1,
        textAlign: 'center',
        padding: 5,
    },

    rightHeader: {
        fontFamily: 'Artifakt-Bold',
        fontSize: 20,
        flex: 1,
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    labelWhite: {
        fontFamily: 'Artifakt',
        fontSize: 8,
        flex: 1,
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    labelWithBackground: {
        fontFamily: 'Artifakt-Bold',
        fontSize: 12,
        justifyContent: 'center',
        textAlign: 'center',
        padding: 4,
        color: 'white',
        backgroundColor: 'red',
    },
    labelWhiteAlignedRight: {
        fontFamily: 'Artifakt',
        fontSize: 12,
        color: 'white',
        textAlign: 'right',
    },
    standard: {
        width: 200,
    },
    paragraph: {
        flexDirection: 'column', alignItems:'flex-start', paddingHorizontal: 5, marginVertical: 3
    },
    header: {
        fontFamily: 'Artifakt',
        fontSize: 12,
        textAlign: 'left',
        padding: 5,
        color: '#003D84',
    },
    inlineBold: {
        fontFamily: 'Artifakt-Bold',
    },
    textStandard: {
        marginLeft: 10,
        marginVertical: 5,
        fontFamily: 'Artifakt',
        fontSize: 10,
        flex: 1,
        textAlign: 'left',
        padding: 5,
    },
});
