import { Text, View } from 'react-native'
import React, { useState, useEffect ,useRef} from 'react'
import axios from 'axios';
import { Input,Button,DatePicker,IconButton , List, Drawer, MaskedInput,Grid,Col,Panel, Row ,Stack, Divider} from 'rsuite';
import "rsuite/dist/rsuite.min.css";
import ComponentListView from '../../../Components/CarComponents/ComponentListView';
import FileViewer from '../../../Components/FileManagements/FileViewer';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import configdata from '../../../config/config.json';
import { useUserToken } from '../../../hooks/useUserToken';
import { faBuilding, faHashtag, faMoneyBill, faPhone , faGauge} from '@fortawesome/free-solid-svg-icons';
import { Icon } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid} from '@fortawesome/fontawesome-svg-core/import.macro'; 
import { createNumberMask } from 'text-mask-addons';
import CustomListItem from './CustomListItem';
import CarInspection from '../../../Components/CarComponents/CarInspection';
import ExportData from '../../../Components/TableManagement/ExportData';
import CustomText from '../../../Components/CustomText';
import ImageLoader from '../../../Components/ImageManagements/ImageLoader';
import {useMessage} from '../../../hooks/useMessage';
import Carousel from 'react-bootstrap/Carousel';
import ImageViewer from '../../../Components/ImageManagements/ImageViewer';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const CarDamageReportScreen = (props) => {

    const [modal, setModal] = useState(false);
    const [modalType,setModalType] = useState("New")
    const [data,setData] = useState([]);
    const [current,setCurrent] = useState(undefined);
    const [editData,setEditData] = useState({});

    
    function handleModalClose(){
        setModal(false);
    }

    const [modalCarInspection, setModalCarInspection] = useState(false);
    function handleModalCarInspectionClose(){
      setModalCarInspection(false);
    }
    const [modalFILEOpen,setModalFILEOpen] = useState(false);

    function handleCloseFILEModal(){
      setModalFILEOpen(false);
    }

    useEffect(()=> {
        getData();
    },[]);

    function editRow(){
        setModalType("Edit");
    }

    const {fetchIdToken} = useUserToken();


    async function getData(){
      const idToken = await fetchIdToken();
      const headers = {
        "Authorization" : `Bearer ${idToken}`
      }
      const api = configdata.API_BASE + '/cardata';

      const body = {
        "ENV" :configdata.APP_ENV ,  
        "type" : "GET_DAMAGES",
        "ID" : props.data["NO"],
      }
      axios.post(api,body,{headers}).then((response)=>{
          console.log(response.data);
          if(response.data.length > 0){
            setCurrent(response.data[0]);
          }
          setData(response.data);
      }
      ).catch((error)=> {
          console.log(error);
      })


    }

        


    function setCurrentActive(input){
      for(var i = 0; i< input.length ; i++){
        if(input[i]["IS_ACTIVE"] == 1){
          setCurrent(input[i]);
          // console.log(input[i]["S3_KEY"]);
          // fileViewRef.current.set(input[i]["S3_KEY"]);
          break;
        }
      }
    }

    function chooseANDEdit(input){
      setEditData(input);
      setModalType("EDIT");
      setModalCarInspection(true);
    }
    const[viewData,setViewData] = useState({});
      
    function chooseANDShow(input){
      setViewData(input);
      setModalFILEOpen(true);
    }

    function getS3Keys(){
      // if(JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data != null){
      //   return JSON.parse(props.data["S3 KEYS MAINTENANCE"]).data[0];
      // }
      return [];
    }
    const fileViewRef = useRef();
    const FileViewModal = () => {
      return(
        <View>
           <Drawer  backdrop={'static'} keyboard={false} open={modalFILEOpen} onClose={handleCloseFILEModal}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                        <Text> {"Hasar Bilgileri"} </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
  
                    <Drawer.Body>
                    <FileViewer inline={true} s3KEY = {viewData["S3_KEY"]}></FileViewer>
                    </Drawer.Body>
                    {/* <Modal.Footer>
                      <Button onClick={handleCloseFILEModal} appearance="subtle">
                        Cancel
                      </Button>
                    </Modal.Footer> */}
                  </Drawer>
        </View>
      )
    }
    
    const ref = useRef();

    const tableHeads= ["KM" ,"TUTAR" ,"SERVİS" ,"NOTLAR"];
    const listHeads = ["KM" ,"TUTAR","SERVİS" ,"NOTLAR"];


    const [headers,setHeaders] = useState([]);
    const [opponentHeaders,setOpponentHeaders] = useState([]);



    const [carInspectionData,setCarInspectionData] = useState(
        {
          "LEFT_FRONT_DOOR" : 0,
          "LEFT_BACK_DOOR" : 0,
          "RIGHT_FRONT_DOOR" : 0,
          "RIGHT_BACK_DOOR" : 0,
          "FRONT_LEFT_FENDER" : 0,
          "FRONT_RIGHT_FENDER" : 0,
          "BACK_LEFT_FENDER" : 0,
          "BACK_RIGHT_FENDER" : 0,
          "LEFT_BOTTOM_FENDER" : 0,
          "RIGHT_BOTTOM_FENDER" : 0,
          "HOOD" : 0,
          "ROOF" : 0,
          "TRUNK" : 0,
          "TOP_SUPPORT_LEFT" : 0,
          "TOP_SUPPORT_RIGHT" : 0,
        }
    );

    useEffect(() => {


      if(current != null && current != undefined)      {  
      console.log(carInspectionData);
      console.log(current);
      console.log(current["CAR_BODY_CONDITION"][0]);
      let temp = current["CAR_BODY_CONDITION"][0];
      delete temp["ID"];
      delete temp["CAR_ID"];
      delete temp["CAR_DAMAGE_ID"];
      console.log(temp);
      setCarInspectionData(temp);
      setHeaders(
        [
          {"header" : "KM" , "data" : current["KM"] , "icon" :faGauge },
          {"header" : "TUTAR" , "data" : current["TUTAR"] , "icon" :faMoneyBill },
          {"header" : "SERVİS" , "data" : current["SERVİS"] , "icon" :faBuilding },
          {"header" : "NOTLAR" , "data" : current["NOTLAR"] , "icon" :faHashtag },
      ]
      )
      setOpponentHeaders(
        [
          {"header" : "KARŞI TARAF AD SOYAD" , "data" : current["OPPONENTS"][0]["NAME"] , "icon" :faHashtag },
          {"header" : "TELEFON" , "data" : current["OPPONENTS"][0]["PHONE"] , "icon" :faPhone },
          {"header" : "ADRES" , "data" : current["OPPONENTS"][0]["ADDRESS"] , "icon" :faHashtag },
          {"header" : "TC" , "data" : current["OPPONENTS"][0]["TCNO"] , "icon" :faHashtag },
      ]
      )
    }
    },[current])


    function showDetailsOfChoosen(input){ 
      console.log(input);
      setCurrent(input);
    }
    const inspectRef = useRef();

    useEffect(() => {
      inspectRef.current.update();
    },[carInspectionData])


    

    return(
        <View style={{flex: 1 , backgroundColor :'#F2F2F2',alignItems: 'center', padding: 20}}>
                <Drawer size='full'  backdrop={true} keyboard={false} open={modalCarInspection} onClose={handleModalCarInspectionClose}>
                    <Drawer.Header>
                    <Drawer.Title> 
                      {<View>
                        <Text>{props.data["ARAÇ"] + " " + props.data["PLAKA"] } </Text>
                      </View>} 
                    </Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body>
                      {
                         modalType == "New" ? <NewANDEditData data = {props.data} type={"NEW"}></NewANDEditData> : <NewANDEditData data = {props.data} objData={editData} type={"EDIT"}></NewANDEditData>
                      }
                        
                    </Drawer.Body>
                </Drawer>

                <Grid style={{width: '100%'}} fluid>
                  <Row gutter = {20}>
                    <Col xs = {24} sm = {24} md={24} lg={12} xl ={12} style={{marginBottom: 20}}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , height: 800, minWidth: 200}} 
                            header  = {
                              <View style = {{flexDirection : 'column',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                <Stack wrap spacing={10} style = {{flexDirection : 'row',width: '100%', justifyContent: 'space-between' , alignItems: 'center'}}>
                                  <Stack.Item grow={1}>
                                    <IconButton icon={<Icon><FontAwesomeIcon icon={solid('plus')} /></Icon>}
                                      style={{width: '100%'}}
                                      onClick={() => {
                                      setModalType("New");
                                      setModalCarInspection(true);
                                      }}>
                                      Yeni Hasar Kaydı
                                    </IconButton>
                                  </Stack.Item>
                                  <ExportData data = {data} tableHead = {tableHeads} tableName={"Hasar Kayıtları" + props.data["PLAKA"] +"_"+ new Date()}></ExportData>
                                </Stack> 
                                <CustomText style={{fontWeight: 'bold'}}> {"Hasar Kayıtları" } </CustomText>
                          </View>
                          } >
                            <ComponentListView listHeads={listHeads} current={current} showDetails={showDetailsOfChoosen} chooseANDShow={chooseANDShow} 
                            setchoosen_data={chooseANDEdit}  tableName = {"Hasar_" + props.data["PLAKA"] +"_"+ new Date()} tableHead={tableHeads}  data={data}></ComponentListView>

                      </Panel>
                      </Col>
                      <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
                        <Panel shaded style={{backgroundColor: 'white', flex: 1 , minHeight: 800, minWidth: 200}} header={ <CustomText style={{fontWeight: 'bold'}}> {"Hasar Kayıt Bilgileri - " + props.data["PLAKA"] } </CustomText>}>
                        <Tabs
                            defaultActiveKey="ozet"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            fill
                          >
                             <Tab eventKey="ozet" title="Özet">
                              <Stack wrap spacing={10}>
                                <Stack.Item grow={1}>
                                  <List>
                                    {headers.map((item) =>
                                        {
                                            return(<List.Item key={item} index={item}>
                                            <CustomListItem item={item} ></CustomListItem>
                                            </List.Item>)
                                        }
                                    )}
                                </List>
                              </Stack.Item>
                              <Stack.Item grow={1}>
                                <List>
                                      {
                                          opponentHeaders.map((item) =>
                                              {
                                                  return(<List.Item key={item} index={item}>
                                                  <CustomListItem item={item} ></CustomListItem>
                                                  </List.Item>)
                                              }
                                          )
                                      }
                                </List>
                              </Stack.Item>
                              </Stack>           
                              <CarInspection height= {450} ref={inspectRef} shower carInspectionData={carInspectionData} setInspection={setCarInspectionData} ></CarInspection>
                            </Tab>
                            <Tab eventKey="hasarFoto" title="Fotoğraflar">
                              <Divider><CustomText>Hasar Fotoğrafları</CustomText></Divider>
                              <ImagesShowCase data = {current} imageKey= {"IMAGES_DAMAGE"}></ImagesShowCase>
                              <Divider><CustomText>Onarım Fotoğrafları</CustomText></Divider>
                              <ImagesShowCase data = {current} imageKey= {"IMAGES_REPAIR"}></ImagesShowCase>
                            </Tab>
                            <Tab eventKey="dosyalar" title="Dosyalar">
                              <FileShower data = {current} fileKey= {"TUTANAK"}></FileShower>
                              <FileShower data = {current} fileKey= {"DOCUMENTS_REPAIR"}></FileShower>
                            </Tab>
                            <Tab eventKey="onarımDocs" title="Kaporta Hasar Geçmişi">
                                  <CarBodyDamgeShowcase data = {current}></CarBodyDamgeShowcase>
                            </Tab>
                          </Tabs>
                        </Panel>
                      </Col>

                  </Row>
                </Grid>

            {/* <ShowCurrentPanel  data = {data.length == 0 ? defaultData: current} headers={labelHeads} ></ShowCurrentPanel> */}
            <View style={{flexDirection : 'row' , alignItems : 'center'}}>
                <FileViewModal></FileViewModal>
            </View>
        </View>
    )
}

const CarBodyDamgeShowcase = (props) => {
  const [carInspectionDatas,setCarInspectionDatas] = useState([])

  useEffect(() => {
    console.log(props.data);
    if(props.data && props.data["CAR_BODY_CONDITION"]) {
      setCarInspectionDatas(props.data["CAR_BODY_CONDITION"]);

    }
  }, [props.data]);

  return (

    <View>
      {
        carInspectionDatas.map((item, index) => (
          <CarInspection shower key={index} height= {450} carInspectionData={item} ></CarInspection>
        ))
      }
    </View>
  );
}


const ImagesShowCase = (props) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    console.log(props.data);
    if (props.data && props.data[props.imageKey]) {
      const newImages = props.data[props.imageKey].map(element => element["S3_KEY"]);
      console.log(newImages);
      setImages(newImages);
    }
  }, [props.data]);

  return (
    <Carousel data-bs-theme="dark">
      {images.map((item, index) => (
        <Carousel.Item key={index} >
          <ImageViewer 
            key={index} 
            PDFConvertable={false} 
            inline 
            s3KEY={item} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}

const FileShower = (props) => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    console.log(props.data);
    if (props.data && props.data[props.fileKey]) {
      const newFiles = props.data[props.fileKey].map(element => element["S3_KEY"]);
      console.log(newFiles);
      setFiles(newFiles);
    }
  }, [props.data]);

  return (
    <View>
    {
      files.map((item, index) => (
        <FileViewer inline={true} s3KEY={item} />
      ))
    }
    </View>

  );
}




const NewANDEditData = (props) => {

  const repairInvoiceRef = useRef();
  const tutanakRef = useRef();
  const imageUploaderRef = useRef();
  const repairPhotosRef = useRef();

  const [s3Key,setS3Key] = useState([]);

  const [carInspection,setCarInspection] = useState([]);
  const [carInspectionInitial,setCarInspectionInitial] = useState([]);
  const [KM,setKM] = useState("");
  const [TUTAR,setTUTAR] = useState("");
  const [SERVİS,setSERVİS] = useState("");

  const [oppenentName,setOppenentName] = useState("");
  const [oppenentPhone,setOppenentPhone] = useState("");
  const [oppenentAddress,setOppenentAddress] = useState("");
  const [oppenentTC,setOppenentTC] = useState("");

  const [damageDate,setDamageDate] = useState(null);
  const [repairDate,setRepairDate] = useState(null);
  
  const insRef = useRef();


  const TLMASK = createNumberMask({
    prefix: '',
    suffix: ' TL',
    thousandsSeparatorSymbol: '.',
    allowDecimal: true,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  })

  const KMMASK = createNumberMask({
    prefix: '',
    suffix: ' KM',
    thousandsSeparatorSymbol: '.',
    allowDecimal: false,
    decimalSymbol: ',',
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  })

  const tcMASK = createNumberMask({
    prefix: '',
    suffix: '',
    thousandsSeparatorSymbol: '',
    integerLimit: 11, // limit length of integer numbers
    allowDecimal: false,
  })


  const {fetchIdToken,fetchUserID} = useUserToken();
  const {messageToggle} = useMessage();


  async function sendData(){

      const api = configdata.API_BASE + '/cardata';
      const idToken = await fetchIdToken();
      const UserID = await fetchUserID();
      const headers = {
        'Authorization': `Bearer ${idToken}`
      }

      let kmSent = typeof(KM) != 'number' ? Number(KM.replace(" KM","").replace(".","").replace(",",".")) : KM;
      let tutarSent = typeof(TUTAR) != 'number' ? Number(TUTAR.replace(" TL","").replace(".","").replace(",",".")) : TUTAR;

      const body ={
          "ENV" :configdata.APP_ENV ,
          "CAR_ID" : props.data["ID"],
          "CREATE_USER_ID" : Number(UserID),
          "type" : "ADD_DAMAGE_REPORT",
          "KM" :  kmSent,
          "TUTAR" : tutarSent,
          "SERVICE" : SERVİS,
          "DAMAGE_DATE" : damageDate,
          "REPAIR_DATE" : repairDate,
          "CAR_BODY_DAMAGE" : carInspection,
          "NOTES" : "",
          "CAR_BODY_DAMAGE_CHANGED" : carInspectionInitial != carInspection ? 1 : 0,
          "OPPONENT_DATA" :
          {
            "NAME" : oppenentName,
            "PHONE" : oppenentPhone,
            "ADDRESS" : oppenentAddress,
            "TC" : oppenentTC,
          },
      }

      if(props.type == "EDIT"){
        body["type"] = "EDIT_DAMAGE_REPORT";
        body["ID"] = props.objData["ID"];
        body["OPPONENT_ID"] = props.objData["OPPONENTS"][0]["ID"];
      }

      console.log(body);

      axios.post(api,body,{headers}).then((response)=>{
          console.log(response.data);
          if(props.type == "NEW"){
            if(response.data.split('_')[0] == "SUCCESS"){
              messageToggle("Kayıt Başarılı","success");
                  let temp = response.data.split('_')[1];
                  imageUploaderRef.current.load(temp);
                  repairPhotosRef.current.load(temp);
                  repairInvoiceRef.current.load(temp);
                  tutanakRef.current.load(temp);
            }else{
              messageToggle("Kayıt Başarısız","error");
            }
          }else{
            if(response.data == "SUCCESS"){
              messageToggle("Kayıt Başarılı","success");
                  let temp = props.objData["ID"];
                  imageUploaderRef.current.load(temp);
                  repairPhotosRef.current.load(temp);
                  repairInvoiceRef.current.load(temp);
                  tutanakRef.current.load(temp);
            }else{
              messageToggle("Kayıt Başarısız","error");
            }
        }

      }).catch((error)=> {
          console.log(error);
          messageToggle("Kayıt Başarısız","error"); 
      })
      

      console.log(body);
  }

  const getEditData = () => {
    console.log("get edit data");
    console.log(props.objData);
    setKM(props.objData["KM"]);
    setTUTAR(props.objData["TUTAR"]);
    setSERVİS(props.objData["SERVİS"]);
    setDamageDate(new Date(props.objData["DAMAGE_DATE"]));
    setRepairDate(new Date(props.objData["REPAIR_DATE"]));
    setCarInspection(props.objData["CAR_BODY_CONDITION"][0]);
    setCarInspectionInitial(props.objData["CAR_BODY_CONDITION"][0]);
    setOppenentName(props.objData["OPPONENTS"][0]["NAME"]);
    setOppenentPhone(props.objData["OPPONENTS"][0]["PHONE"]);
    setOppenentAddress(props.objData["OPPONENTS"][0]["ADDRESS"]);
    setOppenentTC(props.objData["OPPONENTS"][0]["TCNO"]);
    setS3Key(props.objData["S3_KEY"]);
  }

  useEffect(() => {
    if(props.type == "EDIT"){
      console.log("edit update");
      insRef.current.update();
    }},[carInspection])

  useEffect(() => {
    if(props.type == "EDIT"){
      getEditData();
    }
  },[])

  return(
    <View>
       <Grid style={{width: '100%'}} >
          <Row gutter = {20}>
            <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
            
              <List bordered >
                  <List.Item>
                      <View>
                          <CustomText fontWeight = {800} > {"Hasar Bilgileri" } </CustomText>
                          <View>
                              <View style={{flexDirection: 'row',marginVertical: 10}}>
                                  <View style={{flex: 1,marginHorizontal: 10}}>
                                      <MaskedInput value={KM} mask={KMMASK} placeholder="Hasar KM"  onChange={(value) => {setKM(value)}} />
                                  </View>
                                  <View style={{flex: 1, marginHorizontal: 10}}>
                                      <Input value={SERVİS}  placeholder="Servis"  onChange={(value) => {setSERVİS(value)}} />
                                  </View>
                                  <View style={{flex: 1, marginHorizontal: 10}}>
                                      <DatePicker value={damageDate} oneTap placeholder="Hasar Tarihi" onChange={(value) => {setDamageDate(value)}} />
                                  </View>
                              </View>
                            </View>
                      </View>
                  </List.Item>
                  <List.Item>                                    
                      <View>
                          <CustomText fontWeight = {800} > {"Hasar Fotoğrafları" } </CustomText>
                          <ImageLoader ref = {imageUploaderRef} fileCategory = {"DAMAGE_PHOTO"} carID = {props.data["ID"]} setS3Value = {(value) => {setS3Key(value);}}></ImageLoader>
                      </View>
                  </List.Item>
                  <List.Item>                                    
                      <View>
                          <CustomText fontWeight = {800} > {"Tutanak" } </CustomText>
                          <FileLoader ref = {tutanakRef} fileCategory = {"TUTANAK"} carID = {props.data["ID"]} setS3Value = {(value) => {setS3Key(value);}}></FileLoader>
                      </View>
                  </List.Item>
                  <List.Item>                                    
                      <View>
                          <CustomText fontWeight = {800} > {"Karşı Taraf Bilgileri" } </CustomText>
                          <View>
                              <View style={{flexDirection: 'row',marginVertical: 10}}>
                                  <View style={{flex: 1, marginHorizontal: 10}}>
                                      <Input value={oppenentName} placeholder="Ad Soyad"  onChange={(value) => {setOppenentName(value)}} />
                                  </View>
                                  <View style={{flex: 1,marginHorizontal: 10}}>
                                      <Input value={oppenentPhone} placeholder="Telefon"  onChange={(value) => {setOppenentPhone(value)}} />
                                  </View>
                              </View>
                              <View style={{flexDirection: 'row',marginVertical: 10}}>
                                  <View style={{flex: 1, marginHorizontal: 10}}>
                                      <Input value={oppenentAddress} placeholder="Adres"  onChange={(value) => {setOppenentAddress(value)}} />
                                  </View>
                                  <View style={{flex: 1, marginHorizontal: 10}}>
                                      <MaskedInput value={Number(oppenentTC)} placeholder="T.C. No"  onChange={(value) => {setOppenentTC(value)}} mask={tcMASK} />
                                  </View>
                              </View>
                          </View>
                      </View>
                  </List.Item>
                  <List.Item>                                    
                      <View>
                        <View style={{flexDirection: 'row',marginVertical: 10}}>
                          <View style={{flex: 1, marginHorizontal: 10}}>
                                      <MaskedInput value={TUTAR} mask={TLMASK} placeholder="Onarım Tutarı" onChange={(value) => {setTUTAR(value)}} />
                            </View>
                            <View style={{flex: 1, marginHorizontal: 10}}>
                                      <DatePicker value={repairDate} oneTap  placeholder="Onarım Tarihi"  onChange={(value) => {setRepairDate(value)}} />
                            </View>
                          </View>
                          <CustomText fontWeight = {800} > {"Onarım Fotoğraflarını Yükle" } </CustomText>
                          <ImageLoader ref = {repairPhotosRef} fileCategory = {"REPAIR_PHOTO"} carID = {props.data["ID"]} setS3Value = {(value) => {setS3Key(value);}}></ImageLoader>
                      </View>
                  </List.Item>
                  <List.Item>                                    
                      <View>
                          <CustomText fontWeight = {800} > {"Onarım Faturası Yükle" } </CustomText>
                          <FileLoader ref = {repairInvoiceRef} fileCategory = {"REPAIR_INVOICE"} carID = {props.data["ID"]} setS3Value = {(value) => {setS3Key(value);}}></FileLoader>
                      </View>
                  </List.Item>
                  <List.Item>
                    <Button appearance="primary" onClick={() => {sendData()}} style={{width: '100%'}}>Kaydet</Button>
                  </List.Item>
              </List>
            </Col >
            <Col xs = {24} sm = {24} md={24} lg={12} xl ={12}>
              <CustomText fontWeight = {800} > {"Kaporta Hasar Durumu" } </CustomText>
              <View style={{padding: 20}}>
                {
                  props.type == "EDIT" ? <CarInspection editable ref={insRef}  carInspectionData={carInspection} setInspection = {setCarInspection} ></CarInspection> :
                    <CarInspection ref={insRef}  carInspectionData={carInspection} setInspection = {setCarInspection} ></CarInspection>
                }
              </View>
            </Col>
          </Row>
        </Grid>
    </View>
  )
}


export default CarDamageReportScreen;