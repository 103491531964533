import { Text, View, StyleSheet, TouchableOpacity,FlatList,Image} from 'react-native'
import React, {  useState ,useEffect ,useRef, forwardRef, useImperativeHandle} from 'react'
import {useToaster, Message, Button,Modal ,Form ,DatePicker,TreePicker ,SelectPicker ,InputNumber, List, Drawer,IconButton, Stack, Panel, Divider, Grid, Row, Col} from 'rsuite';
import FileLoader from '../../../Components/FileManagements/FileLoader';
import configdata from '../../../config/config.json'
import { useUserToken } from '../../../hooks/useUserToken';
import { faBrush, faCar , faCarSide, faGasPump , faGaugeHigh, faPersonBooth, faSquare, faWorm ,faCalendar, faHorse, faDiamond, faDollar, faBuilding } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import FileViewer from '../../../Components/FileManagements/FileViewer';
// import Image from 'react-bootstrap/Image';
import CustomText from '../../../Components/CustomText';


  const GeneralCarInfo = forwardRef((props,ref) => {
    const {fetchIdToken} = useUserToken();
  
    const [modalOpen,setModalOpen] = useState(false);
    const [s3Keys,setS3Keys] = useState([]);
    const [filesRuhsat,setFilesRuhsat] = useState([]);
    const [refresh,setRefresh] = useState(false);
    const [image,setImage] = useState(undefined);


    function handleCloseModal(){
      setModalOpen(false);
    }
  
    const toaster = useToaster();
    function messageToggle(input,type){
        toaster.push(
            <Message showIcon type={type}>
              {input}
            </Message>, { placement: 'topEnd' })
    }
  
    const FileViewModal = () => {
      return(
        <View>

        </View>
      )
    }
    
    const fViewRef = useRef();
    const fViewRef2 = useRef();
  
  
    async function getS3Keys(){
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        'Authorization': `Bearer ${idToken}`
      };

      const body = {
        "ENV" :configdata.APP_ENV ,
        "type" : "GET_S3_DATA",
        "CAR_ID" : props.data["NO"]
      }
      console.log(body);
      axios.post(api,body,{headers}).then((response) => {
        if(response != null){
          console.log(response);
          fViewRef.current.set(response);
          setS3Keys(parseS3(response));
        }
          // return parseS3(response);
      }).catch((error) => {
        console.log(error);
      });
    }
  
    async function getS3list(){
      const idToken = await fetchIdToken();
      const api = configdata.API_BASE + '/cardata';
      const headers = {
        'Authorization': `Bearer ${idToken}`
      };
      axios.post(api,{
        "ENV" :configdata.APP_ENV ,
        "type" : "GET_S3_DATA",
        "CAR_ID" : props.data["NO"]
      },{headers}).then((response) => {
        var filesR = []
        if(response != null){
          if(response.data != null || response.data != undefined){
            //  console.log(response.data[0]["S3 KEY LICENCES"]);
            var dataS3 = JSON.parse(response.data[0]["S3 KEY LICENCES"])["data"]
            // console.log(dataS3)
            if( dataS3 != null){
               dataS3.forEach(element => {
                filesR.push(element.S3_KEY)
               })
  
               setFilesRuhsat(filesR);
            // console.log(dataS3[0].S3_KEY)
            
            }
          }
        }
          // return parseS3(response);
      }).catch((error) => {
        console.log(error);
      });
    }
  
  
    function parseS3(response){
       var filesR = [];
      if(response.data != null  || response.data != undefined){
        //  console.log(response.data[0]["S3 KEY LICENCES"]);
        var dataS3 = JSON.parse(response.data[0]["S3 KEY LICENCES"])["data"]
        if( dataS3 != null){
          dataS3.sort(function(a, b) {
            return parseFloat(b.ID) - parseFloat(a.ID);
        });
        // console.log(dataS3[0].S3_KEY)
          return dataS3[0].S3_KEY;
        }
        return null;
      }
      return null;
    }
  


    useEffect(()=> {
      // console.log(s3Keys);
    },[s3Keys])
    
    function modalViewOpen(){
      if(s3Keys != null && s3Keys != undefined && s3Keys.length > 0) {
        setModalOpen(true); 
      }
      else{
        messageToggle("Ruhsat Dökümanı bulunamadı", "error")
      } 
      
    }

    useImperativeHandle(ref, () => ({
      refresh: () => {
        console.log("refreshed");
        getS3Keys();
        getS3list();
        getDataWithID();
      }
    }));



    
    async function getDataWithID(){
      const api = configdata.API_BASE + "/cardata";
      const token = await fetchIdToken();
      const headers = {
        'Authorization': `Bearer ${token}`,
      }
  
      const data = {
        "ENV" : configdata.APP_ENV,
        "type" : 'GET_CAR_W_ID',
        "ID": props.data["NO"]
      }
  
      axios.post(api,data,{headers}).then((response) => {
        console.log(response.data);
        let temp = {...response.data[0]};
        temp["ARAÇ"] = temp["DONANIM_EKSTRA"] != null ? temp["ARAÇ"] + " " + temp["DONANIM_EKSTRA"] : temp["ARAÇ"];
        getTargetImagesByChoosenCar(temp);
        setHeaderData(temp);
      }
      ).catch((error) => {
        console.log(error);
      }
    )
  
    }
  
    function reset(){
        props.reset();
        setRefresh(!refresh);
        // setS3Keys(getS3Keys());
        fViewRef.current.refresh();
    }

    useEffect(() => {
      getS3Keys();
      getS3list();
      getDataWithID();
    },[])
    
  
    // Initialize header data on mount
    function getTargetImagesByChoosenCar(dataInput){
      
      console.log(dataInput);
      let nameData = Object.keys(carImages);
      nameData.forEach((name) => {
          if(name.includes(dataInput["MARKA"]) && name.includes(dataInput["MODEL"])
          && name.includes(dataInput["KASA"].toUpperCase()) && name.includes(dataInput["PAKET"])){
              console.log("-----" + name);
              console.log(carImages[name]);
              console.log(carImages);
              setImage(carImages[name]);
          }
      });
    }


    const [headerData,setHeaderData] = useState({});

    useEffect(() => {
      console.log(image);
    },[image])

    // const [headers,setHeaders] = useState([])
  
    // const [headers2,setHeaders2] = useState([])
  
    const headers = [
      {"header" : "PLAKA" , "data" : headerData["PLAKA"] , "icon" :faSquare },
      // {"header" : "ARAÇ" , "data" :headerData["ARAÇ"] , "icon" : faCar},
      // {"header" : "RUHSAT SAHİBİ" , "data" : headerData["RUHSAT SAHİBİ"] , "icon" : faPersonBooth},
      {"header" : "RENK" , "data" : headerData["RENK"] , "icon" : faBrush},
      {"header" : "MODEL YILI" , "data" : headerData["MODEL YILI"] , "icon" : faCalendar},
      {"header" : "ŞASİ NO" , "data" : headerData["ŞASİ"] , "icon" : faGaugeHigh},
      {"header" : "MOTOR" , "data" : headerData["MOTORNO"] , "icon" : faHorse},

      // {"header" : "ARAÇ KM" , "data" : headerData["ARAÇ KM"] , "icon" : faGaugeHigh},
      // {"header" : "YAKIT TİPİ" , "data" : headerData["YAKIT TİPİ"] , "icon" :faGasPump},
      // {"header" : "HP" , "data" : headerData["HP"] , "icon" : faDiamond},
      // {"header" : "KASA" , "data" : headerData["KASA"] , "icon" : faCarSide},
      // {"header" : "VİTES TİPİ" , "data" : headerData["VİTES"] , "icon" : faWorm},
    ]

    const headers3 = [
      {"header" : "ARAÇ KM" , "data" : formatToKMText(headerData["ARAÇ KM"]) , "icon" : faGaugeHigh},
      {"header" : "YAKIT TİPİ" , "data" : headerData["YAKIT TİPİ"] , "icon" :faGasPump},
      // {"header" : "HP" , "data" : headerData["HP"] , "icon" : faDiamond},
      // {"header" : "KASA" , "data" : headerData["KASA"] , "icon" : faCarSide},
      {"header" : "VİTES TİPİ" , "data" : headerData["VİTES"] , "icon" : faWorm},
    ]
     const headers2 = [
      {"header" : "Kasko Bitiş" , "data" : headerData["KASKO BİTİŞ TARİHİ"] , "icon" : faCalendar},
      {"header" : "Kasko Şirketi" , "data" : headerData["KASKO ŞİRKETİ"] , "icon" : faBuilding},
      {"header" : "Trafik Bitiş" , "data" : headerData["TRAFİK BİTİŞ TARİHİ"] , "icon" : faCalendar},
      {"header" : "Trafik Şirketi" , "data" : headerData["TRAFİK ŞİRKETİ"] , "icon" : faBuilding},
    ]

    const headers4 = [
      {"header" : "MUAYENE TARİHİ" , "data" : headerData["MUAYENE TARİHİ"], "icon" : faCalendar},
    ]

    const headers5 = [
      {"header" : "KASKO DEĞERİ" , "data" : headerData["KASKO DEĞERİ"], "icon" : faDollar},
    ]


    function formatToKMText(kmInput) {
      if(kmInput != null){
        return kmInput.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + " KM";
      }
      else{
        return "0";
      }
    }

  
    const CustomListItem = ({item}) => {
      return(
        <View style = {{flexDirection: 'row', justifyContent:'flex-start',alignItems:'center'}}>
          <View style= {{marginHorizontal: 10}}>
           <Icon style={{color : '#707070' , width: 15, height : 15}}><FontAwesomeIcon icon = {item.icon}/></Icon>
          </View>
           <View style= {{marginHorizontal: 10}}>
              <CustomText style={{textAlign: 'center', color: '#707070', fontWeight:'bold'}}>{item.header}</CustomText>
           </View>
           <View style= {{marginHorizontal: 10, alignItems :'flex-end',flex: 1}}>
              <CustomText style ={{textAlign: 'center', fontWeight: 'bold'}} > {item.data} </CustomText>
          </View>
          {props.copy != undefined && 
          <IconButton style={{color : '#707070' , width: 10 , height: 10 }} onClick={()=> {copyToClipboard(item.data)}} icon={<Icon><FontAwesomeIcon icon={solid('copy')} /></Icon>}></IconButton>
          }
        </View> 
      )
    }
    const LabeledPanel = (props) => {

      return(
        <Panel shaded style={styles.PanelStyle}>
            {props.data.map((item) =>
            <View style={{flexDirection: 'row', justifyContent:'space-between',alignItems:'center', width: '100%', marginVertical: 10}}>
                <CustomText style={{textAlign: 'left', fontSize: 16,width : '35%',color: '#5A6273'}}>{item.header} </CustomText>
                <View style={{marginHorizontal: 10}}></View>
                <CustomText fontWeight={800} style={{textAlign: 'left', fontSize: 16,width : '100%' , color :'#5A6273'}}>{item.data} </CustomText>
            </View>
            )}
        </Panel>
      )
    }

    function formatToCurrency(amount) {
      return amount.toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&.');
    }
  
    return(
      
      
      <View style={{flex: 1 , backgroundColor :'#F2F2F2', padding: 20, width: '100%'}}>

        <Grid fluid style={{flex: 1, width : '100%'}} >
          <Row gutter={20} >
            <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={8} >
              <Panel shaded style={styles.PanelStyle}>
                <View style={{flex: 1 , alignItems: 'center', justifyContent :'center', height: '100%'}}> 
                  <Image style={{height : 120, width: 120}} source={require('../../../../assets/BrandLogos/LOGO_'+ props.data["MARKA"] + '.png')} resizeMode='contain' ></Image>
                      {/* <CustomText fontWeight= {800} style={{textAlign: 'center', fontSize: 32, marginLeft: 10}}>{props.data["MARKA"]}</CustomText> */}
                    <View style={{height: 30}}></View>
                  <CustomText fontWeight={800} style={{color: '#5A6273' ,textAlign: 'center', fontSize: 16}}>{props.data["MARKA"] + " " + props.data["MODEL"] + " " + props.data["KASA"] + " " + props.data["PAKET"]}</CustomText>
                </View>
              </Panel>
            </Col>
            <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={8}>
              <LabeledPanel data={headers}></LabeledPanel> 
            </Col>
            <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={8}> 
              <LabeledPanel data={headers3}></LabeledPanel> 
            </Col>
          </Row>
          <Row gutter={20}>
            <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={8}> 
              <LabeledPanel data={headers2}></LabeledPanel> 
            </Col>
            {/* <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={8}> 
              <LabeledPanel data={headers4}></LabeledPanel> 
            </Col> */}
            <Col style={{marginBottom: 20}} xs = {24} sm = {24} md={24} lg={16}> 
              <Panel shaded style={styles.PanelStyle}>
                    <View style={{flexDirection: 'row' , flex : 1 , paddingHorizontal: 15, justifyContent :'space-between', backgroundColor: '#F2F2F2', height: 40, alignItems :'center'}}>
                        <CustomText style={{fontSize: 18, textAlign :'center',flex: 1 ,color: 'black'}}> {" Güncel Kasko Değeri "} </CustomText>
                    </View>
                    <View  style={{height: 40, alignItems: 'center', justifyContent: 'center'}} >
                        <CustomText fontWeight={800} style={{textAlign: 'center', fontSize : 24  ,color:'#5A6273'}}>
                            {headerData["KASKO DEĞERİ"] != null ? formatToCurrency(headerData["KASKO DEĞERİ"]) + " TL" : "Kasko Değeri Hesaplanmadı"}
                        </CustomText>
                    </View>
                    <View style={{flex: 1 , justifyContent : 'center', alignItems :'center', marginVertical: 10 , borderTopWidth: 1}}>
                      <View style={{flexDirection: 'row', justifyContent:'space-between',alignItems:'center', width: '100%', marginVertical: 10}}>
                          <CustomText style={{textAlign: 'left', fontSize: 16, fontWeight: 'bold',width : '100%'}}>{"Kasko Değer Kodu"} </CustomText>
                          <CustomText style={{textAlign: 'left', fontSize: 16,width : '100%'}}>{props.data["KASKO MARKA ID"] + "-" +  props.data["KASKO TİP ID"] } </CustomText>
                      </View>
                      <View style={{flexDirection: 'row', justifyContent:'space-between',alignItems:'center', width: '100%', marginVertical: 10}}>
                          <CustomText style={{textAlign: 'left', fontSize: 16, fontWeight: 'bold',width : '100%'}}>{"Kasko Değer Adı"} </CustomText>
                          <CustomText style={{textAlign: 'left', fontSize: 16,width : '100%'}}>{props.data["KASKO ADI"]} </CustomText>
                      </View>
                  </View>
              </Panel>
            </Col>
          </Row>
        </Grid>
        <Drawer size={'full'}  open={modalOpen} onClose={handleCloseModal}>
          <Drawer.Header>
            <Drawer.Title> {props.data["ARAÇ"]} </Drawer.Title>
          </Drawer.Header>
          <Modal.Body>
            <View>
              <FileLoader carID ={props.data["NO"]} fileCategory ={"CAR_LICENCE"} reset = {reset}></FileLoader>
              <FileViewer ref={fViewRef2} inline={true} s3KEY = {s3Keys}></FileViewer>
            </View>
          </Modal.Body>
        </Drawer>
      </View>
     
   
    )
  });


  
  function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }
  

const carImages = {
    'FIAT_EGEA_SEDAN_EASY': require('../../../../assets/CarStockImages/FIAT_EGEA_SEDAN_EASY.png'),
    'FIAT_EGEA_SEDAN_URBAN': require('../../../../assets/CarStockImages/FIAT_EGEA_SEDAN_URBAN.png'),
    'FIAT_EGEA_SEDAN_LOUNGE': require('../../../../assets/CarStockImages/FIAT_EGEA_SEDAN_LOUNGE.png'),
    'AUDI_A3': require('../../../../assets/CarStockImages/AUDI_A3.png'),
    'CITROEN_C3_AIRCROSS': require('../../../../assets/CarStockImages/CITROEN_C3_AIRCROSS.png'),
    'CITROEN_C3': require('../../../../assets/CarStockImages/CITROEN_C3.png'),
    'DACIA_DUSTER': require('../../../../assets/CarStockImages/DACIA_DUSTER.png'),
    'FIAT_DOBLO': require('../../../../assets/CarStockImages/FIAT_DOBLO.PNG'),
    'FIAT_EGEA_CROSS_WAGON': require('../../../../assets/CarStockImages/FIAT_EGEA_CROSS_WAGON.PNG'),
    'FIAT_EGEA_CROSS': require('../../../../assets/CarStockImages/FIAT_EGEA_CROSS.PNG'),
    'FIAT_EGEA_SEDAN': require('../../../../assets/CarStockImages/FIAT_EGEA_SEDAN.PNG'),
    'FIAT_EGEA_HATCHBACK': require('../../../../assets/CarStockImages/FIAT_EGEA_HATCHBACK.PNG'),
    'FIAT_FIORINO': require('../../../../assets/CarStockImages/FIAT_FIORINO.PNG'),
    'FORD_COURIER': require('../../../../assets/CarStockImages/FORD_COURIER.PNG'),
    'FORD_RANGER': require('../../../../assets/CarStockImages/FORD_RANGER.PNG'),
    'HYUNDAI_BAYON': require('../../../../assets/CarStockImages/HYUNDAI_BAYON.PNG'),
    'HYUNDAI_i20': require('../../../../assets/CarStockImages/HYUNDAI_i 20.PNG'),
    'JEEP_COMPASS': require('../../../../assets/CarStockImages/JEEP_COMPASS.PNG'),
    'JEEP_RENEGADE': require('../../../../assets/CarStockImages/JEEP_RENEGADE.PNG'),
    'MERCEDES_VITO': require('../../../../assets/CarStockImages/MERCEDES_VITO.PNG'),
    'MITSIBUSHI_L200': require('../../../../assets/CarStockImages/MITSIBUSHI_L200.PNG'),
    'PEUGEOT_2008': require('../../../../assets/CarStockImages/PEUGEOT_2008.PNG'),
    'PEUGEOT_208': require('../../../../assets/CarStockImages/PEUGEOT_208.PNG'),
    'PEUGEOT_3008': require('../../../../assets/CarStockImages/PEUGEOT_3008.PNG'),
    'PEUGEOT_TRAVALLER': require('../../../../assets/CarStockImages/PEUGEOT_TRAVALLER.PNG'),
    'RENAULT_CLIO': require('../../../../assets/CarStockImages/RENAULT_CLIO.PNG'),
    'RENAULT_MEGANE': require('../../../../assets/CarStockImages/RENAULT_MEGANE.PNG'),
    'TOYOTA_COROLLA': require('../../../../assets/CarStockImages/TOYOTA_COROLLA.PNG'),
    'TOYOTA_HILUX': require('../../../../assets/CarStockImages/TOYOTA_HILUX.PNG'),
    'VOLKSWAGEN_POLO': require('../../../../assets/CarStockImages/VOLKSWAGEN_POLO.PNG'),
    'VOLKSWAGEN_PASSAT': require('../../../../assets/CarStockImages/VOLKSWAGEN_PASSAT.PNG'),
    'VOLKSWAGEN_CARAVELLE': require('../../../../assets/CarStockImages/VOLKSWAGEN_CARAVELLE.PNG'),
};
  
  
  const styles = StyleSheet.create(
    {
      container:{
        flex:1, 
        alignItems:'center',
        width: "100%",
        backgroundColor :'#F2FFFB'
      },
      bottomBar: {
        height: 60,
        backgroundColor: 'white',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent:'flex-start',
        borderBottomColor :'#00589B',
        borderBottomWidth: 1
      },
      card:{
        padding: 13, borderRadius: 12,height: 80 ,backgroundColor: 'white',  flexDirection : 'row', marginVertical: 20 ,
        shadowColor: '#BDC9FF', shadowOffset: { width: 0, height: 1 }, shadowOpacity: 0.3, shadowRadius: 12, elevation: 5
      },
      PanelStyle:{ backgroundColor: 'white', height: 250 , width: '100%'}
    }
  )

  export default GeneralCarInfo;