import { Text, View ,TextInput, TouchableOpacity,StyleSheet} from 'react-native'
import React, { Component, useState ,useEffect } from 'react'
import { MaterialCommunityIcons } from '@expo/vector-icons';
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from '../../../aws-exports';
import PasswordInput from '../../Components/PasswordInput';
import StandardButton from '../../Components/StandardButton';
import CustomText from '../../Components/CustomText';
import { Button, IconButton, Panel } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Icon } from '@rsuite/icons';
// Amplify.configure(awsconfig);

const  ProfilePopUpComponent  = (props) => {
    
  const [authUserUserName,setAuthUserUsername] = useState(""); 
  const [authUserPassword,setAuthUserPassword] = useState(""); 
  const [authUserNewPassword,setAuthUserNewPassword] = useState(""); 
  const [authUserNewPasswordChecker,setAuthUserNewPasswordChecker] = useState(""); 
  const [currentPasswordError,setCurrentPasswordError] = useState (false);   
  const [CheckerPasswordError,setCheckerPasswordError] = useState (false);   
  const [secureCurrentPassword, toggleSecurityCP] = useState(true);
  const [secureNewPassword, toggleSecurityNP] = useState(true);
  const [secureNewPasswordChecker, toggleSecurityNPC] = useState(true);


  const errorText_currentPassword = "Yanlış Şifre"
  const [errorText_newPassword,setET_NewP] = useState("");
//   const [errorText_NewPassword_match,setET_NewPMatch] = useState("Passwords not match");
//   const [errorText_NewPassword_requiremnts,setET_NewPReq] = useState("Passwords must be at least 8 charachter long and include at least 1 number, 1 special character, 1 uppercase letter, 1 lowercase letter");
  const errorText_NewPassword_match = "Şifreler Eşleşmiyor";
  const errorText_NewPassword_requiremnts = "Şifreler en az 8 karakter uzunluğunda olup en az birer sayı, özel karakter, büyük harf ve küçük harf içermelidir";
  const get_curent_user= async () => {

    const { attributes } = await Auth.currentAuthenticatedUser();
    const username = attributes.email;
    setAuthUserUsername(username);
    console.log(authUserUserName);

  }
  useEffect(()=>{
      if(!props.isNewUser){
        get_curent_user();
      }else{
        setAuthUserUsername(props.username);
      }
  
  },[]);

  const setNewPassword = async () => {
    Auth.currentAuthenticatedUser()
    .then(user => {
       
        return Auth.changePassword(user, authUserPassword, authUserNewPassword);
    })
    .then(data => console.log(data))
    .catch(err => console.log(err));

    
  }

  const completeNewPassword = async () => {
    await  Auth.signIn(authUserUserName, authUserPassword)
    .then(user => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
           
            Auth.completeNewPassword(
                user,               // the Cognito User Object
                authUserNewPassword,       // the new password
                // OPTIONAL, the required attributes

            ).then(user => {
                // at this time the user is logged in if no MFA required
                console.log(user);
                props.toggleVisible(!props.isVisible);
                props.navigate();
            }).catch(e => {
            console.log(e);
            setCurrentPasswordError(true);
            });
        } else {
            // other situations
        }
    }).catch(e => {
        console.log(e);
        setCurrentPasswordError(true);
    });
}
    function submit(){
        if(props.isNewUser)  {
            if(authUserNewPassword != authUserNewPasswordChecker){
                setCheckerPasswordError(true);
                setET_NewP( errorText_NewPassword_match);
            }else {
                if(authUserNewPassword.length < 8){
                    setCheckerPasswordError(true);
                    setET_NewP( errorText_NewPassword_requiremnts);
                }else{
                    completeNewPassword();
                }
            }
        }  
        else{
            setNewPassword();
        }
    }
    function cancel(){
        props.toggleVisible(!props.isVisible)
    }
            
    

    return (
      <View style = {styles.container}>
    
        <Panel bordered style={{padding: 2, backgroundColor: 'white', width :'100%'}}>
            <View style = {{ justifyContent: 'center' , alignItems :'center', flex: 1}}>
                <View style = {{flexDirection: 'row', justifyContent: 'center' , alignItems :'center'}}>
                    <Icon><FontAwesomeIcon icon={solid('user')}></FontAwesomeIcon></Icon>
                    <View style = {{width: 10,}}></View>
                    <CustomText style = {{fontSize: 20 , color: '#5A6273'}}> {authUserUserName}</CustomText>
                </View>
                <View style = {{height: 10,}}></View>
                <IconButton icon ={<Icon><FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon></Icon>}  onClick = {()=>{props.onlogout()}}> <CustomText> Çıkış Yap </CustomText> </IconButton>
            </View>
        </Panel>
       
        <View style = {{height: 20,}}></View>
        <Panel bordered style={{padding: 2, backgroundColor: 'white' , width :'100%'}} header = {<CustomText style = {{fontSize: 20 , color: '#5A6273'}}> Şifre Değiştirme </CustomText>} collapsible>
            <PasswordInput showError = {currentPasswordError} errorText = {errorText_currentPassword} placeholder = {" Şifeniz "} setText={setAuthUserPassword}></PasswordInput>
            <PasswordInput showError = {CheckerPasswordError} errorText = {errorText_newPassword} placeholder = {" Yeni Şifreniz "} setText={setAuthUserNewPassword}></PasswordInput>
            <PasswordInput showError = {CheckerPasswordError} errorText = {errorText_newPassword} placeholder = {" Yeni Şifreniz "} setText={setAuthUserNewPasswordChecker}></PasswordInput>
            <View style = {{height: 10,}}></View>
            <View style={{flexDirection: 'row'}}>
                <IconButton icon ={<Icon><FontAwesomeIcon icon={solid('check')}></FontAwesomeIcon></Icon>}  onClick = {submit}> <CustomText> Kaydet </CustomText> </IconButton>
                <View style = {{width: 10,}}></View>
                <IconButton icon ={<Icon><FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon></Icon>}  onClick = {cancel}> <CustomText> İptal </CustomText> </IconButton>
            </View>
        </Panel>
        <View style = {{height: 20,}}></View>
        {/* <Panel bordered style={{padding: 2, backgroundColor: 'white' , width :'100%'}} header = {<CustomText style = {{fontSize: 20 , color: '#5A6273'}}> Talep Yönetimi </CustomText>} collapsible>

        </Panel> */}

      </View>
    )
  
}

const styles = StyleSheet.create({

    container:{
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },  
    passwordInput:{
        width: 200,
        height: 30,
        padding: 10,
        marginVertical: "1rem",
        borderRadius: "7px",
        outlineStyle : 'none' 
    },
    inputWithButton:{flexDirection : 'row', alignItems: 'center', justifyContent: 'center' , borderWidth: 1, borderColor: '#242424', borderRadius: '7px' , marginVertical : '1rem'},
    inputRightButton:{bordeRadius: "7px" , backgroundColor: '#F26938', padding: 10, borderRadius: '7px', marginVertical: '1rem', width: 100, marginHorizontal: 20}
});

export default ProfilePopUpComponent;