import { Text, View , StyleSheet,FlatList, TouchableOpacity,TextInput,ScrollView,SafeAreaView,Image,ActivityIndicator } from 'react-native'
import React, { useState, useEffect ,useRef, useImperativeHandle, forwardRef } from 'react'
import axios from 'axios';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import { useNavigation } from '@react-navigation/native';
import { Stack,Modal, Button, Form ,Nav,TreePicker ,SelectPicker , MultiCascader,Input,Pagination,IconButton, Table ,CheckTreePicker,Checkbox, DateRangePicker, Container,RangeSlider,TagPicker, Slider, Panel} from 'rsuite';
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import ResponsiveNav from '@rsuite/responsive-nav';
import ExportData from '../../Components/TableManagement/ExportData';
import FileViewer from '../../Components/FileManagements/FileViewer';
import FileLoader from '../../Components/FileManagements/FileLoader';
import locale from '../../tr';
import { BrandSvg } from '../../Components/BrandIconManager';
import AgreementInfoScreen from './AgreementInfoScreen';
import FileLoaderInline from '../../Components/FileManagements/FileLoaderInline';
import FreeTextFilter from '../CarScreens/Filters/FreeTextFilter';
import DateRangeFilter from '../CarScreens/Filters/DateRangeFilter';
import TagPickerFilter from '../CarScreens/Filters/TagPickerFilter';

import configdata from '../../config/config.json';
import { useUserToken } from '../../hooks/useUserToken';
  const Tab = createMaterialTopTabNavigator();
 
  const TabNavigator = (props) => {

    function closeTab(id){
      console.log("close clicked");
      var TabListNew = [];

      props.tabList.forEach(element => {
        if(element.id != id){
          TabListNew.push(element);
        }
      });
      props.setArray(TabListNew);
      props.removeFromEnabled(id);
    }

    const CustomTabBar = ({ state, descriptors, navigation }) => {
     
      return (
        <View
          style={styles.bottomBar}>
          {state.routes.map((route, index) => {
            const isFocused = state.index === index;
            const { options } = descriptors[route.key];
            const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

              const borderShadowStyle = isFocused ? {
                shadowColor: '#000',
                shadowOffset: { width: 0, height: 0 }, // centered shadow
                shadowOpacity: 0.3,
                shadowRadius: 10,
                elevation: 5 ,
                borderBottomWidth: 2,
                borderColor: '#E4E3E3'
              } : {};


            const onPress = () => {
              const event = navigation.emit({
                type: 'tabPress',
                target: route.key,
              })
              if (!isFocused && !event.defaultPrevented) {
                navigation.navigate(route.name);
                
              }
            }
    
            const color = 'gray';
            const iconBrand = <BrandSvg brand={"FIAT"}></BrandSvg>
            
            return (

              <View style={[{
                backgroundColor: isFocused ? "#F8F9FA" : '#E4E3E3',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                flex: 1,
                borderLeftWidth: 1,
                borderRightWidth: 1,
                borderBottomWidth: 1,
                borderColor: '#ECEBEB',
              
              },borderShadowStyle]} 
              >
            
                  {options.id != "ANASAYFA" && 
                      <TouchableOpacity
                    
                       style={{marginHorizontal: 10}}
                        key={index}
                        onPress={()=> {closeTab(options.id);}}
                        accessibilityRole="button"
                        >
                        <View >
                          <Icon><FontAwesomeIcon icon={solid('times')} size={15} color={'#5A6273'} /></Icon>
                        </View>
                    </TouchableOpacity>
                  }

                  <TouchableOpacity
                      key={index}
                      onPress={onPress}
                      accessibilityRole="button"
                      style = {{marginHorizontal: 10, flex: 1, justifyContent: 'center', alignItems: 'center'}}
                      >
                     <View style={{padding: 5}}>
                          <Text>{label}</Text>
                      </View>
                  </TouchableOpacity>

                  
              </View>
            )
          })}
        </View>
      )
    }
  
    return (
    
      <Tab.Navigator tabBar={(props) => <CustomTabBar {...props} />}>
            {props.tabList.map((tab) => (
              <Tab.Screen
                key={tab.id}
                name={tab.name}
                component={tab.component}
                options={{
                  id: tab.id,
                  swipeEnabled: false,
                  brand: tab.brand                
                }}
              />
          ))}
       </Tab.Navigator>

  );
}
  const RentAgreementManagement = () => {
    const compMain = () =>  <RentsManagement ref= {rentManagementRef} handleChange = {handleChange} ></RentsManagement>
    const rentManagementRef= useRef();
    const [TabList, setTabArray] = useState([    {
      id: "ANASAYFA",
      name: "Kira Sözleşmeleri",
      icon: "information-outline",
      component: compMain
    }]);
    function reactComponentToJsonString(component) {
      return JSON.stringify(component);
    }



    function handleChange (input){
      setTabArray((TabList) => [...TabList,input])
    }

    useEffect(()=>{
      sessionStorage.setItem("RentAgreementTabs",JSON.stringify(TabList))
      console.log(JSON.parse(sessionStorage.getItem("RentAgreementTabs")));

    },[TabList])

    function removeFromEnabled (input){
      rentManagementRef.current.removeElement(input);
    }
  
    return(
      <TabNavigator removeFromEnabled={removeFromEnabled} setArray= {setTabArray} tabList = {TabList}></TabNavigator>
    )
  
  }


  const RentsManagement = forwardRef((props,ref) =>  {
    const tableRef = useRef()
    const filterRef = useRef()
    const [data,setData] = useState([]);   
    const [activate,closeIndictor] = useState(true);  
    const [refresh,setRefresh] = useState(false);

    const {fetchIdToken} = useUserToken();

    async function getAgreements () {
          const api = configdata.API_BASE + '/rent-agreements'
          let idToken = await fetchIdToken();

          const body = {
            "ENV" : configdata.APP_ENV,
            "type" : "GET_DATA"
          }

          const headers = {
            'Authorization': `Bearer ${idToken}`,
          }

          axios.post(api,body,headers).then(
              (response) => {
                  console.log(response.data);
                  setData(response.data);
                  closeIndictor(false);
              }
          ).catch(
              (error) => {
                  console.log(error);
              }
          )
    } 

    function reset(){
      console.log("reset");
      getAgreements();
    }

    useEffect(()=> {
      if(data.length > 0){
        console.log("dataChanged");
        tableRef.current.refresh();
      }
    },[data])

    // TABLE MANAGEMENT
    // all table headers ?? TODO -> can be taken from sql database 
    const [tableHead,setTableHead] = useState([ 
        "NO",
        "SÖZLEŞME ADI",
        "SÖZLEŞME DURUMU",
        "KİRA TİPİ",
        "BAŞLANGIÇ TARİHİ",
        "BİTİŞ TARİHİ",
        "MÜŞTERİ ADI",
        "KDV ORANI",
        "KDV HARİÇ TUTAR",
        "KDV DAHİL TUTAR",
        "TAKSİT SAYISI",
        "İLK TAKSİT TARİHİ"
]); 

    useImperativeHandle(ref,()=>({

        removeElement: (element) => {
            removeElementEnabledTabs(element);
        }
    }))

    
    const [choosenAgreement, setChoosenAgr] = useState(undefined)
    const [CarChoosenRefresh,setChooseRefresh] = useState(false);
    // Tab Conttroller for adding new tab or navigating current
    const [isPopUpvisible,setPopUpVisible] = useState(false) // car popup for expandable car window option TO BE DEVELOPED -- ADD SCROLL for pdf viewer
    const navigation = useNavigation();

    const [enabledTabs,setEnabledTabs] = useState([]);
    // const [enabledTabs,setEnabledTabs] = useState(sessionStorage.getItem("RentAggrementsEnabledTabs") != null ? JSON.parse(sessionStorage.getItem("RentAggrementsEnabledTabs")): []);
    function handleEnabledTabs (input){
      setEnabledTabs((enabledTabs) => [...enabledTabs,input])
    }
    useEffect(()=>{
      sessionStorage.setItem("RentAggrementsEnabledTabs",JSON.stringify(enabledTabs))
    },[enabledTabs])

    function removeElementEnabledTabs(element){
        var index = enabledTabs.indexOf(element);
        if(index >= 0){
          enabledTabs.splice(index,1);
        }
        console.log(enabledTabs);
       
    }
    function chooseRow(input){
      setChooseRefresh(!CarChoosenRefresh);
      setChoosenAgr(input);
    }

    const AddTab = (input) => {
      if(input  != undefined ){
        if(enabledTabs.includes(input["NO"])){
          console.log("avaliable tab do not create new")
          navigation.navigate(input["NO"] + "-" + input["SÖZLEŞME ADI"]);
        }else{
          props.handleChange(
            {
              id: input["NO"],
              name: input["NO"] + "-" + input["SÖZLEŞME ADI"],
              icon: "document",
              component: AgreementInfo
            })
          handleEnabledTabs(input.NO);
        }
      }
    }

    function refreshTable(){
      getAgreements();
      setRefresh(!refresh);
    }


     const  AgreementInfo = () => (<AgreementInfoScreen refreshTable={refreshTable}  data= {choosenAgreement} ></AgreementInfoScreen>)

    // table Button controllers

    useEffect(()=>{if(choosenAgreement != undefined ||choosenAgreement != null) {AddTab(choosenAgreement); console.log("car choose")} } ,[CarChoosenRefresh])
    
    useEffect(()=>{getAgreements(); },[])

    
    // FILTER Operations 
    const [filteredData,setFiltered] = useState([]);
    const [isFiltered, setApplied] = useState(false);

    function resetTableFilter(){
      setApplied(false);
      setFiltered(data);
      tableRef.current.refresh();
    }

    function filterApplied(input){
      setApplied(input)
      tableRef.current.setPage1();
    }

    return(
      <View style = {{ flex: 1 ,height: '100%'}}>
          {data.length == 0 || data == undefined || data == null ? (
            <View>
                <Text style={{color : 'powderblue' , fontWeight: 'bold' , fontSize: 18 , textAlign :'center', marginTop: 10}}> Kira Sözleşmeleri Yükleniyor </Text>
                <ActivityIndicator animating = {data.length == 0} color = '#BABABA' size = 'large' style={{marginTop : 40}}/>
               
            </View>
          )
          : 
          (
                <View style = {{flex:4}}>
                    <View style={{height :'100%',padding: 20,flex: 1}}>
                            <TableFilter  ref ={filterRef}  reset = {resetTableFilter} initialData= {data}  data =  {data} isFilterApplied={isFiltered} setFiltered = {setFiltered} setApplied={filterApplied}> </TableFilter>
                            <TableRSuite reset={() => {reset();}} refresh={refresh} setCarData={chooseRow} ref ={tableRef} tableHead = {tableHead} data = { isFiltered ? filteredData :data}></TableRSuite>
                    </View>
                </View>
          )}
      </View>
    );
  }
  );
  //

  const TableFilter = forwardRef( (props,ref) =>{


    const [refresh , setRefresh] = useState(false);
    const [filterChange , setFilterChange] = useState(0);
    const toggle_refresh = () => {
        setRefresh(!refresh)
    }

    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      refresh: () => {
        toggle_refresh()
      },
      filter: () => {
        toggle_refresh();
      }
    }))

    const customerRef = useRef();
    const agreementNoRef = useRef();
    const agreementIntervalDate = useRef();
    const agreementStatusRef = useRef();

    function resetAndClear(){
      props.reset();
      customerRef.current.clear();
      agreementNoRef.current.clear();
      agreementIntervalDate.current.clear();
      agreementStatusRef.current.clear();
      props.setApplied(false)
    }


    function intersect (first, rest) {    
      return first.filter(e => rest.every(item => checkContains(item,e)))
    }
    function checkContains(arr,check){
      return arr.indexOf(check) >= 0
    }
    function multipleFilter(input){
      let first =  input.shift();
      let final = intersect(first,input);
      props.setFiltered(final)
      props.setApplied(true)
    }

    function filter(){
    
      var arr1 = customerRef.current.filter();
      var arr2 = agreementNoRef.current.filter();
      var arr3 = agreementIntervalDate.current.filter();
      var arr4 = agreementStatusRef.current.filter();
      multipleFilter([arr1,arr2,arr3,arr4])
    }

    const item_style = {
      narrow: {width: 150},
      standard: { width : 500, marginVertical : 10 },
      viewStyle: {flex: 1,minWidth: 350}
    }

    return(
         <View style = {{ marginBottom: 10}}>
              <Panel shaded style={{backgroundColor: 'white'}} >
                    <Stack wrap spacing={5} style={{padding: 10}}>
                        <Stack.Item grow={1}>
                          <View style={{flex: 1}}>
                            <Text> Müşteri Adı </Text>
                            <FreeTextFilter item_style={item_style.viewStyle} filter={filter} refresh={toggle_refresh} ref ={customerRef} data = {props.data} tableHeadName = {"MÜŞTERİ ADI"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></FreeTextFilter>
                          </View>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                          <View style={{flex : 1 }}>
                              <Text> Sözleşme No </Text>
                              <FreeTextFilter item_style={item_style.viewStyle} filter={filter}  refresh={toggle_refresh} ref ={agreementNoRef} data = {props.data} tableHeadName = {"NO"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></FreeTextFilter>                        
                          </View>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                          <View style={{flex : 1 }}>
                              <Text> Sözleşme Durumu </Text>
                              <TagPickerFilter item_style={item_style.viewStyle} filter={filter}  refresh={toggle_refresh} ref ={agreementStatusRef} data = {props.data} tableHeadName = {"SÖZLEŞME DURUMU"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied}></TagPickerFilter>
                            </View>
                          </Stack.Item>
                        <Stack.Item grow={1}>
                        <View style={{flex : 1 }}>
                            <Text> Sözleşme Tarihi </Text>
                            <DateRangeFilter  item_style={item_style.viewStyle}  filter={filter}  refresh={toggle_refresh} ref ={agreementIntervalDate} data = {props.data} tableHeadName = {"BAŞLANGIÇ TARİHİ"} setApplied = {props.setApplied} setFiltered = {props.setFiltered} reset = {props.reset} isFilterApplied= {props.isFilterApplied} ></DateRangeFilter>
                          </View>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                          <View style={{flex : 1, alignItems: 'center' }}>
                            <Button style={{flex: 1}}  onClick = {() => { resetAndClear(); }}> Temizle </Button>
                          </View>
                        </Stack.Item>
                        <Stack.Item grow={1}>
                          <View style={{flex : 1, alignItems: 'center' }}>
                              <Button style={{flex: 1}} onClick = {() => { filter();}}> Filtrele </Button>
                          </View>
                        </Stack.Item>
                    </Stack>
              </Panel>
        </View>
      
       
    )
  })


  const DocumentAddModal = forwardRef((props,ref) => {

    const [PopupActive, setPopupActive] = useState(false);
    const [choosenData,setChoosenData] = useState(undefined);
    const [S3Key,setS3Key] = useState("");
    function handleModalClose(){
      setPopupActive(false);
   }



    useImperativeHandle(ref, () => ({
      // methods connected to `ref`
      open: (rowData) => {
        console.log(rowData);
          setChoosenData(rowData);
          setPopupActive(true);
      },
      
    }))

    useEffect(() => {
      if(choosenData != undefined){
        console.log(choosenData["CAR_IDs"][0]);
      }
    
    },[])

    useEffect(() => {
      if(S3Key != "" && S3Key != undefined && S3Key != null){
        props.setS3Key(S3Key);
      }
    },[S3Key])

    function reset(){
      props.reset();
      handleModalClose();
    }

    const fileUploaderRef = useRef();
    return(
      choosenData != undefined  ?
        (<Modal overflow={true} size={"full"}  backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
          <Modal.Header>
            <Modal.Title> {choosenData["ARAÇ"]} </Modal.Title>
          </Modal.Header>

          <Modal.Body>
          <View style={{flexDirection : 'row' , alignItems : 'center'}}>
            <Text style={{marginHorizontal : 10 }} > Sözleşme Yükle </Text>
            
            <FileLoaderInline ref = {fileUploaderRef} reset={reset} fileCategory = {"RENT_AGGREEMENT"} isMultiRelated = {true} ClientID = {choosenData["CLIENT_ID"]} carID = {JSON.parse(choosenData["CAR_IDs"])} setS3Value = {(value) => {setS3Key(value);}}></FileLoaderInline>
            {/* <FileLoader reset={props.reset} carID ={choosenData["NO"]} fileCategory = {"CAR_LICENCE"} ></FileLoader> */}
            {/* <Button style={{width : 120}}  onClick={() => {if(getS3Keys().length > 0) setModalOpen(true); else messageToggle("Ruhsat Dökümanı bulunamadı", "error")}}> Görüntüle </Button> */}
           
          </View>
          
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={handleModalClose} appearance="subtle">
              İptal Et
            </Button>
            <Button onClick={ () => 
                {  
                  fileUploaderRef.current.load()

                }} appearance="subtle">
              Yükle
            </Button>
          </Modal.Footer>
      </Modal>):
      (<View>

      </View>)
    )
  });

  // filterItems = {filterItems} setFilterItems = {setFilterItems}
  const TableRSuite = forwardRef( (props,ref) => {
    
    const [bordered, setBordered] = useState(true);
    const [showHeader, setShowHeader] = useState(true);
    const [PopupActive, setPopupActive] = useState(false);
    const [refresh , setRefresh] = useState(false);
    const [limit, setLimit] = React.useState(10);
    const [page, setPage] = React.useState(1);
  
    const handleChangeLimit = dataKey => {
      setPage(1);
      setLimit(dataKey);
    };
  
    const data = props.data.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  
    const [sortColumn, setSortColumn] = React.useState();
    const [sortType, setSortType] = React.useState();
    const [loading, setLoading] = React.useState(false);
  
    const getData = () => {
      if (sortColumn && sortType) {
        return data.sort((a, b) => {
          let x = a[sortColumn];
          let y = b[sortColumn];
          if (typeof x === 'string') {
            x = x.charCodeAt();
          }
          if (typeof y === 'string') {
            y = y.charCodeAt();
          }
          if (sortType === 'asc') {
            return x - y;
          } else {
            return y - x;
          }
        });
      }
      return data;
    };
  
    const handleSortColumn = (sortColumn, sortType) => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setSortColumn(sortColumn);
        setSortType(sortType);
      }, 500);
    };

    const toggle_refresh = () => {
        console.log(refresh);
        setRefresh(!refresh);
    }

    function handleModalClose(){
      setPopupActive(false);
    }

    useImperativeHandle(ref, () => ({
        // methods connected to `ref`
        refresh: () => {
          toggle_refresh()
        },
        setPage1:() => {
          setPage(1);
        },
        filter: () => {
          toggle_refresh();
        }
    }))
  
    // const data = 
    const columns = props.tableHead;
    
    const[choosen_s3KEY,setChoosenS3KEY] = useState("");
    function viewDocument(input){
      var dataS3 = JSON.parse(input["S3 KEYS"]).data
      console.log(dataS3);
      if( dataS3 != null){
       dataS3.sort(function(a, b) {
          return parseFloat(b.ID) - parseFloat(a.ID);
      });}
      console.log(dataS3);
      setChoosenS3KEY(dataS3[0].S3_KEY);
      setPopupActive(true);
      // console.log(dataS3[0].s3_KEY);
    }

    function viewDocEditPopup(rowData){
      newDocPopupRef.current.open(rowData);
    }
    function editRowData(rowData){
      updateCarRef.current.open(rowData);
    }
    function chooseRow(input){
      console.log(input);
      props.setCarData(input);
    }

    const ActionCell = ({ rowData, dataKey, ...props }) => (
        <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
          <View style={{ height: '80%'  }}>
            {(JSON.parse(rowData["S3 KEYS"]).data != null ) ?  <IconButton icon={<Icon><FontAwesomeIcon icon={solid('file')} /></Icon>} 
              onClick={() => {viewDocument(rowData)}}
            />
           :  <IconButton icon={<Icon><FontAwesomeIcon icon={regular('file')} /></Icon>} 
              onClick={
              () => {
                viewDocEditPopup(rowData);
              }
            }
            />}
          </View>
        </Table.Cell>
    );

    var currency_symbol = "₺"
    var formatter = new Intl.NumberFormat('tr-TR', {
      style: 'currency',
      currency: 'TRY',
    
      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
    function formatAmount(input){
      return formatter.format(input).replace("₺","₺ ")
    }

    function editRowData(input,dataKey){
      if(dataKey.includes("TARİH") && input[dataKey] != null && input[dataKey] != undefined){
       return reverseDate(input[dataKey]);
      }
      else if(dataKey.includes("TUTAR") && input[dataKey] != null && input[dataKey] != undefined){
        return formatAmount(input[dataKey]);
       }
       else if(dataKey.includes("ORANI") && input[dataKey] != null && input[dataKey] != undefined){
        return "% " +(input[dataKey]);
       }
      else{
        return input[dataKey];
      }
    }
  
    function reverseDate(input){
      var result = "";  
  
      var comps = input.split("-");
  
      for(var c = 2 ; c >= 0 ; c--){
        for(var i = 0 ; i < comps[c].length  ; i++){
          result += comps[c][i];
      }
      c != 0 ? result += "-": result += "";
      }
     
      return result;
    }

    const CustomCellView = ({ rowData, dataKey, ...props }) => (
      <Table.Cell {...props} style={{ padding: 5 , height: '100%' }}>
        {dataKey == "NO" ?
        <View style={{alignItems : 'center'   }}>
          <Button
           onClick={() => {chooseRow(rowData);}}
          > {rowData[dataKey]} </Button>
         </View>  :
         <View style={{  justifyContent : 'center'  }}>
            <Text style={{textAlign: 'left'}}>{editRowData(rowData,dataKey)}</Text>
        </View>
        }
        
      </Table.Cell>
    );

      const newDocPopupRef = useRef();
      const updateCarRef = useRef();

      function getWidth(input){
        var max = input.length * 12;
        
        data.forEach(element => {
          if(element[input] != null || element[input] != undefined){
            var str = element[input].toString();
            if(str.length * 12 > max){
              max = str.length * 12;
            }
          }
        })
        if(max < 60){max = 60};
          return max;
      }

    return ( 

    <Panel shaded style = {{height: '80%', backgroundColor: 'white'}}>
         <Modal  overflow={true} size={'full'} backdrop={true} keyboard={false} open={PopupActive} onClose={handleModalClose}>
                  <Modal.Header>
                    <Modal.Title> {props.data["ARAÇ"]} </Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                      <FileViewer inline={true} s3KEY = {choosen_s3KEY}></FileViewer>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleModalClose} appearance="subtle">
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>

            <DocumentAddModal reset={props.reset} setS3Key={setChoosenS3KEY} ref = {newDocPopupRef} ></DocumentAddModal>
           
            <View style = {{flexDirection : 'row', marginVertical: 10}}>
              <ExportData data = {props.data} tableHead = {props.tableHead} tableName={"ARAÇLAR"}></ExportData>
            </View>
            <View>
                <Table
                        wordWrap="break-word"
                        loading={loading}
                        showHeader={showHeader}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        data={getData() == null ? [] : getData()}
                        bordered={bordered}
                        cellBordered={bordered}
                        autoHeight
                    >
                    <Table.Column  sortable resizable>
                        <Table.HeaderCell> Dosya </Table.HeaderCell>
                        <ActionCell dataKey= 'DOSYA'/>
                    </Table.Column>

                    {columns.map(column => {
                        const { key, label, ...rest } = column;
                        return (
                            <Table.Column  sortable resizable width={getWidth(column)}>
                                <Table.HeaderCell>{column}</Table.HeaderCell>
                                <CustomCellView dataKey={column} />
                            </Table.Column>
                        );
                    })}
                    </Table>
                    <Pagination
                        prev
                        next
                        first
                        last
                        ellipsis
                        boundaryLinks
                        maxButtons={5}
                        size="xs"
                        layout={['total', '-', 'limit', '|', 'pager', 'skip']}
                        total={props.data.length}
                        limitOptions={[10, 20]}
                        limit={limit}
                        activePage={page}
                        onChangePage={setPage}
                        onChangeLimit={handleChangeLimit}
                        locale={{
                          more: 'Daha',
                          prev: 'Önceki',
                          next: 'Sonraki',
                          first: 'İlk',
                          last: 'Son',
                          limit: '{0} / sayfa',
                          total: 'Toplam Satır: {0}',
                          skip: 'Git {0}'
                      }}
                        />
            </View>
        </Panel>
    
  
    )
  
  }) 
  
  const styles = StyleSheet.create({
    bottomBar: {
      height: 60,
      backgroundColor: 'white',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent:'flex-start',
    },

        HeadStyle: { 
          height: 50,
          alignContent: "center",
          backgroundColor: '#f7f7f5',
          // borderWidth: "1px",
          //  borderColor: "black"
        },
        RowStyle: { 
          height: 40,
          alignContent: "center",
          backgroundColor: '#fff'
        },
        TableText: { 
          textAlign: 'center',
          margin: 10
        },
        row: { flexDirection: 'row', backgroundColor: '#fff', height: 40,
      // borderColor: "black", borderWidth : "1px" 
        },
        headerRow: { flexDirection: 'row', backgroundColor: '#f7f7f5', height: 40,
        // borderColor: "black", borderWidth : "1px" 
        },
        btn: { width: "100%", },
        btnText: { textAlign: 'center', color: 'black' },
        modalView: {
          width: "95%", 
          height:"95%",
          margin: 5,
          backgroundColor: "white",
          borderRadius: 7,
          padding: 5,
          alignItems: "center",
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 2
          },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          elevation: 5
        },
        modalText: {
          marginBottom: 15,
          textAlign: "center"
        }
  })

export default RentAgreementManagement;
