import { useState } from "react";
import axios from "axios";
import configdata from '../config/config.json'
import formatter from "../utils/formatter";



const useDropdownData = () => {
    
    const {formatterKm} = formatter() 

    function parseBrands(input,setInput){
    var result= [];
    var Brands = [];
    input.forEach(element => {
        if(element.LEVEL == "1"){
        Brands.push( {"label": element.İSİM, "id" : element.ID ,"value": element.İSİM  ,"children": [], "childrenExtras": [] })
        }
    });
    input.forEach(element => {
        if(element.LEVEL == "2"){
        Brands.find(brand => brand.id == element.PARENT_ID).children.push({"label": element.İSİM, "id" : element.ID , "value": element.İSİM ,"children": [] })
        }
        if(element.LEVEL == "4"){
          Brands.find(brand => brand.id == element.PARENT_ID).childrenExtras.push({"label": element.İSİM, "id" : element.ID , "value": element.İSİM })
        }
    });

    input.forEach(element => {
        if(element.LEVEL == "3"){
        Brands.forEach(brand => {
            // console.log(brand.children.length);
            brand.children.forEach(model => 
            { 
                if(model.id == element.PARENT_ID){
                // console.log(model.children);
                model.children.push({"label": element.İSİM, "id" : element.ID ,"value": element.İSİM })
            }}
            )
        
            // push({"label": element.İSİM, "value": element.ID ,"children": [] }
            // if(brand.children.length > 0 ){
            //  
            // }
        })
        }
    });

    
    Brands.forEach(element => {
        result.push(element);
    })
    // result.push(Brands[0]);
    // result.push(Brands[1]);
    // console.log(Brands[0]);
    setInput(result);
    }

    function getBrands(set){
        const api = configdata.API_BASE + '/uidropdownlist';
        axios
        .post(api,{
            "ENV" :configdata.APP_ENV ,  
            "type" : "GET_BRAND_TREE",
            
        })
        .then((response) => {
            parseBrands(response.data,set);
        })
        .catch((error) => {
            console.log(error);
            
        });
    }

    function getDropDownValues(input,set){
        const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
        axios
        .post(api,{
            "ENV" :configdata.APP_ENV ,  
            "category": input,
            "hasParent": "NO",
            "type" : "GET",
            
        })
        .then((response) => {
            //setUserData(JSON.parse(response.data));
            
            // console.log(response);
            var result = [];
            response.data.forEach( element =>
            { 

                console.log(element);
                var label = element.İSİM
                if(input == "SERVICE_KM_PERIOD"){
                label = formatterKm.format(label)
                }
                if(input.includes("(lt)")){
                label = formatterKm.format(label)
                }
                if(element.İSİM == undefined){
                label = element.VALUE
                }
                result.push({"label" : label , "value" : element.ID})
            }
            );
            set(result);
            console.log(result);
        })
        .catch((error) => {
            console.log(input + error);
            
        });
    }

    function getDropdownValuesWithObject(input,set){
        const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
        axios
        .post(api,{
            "ENV" :configdata.APP_ENV ,  
            "category": input,
            "hasParent": "NO",
            "type" : "GET",
            
        })
        .then((response) => {
            //setUserData(JSON.parse(response.data));
            
            console.log(response);
            var result = [];
            response.data.forEach( element =>
            { 

                var label = element.İSİM
                if(input == "SERVICE_KM_PERIOD"){
                label = formatterKm.format(label)
                }
                if(input.includes("(lt)")){
                    label = formatterKm.format(label)
                }
                if(input == "BCR_PORTFOY"){
                    label = element.ARAÇ;
                }
                result.push({"label" : label , "value" : element.ID , "object" : element})
            }
            );
            set(result);
            // console.log(result);
        })
        .catch((error) => {
            console.log(input + error);
        });
    }

    function getDropDownValuesGlobal(input,set){
    
    const api = configdata.API_BASE + '/uidropdownlist';
    axios
    .post(api,{
        "ENV" :configdata.APP_ENV ,  
        "category": input,
        "type" : "GET_GLOBAL",
        
    })
    .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        // console.log(response.data);
        var result = [];
        response.data.forEach( element =>
        { 
            result.push({"label" : element.VALUE , "value" : element.ID})
        }
        );
        set(result);
        // console.log(result);
    })
    .catch((error) => {
        console.log(error);
        
    });

    }

    function getDropDownValuesService(input,set){
    
    const api = configdata.API_BASE + '/uidropdownlist';
    axios
    .post(api,{
        "ENV" :configdata.APP_ENV ,  
        "hasParent": "NO",
        "type" : input,
        
    })
    .then((response) => {
        // console.log(response);
        // console.log(response.data);
        //setUserData(JSON.parse(response.data));
        
        var result = [];
        (response.data).forEach( element =>
        { 
            result.push({"label" : element , "value" : element})
        }
        );

        set(result);
        // console.log(result);
    })
    .catch((error) => {
        console.log(error);
        
    });

    }

    function getParseAraçDurum(set){
        const api = 'https://a16d1wffub.execute-api.eu-central-1.amazonaws.com/dev/uidropdownlist';
        axios
        .post(api,{
          "ENV" :configdata.APP_ENV ,  
            "category": "ARAÇ_DURUM",
            "hasParent": "NO",
            "type" : "GET",
            
        })
        .then((response) => {
          // console.log(response);
          // console.log(response.data);
          //setUserData(JSON.parse(response.data));
          
          var result = [];
          response.data.forEach( element =>
            { 
              if(element.PARENT == -1){
                result.push({"label" : element.İSİM , "value" : element.ID, children : []})
              }
            }
           );
  
           response.data.forEach( element =>
            {
              if(element.PARENT != -1){
                result.find( parent => parent.value == element.PARENT).children.push({"label" : element.İSİM , "value" : element.ID})
              }
            });
          set(result);
          console.log(result);
        })
        .catch((error) => {
          console.log(error);
          
        });
    }

    function getClients(setData){
        // console.log("getClients");
        const api = configdata.API_BASE + '/customers';
        axios.post(api,{
            "ENV" : configdata.APP_ENV,
            "type" : "GET_CLIENTS_TEDARIK"
        }).then(
            (response) => {
                // console.log(response.data);
                let temp = [];
                    response.data.forEach(element => {
                        if(element.NAME == null || element.NAME == ""){
                            temp.push({"label" : element.PERSON_NAME + " " + element.PERSON_SURNAME , "value" : element.ID})
                        }else {
                            temp.push({"label" : element.NAME , "value" : element.ID})
                        }
                    });
                    // console.log(temp);
                    setData(temp);
            }
        ).catch(
            (error) => {
                console.log(error);
            }
        )
    } 



        

    const taahhütMonthsData = [
        {label : " AY TAAHHÜTÜ YOK " , value : 0},
        {label : "4 Ay" , value : 4},
        {label : "6 Ay" , value : 6},
        {label : "9 Ay" , value : 9},
        {label : "10 Ay" , value : 10},
        {label : "12 Ay" , value : 12},
    ]

    const taahhütKmData = [
        {label : " KM TAAHHÜTÜ YOK " , value : 0},
        {label : "1.000 KM" , value : 1000},
        {label : "5.000 KM" , value : 5000},
        {label : "6.000 KM" , value : 6000},
        {label : "10.000 KM" , value : 10000},
        {label : "15.000 KM" , value : 15000},
        {label : "20.000 KM" , value : 20000}
    ]

    const taahhütLogicData = [
        {label :"VE" , value : "VE"},
        {label :"VEYA" , value : "VEYA"}
    ]

    const DurumData = [
        {label :"VAR" , value : 1},
        {label :"YOK" , value : 0}
    ]

    const kaskoTipiData = [
        {label :"YOK" , value : 0},
        {label :"HAVUZ KASKO" , value : "HAVUZ"},
        {label :"STANDARD" , value : "STANDARD"},
    ]

    const alisKanallari = [
        {"label" : "Kurumsal" , "value" : "Kurumsal"},
        {"label" : "FBK" , "value" : "FBK"},
        {"label" : "RENT TO RENT" , "value" : "RENT TO RENT"},
    ]

    const [kdvOranları,setKdvOranları] = useState([ 
        {"label" : "%20" , "value" : 20},
        {"label" : "%18" , "value" : 18},
        {"label" : "%10" , "value" : 10},
        {"label" : "%8" , "value" : 8},
        {"label" : "%1" , "value" : 1},
        {"label" : "%0" , "value" : 0},
    ]);

    const [otvOranları,setOtvOranları] = useState([
        {"label" : "%10" , "value" : 10},
        {"label" : "%15" , "value" : 15},
        {"label" : "%40" , "value" : 40},
        {"label" : "%45" , "value" : 45},
        {"label" : "%50" , "value" : 50},
        {"label" : "%60" , "value" : 60},
        {"label" : "%70" , "value" : 70},
        {"label" : "%80" , "value" : 80},
        {"label" : "%130" , "value" : 130},
        {"label" : "%150" , "value" : 150},
        {"label" : "%220" , "value" : 220},

    ]);

    return {
        getDropDownValuesGlobal,
        getDropDownValuesService,
        getDropdownValuesWithObject,
        getDropDownValues,
        getBrands,
        getParseAraçDurum,
        getClients,
        DurumData,
        taahhütMonthsData,
        taahhütLogicData,
        taahhütKmData,
        kaskoTipiData,
        alisKanallari,
        kdvOranları,
        otvOranları
    };
};

export {useDropdownData}
