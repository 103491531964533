import React, { useEffect,useState,useCallback } from 'react';
import { useToaster,Schema  ,Message, Button ,List,IconButton ,SelectPicker ,InputGroup, MaskedInput,Table, Input, Drawer, RadioGroup, Radio, Panel  } from 'rsuite';
import { Image,View, Text, StyleSheet,TouchableOpacity} from 'react-native';
import { useDropdownData } from '../../hooks/useDropdownData';
import { useUserToken } from '../../hooks/useUserToken';
import {Icon} from '@rsuite/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { createNumberMask } from 'text-mask-addons';
import axios from 'axios';
import configdata from '../../config/config.json';
import RentContractView from './RentContractView';
import RentContractTemplate from './RentContractTemplate';
import { ScrollView } from 'react-native-web';
import  CustomText  from '../../Components/CustomText';

const RentContractOffers = () => {

    const [offerData, setOfferData] = useState([]);
    const [choosenOfferData, setChoosenOfferData] = useState({});
    const [carDetails, setCarDetails] = useState([]);
    const [choosenCarDetails, setChoosenCarDetails] = useState([]);

    const [safetyPacks, setSafetyPacks] = useState([]);
    const [selectedSafetyPacks, setSelectedSafetyPacks] = useState([]);
    const {fetchIdToken} = useUserToken();
    const [offerDrawer, setOfferDrawer] = useState(false);

    async function getData(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }

        const body = {
            "type" : "GET_RENT_INFO",
            "ENV" : configdata.APP_ENV
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setOfferData(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    }



    async function getDetailsCars(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }
        const body = {
            "type" : "GET_RENT_INFO_CARS",
            "ENV" : configdata.APP_ENV
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setCarDetails(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });

    }

    async function getSafetyPacks(){
        const api = configdata.API_BASE + '/cardata'
        const token = await fetchIdToken();
        const header = {
            'Authorization': `Bearer ${token}`
        }
        const body = {
            "type" : "GET_SAFETY_PACKS",
            "ENV" : configdata.APP_ENV
        }

        axios.post(api, body, {headers: header})
        .then(function (response) {
            console.log(response.data);
            setSafetyPacks(response.data);
        })
        .catch(function (error) {
            console.log(error);
        });
    }





    useEffect(() => {
        getData();
        getDetailsCars();
        getSafetyPacks();
    }
    ,[]);

    useEffect(() => {
        if(choosenCarDetails.length > 0 && choosenOfferData != {})
         setOfferDrawer(true);
    },[choosenCarDetails,choosenOfferData]);

    function newOpen(){
        setChoosenCarDetails([]);
        setChoosenOfferData({});
        setOfferDrawer(true);
        setSelectedSafetyPacks([]);
    }

    function handleClose(){
        setOfferDrawer(false);
    }

    function getChoosenCars(input){
       console.log(input); 
       return carDetails.filter((item) => item.RENT_OFFER_ID == input);
    }

    function getChoosenSafetyPacks(input){
        let parsedData = [];
        let tempFirst = safetyPacks.filter((item) => item.RENT_OFFER_ID == input);
        tempFirst.forEach((item) => {
            let catalogDataTemp = {
                "MUAF" : item.PRICE_MUAF,
                "TAM" : item.PRICE_TAM,
                "IMM" : item.PRICE_IMM,
                "MINI" : item.PRICE_MINI,
                "TIRE" : item.PRICE_TIRE,
                "DRIVER" : item.PRICE_DRIVER,

            }
            parsedData.push({
                "CLASS" : item.CLASS,
                "data" : catalogDataTemp,
            });
        }
        );
        console.log(parsedData);

        return parsedData;
    }

    function getParsedCars(){
      
        console.log(choosenCarDetails);
        let cars = [];
        choosenCarDetails.forEach((item,index) => {
            cars.push({
                'ID' : index,
                'OZET': item.BRAND + ' ' + item.MODEL + ' ' + item.PACKAGE + ' ' + item.CAR_BODY_STYLE,
                'VITES_YAKIT' : item.FUEL_TRANSMISSION,
                'FIYAT' : item.RENT_PRICE,
                'RENT_TIME': item.RENT_TIME,
                'KM' : item.KM ,
                'CAR_BODY_STYLE' : item.CAR_BODY_STYLE,
                'KM_LIMIT' : item.KM_LIMIT,
                'KM_FIYAT' : item.KM_LIMIT_PRICE,
                'MODEL_YILI' : item.MODEL_YEAR,
                'name' :( item.BRAND + '_' + item.MODEL + '_' + item.CAR_BODY_STYLE + "_" + item.PACKAGE ).toUpperCase(),
                'image' : item.IMAGE,
                'BMP' : [item.BRAND,item.MODEL,item.PACKAGE],
                'BMP_ID': [item.BRAND_ID,item.MODEL_ID,item.PACKAGE_ID],
                'class' : item.CAR_CLASS,
                'CAR_BODY_STYLE_ID' : item.CAR_BODY_STYLE_ID,
            });
        });
        return cars;
    }

    function getCustomer(){
        console.log(choosenOfferData);
        let customer = {
                'ID' : choosenOfferData.ID,
                'CLIENT' : choosenOfferData.CLIENT,
                'CITY' : choosenOfferData.CITY,
                'COMPANY' : choosenOfferData.COMPANY,
                'OFFER_DATE' : choosenOfferData.OFFER_DATE,
                'OFFER_TIME_LIMIT' : choosenOfferData.OFFER_TIME_LIMIT,
                'WINTER_TIRE_COND' : choosenOfferData.WINTER_TIRE_COND == "VAR" ? true : false,
                
                // 'OFFER_TIME_LIMIT' : {"label" :choosenOfferData.OFFER_TIME_LIMIT + " Gün" , "value" : choosenOfferData.OFFER_TIME_LIMIT}
        }
        return customer;
    }

    function getOfferInfo(){
        let offerInfo = {
            'ID' : choosenOfferData.ID,
            'RENT_NO' : choosenOfferData.RENT_NO,
            'OFFER_DATE' : choosenOfferData.OFFER_DATE,
            'OFFER_TIME_LIMIT' : choosenOfferData.OFFER_TIME_LIMIT,
            'WINTER_TIRE_COND' : choosenOfferData.WINTER_TIRE_COND == "VAR" ? true : false,
            'SIGNED_EMAIL' : choosenOfferData.SIGNED_EMAIL,
        }
        return offerInfo;
    }

    const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);

    const handleExpanded = (rowData, dataKey) => {
      let open = false;
      const nextExpandedRowKeys = [];
  
      expandedRowKeys.forEach(key => {
        if (key === rowData["ID"]) {
          open = true;
        } else {
          nextExpandedRowKeys.push(key);
        }
      });
  
      if (!open) {
        nextExpandedRowKeys.push(rowData["ID"]);
      }
  
      setExpandedRowKeys(nextExpandedRowKeys);
    };

    const renderRowExpanded = rowData => {
        console.log(rowData);
        return (
          <ScrollView>
            <List>
                {getChoosenCars(rowData["ID"]).map((item) => {
                    return (
                        <List.Item>
                            <CarLabel car={item}></CarLabel>
                        </List.Item>
                    );
                }
                )}
            </List>
          </ScrollView>
        );
    };


    return (
        <View style={{padding: 20}}>
            <Drawer open={offerDrawer} onClose={handleClose} size='full' backdrop= 'static'>
                <Drawer.Header>
                    <Drawer.Title>Yeni Teklif Dökümanı Oluştur</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body style={{ backgroundColor: '#F2F2F2'}}>
                    <RentContractTemplate cars = {getParsedCars()} customer = {getCustomer()} offerInfo = {getOfferInfo()}  safetyPacks= {selectedSafetyPacks} ></RentContractTemplate>
                </Drawer.Body>
            </Drawer>
            <Panel shaded style={{backgroundColor: 'white', marginBottom :20}}>
                <IconButton  icon = {<Icon style={{backgroundColor: '#5E6EED'}} ><FontAwesomeIcon icon={solid("plus")}></FontAwesomeIcon></Icon>} style={{backgroundColor: '#5E6EED', color: 'white'}} onClick={newOpen}> 
                <CustomText style={{color:'white'}}>{"Yeni Teklif Dökümanı Oluştur"}</CustomText> </IconButton>
            </Panel>
            <Panel shaded style={{backgroundColor: 'white'}}>
                <Table
                    height={1000}
                    data = {offerData}
                    bordered
                    fluid
                    rowKey={"ID"}
                    onRowClick={data => {
                        console.log(data);
                      }}
                    rowExpandedHeight={500}
                    renderRowExpanded={renderRowExpanded}
                    expandedRowKeys={expandedRowKeys}
                >
                    <Table.Column width={70} align="center">
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <ExpandCell dataKey="ID" expandedRowKeys={expandedRowKeys} onChange={handleExpanded} />
                    </Table.Column>
                    <Table.Column flexGrow={1} align="center" fixed>
                        <Table.HeaderCell>İşlem</Table.HeaderCell>
                        <Table.Cell>
                            {rowData => {
                                function handleAction(){
                                    setChoosenCarDetails(getChoosenCars(rowData.ID));
                                    setSelectedSafetyPacks(getChoosenSafetyPacks(rowData.ID));
                                    console.log(rowData);
                                    setChoosenOfferData(rowData);
                                }
                                return (
                                    <span>
                                        <IconButton icon={<Icon><FontAwesomeIcon icon={solid("edit")}></FontAwesomeIcon></Icon>} onClick={handleAction} />
                                    </span>
                                );
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Teklif No </Table.HeaderCell>
                            <Table.Cell dataKey="RENT_NO" />
                    </Table.Column>
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Şirket </Table.HeaderCell>
                            <Table.Cell dataKey="COMPANY" />
                    </Table.Column>                    
                    <Table.Column  flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Yetkili Kişi </Table.HeaderCell>
                            <Table.Cell dataKey="CLIENT" />
                    </Table.Column>
                    <Table.Column flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Şehir </Table.HeaderCell>
                            <Table.Cell dataKey="CITY" />
                    </Table.Column>
                    <Table.Column flexGrow={2}  align="center" fixed>
                            <Table.HeaderCell> Sözleşme Tarihi </Table.HeaderCell>
                            <Table.Cell dataKey="OFFER_DATE" />
                    </Table.Column>
                    <Table.Column flexGrow={1}  align="center" fixed>
                            <Table.HeaderCell> Teklif Geçerlilik Süresi </Table.HeaderCell>
                            <Table.Cell dataKey="OFFER_TIME_LIMIT" />
                    </Table.Column>
                    <Table.Column flexGrow={1}  align="center" fixed>
                            <Table.HeaderCell> Kış Lastiği </Table.HeaderCell>
                            <Table.Cell dataKey="WINTER_TIRE_COND" />
                    </Table.Column>
                </Table>
            </Panel>

        </View>
    );

}



export default RentContractOffers;


const CarLabel = (props) => {

    return (
        <View style={{flexDirection: 'row', height: 120, alignItems: 'center', padding: 3 }}>
            <Image {...props.car} style={{height: 50, width: 200}} resizeMode='contain' source={images[props.car.IMAGE]} />
            <View style ={{flex: 1 }}>
                <Text  style={styles.label} > {props.car.BRAND + " " + props.car.MODEL + " " + props.car.PACKAGE+ " " + props.car.CAR_BODY_STYLE} </Text>
                <Text  style={styles.label} > {props.car.VITES_YAKIT} </Text>
                <Text  style={styles.label} > {props.car.MODEL_YILI} </Text>
                <Text  style={styles.label} > {props.car.KM} </Text>
            </View>
            <View style ={{flex: 1 }}>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Kiralama Süresi : </Text>
                    <Text  style={styles.label} >  {props.car.RENT_TIME + " AY"} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Aylık Km Limiti : </Text>
                    <Text style={styles.label} >  {props.car.KM_LIMIT} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start', borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Km Aşım Fiyatı : </Text>
                    <Text style={styles.label} >  {props.car.KM_LIMIT_PRICE} </Text>
                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-start',borderBottomWidth: 0.5 , borderBottomColor: 'powderblue'}}>
                    <Text style={styles.label} > Aylık Kira Bedeli : </Text>
                    <Text style={styles.label} >  {props.car.RENT_PRICE} </Text>
                </View>
            </View>
          
        </View>
    );
}

const styles = StyleSheet.create({
    label: {
        textAlign: 'left',
        padding: 5,
    },
    labelBold: {
        textAlign: 'left',
        padding: 5,
        fontWeight: 'bold',
    },
    labelHeader:{
        textAlign: 'right',
        padding: 5,
    },
    labelWhite: {
        textAlign: 'center',
        padding: 5,
        color: 'white',
    },
    standard: {
        width: 160,
    },
    dropDownGroupBack : {
        borderRadius: 12,flexDirection: 'row',height: 40, paddingHorizontal : 0, justifyContent: 'center', alignItems: 'center',backgroundColor:'#F2FFFB'}

});


const ExpandCell = ({ rowData, dataKey, expandedRowKeys, onChange, ...props }) => (
    <Table.Cell {...props} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => {
          onChange(rowData);
        }}
        icon={
          expandedRowKeys.some(key => key === rowData[dataKey]) ? (
            <Icon><FontAwesomeIcon icon={solid('arrow-down')}></FontAwesomeIcon></Icon>

          ) : (
            <Icon><FontAwesomeIcon icon={solid('arrow-right')}></FontAwesomeIcon></Icon>
          )
        }
      />
    </Table.Cell>
);


const images = {
    'FIAT_EGEA_SEDAN_EASY': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_EASY.PNG'),
    'FIAT_EGEA_SEDAN_URBAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_URBAN.PNG'),
    'FIAT_EGEA_SEDAN_LOUNGE': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN_LOUNGE.PNG'),
    'ALFA ROMEO_TONALE': require('../../../assets/CarStockImages/ALFA ROMEO_TONALE.PNG'),
    'AUDI_A3': require('../../../assets/CarStockImages/AUDI_A3.PNG'),
    'AUDI_A4': require('../../../assets/CarStockImages/AUDI_A4.PNG'),
    'AUDI_A6': require('../../../assets/CarStockImages/AUDI_A6.PNG'),
    'CITROEN_C3_AIRCROSS': require('../../../assets/CarStockImages/CITROEN_C3_AIRCROSS.PNG'),
    'CITROEN_C3': require('../../../assets/CarStockImages/CITROEN_C3.PNG'),
    'DACIA_DUSTER': require('../../../assets/CarStockImages/DACIA_DUSTER.PNG'),
    'FIAT_DOBLO': require('../../../assets/CarStockImages/FIAT_DOBLO.PNG'),
    'FIAT_EGEA_CROSS_WAGON': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS_WAGON.PNG'),
    'FIAT_EGEA_CROSS': require('../../../assets/CarStockImages/FIAT_EGEA_CROSS.PNG'),
    'FIAT_EGEA_SEDAN': require('../../../assets/CarStockImages/FIAT_EGEA_SEDAN.PNG'),
    'FIAT_EGEA_HATCHBACK': require('../../../assets/CarStockImages/FIAT_EGEA_HATCHBACK.PNG'),
    'FIAT_FIORINO': require('../../../assets/CarStockImages/FIAT_FIORINO.PNG'),
    'FORD_COURIER': require('../../../assets/CarStockImages/FORD_COURIER.PNG'),
    'FORD_RANGER': require('../../../assets/CarStockImages/FORD_RANGER.PNG'),
    'HYUNDAI_BAYON': require('../../../assets/CarStockImages/HYUNDAI_BAYON.PNG'),
    'HYUNDAI_i20': require('../../../assets/CarStockImages/HYUNDAI_i 20.PNG'),
    'JEEP_COMPASS': require('../../../assets/CarStockImages/JEEP_COMPASS.PNG'),
    'JEEP_RENEGADE': require('../../../assets/CarStockImages/JEEP_RENEGADE.PNG'),
    'JEEP_AVENGER': require('../../../assets/CarStockImages/JEEP_AVENGER.PNG'),
    'MERCEDES_VITO': require('../../../assets/CarStockImages/MERCEDES_VITO.PNG'),
    'MITSIBUSHI_L200': require('../../../assets/CarStockImages/MITSIBUSHI_L200.PNG'),
    'PEUGEOT_2008': require('../../../assets/CarStockImages/PEUGEOT_2008.PNG'),
    'PEUGEOT_208': require('../../../assets/CarStockImages/PEUGEOT_208.PNG'),
    'PEUGEOT_408': require('../../../assets/CarStockImages/PEUGEOT_408.PNG'),
    'PEUGEOT_3008': require('../../../assets/CarStockImages/PEUGEOT_3008.PNG'),
    'PEUGEOT_TRAVALLER': require('../../../assets/CarStockImages/PEUGEOT_TRAVALLER.PNG'),
    'RENAULT_CLIO': require('../../../assets/CarStockImages/RENAULT_CLIO.PNG'),
    'RENAULT_CAPTUR': require('../../../assets/CarStockImages/RENAULT_CAPTUR.PNG'),
    'RENAULT_TALIANT': require('../../../assets/CarStockImages/RENAULT_TALIANT.PNG'),
    'RENAULT_MEGANE': require('../../../assets/CarStockImages/RENAULT_MEGANE.PNG'),
    'RENAULT_ZOE': require('../../../assets/CarStockImages/RENAULT_ZOE.PNG'),
    'TOYOTA_COROLLA': require('../../../assets/CarStockImages/TOYOTA_COROLLA.PNG'),
    'TOYOTA_HILUX': require('../../../assets/CarStockImages/TOYOTA_HILUX.PNG'),
    'SKODA_OCTAVIA': require('../../../assets/CarStockImages/SKODA_OCTAVIA.PNG'),
    'SKODA_SUPERB': require('../../../assets/CarStockImages/SKODA_SUPERB.PNG'),
    'VOLKSWAGEN_POLO': require('../../../assets/CarStockImages/VOLKSWAGEN_POLO.PNG'),
    'VOLKSWAGEN_PASSAT': require('../../../assets/CarStockImages/VOLKSWAGEN_PASSAT.PNG'),
    'VOLKSWAGEN_CARAVELLE': require('../../../assets/CarStockImages/VOLKSWAGEN_CARAVELLE.PNG'),
    '' : require('../../../assets/CarStockImages/placeholderCar.png'),
};
        