import { Text, View ,ScrollView, StyleSheet} from 'react-native'
import React, { useState ,useEffect , useRef } from 'react';
import { useToaster,Message,Button,Table,Column,Input, Container,IconButton, Modal, SelectPicker ,Loader,List, MaskedInput, CheckPicker} from 'rsuite';
import { useDropdownData } from '../../hooks/useDropdownData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import { DataAuthorize, Icon, MiniProgram } from '@rsuite/icons';
const CreateExcelTemplate = () => {


    const [choosenOptions, setChoosenOptions] = useState([]);

    const options = [
        {label: 'KASKO BİLGİLERİ', value: 'KASKO_INFO'},
        {label: 'TRAFİK BİLGİLERİ', value: 'TRAFFIC_INFO'},
        {label: 'SATIN ALMA BİLGİLERİ', value: 'BUY_INFO'},
    ];

    const [arrayForMarka, setArrayForMarka] = useState([]);
    const [arrayForModel, setArrayForModel] = useState([]);
    const [arrayForPaket, setArrayForPaket] = useState([]);
    const [arrayForCC, setArrayForCC] = useState([]);
    const [arrayForRenk, setArrayForRenk] = useState([]);
    const [arrayForYakit, setArrayForYakit] = useState([]);
    const [arrayForVites, setArrayForVites] = useState([]);
    const [arrayForKasa, setArrayForKasa] = useState([]);
    const [arrayForKmPeriyodu, setArrayForKmPeriyodu] = useState([]);
    const [arrayForAracDurumu, setArrayForAracDurumu] = useState([]);
    const [arrayForModelYili, setArrayForModelYili] = useState([]);
    const [arrayForCarOwners, setArrayCarOwners] = useState([]);
    const [arrayForBuydeps, setArrayBuydeps] = useState([]);
    const [arrayForCarBuyTypes, setArrayCarBuyTypes] = useState([]);
    const [arrayForBuyTypes, setArrayBuyTypes] = useState([]);
    const [arrayForInsurenceFirms,setInsurenceFirmsAll] = useState([]);
    const [arrayForAgentFirms,setAgentFirmsAll] = useState([]);
    const [arrayForClients,setClients] = useState([]);
    const [arrayForExtraDonanim, setArrayForExtraDonanim] = useState([]);
    const {getBrands, getDropDownValues, getDropDownValuesGlobal, getDropDownValuesService, getParseAraçDurum
        ,DurumData,taahhütMonthsData,taahhütLogicData,taahhütKmData,kaskoTipiData,alisKanallari,getClients, kdvOranları, otvOranları
    } = useDropdownData();

    useEffect(() => {  
        getDropDownValues("BRANDS",setArrayForMarka);
        getDropDownValues("MODELS",setArrayForModel);
        getDropDownValues("PACKAGES",setArrayForPaket);
        getDropDownValues("CAR_EXTRAS",setArrayForExtraDonanim);
        getDropDownValues("CAR_BODY_STYLES",setArrayForKasa);
        getDropDownValues("SERVICE_KM_PERIOD",setArrayForKmPeriyodu);
        getDropDownValues("TRANSMISSIONS",setArrayForVites);
        getDropDownValues("FUEL_TYPES",setArrayForYakit);
        getDropDownValues("CC_TYPES",setArrayForCC);
        getDropDownValuesGlobal("COLOR",setArrayForRenk);
        getDropDownValuesService("GET_LAST_50_YEARS",setArrayForModelYili);
        getDropDownValuesGlobal("CAR_OWNER",setArrayCarOwners);
        getDropDownValuesGlobal("BUY_DEPARTMENT",setArrayBuydeps);
        getDropDownValuesGlobal("CAR_BUY_TYPE",setArrayCarBuyTypes);
        getDropDownValuesGlobal("BUY_TYPE",setArrayBuyTypes);
        getDropDownValuesGlobal("INSURENCE_FIRM",setInsurenceFirmsAll);
        getDropDownValuesGlobal("AGENT_FIRM",setAgentFirmsAll);
        getClients(setClients);
        getParseAraçDurum(setArrayForAracDurumu);
    }, []);

    useEffect(() => {
        console.log("array for extras",arrayForExtraDonanim);
    }, [arrayForExtraDonanim]);


    function toExcelColumnName(num) {
        let columnName = '';
        while (num >= 0) {
          let remainder = num % 26;
          columnName = String.fromCharCode(65 + remainder) + columnName;
          num = Math.floor(num / 26) - 1;
        }
        return columnName;
      }


    async function createTempExcel(){
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
    
        let headers = ['PLAKA', 'RUHSAT SAHİBİ', 'ŞASİ', 'ARAÇ DURUM', 'ARAÇ ALIŞ DEPARTMANI' ,'ARAÇ EDİNİM ŞEKLİ'  ,'MOTORNO', 'MODEL YILI', 
        'TESCİL TARİHİ', 'TESCİL BELGE NO', 'MUAYENE BİTİŞ TARİHİ', 'MARKA', 'MODEL','PAKET','EKSTRA DONANIM','KASA','YAKIT TİPİ', 'CC', 'CC SINIFI',  'VİTES', 'HP',
         'RENK', 'MARKA RENK', 'ARAÇ KM', 'BAKIM KM PERİYODU', 'MARKA KODU' , 'TİP KODU', "TAAHHUT DURUMU","TAAHHUT AYI","VE/VEYA","TAAHHUT KM", "TAAHHUT NOTU"];

        let dropdownColumns = [ 'RUHSAT SAHİBİ' , 'ARAÇ DURUM' , 'ARAÇ ALIŞ DEPARTMANI' ,'ARAÇ EDİNİM ŞEKLİ' ,'MODEL YILI', 'MARKA', 'MODEL', 'PAKET', 'EKSTRA DONANIM',
        'KASA','YAKIT TİPİ','CC', 'VİTES', 'BAKIM KM PERİYODU',"RENK", "TAAHHUT DURUMU","TAAHHUT AYI","VE/VEYA","TAAHHUT KM"];
     
        // Data for each dropdown column, assumed to be an array of arrays
        let dropdownData = [ arrayForCarOwners, arrayForAracDurumu, arrayForBuydeps, arrayForCarBuyTypes ,arrayForModelYili, arrayForMarka, arrayForModel, arrayForPaket, arrayForExtraDonanim, arrayForKasa, 
            arrayForYakit,arrayForCC ,arrayForVites, arrayForKmPeriyodu,arrayForRenk,DurumData,taahhütMonthsData,taahhütLogicData,taahhütKmData];   

        let formatedColumns = []

        let KaskoHeaders = ["KASKO TİPİ" , "KASKO POLİÇE NUMARASI" ,"KASKO BAŞLANGIÇ TARİHİ", "KASKO BİTİŞ TARİHİ" , "KASKO TUTAR" , "KASKO FİRMASI" , "KASKO ACENTE" , "KASKO NOT"];
        let KaskoDropdownColumns = ["KASKO TİPİ", "KASKO FİRMASI" , "KASKO ACENTE"];
        let KaskoDropdownData = [kaskoTipiData,arrayForInsurenceFirms,arrayForAgentFirms];
        let KaskoFormatedColumns = ["KASKO TUTAR"];

        let TrafficInsurenceHeaders = ["TRAFİK SİGORTA DURUMU" ,"TRAFİK SİGORTA POLİÇE NUMARASI" , "TRAFİK SİGORTA BAŞLANGIÇ TARİHİ" , "TRAFİK SİGORTA BİTİŞ TARİHİ" , "TRAFİK SİGORTA TUTAR" , "TRAFİK SİGORTA FİRMASI" , "TRAFİK SİGORTA ACENTE" , "TRAFİK SİGORTA NOT"];
        let TrafficInsurenceDropdownColumns = ["TRAFİK SİGORTA DURUMU","TRAFİK SİGORTA FİRMASI" , "TRAFİK SİGORTA ACENTE"];
        let TrafficInsurenceDropdownData = [DurumData,arrayForInsurenceFirms,arrayForAgentFirms];
        let TrafficInsurenceFormatedColumns = ["TRAFİK SİGORTA TUTAR"];

        let BuyInfoHeaders = [ "ALIŞ BİLGİSİ DURUMU" ,"SATIN ALIM ŞEKLİ", "SATIN ALIM KANALI", "ALIŞ TARİHİ", "ÖTV KDV HARİÇ TUTAR", "TEDARİKÇİ", "KDV", "ÖTV","ÖTV TUTARI", "DİĞER FATURALI KDVSİZ TUTAR", "DİGER KDV", "DİĞER FATURASIZ TUTAR"];
        let BuyInfoDropdownColumns = ["ALIŞ BİLGİSİ DURUMU","TEDARİKÇİ","SATIN ALIM ŞEKLİ", "SATIN ALIM KANALI","KDV","ÖTV","DİGER KDV"];
        let BuyInfoDropdownData = [DurumData,arrayForClients,arrayForBuyTypes,alisKanallari,kdvOranları,otvOranları,kdvOranları];
        let BuyInfoFormatedColumns = ["ÖTV KDV HARİÇ TUTAR" , "ÖTV TUTARI", "DİĞER FATURALI KDVSİZ TUTAR", "DİĞER FATURASIZ TUTAR"];
        // let BuyInfoDropdownColumns = ["ALIŞ BİLGİSİ DURUMU","TEDARİKÇİ", "SATIN ALIM KANALI"];
        // let BuyInfoDropdownData = [DurumData,arrayForClients,alisKanallari];

        console.log("Before:", headers, dropdownColumns, dropdownData);

        if(choosenOptions.includes("KASKO_INFO")){
            console.log("Adding KASKO_INFO");
            headers.push(...KaskoHeaders);
            dropdownColumns.push(...KaskoDropdownColumns);
            dropdownData.push(...KaskoDropdownData);
            formatedColumns.push(...KaskoFormatedColumns);
        }
        
        if(choosenOptions.includes("TRAFFIC_INFO")){
            console.log("Adding TRAFFIC_INFO");
            headers.push(...TrafficInsurenceHeaders);
            dropdownColumns.push(...TrafficInsurenceDropdownColumns);
            dropdownData.push(...TrafficInsurenceDropdownData);
            formatedColumns.push(...TrafficInsurenceFormatedColumns);
        }
        
        if(choosenOptions.includes("BUY_INFO")){
            console.log("Adding BUY_INFO");
            headers.push(...BuyInfoHeaders);
            dropdownColumns.push(...BuyInfoDropdownColumns);
            dropdownData.push(...BuyInfoDropdownData);
            formatedColumns.push(...BuyInfoFormatedColumns);
        }
        
        console.log("After:", headers, dropdownColumns, dropdownData);

        let workbook = new ExcelJS.Workbook();
        let worksheet = workbook.addWorksheet('Sheet 1');
        let hiddenSheet = workbook.addWorksheet('Hidden');
        hiddenSheet.state = 'veryHidden'; // Make the sheet hidden
    
        // Add headers to the worksheet
        worksheet.addRow(headers);
        let headerRow = worksheet.getRow(1); // get the first row
        headerRow.eachCell((cell) => {
            cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'000000' },
            
              };
            cell.font = {
                bold: true,
                color: { argb: "FFFFFF" },
            };  
          
        });

        headers.forEach((header, index) => {

          worksheet.getColumn(index + 1).width = header.length + 5;
        });
        
        // Create data validation
        for(let i = 0; i < dropdownColumns.length; i++) {
            let column = dropdownColumns[i];
            let data = [];
            dropdownData[i].forEach((element,index) => {
              data.push(element["label"])
            });
            let columnIndex = headers.indexOf(column) + 1;
    
            // Add data to hidden sheet
            data.forEach((value, index) => {
              hiddenSheet.getCell(index + 1, i + 1).value = value;
            });
            
            // Set data validation in the main sheet to reference the hidden sheet
            let excelColumn = toExcelColumnName(i);
            let dataRange = "'Hidden'!$" + excelColumn + "$1:$" + excelColumn + "$" + data.length;
            
            console.log(dataRange);
            worksheet.getCell(1,columnIndex)// Adjust the range and sheet name as needed
            for(let row = 2; row <= 100; row++) {  // Adjust range as needed
              worksheet.getCell(row, columnIndex).dataValidation = {
                type: 'list',
                allowBlank: true,
                formulae: [dataRange],
                showDropDown: true,
              };
            }
        }

        //formatting to currency
        for(let f = 0; f < formatedColumns.length; f++) {
            let column = formatedColumns[f];
            let columnIndex = headers.indexOf(column) + 1; // 1-based index
            worksheet.getColumn(columnIndex).numFmt = '"₺ "#,##0.00';
        }

    
        // Save the workbook to a file
        let buffer = await workbook.xlsx.writeBuffer();
        let blob = new Blob([buffer], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
        FileSaver.saveAs(blob, 'ÇokluAraçŞablon.xlsx');
    }

    return (
      <View style={{flexDirection: 'row', borderWidth: 1 , padding: 10 , borderStyle: 'dashed', borderColor : 'powderblue', borderRadius: 12}}>
         <IconButton  Button onClick={createTempExcel} icon={<Icon><FontAwesomeIcon icon = {solid("file-excel")}></FontAwesomeIcon></Icon>}  > Excel Şablonu İndir </IconButton>
         <View style={{width: 12}}></View>
         <CheckPicker style={{width: 150}} data={options} onChange={setChoosenOptions} value={choosenOptions} placeholder={"Ekstra Girilecek Alanlar"} />
      </View>
    )
  }

export default CreateExcelTemplate